const Dmic = () => {
	return (
		<div>
			<section>
				<div className="charity-content">
					<img src={require("./../../assets/img/banner-5.png")} />
				</div>
			</section>

			<section className="bg-orange bgimg-orange sell-orange">
				<div className="container-fluid">
					<div className="row align-items-center">
						<div className="offset-xl-1"></div>
						<div className="col-xl-5 col-md-6 col-sm-9 lpadding rpadding sell-order1">
							<div className="content-left-img2">
								<div className="charity-content-outer pd30">
									<div className="about-fan-content charity-content-inner">
										<h2 className="text-white mb20 justify">
											To say we <span>LOVE</span> music and musicians is a
											considerable understatement. That is why part of our
											company DNA is to foster music education and advancement
											throughout the world.
										</h2>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-5 col-md-6 col-sm-3 text-center sell-order2">
							<img
								className="img-responsive"
								src={require("./../../assets/img/listen-love.png")}
							/>
							<h3 className="din-font text-uppercase mt20">LISTEN.LOVE.HELP</h3>
						</div>
					</div>
				</div>
			</section>

			<section className="pd20 charity-last">
				<div className="container">
					<div className="row">
						<div className="offset-xl-7 offset-lg-4"></div>
						<div className="col-xl-5 col-lg-8">
							<div className="charity-last-content text-center mt90 pt20">
								<h2 className="text-white justify">
									It has been scientifically proven that music education opens
									minds, lifts spirits, as well as makes kids smarter. We
									passionately agree with these studies, which is why we at
									DalbumX pledge to set aside a percentage of our net profits to
									donate to a worthy music-related charity. We are working on
									finding the right partner (or partners) now. We'll announce
									here once we do.
								</h2>
							</div>
						</div>
						<div className="col-md-12 text-center">
							<h1 className="text-white text-uppercase din-font mt50 mb0">
								Let’s spread the MUSIC. Let’s spread the LOVE.
							</h1>
						</div>
					</div>
					<div className="row mb50 mt20">
						<div className="offset-xl-8"></div>
						<div className="col-xl-4 dmic-socil-space">
							<div className="row text-center">
								<div className="col-md-6 col-6">
									<a href="">
										<img src={require("./../../assets/img/app-store.png")} />
									</a>
								</div>
								<div className="col-md-6 col-6">
									<a href="">
										<img src={require("./../../assets/img/google-play.png")} />
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Dmic;
