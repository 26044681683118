import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useState,
} from "react";
import axios from "axios";
import Loader from "../../../shared/loader/loader";
import { confirmAlert } from "react-confirm-alert";
import { showNotification } from "../../../shared/showNotification";
import ImageCropperModal from "../../ImageCropperModal";
import useArtwork from "../../useArtwork";
import { errorModal } from "../../../shared/errorModal";

const Step3AddAlbumComponent = forwardRef(({ changestep, updateSize }, ref) => {
	const [state, setState] = useState({
		stepdata: [],
		loader: false,
		activetab: 2,
		is_booklet: 1,
		embedded_material : {}
	});

	const [isDynamic, setIsDynamic] = useState(0);
	const [artworksChanged, setArtworksChanged] = useState(false);

	const path = axios.defaults.baseURL + "uploads/";

	useEffect(() => {
		getstepdata(true);
		getEmbeddedMaterials();
	}, []);

	useEffect(() => { }, [isDynamic]);

	useEffect(() => {
		if (artworksChanged) { 
			setArtworksChanged(false);
		}
	}, [artworksChanged]);

	useImperativeHandle(ref, () => ({
		save: save,
	}));

	const back = () => {
		changestep(2);
	};

	const { hasAnimatedFrontCover, hasFrontCoverStill } = useArtwork(state.stepdata, 250, artworksChanged);

	const [showImageCropperModal, setShowImageCropperModal] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState(null);
	const [selectedImage, setSelectedImage] = useState(null);

	const cropImage = (index) => {
		setSelectedIndex(index);
		setSelectedImage(path + state.stepdata[index].name)
		setShowImageCropperModal(true);
	};

	const cropImageCallback = async (image) => {
		await uploadCroppedArtwork(image, state.stepdata[selectedIndex].name);
		setArtworksChanged(true);
		setShowImageCropperModal(false);
		setSelectedImage(null);
		setSelectedIndex(null);
	};

	const cropImageCancelCallback = (image) => {
		setShowImageCropperModal(false);
		setSelectedImage(null);
		setSelectedIndex(null);
	};

	const getCroppedImageName = (imageName) => {
		let parts = imageName.split('.');
		let name = parts[0];
		let extension = parts[1];
		return name + '-cropped.' + extension;
	};

	const uploadCroppedArtwork = async (image, fileName) => {
		const config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};

		const formData = new FormData();
		formData.append("artwork", image, getCroppedImageName(fileName));
		formData.append('useOriginalFilename', true);

		try {
			const response = await axios.post("app/artwork/uploads", formData, config);
			if (response.data.success) { 
				// console.log("Cropped Artwork has been uploaded successfully.");
			}
		} catch (error) {
			showNotification(error.name, "warning");
		}
	}

	const getEmbeddedMaterials = () => {
		setState((oldState) => ({
			...oldState,
			loader: true,
		}));

		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};

		axios
			.get(
				"app/addalbum/step6?album_id=" + sessionStorage.getItem("album_id"),
				config
			)
			.then((response) => {
				const embedded_material = response.data.data.hidden_dimension;

				const emMap = embedded_material.reduce((acc, obj) => {
					if (obj.artwork_image_id in acc) {
					  acc[obj.artwork_image_id].push(obj);
					} else {
					  acc[obj.artwork_image_id] = [obj];
					}
					return acc;
				}, {});
				
				setState((oldState) => ({
					...oldState,
					embedded_material: emMap,
					loader: false,
				}));
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const getEmbeddedMaterialForArtwork = (elem) => {
		return state.embedded_material[elem.artwork_image_id] || [];
	}

	const artworkHasEmbeddedMaterial = (elem) => {
		return getEmbeddedMaterialForArtwork(elem)?.length !== 0;
	}

	const changetab = (tab, flag) => {
		setState((oldState) => ({
			...oldState,
			is_booklet: flag == 1 ? 1 : 0,
			activetab: tab,
		}));
	};

	const sortintoCatagory = (data) => {
		var sorted = data.sort(function (a, b) {
			return a.at_position - b.at_position;
		});
		setState((oldState) => ({
			...oldState,
			stepdata: sorted,
		}));
	};

	const deleteArtwork = async (id) => {
		if (artworkHasEmbeddedMaterial(id)) {
			confirmArtworkDeletion(async () => {
				const dimensions = await getEmbeddedMaterialForArtwork(id);

				await Promise.all(dimensions.map(async (hidden_dimension, index) => {
					await deleteEmbeddedMaterial(hidden_dimension);
				}));

				await _delete(id);
			});
		} else {
			await _delete(id);
		}
	}

	const deleteEmbeddedMaterial = async (hidden_dimension) => {
		setState((oldState) => ({
			...oldState,
			loader: true,
		}));

		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};

		await axios
			.post(
				"app/addalbum/step6delete",
				hidden_dimension,
				config
			)
			.then(function (response) {
				setState((oldState) => ({
					...oldState,
					loader: false,
				}));
			})
			.catch(function (error) {
				setState((oldState) => ({
					...oldState,
					loader: false,
				}));
			});
	};

	const _delete = async (id) => {
		setState((oldState) => ({
			...oldState,
			loader: true,
		}));

		var index = state.stepdata.findIndex(
			(item) => item.artwork_image_id === id.artwork_image_id
		);
		var filter = state.stepdata;

		filter.splice(index, 1);
		filter.map((elem, index) => {
			elem.at_position = index;
		});

		const cover = filter.find(obj => obj.at_position === 0);
		if (cover && cover.name.endsWith('.gif')) {
			setIsDynamic(1);
		} else { 
			setIsDynamic(0);
		}

		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};

		await axios
			.post(
				"app/addalbum/step3delete",
				{ id: id, data: filter, album_id: sessionStorage.getItem("album_id") },
				config
			)
			.then(async function (response) {
				await getstepdata(false);
				updateSize();
			})
			.catch(function (error) {
				setState((oldState) => ({
					...oldState,
					loader: false,
				}));
			});
	};

	const getstepdata = (flag) => {
		setState((oldState) => ({
			...oldState,
			loader: true,
		}));

		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};

		axios
			.get(
				"app/addalbum/step3?album_id=" + sessionStorage.getItem("album_id"),
				config
			)
			.then(function (response) {
				// Lalo: 04/22/2021 - Dynamic GIF ----
				//                    The following code must be implemented when a dynamic GIF is found
				//                      setState({
				//                              hasDynamic:1
				//                          })
				setState((oldState) => ({
					...oldState,
					loader: false
				}));
				if (flag) {
					setIsDynamic(response.data.data.album.has_dynamic_cover);
				}
				setArtworksChanged(true);
				sortintoCatagory(response.data.data.data);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const save = () => {
		submit();
	};

	const validate = async () => {
		if (hasAnimatedFrontCover && !hasFrontCoverStill) { 
			gifWarning();
			return;
		}

		let temp = await submit();
		if (temp === false) {
			return false;
		}
		changestep(4);
	};

	const submit = () => {
		setState((oldState) => ({
			...oldState,
			loader: true,
		}));

		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};
		if (!state.stepdata.length > 0) {
			errorModal("Album Must Have Artwork")
			setState((oldState) => ({
				...oldState,
				loader: false,
			}));
			return false;
		}

		axios
			.post(
				"app/addalbum/step3submit",
				{
					data: state.stepdata,
					is_booklet: state.is_booklet,
					hasDynamic: isDynamic,
				},
				config
			)
			.then(function (response) {
				setState((oldState) => ({
					...oldState,
					loader: false,
				}));
				getstepdata(true);
				updateSize();
			})
			.catch(function (error) {
				setState((oldState) => ({
					...oldState,
					loader: false,
				}));
			});
	};

	const drop = (event, index) => {
		event.preventDefault();
		var id = event.dataTransfer.getData("id");
		if (!id) {
			return false;
		}
		var data = state.stepdata;
		var temp = data[id].at_position;
		data[id].at_position = data[index].at_position;
		data[index].at_position = temp;

		const cover = data.find(obj => obj.at_position === 0);
		if (cover.name.endsWith('.gif')) {
			setIsDynamic(1);
		} else { 
			setIsDynamic(0);
		}

		sortintoCatagory(data);
		setArtworksChanged(true);
	};

	const drag = (event) => {
		event.dataTransfer.setData("id", event.target.id);
	};
	const allowdrop = (event) => {
		event.preventDefault();
	};

	const addStatic = (flag) => {
		if (flag) {
			setIsDynamic(1);
		} else {
			setIsDynamic(0);
		}
	};

	// const upload = (event, name) => {
	// 	const files = Array.from(event.target.files);
	// 	console.log("files",files)

	// 	var config = {
	// 		headers: {
	// 			Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
	// 		},
	// 		onUploadProgress: function (progressEvent) {
	// 			var uploadPercentage = parseInt(
	// 				Math.round((progressEvent.loaded * 100) / progressEvent.total)
	// 			);
	// 		},
	// 	};

	// 	var formData = new FormData();
	// 	formData.append("album_id", sessionStorage.getItem("album_id"));

	// 	var at_position = null;

	// 	var result = state.stepdata.reduce(function (r, a) {
	// 		r[a.artwork_type] = r[a.artwork_type] || [];
	// 		r[a.artwork_type].push(a);
	// 		return r;
	// 	}, Object.create(null));

	// 	if (!result[name.toUpperCase()]) {
	// 		result[name.toUpperCase()] = [];
	// 	}

		

	// 	// if (name === "poster") {
			
	// 	// 	at_position = state.stepdata.length;
	// 	// 	for (var i = 0; i < event.target.files.length; i++) {
	// 	// 		formData.append(name, event.target.files[i]);
	// 	// 	}
	// 	// 	// }
	// 	// }
	// 	// if (name === "booklet") {
	// 	// 	at_position = state.stepdata.length;

	// 	// 	for (var i = 0; i < event.target.files.length; i++) {
	// 	// 		formData.append(name, event.target.files[i]);
	// 	// 	}
	// 	// }

	// 	if (name === "poster" || name === "booklet") {
	// 		at_position = state.stepdata.length;
	// 		// Append all files to FormData
	// 		for (var i = 0; i < event.target.files.length; i++) {
	// 			console.log(event.target.files[i])
	// 			// formData.append(name, event.target.files[i]);

	// 			// formData.append(name + "[]", event.target.files[i]);
	// 		}
	// 	}

	// 	formData.append("at_position", at_position);

	// 	console.log(formData);
	// 	console.log(JSON.stringify(config));

	// 	axios
	// 		.post("app/addalbum/step3", formData, config)
	// 		.then(function (response) {
	// 			console.log("response",response)
	// 			getstepdata();
	// 			updateSize();
	// 			console.log(JSON.stringify(response.data));
	// 		})
	// 		.catch(function (error) {
	// 			console.log(error);
	// 		});
	// };

	// const upload = (event, name) => {
	// 	const files = Array.from(event.target.files);
	// 	console.log("files",files)
	// 	// Loop through each uploaded file
	// 	files.forEach((file, index) => {
	// 		var config = {
	// 			headers: {
	// 				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
	// 			},
	// 			onUploadProgress: function (progressEvent) {
	// 				var uploadPercentage = parseInt(
	// 					Math.round((progressEvent.loaded * 100) / progressEvent.total)
	// 				);
	// 			},
	// 		};
	
	// 		var formData = new FormData();
	// 		formData.append("album_id", sessionStorage.getItem("album_id"));
	// 		formData.append(name, file); // Append the current file
	// 		var at_position = null;
	
	// 		var result = state.stepdata.reduce(function (r, a) {
	// 			r[a.artwork_type] = r[a.artwork_type] || [];
	// 			r[a.artwork_type].push(a);
	// 			return r;
	// 		}, Object.create(null));
	
	// 		if (!result[name.toUpperCase()]) {
	// 			result[name.toUpperCase()] = [];
	// 		}
	
	// 		if (name === "poster" || name === "booklet") {
	// 			at_position = state.stepdata.length;
	// 		}
	
	// 		formData.append("at_position", at_position);
	
	// 		axios
	// 			.post("app/addalbum/step3", formData, config)
	// 			.then(function (response) {
	// 				getstepdata();
	// 				updateSize();
	// 				console.log(JSON.stringify(response.data));
	// 			})
	// 			.catch(function (error) {
	// 				console.log(error);
	// 			});
	// 	});
	// };

	const upload = (event, name) => {
		const files = Array.from(event.target.files);
		// Define accepted file types
		const acceptedFileTypes = ["image/jpeg", "image/png", "image/gif"];
		files.forEach((file, index) => {
			if (!acceptedFileTypes.includes(file.type)) {
				errorModal("Please select a JPG, PNG, or GIF file")
				return;
			}
	
			var config = {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
				},
				onUploadProgress: function (progressEvent) {
					var uploadPercentage = parseInt(
						Math.round((progressEvent.loaded * 100) / progressEvent.total)
					);
				},
			};
	
			var formData = new FormData();
			formData.append("album_id", sessionStorage.getItem("album_id"));
			formData.append(name, file); // Append the current file
			var at_position = null;
	
			var result = state.stepdata.reduce(function (r, a) {
				r[a.artwork_type] = r[a.artwork_type] || [];
				r[a.artwork_type].push(a);
				return r;
			}, Object.create(null));
	
			if (!result[name.toUpperCase()]) {
				result[name.toUpperCase()] = [];
			}
	
			if (name === "poster" || name === "booklet") {
				at_position = state.stepdata.length + index;
			}
	
			formData.append("at_position", at_position);

			if (at_position === 0 && file.type === "image/gif") {
				setIsDynamic(1);
			}

			if (at_position === 0 && file.type !== "image/gif") {
				setIsDynamic(0);
			}
	
			axios
				.post("app/addalbum/step3", formData, config)
				.then(function (response) {
					getstepdata(false);
					updateSize();
				})
				.catch(function (error) {
					console.log(error);
				});
		});
	};
	

	
	
	const gifWarning = () => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="confirm-ui">
						<div className="delete-prompt-box">
							<h1
								className="text-uppercase mb10"
								style={{ fontFamily: "AvenirNext-UltraLight" }}
							>
								PLEASE NOTE:
							</h1>
							<div
								style={{
									borderTop: "1px solid #278C8e",
									marginLeft: 20,
									marginRight: 20,
									marginBottom: 20,
								}}
							></div>
							<h2 className="justify-content-center mb40">
								<strong>
									Animated Front Covers will only be seen on a device once it
									has been purchased by a fan. If you are using a GIF as your
									Dalbum front cover, you must submit a JPG or PNG to represent
									your Dalbum cover in the DalbumXchange. Thank you!
								</strong>
							</h2>
							<div className="d-flex mr-3 justify-content-center">
								<button
									type="button"
									onClick={() => {
										onClose();
										// removing this function call, as navigation intercepted already
										// cancelAndClose();
									}}
									className="btn-save"
								>
									Okay
								</button>
							</div>
						</div>
					</div>
				);
			},
		});
	};

	let bookletPage = 0;
	let posterPage = 0;

	const confirmArtworkDeletion = (_delete) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="confirm-ui">
						<div className="delete-prompt-box">
							<h2 className="text-uppercase mb10">
								This image contains Embedded Content. If you delete this image, you will also delete its related Embedded Content. Are you sure you want to delete the image and its Embedded Content?
							</h2>
							<div className="d-flex mr-3 justify-content-center">
								<button
									type="button"
									onClick={async () => {
										onClose();
										await _delete();
									}}
									className="btn-save text-uppercase"
								>
									Yes,delete !
								</button>
								<button
									type="button"
									onClick={() => {
										onClose();
									}}
									className="btn-cancel text-uppercase"
								>
									Cancel
								</button>
							</div>
						</div>
					</div>
				);
			},
		});
	};

	return (
		<>
			{showImageCropperModal && <ImageCropperModal
				image={selectedImage}
				onCropImage={cropImageCallback}
				onCropImageCancel={cropImageCancelCallback}
			/>}
			{!showImageCropperModal && <div>
				{state.loader && <Loader />}
				<div className="row mb30">
					<div className="col-md-12">
						
						<h3 className="text-justify">
							Click on 'ADD ARTWORK' to browse for images to upload. You can
							rearrange the order of the artwork by dragging and dropping your
							images in the order you want. Please double check the order of your
							artwork because once your Dalbum is published, this cannot be
							changed. Please know that you can upload gifs (moving images) for
							your artwork, too. If you are uploading an animated gif image for
							your cover (Dynamic Cover), you must also upload the 'still' cover
							image that will appear in the DalbumXchange. All Dalbums require at
							least a front cover. Please note that if your Dalbum contains
							graphic or hateful content, your Dalbum will be deleted, and there
							are no refunds for published Dalbums.
						</h3>
					</div>
				</div>

				
				<div className="row mb30 upload-wrapper">
					<div className="col-md-12">
						<div className="tab-content" id="nav-tabContent">
							<div className="row ">
								<div className="col-lg-9">
								
								</div>
							</div>
							{state.is_booklet == 0 && (
								<div
									className="tab-pane fade show active"
									id="POSTER"
									role="tabpanel"
									aria-labelledby="BOOKLET"
								>
									<div className="step-3-section">
										<div className="row mb10 mt10">
											<div className="col-md-6 order1">
												<div
													onDrop={(e) => {
														e.preventDefault();
													}}
													className="input-group cu"
												>
													<div className="btn-cancel uploadButton">
														ADD ARTWORK
														<input
															type="file"
															onChange={(e) => {
																upload(e, "poster");
															}}
															className="form-control uploadFile"
															aria-label="Text input with dropdown button"
															multiple
															accept="image/jpeg, image/jpg, image/png, image/gif"
														/>
													</div>
												</div>
											</div>
											<div className="col-md-6 tabStep4 order2">
												<div className="upload-content-detail pd10 bg-white clearfix uploadTract">
													<div className="album-specification">
														<div className="spacer20"></div>
														<h3 className="text-uppercase">Specs</h3>
														<h4>Formats accepted: JPG, PNG or GIF</h4>
														<div className="spacer20"></div>
														<h4>
															NOTE: If you have an animated GIF front cover, you
															must also provide a JPG or PNG still of the cover
															for the DalbumXchange
														</h4>
														<h4>Minimum Resolution: TBD</h4>
														<h4>Maximum File Size: TBD</h4>
														
													</div>
												</div>
											</div>
										</div>
										<div className="row mb10">
											{state.stepdata.map((elem, index) => {
												if (
													elem.artwork_type === "POSTER" ||
													elem.artwork_type === "BOOKLET"
												) {
													return (
														<div
															key={index}
															className="col-md-3"
															onDrop={(e) => {
																drop(e, index);
															}}
															onDragOver={allowdrop}
														>
															<div className="card text-center">
																{elem && (
																	<div
																		className="upload-image step3-upload"
																		style={{
																			// backgroundSize: 'auto',

																			backgroundImage:
																				`url(` + path + elem.name + `)`,
																		}}
																		id={index}
																		draggable="true"
																		onDragStart={drag}
																	></div>
																)}
																<div className="card-body">
																	<p className="card-text">
																		{index == 0
																			? "Poster Front"
																			: index == 1
																			? "Poster Back"
																			: parseInt(index) == 2 && isDynamic
																			? "Static Image for Gif"
																			: "Poster Page " + ++posterPage}
																	</p>
																</div>
																<a
																	onClick={async () => {
																		await deleteArtwork(elem);
																	}}
																>
																	<span className="close">
																		<i className="fa fa-times"></i>
																	</span>
																</a>
															</div>
														</div>
													);
												}
											})}
										</div>
									</div>
								</div>
							)}
							{state.is_booklet == 1 && (
								<div
									className="tab-pane fade show active"
									id="BOOKLET"
									role="tabpanel"
									aria-labelledby="BOOKLET"
								>
									<div className="step-3-section">
										<div className="row mb10 mt10">
											<div className="col-md-6 order1">
												<div
													onDrop={(e) => {
														e.preventDefault();
													}}
													className="input-group cu"
												>
													<div className="btn-cancel uploadButton">
														ADD ARTWORK
														<input
															type="file"
															onChange={(e) => {
																upload(e, "booklet");
															}}
															className="form-control uploadFile"
															aria-label="Text input with dropdown button"
															multiple
															accept="image/jpeg, image/jpg, image/png, image/gif"
														/>
													</div>
												</div>
											</div>
											<div className="col-md-6 tabStep4 order2">
												<div className="upload-content-detail pd10 bg-white clearfix uploadTract">
													<div className="album-specification">
														<h3 className="text-uppercase">Specs</h3>
														<h4>Formats accepted: JPG, PNG or GIF</h4>
														<div className="spacer20"></div>
														<h4>
															NOTE: If you have an animated GIF front cover, you
															must also provide a JPG or PNG still of the cover
															for the DalbumXchange
														</h4>
														<div className="spacer20"></div>
														<h4>Front Cover, Back Cover and Fron Cover Still (in case of animated Front Cover)
															need to be in 1:1 aspect ratio.
															These covers are restricted and by default would have center of the image uploaded there,
															but that can be edited by using <i className="fa fa-edit" style={{ fontSize: "1.2em" }}></i>
															icon. Other artworks can be of any aspect ratio.
														</h4>
														<div className="spacer20"></div>
														<h4>Minimum Resolution: TBD</h4>
														<h4>Maximum File Size: TBD</h4>
														<div className="spacer20"></div>
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											{state.stepdata.map((elem, index) => {
												let a = state.stepdata.filter(function (x) {
													return x.artwork_type == "BOOKLET";
												}).length;
												if (
													elem.artwork_type === "BOOKLET" ||
													elem.artwork_type === "POSTER"
												) {
													return (
														<div
															key={index}
															// className="col-md-3"
															style={{
																flex: "0 0 auto",
																paddingRight: "15px",
																paddingLeft: "15px",
																width:
																	state.stepdata[index].width ?? 250 + "px !important",
															}}
															onDrop={(e) => {
																drop(e, index);
															}}
															onDragOver={allowdrop}
														>
															<div className="card text-center">
																{elem && (
																	<div
																		className="upload-image step3-upload"
																		style={{
																			backgroundImage: `${state.stepdata[index].urls}`,
																			backgroundSize: `${state.stepdata[index].editable || state.stepdata[index].square ? "cover" : "100% 100%"}`,
																		}}
																		id={index}
																		draggable="true"
																		onDragStart={drag}
																	></div>
																)}
																<div className="card-body">
																	<div
																		className="card-text"
																		style={{
																			display: "flex",
																			justifyContent: "space-between",
																			alignItems: "center",
																		}}
																	>
																		<span
																			style={{ flex: "1", textAlign: "center" }}
																		>
																			{state.stepdata[index].label}
																		</span>
																		{state.stepdata[index].editable && (
																			<a
																				onClick={() => cropImage(index)}
																				style={{
																					display: "flex",
																					alignItems: "center",
																					padding: "0 10px",
																				}}
																			>
																				<i
																					className="fa fa-edit"
																					style={{ fontSize: "1.2em" }}
																				></i>
																			</a>
																		)}
																	</div>
																</div>
																<a
																	onClick={async () => {
																		await deleteArtwork(elem);
																	}}
																>
																	<span className="close">
																		<i className="fa fa-times"></i>
																	</span>
																</a>
															</div>
														</div>
													);
												}
											})}
										</div>
									</div>
									
								</div>
							)}
						</div>
					</div>
				</div>

				<div className="row form-group justify-content-center">
					<button
						type="button"
						className="btn-cancel text-uppercase"
						onClick={() => {
							back();
						}}
					>
						Back
					</button>
					<button
						type="button"
						className="btn-save text-uppercase"
						onClick={() => {
							validate();
						}}
					>
						Next
					</button>
				</div>
			</div>}
		</>
	);
});

export default Step3AddAlbumComponent;

// import React, { useState } from "react";
// import { nanoid } from "nanoid";

// const Step3AddAlbumComponent = () => {
// 	const [selectedFiles, setSelectedFiles] = useState([]);
// 	const [files, setFiles] = useState([]);
// 	const handleFileUploadSubmit = (e) => {
// 		e.preventDefault();

//         // form reset on submit 
//         e.target.reset();
//         if (selectedFiles.length > 0) {
//             for (let index = 0; index < selectedFiles.length; index++) {
//                 setFiles((preValue)=>{
//                     return[
//                         ...preValue,
//                         selectedFiles[index]
//                     ]   
//                 })
//             }
//             setSelectedFiles([]);
//         } else {
//             alert('Please select file')
//         }
// 	}
// 	const handleFileChange = (e) => {
// 		const files = Array.from(e.target.files);
// 		const selectedFilesDetails = files.map((file) => ({
//             id: nanoid(),
//             name: file.name,
//             size: file.size,
//             type: file.type,
//             file: file,
//         }));
// 		setSelectedFiles((prevFiles) => [...prevFiles, ...selectedFilesDetails]);
// 	}
	
// 	return (
// 		<div>
// 			<form onSubmit={handleFileUploadSubmit}>
// 				<input type="file" onChange={handleFileChange} multiple />
// 				<button type="submit">Upload</button>
// 			</form>
// 			{selectedFiles.map((file) => (
//                 <div key={file.id}>
//                     <p>{file.name}</p>
//                     {/* <button onClick={() => handleDeleteSelectedFile(file.id)}>Delete</button> */}
//                 </div>
//             ))}

//             {files.map((file) => (
//                 <div key={file.id}>
//                     <p>{file.name}</p>
//                     {/* <button onClick={() => handleDeleteUploadedFile(file.id)}>Delete</button> */}
//                 </div>
//             ))}
// 		</div>
// 	)
// }

// export default Step3AddAlbumComponent;