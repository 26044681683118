import Header from "../../shared/ArtistHeader/header";
import Footer from "../../shared/footer/footer";
import AddArtistDetailsComponent from "../../components/artist/addArtistDetailsComponent";
import { useLocation } from "react-router";

const AddArtistDetails = () => {
	const location = useLocation();

	return (
		<div>
			<Header />
			<AddArtistDetailsComponent location={location} />
			<Footer />
		</div>
	);
};

export default AddArtistDetails;
