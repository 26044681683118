import { confirmAlert } from "react-confirm-alert";

// export const errorModal = (message) => {
// 	confirmAlert({
// 		customUI: ({ onClose }) => {
// 			return (
// 				<div className="confirm-ui">
// 					<div className="delete-prompt-box">
// 						{/* <h1
// 							className="text-uppercase mb10"
// 							style={{ fontFamily: "AvenirNext-UltraLight" }}
// 						>
// 							ERROR:
// 						</h1>
// 						<div
// 							style={{
// 								borderTop: "1px solid #278C8e",
// 								marginLeft: 20,
// 								marginRight: 20,
// 								marginBottom: 20,
// 							}}
// 						></div> */}
// 						<h2 className="justify-content-center mb20">
// 							<strong>
// 								{message}
// 							</strong>
// 						</h2>
// 						<div className="d-flex justify-content-center">
// 							<button
// 								type="button"
// 								onClick={() => {
// 									onClose();
// 									// removing this function call, as navigation intercepted already
// 									// cancelAndClose();
// 								}}
// 								className="btn-save"
// 							>
// 								Okay
// 							</button>
// 						</div>
// 					</div>
// 				</div>
// 			);
// 		},
// 	});
// };

export const errorModal = (message) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="confirm-ui">
						<div className="delete-prompt-box">
							<h2 className="text-uppercase mb10">
								{message}
							</h2>
							<div className="d-flex mr-3 justify-content-center">
								<button
									type="button"
									className="btn-save text-uppercase"
									onClick={onClose}
								>
									Okay
								</button>
								{/* <button
									type="button"
									onClick={() => {
										onClose();
										// removing this function call, as navigation intercepted already
										// cancelAndClose();
									}}
									className="btn-cancel text-uppercase"
								>
									No, I'm good. Delete changes.
								</button> */}
							</div>
						</div>
					</div>
				);
			},
		});
	};