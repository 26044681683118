import { useEffect, useState } from "react";
import { useBlocker, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import validator from "validator";
import DummyImage from "../../../assets/img/uploadArtist.png";
import EditMusician from "./editMusician";
import SocialLink from "./socialLink";
import Preview from "./preview";
import BizContact from "./bizContact";
import { confirmAlert } from "react-confirm-alert";
import { showNotification } from "../../../shared/showNotification";
import { notify } from "react-notify-toast";
import ImageCropperModal from "../../ImageCropperModal";
import { errorModal } from "../../../shared/errorModal";

const EditArtist = (props) => {
	const location = useLocation();
	const navigate = useNavigate();

	const [state, setState] = useState({
		error: {},
		inputErrors: {},
		genre: [],
		subGenre: [],
		artistData: {},
		social: [],
		musician: [],
		biz_contact: [],
		instrumentCategory: [],
		instrument: [],
		primaryRoles: [],
		maxChar: 500,
		bioCharRemainderCount: [],
		profilePicFile: null
	});

	const [showImageCropperModal, setShowImageCropperModal] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);

	const [stateChanged, setStateChanged] = useState(false);
	let Snb = false;

	useBlocker(() => { 
		if (stateChanged) {
			confirmNoSave()
		}
	});

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});

		getGenre();
		getMusicRoles();
		getInstruments();
		getPrimaryRoles();

		if (location && location.state && location.state.id) {
			getArtistData(location.state.id);
		}
	}, []);

	// function to count characters on page load and during component action
	const setBioCounter = (newValue) => {
		return new Promise((resolve) => {
			// used to handle if triggerd from user input, if null then called from
			// backend data pop call
			var charLength = 0;
			if (newValue) {
				charLength = newValue.length;
			} else {
				if (state.artistData.bio) {
					charLength = state.artistData.bio.length;
				}
			}

			const remainingChars = state.maxChar - charLength;
			setState((oldState) => ({
				// description: e.target.value.length <= state.maxChar ? e.target.value : e.target.value.substring(0, 250),
				...oldState,
				bioCharRemainderCount: remainingChars,
			}));

			if (remainingChars <= 0) {
				resolve(false);
			} else {
				resolve(true);
			}
		});
	};

	// const errorValidation = (name, flag) => {
	// 	let required = { genre: "genre" };
	// 	var err = state.error;
	// 	if (flag) {
	// 		for (var elem in required) {
	// 			errorValidation(elem);
	// 		}
	// 		return;
	// 	}

	// 	if (required[name]) {
	// 		if (String(state.artistData[name]) === "" || !state.artistData[name]) {
	// 			err[name] = required[name] + " is required";
	// 			notify.show(`${required[name]} is required`, "error");
	// 		} else {
	// 			delete err[name];
	// 		}
	// 	}

	// 	setState((oldState) => ({
	// 		...oldState,
	// 		error: err,
	// 	}));
	// };

	const errorValidation = (name, flag) => {
		let required = { genre: "genre", image: "image" }; // Add image validation
		var err = state.error;
		if (flag) {
			let errorMessage = "";
			// Check if both genre and image are missing
			if ((!state.artistData.genre || String(state.artistData.genre) === "") && !state.profilePicFile && !state.artistData.profile_pic_path) {
				errorMessage = "Genre and image are required";
			} else if (!state.artistData.genre || String(state.artistData.genre) === "") {
				errorMessage = "Genre is required";
			} else if (!state.profilePicFile && !state.artistData.profile_pic_path) {
				errorMessage = "Image is required";
			}
	
			if (errorMessage) {
				errorModal(errorMessage)
				for (var elem in required) {
					err[elem] = required[elem] + " is required";
				}
			} else {
				for (var elem in required) {
					delete err[elem];
				}
			}
			return;
		}
	
		if (required[name]) {
			if (name === 'image') {
				// Check if image is uploaded
				if (!state.profilePicFile && !state.artistData.profile_pic_path) {
					err[name] = required[name] + " is required";
					notify.show(`${required[name]} is required`, "error");
				} else {
					delete err[name];
				}
			} else {
				// Check for other required fields
				if (String(state.artistData[name]) === "" || !state.artistData[name]) {
					err[name] = required[name] + " is required";
					notify.show(`${required[name]} is required`, "error");
				} else {
					delete err[name];
				}
			}
		}
	
		setState((oldState) => ({
			...oldState,
			error: err,
		}));
	};
	
	

	const saveAndBack = async () => {
		Snb = true;
		setStateChanged(false);
		await save(true);
	};

	const save = async (back = false) => {
		
		errorValidation("temp", true);
		await handleSubmit(back);
	};

	const getPrimaryRoles = () => {
		axios
			.get("/primary_role?page=1&limit=20", { params: { role_type: "p" } })
			.then(function (response) {
				if (response.data.data.result) {
					setState((oldState) => ({
						...oldState,
						primaryRoles: response.data.data.result,
					}));
					// setStateChanged(true);
				}
			})
			.catch(function (error) {});
	};

	/**
	 *
	 * @param {*} id
	 *
	 * Retrieves all stored user data for existing artist. If new artist, then nothiung retrieved. Sets defaults and preps user for
	 * inputs.
	 */
	const getArtistData = (id) => {
		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};
		axios
			.get("app/getartist/?artist_id=" + id, config)
			.then(function (response) {
				if (response.data.success) {
					/**
					 * (JCR) resolved issue with sub-genre. One page load if there is a genre selected, then call sub-genre to populate
					 * selection fields based on the genre id
					 */
					const subGenre = response.data.data.artist.sub_genre === 0 ? null : response.data.data.artist.sub_genre;
					const genreID = response.data.data.artist.genre;
					if (genreID) {
						// get sub-genre for genre
						getSubGenre(genreID);
					}

					setState((oldState) => ({
						...oldState,
						artistData: response.data.data.artist,
						biz_contact: response.data.data.biz_contact,
						musician:
							response.data.data.musician.length > 0
								? response.data.data.musician
								: [{ firstname: "", lastname: "", instrument: [] }],
						social: response.data.data.social,
						sub_genre: subGenre,
					}));

					if (response.data.data.biz_contact.length === 0) {
						addBiz();
					}

					// count any characters in the bio field and update counter
					setBioCounter();
				} else {
					showNotification(response.data.message, "error");
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
			});
	};

	const togglePreview = () => {
		setState((oldState) => ({
			...oldState,
			preview: state.preview ? false : true,
		}));
	};

	const handleInput = (e) => {
		let name = e.target.name;
		let value = e.target.value;

		if (name === "bio") {
			/* value = value.length <= state.maxChar ? value : value.substring(0, 500)
            setState({
                // description: e.target.value.length <= state.maxChar ? e.target.value : e.target.value.substring(0, 250),
                chars: value.length <= state.maxChar ? value.length : 500
            }); */
			setBioCounter(value).then((response) => {
				if (response !== true) {
					// alert user that they must delete some characters from bio to continue
					confirmAlert({
						customUI: ({ onClose }) => {
							return (
								<div className="confirm-ui">
									<div className="delete-prompt-box">
										<h2 className="text-uppercase mb10">
											Your bio has hit max characters, must delete something
											before you can add anymore...
										</h2>
										<div className="d-flex mr-3 justify-content-center">
											<button
												type="button"
												onClick={() => {
													onClose();
												}}
												className="btn-save text-uppercase"
											>
												OK, Got it!
											</button>
										</div>
									</div>
								</div>
							);
						},
					});
				}
			});

			setStateChanged(true);
		}
		if (name === "genre") {
			/*
			 * (JCR) Genre was not resetting sub-genre after selection, now set it to null to force user to
			 * make a selection if desired
			 */
			state.artistData.sub_genre = null;
			getSubGenre(value);
		}

		if (name === "website_url") {
			// validate url pattern
			validateURLField(name, value);
		}

		if (name === "email") {
			// validate email pattern
			validateEmail(name, value);
		}

		let temp = state.artistData;
		temp[name] = value;
		setState((oldState) => ({
			...oldState,
			artistData: temp,
		}));

		errorValidation(name);
		setStateChanged(true);
	};

	const validateURLField = (name, value) => {
		const INVALID_URL_ERROR = "Please enter valid URL";
		var tempInputErrors = { ...state.inputErrors };

		if (value === "") {
			delete tempInputErrors.website_url;
			setState((oldState) => ({
				...oldState,
				inputErrors: tempInputErrors,
			}));
			return;
		}

		if (!validator.isURL(value)) {
			tempInputErrors.website_url = INVALID_URL_ERROR;
		} else {
			delete tempInputErrors.website_url;
		}

		setState((oldState) => ({
			...oldState,
			inputErrors: tempInputErrors,
		}));
	};

	const validateEmail = (name, value) => {
		const INVALID_EMAIL_ERROR = "Please enter valid EMAIL address";
		var tempInputErrors = { ...state.inputErrors };

		if (value === "") {
			delete tempInputErrors.email;
			setState((oldState) => ({
				...oldState,
				inputErrors: tempInputErrors,
			}));
			return;
		}

		if (!validator.isEmail(value)) {
			tempInputErrors.email = INVALID_EMAIL_ERROR;
		} else {
			delete tempInputErrors.email;
		}

		setState((oldState) => ({
			...oldState,
			inputErrors: tempInputErrors,
		}));
	};

	const getInstruments = () => {
		axios
			.get("/instrument?page=1&limit=210")
			.then(function (response) {
				if (response.data.data.result) {
					setState((oldState) => ({
						...oldState,
						instrument: response.data.data.result,
					}));
					// setStateChanged(true);
				}
			})
			.catch(function (error) {});
	};

	const getMusicRoles = () => {
		axios
			.get("/instrument_category?page=1&limit=1200")
			.then(function (response) {
				if (response.data.data.result) {
					setState((oldState) => ({
						...oldState,
						instrumentCategory: response.data.data.result,
					}));
					// setStateChanged(true);
				}
			})
			.catch(function (error) {});
	};

	const getGenre = () => {
		axios
			.get("/genre?page=" + 1 + "&limit=" + 1200)
			.then(function (response) {
				if (response.data.data.result) {
					setState((oldState) => ({
						...oldState,
						genre: response.data.data.result,
					}));
				}
			})
			.catch(function (error) {
				showNotification("Network Connection Error", "error");
			});
	};

	const getSubGenre = (genreIdArg) => {
		var genreId = genreIdArg;
		if (genreId) {
			axios
				.get(
					"/sub_genrebygenre?page=" +
						1 +
						"&limit=" +
						1200 +
						"&genre_id=" +
						genreId
				)
				.then(function (response) {
					if (response.data.data.result) {
						showNotification("All Entries Loded", "success");
						setState((oldState) => ({
							...oldState,
							subGenre: response.data.data.result,
						}));
						// setStateChanged(true);
					}
				})
				.catch(function (error) {
					console.error("error = " + error);
				});
		}
	};

	const cropImageCallback = (image) => {
		setShowImageCropperModal(false);

		setState((oldState) => ({
			...oldState,
			profilePicFile: image,
		}));

		setStateChanged(true);
	};

	const cropImageCancelCallback = (image) => {
		setShowImageCropperModal(false);
	};

	const fileUpload = (e) => {
		const acceptedFileTypes = ['image/jpeg', 'image/png'];
	
		const selectedFile = e.target.files[0];
		if (selectedFile) {
			if (!acceptedFileTypes.includes(selectedFile.type)) {
				notify.show("Please upload jpg or png files", "error")
			} else { 
				setSelectedImage(e.target.files[0]);
				setShowImageCropperModal(true);
			}
		}
	};
	

	const updateMusician = (musi) => {
		let temp = musi;
		for (let [index, elem] of temp.entries()) {
			let instrument = [];
			for (let inst of elem.instrument) {
				for (let value of inst.data) {
					if (value.musician_instrument_id) {
						instrument.push({
							musician_instrument_id: value.musician_instrument_id,
							instrument_category_id: inst.instrument_category_id,
						});
					}
				}
			}
			temp[index].instrument = instrument;
		}
		setState((oldState) => ({
			...oldState,
			musician: temp,
		}));
		setStateChanged(true);
	};

	const cancelAndClose = () => {
		navigate("/artist/dashboard");
	};

	const confirmNoSave = (index) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="confirm-ui">
						<div className="delete-prompt-box">
							<h2 className="text-uppercase mb10">
								WHOA, BEFORE YOU EXIT PAGE, WOULD YOU LIKE TO SAVE YOUR CHANGES?
							</h2>
							<div className="d-flex mr-3 justify-content-center">
								<button
									type="button"
									onClick={async () => {
										onClose();
										await save();
									}}
									className="btn-save text-uppercase"
								>
									Yes, please save!
								</button>
								<button
									type="button"
									onClick={() => {
										onClose();
										// removing this function call, as navigation intercepted already
										// cancelAndClose();
									}}
									className="btn-cancel text-uppercase"
								>
									No, I'm good. Delete changes.
								</button>
							</div>
						</div>
					</div>
				);
			},
		});
	};

	const handleSubmit = async (back) => {
		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};
		if (state.error.genre) {
			return false;
		}

		if (state.profilePicFile || state.artistData.profile_pic_path) {
			var formData = new FormData();
			if (state.profilePicFile) {
				formData.append("artistAvatar", state.profilePicFile);
			}
			Object.entries(state.artistData).forEach(([key, value]) => {
				if (key === 'sub_genre' || key === 'is_band') {
					formData.append(key, value === null ? 0 : value); 
				}
				else {
					formData.append(key, value === "null" ? "" : value);
				}
			});
		
			formData.append("musician", JSON.stringify(state.musician));
			formData.append("social", JSON.stringify(state.social));
			formData.append("biz_contact", JSON.stringify(state.biz_contact));

			try {
				const response = await axios.post("app/editartist", formData, config);
				if (response.data.success) {
					showNotification("Artist Details Edited Succesfully  !", "success");
					setStateChanged(false);
					if (location.state.redirect && Snb)
						navigate("/artist/addAlbum", {
							state: {
								name: state.artistFirtName,
								id: location.state.id,
							},
						});
				} else {
					if (response.status === 401) {
						errorModal("Authentication Error, plese re-login" + response.data.message)
					} else {
						errorModal("Could not save the details.")
					}
				}
				setStateChanged(false);
				if (back === true) {
					navigate("/artist/dashboard");
				}
			} catch (error) {
				errorModal(error.name)
			}
		}
	};

	const idToName = (a) => {
		let obj = state.instrument.find((element) => {
			return element.musician_instrument_id === a;
		});
		if (obj) {
			return <>{obj.name}</>;
		}
	};

	const handleCheckbox = (e) => {
		let temp_artist = state.artistData;
		let temp = temp_artist.artist_profile_permissions;
		let perm;
		for (let [i, elem] of temp.entries()) {
			if (elem.column_name === e.target.name) {
				perm = i;
				break;
			}
		}
		if (perm || perm === 0) {
			temp[perm].is_visible = e.target.checked ? 0 : 1;
		} else {
			temp.push({ column_name: e.target.name, is_visible: 0 });
		}
		temp_artist.artist_profile_permissions = temp;
		setState((oldState) => ({
			...oldState,
			artistData: temp_artist,
		}));
		setStateChanged(true);
	};

	const getCheckboxValue = (value) => {
		if (state.artistData.artist_profile_permissions) {
			let obj = state.artistData.artist_profile_permissions.find((element) => {
				return element.column_name === value;
			});
			if (obj) {
				return obj.is_visible ? false : true;
			}
		}
	};

	const changeLink = (e, idx) => {
		let temp = state.social;
		let link;
		for (let [i, elem] of temp.entries()) {
			if (elem.social_link_id === idx) {
				link = i;
				break;
			}
		}
		if (link || link === 0) {
			temp[link].link = e.target.value;
		} else {
			temp.push({ social_link_id: idx, link: e.target.value });
		}
		setState((oldState) => ({
			...oldState,
			social: temp,
		}));
		setStateChanged(true);
	};

	const addMusician = () => {
		let temp = [];
		temp.push({
			firstname: "",
			lastname: "",
			instrument: [
				{
					instrument_category_id: null,
					data: [{ musician_instrument_id: null }],
				},
			],
		});
		setState((oldState) => ({
			...oldState,
			musician: temp,
		}));
		setStateChanged(true);
	};

	const findto = (a) => {
		let obj = state.social.find((element) => {
			return element.social_link_id === a;
		});
		if (obj) {
			return obj.link;
		}
	};

	const addBiz = () => {
		let temp = state.biz_contact;
		temp.push({
			primary_role_id: null,
			firstname: "",
			lastname: "",
			email: "",
			company: "",
			website_url: "",
			artist_profile_permissions: [],
		});
		setState((oldState) => ({
			...oldState,
			biz_contact: temp,
		}));
		setStateChanged(true);
	};

	const deleteBiz = (index) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="confirm-ui">
						<div className="delete-prompt-box">
							<h2 className="text-uppercase mb10">
								Are You Sure You Would
								<br /> Like to Delete This?
							</h2>
							<div className="d-flex mr-3 justify-content-center">
								<button
									type="button"
									onClick={onClose}
									className="btn-save text-uppercase"
								>
									oops, No
								</button>
								<button
									type="button"
									onClick={() => {
										let temp = state.biz_contact;
										temp.splice(index, 1);
										setState((oldState) => ({
											...oldState,
											biz_contact: temp,
										}));
										onClose();
									}}
									className="btn-cancel text-uppercase"
								>
									Delete
								</button>
							</div>
						</div>
					</div>
				);
			},
		});
	};

	const bizInputChange = (e, index) => {
		let temp = state.biz_contact;
		if(e.target) {
			temp[index][e.target.name] = e.target.value;
		} else {
			temp[index][e.name] = e.value;
		}
		setState((oldState) => ({
			...oldState,
			biz_contact: temp,
		}));
		setStateChanged(true);
	};

	const bizCheckboxChange = (e, index, flag) => {
		let temp_biz = state.biz_contact;
		if (flag && e.target.checked) {
			let arr = [
				{ column_name: "lastname", is_visible: 0 },
				{ column_name: "company", is_visible: 0 },
				{ column_name: "email", is_visible: 0 },
				{ column_name: "website_url", is_visible: 0 },
			];
			for (let i of temp_biz) {
				i.artist_profile_permissions = arr;
			}
		} else if (flag && !e.target.checked) {
			for (let i of temp_biz) {
				i.artist_profile_permissions = [];
			}
		} else {
			let temp = temp_biz[index].artist_profile_permissions;

			if (e.target.checked) {
				temp.push({ column_name: e.target.name, is_visible: 0 });
			} else {
				temp = temp.filter((elem) => elem.column_name != e.target.name);
			}

			// let perm
			// for (let [i, elem] of temp.entries()) {
			//     if (elem.column_name == e.target.name) {
			//         alert(i)
			//         perm = i
			//         break
			//     }
			// }
			// if (perm || perm == 0) {
			//     if (e.target.checked) {
			//         temp[perm].is_visible = 0
			//     }
			//     if (!e.target.checked) {
			//         temp.splice(index, 1)
			//     }
			// }
			// else {
			//     temp.push({ column_name: e.target.name, is_visible: 0 })
			// }
			temp_biz[index].artist_profile_permissions = temp;
		}
		//console.log(333333333, temp_biz)
		setState((oldState) => ({
			...oldState,
			biz_contact: temp_biz,
		}));
		setStateChanged(true);
	};

	const getBizCheckboxValue = (value, index) => {
		let temp = state.biz_contact[index].artist_profile_permissions;

		if (temp) {
			let obj = temp.find((element) => {
				return element.column_name === value;
			});
			if (obj) {
				return obj.is_visible ? false : true;
			}
		}
	};	

	return (
		<div>
			{showImageCropperModal && <ImageCropperModal
				image={selectedImage}
				onCropImage={cropImageCallback}
				onCropImageCancel={cropImageCancelCallback}
			/>}
			{!showImageCropperModal && state.preview && (
				<Preview
					primaryRoles={state.primaryRoles}
					biz_contact={state.biz_contact}
					genre={state.genre}
					sub_genre={state.subGenre}
					idToName={idToName}
					togglePreview={togglePreview}
					profilePicFile={state.profilePicFile}
					social={state.social}
					artist={state.artistData}
					musician={state.musician}
				/>
			)}
			{!showImageCropperModal && !state.preview && (
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="artist-profile-heading">
								<h1 className="text-center text-uppercase text-bold text-green big-text mb0 commonSize">
									{state.artistData.name} (Profile Edit Page)
								</h1>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-8 offset-md-2">
							<div className="artist-profile-content">
								<p className="text-justify">
									This is what fans will see on the {state.artistData.name}{" "}
									Profile Page in the Xchange. This can be edited anytime and
									should reflect the CURRENT status of the Artist regardless of
									the status on a particular Dalbum. Is there a new drummer?
									manager? publicist? This is where that information gets
									updated. We strongly recommend filling out this section as
									much as possible. And make sure to add a cool photo, too!
								</p>
							</div>
						</div>
					</div>

					{/* <img src={axios.defaults.baseURL + state.artistData.profile_pic_path} style={{ height: 479 + 'px', width: 100 + '%', objectFit: 'cover' }} /> */}

					<div className="row d-mb-10">
						<div className="col-md-6 mb10">
							<div
								className="artist-profile-pic relative"
								style={{ backgroundImage: `url(${DummyImage})` }}
							>
								{state.profilePicFile && (
									<img
										style={{
											height: 540 + "px",
											width: 100 + "%",
											// objectFit: "cover",
										}}
										src={URL.createObjectURL(state.profilePicFile)}
									/>
								)}
								{!state.profilePicFile && state.artistData.profile_pic_path && (
									<img
										src={
											axios.defaults.baseURL + state.artistData.profile_pic_path
										}
										style={{
											height: 100 + "%",
											width: 100 + "%",
											objectFit: "cover",
											aspectRatio: 1,
										}}
									/>
								)}
								<a>
									<span>
										{" "}
										<input
											type="file"
											className="artistImgUpload"
											onChange={fileUpload}
											id="uploadImage"
											name="file"
										/>
										<img
											src={require("../../../assets/img/upload-ico.png")}
											alt="Image"
											title="Image"
										/>
									</span>
								</a>
							</div>
						</div>
						<div className="col-md-6 mb10">
							<div className="bg-green pd15 height540 artist-bio">
								<h4 className="text-white mb-10">
									BIO (Must fit in this box, make it short and sweet for your
									fans)
								</h4>
								<div className="form-group">
									<textarea
										className="form-control"
										id="exampleFormControlTextarea1"
										rows="3"
										name="bio"
										maxLength={state.maxChar}
										onChange={handleInput}
										value={state.artistData?.bio === null || state.artistData?.bio === "null" ? "" : state.artistData.bio}
									></textarea>
									<span className="text-white">
										Characters Left: {state.bioCharRemainderCount}
									</span>
								</div>
							</div>
						</div>
					</div>
					<div className="row mb30 mobile-artist-box1">
						<div className="col-md-6">
							<div className="bg-black pd20 height780 customeHeight">
								<div className="row mb20">
									<div className="col-md-12">
										<h3 className="text-white pull-right">
											{state.artistData.name}
										</h3>
									</div>
								</div>
								<form className="form-width">
									<div className="form-group row">
										<label
											className="col-sm-3 col-lg-3 col-form-label text-right mbl-align-left text-white"
											htmlFor="name"
										>
											*Genre:
										</label>
										<div className="col-md-7 col-sm-7 col-10 cusPad">
											<select
												className="form-control"
												name="genre"
												onChange={handleInput}
												value={
													state.artistData.genre
														? state.artistData.genre
														: "default"
												}
											>
												<option disabled value="default">
													Select
												</option>
												{state.genre.map((genre, index) => (
													<option key={"genre" + index} value={genre.genre_id}>
														{genre.name}
													</option>
												))}
											</select>
											{state.error.genre && (
												<span id="error" className="error-text">
													{state.error.genre}
												</span>
											)}
										</div>
										<div className="col-md-2 pr0">
											{/* <div className="white-color">Hide from public view</div> */}
										</div>
									</div>
									<div className="form-group row align-items-center">
										<label
											className="col-lg-3  col-sm-3 col-form-label text-right mbl-align-left text-white"
											htmlFor="name"
										>
											Sub-Genre:
										</label>
										<div className="col-md-7 col-sm-7 col-10 cusPad">
											<select
												className="form-control"
												name="sub_genre"
												onChange={handleInput}
												value={
													state.artistData.sub_genre
														? state.artistData.sub_genre
														: "default"
												}
											>
												<option value="default">Select</option>
												{state.subGenre.map((subGenre, index) => (
													<option
														key={"subGenre" + index}
														value={subGenre.sub_genre_id}
													>
														{subGenre.name}
													</option>
												))}
											</select>
										</div>
										<div className="col-2 col-lg-2">
											<div className="cust-checkbox">
												<input
													checked={getCheckboxValue("sub_genre")}
													type="checkbox"
													name="sub_genre"
													onChange={handleCheckbox}
												/>
											</div>
										</div>
									</div>
									<div className="form-group row align-items-center">
										<label
											className="col-sm-3 col-form-label text-right mbl-align-left text-white"
											htmlFor="name"
										>
											Location:
										</label>
										<input
											type="text"
											className="form-control col-sm-7 col-10"
											placeholder=""
											name="location"
											onChange={handleInput}
											value={state.artistData?.location === null || state.artistData?.location === "null" ? "" : state.artistData.location}
											// value={state.artistData?.location ?? ""}
										/>
										<div className="col-sm-2 col-2">
											<div className="cust-checkbox">
												<input
													type="checkbox"
													checked={getCheckboxValue("location")}
													name="location"
													onChange={handleCheckbox}
												/>
											</div>
										</div>
									</div>
									<div className="form-group row align-items-center">
										<label
											className="col-sm-3  col-form-label text-right mbl-align-left text-white"
											htmlFor="name"
										>
											Record Label:
										</label>
										<input
											type="text"
											className="form-control col-sm-7 col-10"
											
											placeholder=""
											onChange={handleInput}
											value={state.artistData?.record_label === null || state.artistData?.record_label === "null" ? "" : state.artistData.record_label}
											// value={state.artistData?.record_label || ""} 
											name="record_label"
										/>
										<div className="col-sm-2 col-2">
											<div className="cust-checkbox">
												<input
													type="checkbox"
													checked={getCheckboxValue("record_label")}
													name="record_label"
													onChange={handleCheckbox}
												/>
											</div>
										</div>
									</div>

									<div className="form-group row justify-content-end">
										<div className="col-md-10 col-sm-10">
											<div className="white-color text-right">
												Hide from public view
											</div>
										</div>
										<div className="col-md-2">
											<i className="fa fa-arrow-up text-white"></i>
										</div>
									</div>
								</form>
								<div className="row mb10">
									<div className="col-md-12">
										<div className="edit-artisit-info text-uppercase">
											{/* -------------------Band Members Edit-----------------------------  */}

											<h3
												data-toggle="modal"
												data-target="#add-band-role"
												data-backdrop="static"
												// data-target=".bd-band-modal-lg"
											>
												<a className="text-white">
													<i className="fa fa-plus"></i> Click to Edit{" "}
													{state.artistData.is_band ? "Band" : "Artist"} Info{" "}
												</a>
											</h3>
											<EditMusician
												id="add-band-role"
												update={updateMusician}
												instrumentCategory={state.instrumentCategory}
												instrument={state.instrument}
												is_band={state.artistData.is_band}
												musician={state.musician}
											/>
										</div>
									</div>
								</div>
								<div className="row mb10">
									<div className="col-md-12">
										<div className="artist-detail h450 text-right">
											<h4 className="text-white text-uppercase mb20">
												{state.artistData.is_band ? "BAND MEMBERS:" : "ARTIST:"}
											</h4>
											{state.musician &&
												state.musician.map((elem, index) => (
													<div key={index}>
														<h4 className="band-member">
															{elem.firstname} {elem.lastname}
														</h4>
														<p className="band-member-instrument">
															{elem.instrument.map((inst, idx) => (
																<div key={index + "-" + idx}>
																	{idx !== 0 && "+"}{" "}
																	{inst.musician_instrument_id}{" "}
																</div>
															))}
														</p>
													</div>
												))}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-md-6 mobile-artist-box">
							<div className="bg-orange height780 pd15 autoHeight">
								<h3 className="text-white text-uppercase text-center mb10 big-text commonSize">
									Get Connected
								</h3>
								<h6 className="text-white mb20">
									Copy/Paste your official DalbumX url and share with your fans.
								</h6>
								<form>
									<div className="form-group">
										<input
											type="text"
											className="form-control col-sm-12"
											name="dalbumUrl"
											
											disabled
											value={state.artistData?.dalbum_url ?? ""}
											placeholder="Dalbum Url"
										/>
									</div>
									<h6 className="text-white mb20">
										Add your other contact info below... (optional):
									</h6>
									<div className="form-group row formInpiutSpace">
										<label
											className="col-sm-3 col-3 col-form-label text-white text-right"
											htmlFor="name"
										>
											Website:
										</label>
										<input
											type="text"
											className="form-control col-sm-7 col-9"
											name="website_url"
											
											placeholder="ex: http://www.myband.com"
											onChange={handleInput}
											value={state.artistData?.website_url === null || state.artistData?.website_url === "null" ? "" : state.artistData?.website_url}
										/>
									</div>
									{state.inputErrors.website_url && (
										<div id="websiteurl-error" className="form-error-text">
											{state.inputErrors.website_url}{" "}
										</div>
									)}
									<div className="form-group row formInpiutSpace">
										<label
											className="col-sm-3 col-3 col-form-label text-white text-right"
											htmlFor="name"
										>
											Official Public Email:
										</label>
										<input
											type="email"
											className="form-control col-sm-7 col-9"
											name="email"
											
											onChange={handleInput}
											value={state.artistData?.email === null || state.artistData?.email === "null" ? "" : state.artistData.email}
											placeholder="ex: mybandemail@email.com"
										/>
										{state.inputErrors.email && (
											<div id="email-error" className="form-error-text">
												{state.inputErrors.email}{" "}
											</div>
										)}
									</div>

									<SocialLink
										social={state.social}
										findto={findto}
										onLinkChange={changeLink}
									/>
								</form>
							</div>
						</div>
					</div>
					<BizContact
						getCheckboxValue={getBizCheckboxValue}
						roles={state.primaryRoles}
						deleteBiz={deleteBiz}
						biz_contact={state.biz_contact}
						addBiz={addBiz}
						onInputChange={bizInputChange}
						onCheckboxChange={bizCheckboxChange}
					/>
					<div className="row justify-content-center fullButton">
						<div className="col-lg-12 text-center">
							{/* JCR: commented out until fully implemented <button className="btn-gray text-uppercase" disabled type="button">Insider Access </button> */}
							<button
								className="btn-gray text-uppercase"
								type="button"
								onClick={togglePreview}
							>
								Preview
							</button>
							<button
								className="btn-orange text-uppercase"
								type="button"
								onClick={cancelAndClose}
							>
								Dashboard
							</button>
							<button
								className="btn-save text-uppercase"
								type="button"
								onClick={async () => await save()}
							>
								Save
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default EditArtist;
