import Header from "../../shared/FanHeader/header";
import Footer from "../../shared/footer/footer";
import LibraryComponent from "../../components/fan/library";

const Library = () => {
	return (
		<div>
			<Header />
			<LibraryComponent />
			<Footer />
		</div>
	);
};

export default Library;
