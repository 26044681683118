import { useEffect, useState } from "react";
import Header from "../../shared/ArtistHeader/header";
import Footer from "../../shared/footer/footer";
import Manage from "./../../components/artist/manageAlbum/manageAlbum";
import QrCode from "../../components/artist/manageAlbum/qrCode";
import { useLocation } from "react-router";

const ManageAlbum = () => {
	const location = useLocation();

	const [state, setState] = useState({
		imagePath: "",
		page: 0,
	});

	useEffect(() => {
		if (!location.state) {
			const pathArray = window.location.href.split("/");
			const protocol = pathArray[0];
			const host = pathArray[2];
			const url = protocol + "//" + host;
			window.location = url + "/artist/dashboard";
		}
	}, []);

	const change = (no) => {
		setState({
			page: no,
		});
	};

	return (
		<div>
			<Header />
			<section id="content" className="pt50">
				{state.page === 0 && (
					<Manage albumData={location.state} change={change} />
				)}
				{state.page === 1 && (
					<QrCode albumData={location.state} change={change} />
				)}
			</section>
			<Footer />
		</div>
	);
};

export default ManageAlbum;
