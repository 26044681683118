import React from "react";
import Header from "../../shared/ArtistHeader/header";
import Footer from "../../shared/footer/footer";
import QrCode from "../../components/artist/manageAlbum/qrCode";
import { useLocation, useNavigate } from "react-router";

const ShareDalbum = () => {
	const location = useLocation();
	const navigate = useNavigate();

	return (
		<div>
			<Header />
			<QrCode
				albumData={location.state.albumData}
				change={() => navigate(-1)}
			/>
			<Footer />
		</div>
	);
};

export default ShareDalbum;
