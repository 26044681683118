import Header from "../../shared/ArtistHeader/header";
import Footer from "../../shared/footer/footer";
import DashBoardComponentArtist from "../../components/artist/artistDashboard";

const ArtistDashboard = () => {
	return (
		<div>
			<Header />
			<DashBoardComponentArtist />
			<Footer />
		</div>
	);
};

export default ArtistDashboard;
