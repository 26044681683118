import { forwardRef, useEffect, useState } from "react";
import Header from "../../shared/FanHeader/header";
import Footer from "../../shared/footer/footer";
import AlbumPreviewComponent from "../../components/fan/albumPreviewComponent";
import { useLocation, useParams } from "react-router";

const HeaderwithRef = forwardRef((props, ref) => (
	<Header ref={(element) => (ref.current = element)} props={props} />
));

const FanSearch = (props) => {
	const location = useLocation();

	const { id } = useParams();

	const [state, setState] = useState({
		flag: false,
	});

	useEffect(() => {
		if (!location.state && !id) {
			const pathArray = window.location.href.split("/");
			const protocol = pathArray[0];
			const host = pathArray[2];
			const url = protocol + "//" + host;
			window.location = url + "/fan/dashboard";
		}
	});
	// changeCartCount=()=>{

	//     console.log(11111111111,refs[myRef])

	// }

	return (
		<div>
			<Header />
			<AlbumPreviewComponent album_id={id} />
			<Footer />
		</div>
	);
};

export default FanSearch;
