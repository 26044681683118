import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { showNotification } from "../../../shared/showNotification";
import validator from "validator";
import useArtwork from "../../useArtwork";
// import AlbumPricingComponent from '../../components/artist/albumPricing'
// import ToolTip from '../../shared/toolTip';

// const CustomTool =({message})=>{
//     return (
//         <div className="tooltip"><img src={require("./../../assets/img/info.png")} />
//             <span className='tooltiptext' style={{backgroundColor: "#a9adac"}}> {message} </span>
//         </div>
//     );
// }

const Manage = ({ albumData, change }) => {
  const [showPopUp, setShowPopUp] = useState(false);
  const navigate = useNavigate();

  const canvasRef = useRef({});

  const [state, setState] = useState({
    artwork: [],
    size: 0,
    albumData: {},
    name: "",
    artists: [],
	modalVisible: false,
    inputText: "",
    items: [],
  });

  const togglePopup = () => {
    setShowPopUp(!showPopUp);
  };

  const {} = useArtwork(state.artwork);

  useEffect(() => {
    // window.$('#HiddenTracks').carousel('pause');
    window.$("#HiddenTracks").carousel({
      interval: false,
      keyboard: false,
    });
    getalbum();
    getEmails();
  }, []);

  const handleOpenModal = () => {
    setState((oldstate) => ({ ...oldstate, modalVisible: true }));
  };

  const handleCloseModal = () => {
    setState((oldstate) => ({ ...oldstate, modalVisible: false }));
  };

  const handleInputChange = (e) => {
    setState((oldstate) => ({ ...oldstate, inputText: e.target.value }));
  };

  const handleAddItem = async () => {
    const email = state.inputText;
    const dalbum_id = sessionStorage.getItem("album_id");

    if (validator.isEmpty(email) || !validator.isEmail(email)) {
      showNotification("Enter a valid email", "error");
      return;
    }

	

    if (state.items.includes(email)) {
      showNotification("Email already exists in the list", "error");
      return;
    }

	if(state.items.length >= 5) {
		showNotification("Can only add upto 5 fans","error")
		return
	}

    const config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
      },
    };

    const user = await axios.get(`/app/album/gift/checkEmail?email=${email}`, config);

    // Check if email exists
    if (!user.data.success) {
      showNotification(user.data.error, "error");
      return;
    }

    const fanData = {
      email,
      dalbum_id,
    };

    const response = await axios.post("/app/album/gift", fanData, config);

    if (response.data.success) {
      showNotification("Fan added to list", "success");
    }

    if (state.inputText.trim() !== "") {
      setState((oldstate) => ({ ...oldstate, items: [...state.items, state.inputText], inputText: "" }));
    }
  };

  const redirect = (type, no) => {
    if (type === "before") {
      navigate("/artist/addalbum", {
        state: { page: no },
      });
    }
    if (type === "after") {
      if (no === "price") {
        navigate("/artist/managepricing", {
          state: {
            data: state.albumData,
          },
        });
      }
      if (no === "dextra") {
        navigate("/artist/dextra", {
          state: {
            data: state.albumData,
          },
        });
      }
      if (no === "trackdextra") {
        navigate("/artist/track-dextra", {
          state: {
            data: state.albumData,
          },
        });
      }
      if (no === "payment") {
        navigate("/artist/checkout", {
          state: {
            data: state.albumData,
          },
        });
      }
      if (no === "qrCode") {
        change(1);
      }
    }
  };

  const sortintoCatagory = (data) => {
    var sorted = data.sort(function (a, b) {
      return a.at_position - b.at_position;
    });
    setState((oldState) => ({
      ...oldState,
      artwork: sorted,
    }));
  };

  const getalbum = () => {
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
      },
    };

    axios
      .get("app/addalbum/prepublishpreview?album_id=" + albumData.album.dalbum_id, config)
      .then(function (response) {
        if (response.data.success) {
          let temp = response.data.data;
          sortintoCatagory(response.data.data.artwork_images);
          setState((oldState) => ({
            ...oldState,
            // artwork: response.data.data.artwork_images,
            albumData: response.data.data,
            size: response.data.data.total_size,
            name: (
              <h3>
                {temp.name}
                <br />
                {temp.copyright_year}
                <br />
                {temp.dalbum_record_lbl}
              </h3>
            ),
          }));
        } else {
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  const getEmails = async () => {
    const config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
      },
    };

    const response = await axios.get(`/app/album/gift/emails?dalbum_id=${sessionStorage.getItem("album_id")}`, config);

    if (response.data.success) {
      setState((oldstate) => ({ ...oldstate, items: [...response.data.data] }));
    }
  };

  return (
    <div className="container">
      <div className="row mb30">
        <div className="col-md-12">
          <h1 className="text-center text-uppercase">Manage dalbum</h1>
        </div>
      </div>

      <div className="row mb30 justify-content-center">
        <div className="steps-progress-bar" style={{ width: "450px" }}>
          <div className="progress" style={{ height: "40px" }}>
            <div
              className="progress-bar"
              role="progressbar"
              style={{
                width: (((state.size / (1024 * 1024)) * 100) / 750).toFixed(1) + "%",
              }}
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {(((state.size / (1024 * 1024)) * 100) / 750).toFixed(1)}% of 750 MB has been used
            </div>
          </div>
        </div>
      </div>

      <div className="row align-items-end">
        <div className="col-md-7">
          <div className="publish-album-edit pull-right">
            <button type="button" className="btn-edit">
              Edit
            </button>
            <div className="publish-album-dropdown">
              {/* <div className="before-publish bg-orange pd15">
                                    <ul className="list-unstyled mb0">
                                        <li className="list-heading">
                                            <a href="#" className="text-uppercase text-white">Only Before Publishing</a>
                                        </li>
                                        <li>
                                            <a onClick={() => { redirect('before', 3) }} className="text-uppercase text-white"> <img src={require("../../../assets/img/right-arr.png")} />Artwork</a>
                                        </li>
                                        <li>
                                            <a onClick={() => { redirect('before', 4) }} className="text-uppercase text-white"><img src={require("../../../assets/img/right-arr.png")} />Tracks</a>
                                        </li>
                                        <li>
                                            <a onClick={() => { redirect('before', 6) }} className="text-uppercase text-white"><img src={require("../../../assets/img/right-arr.png")} />Embedded Material</a>
                                        </li>
                                        <li>
                                            <a onClick={() => { redirect('before', 7) }} className="text-uppercase text-white"><img src={require("../../../assets/img/right-arr.png")} />Credits*</a>
                                        </li>
                                    </ul>
                                </div> */}
              <div className="edit-publish bg-black pd15">
                <ul className="list-unstyled">
                  {/* <li className="list-heading">
                                            <a href="#" className="text-uppercase text-white">Can Be edited Anytime</a>
                                        </li> */}
                  <li>
                    <a
                      onClick={() => {
                        redirect("after", "dextra");
                      }}
                      className="text-uppercase text-white"
                    >
                      <img src={require("../../../assets/img/right-arr.png")} />
                      Dalbum Dextras
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        redirect("after", "trackdextra");
                      }}
                      className="text-uppercase text-white"
                    >
                      <img src={require("../../../assets/img/right-arr.png")} />
                      Track Dextras
                    </a>
                  </li>
                  {/* <li>
                                            <a href="#" className="text-uppercase text-white"><img src={require("../../../assets/img/right-arr.png")} />Xchange Display</a>
                                        </li> */}
                  <li>
                    <a
                      onClick={() => {
                        redirect("after", "price");
                      }}
                      className="text-uppercase text-white"
                    >
                      <img src={require("../../../assets/img/right-arr.png")} />
                      Manage Price
                    </a>
                  </li>
                  <li>
                    {showPopUp ? (
                      <div className="popup">
                        <h1 className="coming-soon-text">Coming Soon</h1>
                        <button onClick={togglePopup} className="btn-save popup-button">
                          Close
                        </button>
                      </div>
                    ) : null}

                    <a onClick={togglePopup} className="text-uppercase text-white link-disabled">
                      <img src={require("../../../assets/img/right-arr.png")} />
                      Analytics (Coming Soon)
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        redirect("after", "qrCode");
                      }}
                      className="text-uppercase text-white"
                    >
                      <img src={require("../../../assets/img/right-arr.png")} />
                      GET QR CODE / SHARE URL
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        handleOpenModal();
                      }}
                      className="text-uppercase text-white"
                    >
                      <img src={require("../../../assets/img/right-arr.png")} />
                      Add Gift
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {state.modalVisible && (
        <div style={{ display: "block", position: "fixed", zIndex: "1", left: "0", top: "0", width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", textAlign: "center" }}>
          <div style={{ backgroundColor: "white", margin: "15% auto", padding: "20px", borderRadius: "5px", width: "50%", position: "relative" }}>
            <h2 className="text-center text-uppercase" style={{ marginBottom: "10px", fontWeight: "600" }}>
              Add Gift
            </h2>
			<p>(Can add upto 5 fans)</p>
            <button onClick={handleCloseModal} type="button" class="modal-default-close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div style={{ marginTop: "10px", borderTop: "1px solid black", paddingTop: "10px", display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
              <input className="react-autosuggest__input gift-fan-username-field" type="text" value={state.inputText} onChange={handleInputChange} placeholder="Fan username" />
              <button className="btn-save" onClick={handleAddItem}>
                Add
              </button>
            </div>
            <div style={{ marginTop: "10px", borderTop: "1px solid black", paddingTop: "10px", display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
              {state.items.map((item, index) => (
                <React.Fragment key={index}>
                  <span style={{ display: "inline-block", marginRight: "5px" }}>{item}</span>
                  {index !== state.items.length - 1 && <span style={{ marginRight: "5px", marginLeft: "5px" }}>|</span>}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      )}

      <div className="row mb30">
        <div id="HiddenTracks" className="publish-album-slider carousel slide ml-auto mr-auto position-relative" data-ride="carousel">
          <div className="album-name text-left text-uppercase position-absolute">
            {state.name}
            {/* <h3>Interpol <br /> El Pintor <br /> 2014 <br /> matador Records</h3> */}
          </div>

          <div className="carousel-inner">
            {state.artwork.map((art, idx) => (
              <div className={(idx === 0 ? "active " : "") + "carousel-item"}>
                <div
                  ref={(element) => {
                    canvasRef.current["canvas" + idx] = element;
                  }}
                  className="step6-slider"
                  style={{
                    backgroundImage: `${state.artwork[idx].urls}`,
                    backgroundPosition: "center",
                    backgroundSize: `${state.artwork[idx].editable || state.artwork[idx].square ? "cover" : "100%"}`,
                    backgroundRepeat: "no-repeat",
                    width: "500px",
                  }}
                ></div>
              </div>
            ))}
            {/* <div className="carousel-item active">
                                            <img src={require("../../assets/img/album-image.png")} className="d-block" alt="album-image" />
                                        </div> */}
          </div>
          <a className="carousel-control-prev" href="#HiddenTracks" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
          </a>
          <a className="carousel-control-next" href="#HiddenTracks" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>

      <div className="row form-group justify-content-center mblBtn">
        <button type="button" className="btn-save text-uppercase">
          Preview
        </button>
        <button
          onClick={() => {
            redirect("after", "paymaent");
          }}
          type="button"
          className="btn-cancel text-uppercase"
        >
          Publish Update
        </button>
        {/* <CustomTool  message={'dfsgfgdfgmklm'} /> */}
      </div>

      {/* <AlbumPricingComponent/> */}
    </div>
  );
};

export default Manage;
