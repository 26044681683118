import React, { Component } from "react";
import Header from "../../shared/ArtistHeader/header";
import Footer from "../../shared/footer/footer";
import AddArtistComponent from "../../components/artist/addArtistComponent";
import { useLocation } from "react-router";

const AddArtist = () => {
	const location = useLocation();
	return (
		<div>
			<Header />
			<AddArtistComponent
				artistName={location.state ? location.state.name : null}
			/>
			<Footer />
		</div>
	);
};

export default AddArtist;
