import axios from "axios";
import { useState } from "react";
import validator from "validator";
import { showNotification } from "../../shared/showNotification";

const ArtistForgetPassword = () => {
	const [state, setState] = useState({
		email: "",
		isChecked: false,
		mailSent: false,
		validEmail: true,
		emailNotIn: null,
		errors: "",
	});

	const handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;
		setState((oldState) => {
			const updatedState = {
				...oldState,
				[name]: value,
				validEmail: true,
			};

			validate(target.name);
			return updatedState;
		});
	};

	const validate = (fields) => {
		var errors = { ...state.errors };

		if (Array.isArray(fields))
			fields.map(function (val) {
				errors = _validate(val, errors);
			});
		else errors = _validate(fields, errors);

		setState((oldState) => ({
			...oldState,
			errors: errors,
		}));

		return Object.keys(errors).length;
	};

	const _validate = (field, errors) => {
		if (field === "email") {
			if (!validator.isEmail(state.email)) errors.email = "Invalid email";
			else delete errors.email;
		}
		return errors;
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		var errors = 0;
		errors = validate("email");

		if (errors) return;

		if (!state.validEmail) {
			return false;
		}
		axios
			.post("/auth/admin/forgot_password", {
				email: state.email,
			})
			.then(function (response) {
				if (response.data.success) {
					setState((oldState) => ({
						...oldState,
						mailSent: true,
					}));
					showNotification("Email sent.", "success");
				} else {
					showNotification(response.data.message, "error");
					var errors = { ...state.errors };
					errors.email = response.data.message;
					setState((oldState) => ({
						...oldState,
						errors: errors,
					}));
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				//console.log(error);
			});
	};

	return (
		<div>
			{!state.mailSent && (
				<section id="content" className="artist-side-bg flex-wrapper">
					<div className="container">
						<div className="row">
							<div className="col-lg-6 col-md-6 mr-auto ml-auto col-sm-8">
								<h2 className="font67 text-uppercase text-gray text-center mb30 din-font">
									oops,I did it again! Forgot My Password
								</h2>
								<h5 className="text-justify">
									No problem. To reset your password, enter the email address
									associated with your DalbumX account. We'll email you a link
									to a page where you can easily create a new one.
								</h5>
								<div className="spacer40"></div>
								<div className="login-inner-wrapper text-center">
									<span id="error" className="form-text error-text">
										{state.emailNotIn}
									</span>
									<form>
										<div className="form-group">
											<h4 className="bold-text text-gray mb20">
												What is Your e-mail address?
											</h4>
											<input
												type="text"
												className="form-control"
												id="exampleInputEmail1"
												aria-describedby="emailHelp"
												placeholder="Your email"
												name="email"
												onChange={handleInputChange}
												onBlur={validator}
											/>
											{state.errors.email && (
												<span id="error" className="form-text error-text">
													{state.errors.email}
												</span>
											)}
										</div>
										<div className="form-group">
											<button
												type="submit"
												className="btn btn-gradient btn-block"
												onClick={handleSubmit}
											>
												Continue <i className="fa fa-angle-right"></i>
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</section>
			)}

			{state.mailSent && (
				<section id="content" className="artist-side-bg flex-wrapper">
					<div className="container">
						<div className="row">
							<div className="col-lg-6 col-md-6 mr-auto ml-auto col-sm-8">
								<h2 className="font67 text-uppercase text-gray text-center mb30 din-font">
									Don't Worry!
									<br /> Be Happy!
								</h2>
								<h5 className="text-justify">
									Check Your Email! An email with instructions for reseting your
									password was sent to <b>{state.email}</b>. If You don't
									receive this email, please check your junk mail folder or
									contact <a className="text-underline">Customer Service</a> for
									further assistance.{" "}
								</h5>
							</div>
						</div>
					</div>
				</section>
			)}
		</div>
	);
};

export default ArtistForgetPassword;
