import { useEffect, useState } from "react";
import axios from "axios";
import validator from "validator";
import { showNotification } from "../../shared/showNotification";

const FanNewPassword = () => {
	const [state, setState] = useState({
		password: "",
		confirmPassword: "",
		token: "",
		isPasswordChanged: "",
		error: {},
	});

	useEffect(() => {
		extractUrlToken();
	}, []);

	const errorValidation = () => {
		var err = {};

		if (!validator.isLength(state.password, { min: 6, max: 50 })) {
			err.password = "Password must be between 6 to 20 digits";
		}

		setState((oldState) => ({
			...oldState,
			error: err,
		}));

		//console.log(state.error)
		return Object.keys(err).length === 0;
	};
	const extractUrlToken = (event) => {
		// let url = ;
		var urlsplit = window.location.href.split("/");
		var urlToken = urlsplit[urlsplit.length - 1];
		setState((oldState) => ({
			...oldState,
			token: urlToken,
		}));
	};

	const handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;
		setState((oldState) => ({
			...oldState,
			[name]: value,
		}));
	};

	const handleSubmit = (event) => {
		if (!errorValidation()) {
			//console.log(errorValidation())
			return false;
		}
		//console.log("These are states ", state);
		event.preventDefault();
		axios
			.post("/auth/fan/reset_password", {
				password: state.password,
				token: state.token,
			})
			.then(function (response) {
				if (response.data.success) {
					showNotification("Password changed successfully  !", "success");
					setState((oldState) => ({
						...oldState,
						isPasswordChanged: true,
					}));
					showNotification("Password changed successfully  !", "success");
				} else {
					showNotification(
						"Password and Confirm Password are required!",
						"error"
					);
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				//console.log(error);
			});
	};

	return (
		<div>
			{!state.isPasswordChanged && (
				<section id="content" className="fan-side-bg flex-wrapper">
					<div className="container">
						<div className="row">
							<div className="col-lg-6 col-md-6 mr-auto ml-auto col-sm-8">
								<h2 className="font60 text-uppercase text-gray text-center mb30 din-font">
									Create a new password
								</h2>
								<div className="login-inner-wrapper text-center reset-password">
									<form>
										<div className="form-group">
											<input
												type="password"
												className="form-control"
												name="password"
												placeholder="New Password"
												onChange={handleInputChange}
											/>
											{state.error.password && (
												<span id="error" className="form-text error-text">
													{state.error.password}{" "}
												</span>
											)}
										</div>

										<div className="form-group mb50">
											<input
												type="password"
												className="form-control"
												placeholder="Re-Enter Password"
												name="confirmPassword"
												onChange={handleInputChange}
											/>
											{!validator.equals(
												state.password,
												state.confirmPassword
											) && (
												<span id="error" className="form-text error-text">
													Password and Confirm Password should be same{" "}
												</span>
											)}
										</div>

										<div className="form-group">
											<button
												type="button"
												className="btn btn-gradient btn-block"
												onClick={handleSubmit}
											>
												Save Changes
											</button>
										</div>
									</form>
								</div>
							</div>
							<div className="col-lg-12">
								<div className="tips-box">
									<h2>Secure Password tips:</h2>
									<p>
										Use atleast 6 character, a mix of numbers, letters and
										special characters is best.
									</p>
									<p>
										Do Not use the same password you have used with us
										previously.
									</p>
									<p>
										Do not use your, e-mail address or other easily obtained
										personal information.
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>
			)}
			{state.isPasswordChanged && (
				<section id="content" className="artist-side-bg flex-wrapper">
					<div className="container">
						<div className="row">
							<div className="col-lg-6 col-md-6 mr-auto ml-auto col-sm-8">
								<h2 className="font60 text-uppercase text-gray text-center mb30 din-font">
									Look what You've Done
								</h2>
								<h5 className="text-gray text-center">
									You Have Successfully Changed Your Password!
								</h5>
								<div className="spacer60"></div>
								<div className="login-wrapper text-center">
									<div className="form-group">
										<button
											type="submit"
											onClick={(_) => (window.location = "/fan/login")}
											className="btn btn-gradient"
										>
											Continue
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			)}
		</div>
	);
};
export default FanNewPassword;
