import { useEffect, useState } from "react";

import axios from "axios";
import { withRouter } from "./../../shared/withRouter";
import DummyImage from "./../../assets/img/uploadArtist.png";
import fb from "../../assets/img/fb.png";
import twitter from "../../assets/img/twitter.png";
import gp from "../../assets/img/google-plus.png";
import insta from "../../assets/img/instagram.png";
import vimeo from "../../assets/img/vue.png";
import tumblr from "../../assets/img/tumbler.png";
import url from "../../defaults";
import { useLocation, useNavigate } from "react-router";
import { showNotification } from "../../shared/showNotification";

const EditArtistDetailsComponent = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const [state, setState] = useState({
		isChecked: false,
		genre: [],
		subGenre: [],
		musicianRoles: [],
		instruments: [],
		// data
		bio: "",
		file: "",
		selectedGenre: "",
		selectedSubGenre: "",
		selectedMusicianRole: "",
		artistFirtName: "",
		artistLastName: "",
		location: "",
		recordLabel: "",
		// Urls
		dalbumUrl: "",
		website: "",
		email: "",
		social: [
			{ link: 0 },
			{ link: 0 },
			{ link: 0 },
			{ link: 0 },
			{ link: 0 },
			{ link: 0 },
		],
		// Others
		musician: [],
		// biz_contact: [],
		fakeMusicians: [{ name: "" }],
		bandMembers: [],
		artistProfilePermissions: [
			{
				column_name: "sub_genre",
				is_visible: 1,
			},
			{
				column_name: "location",
				is_visible: 1,
			},
			{
				column_name: "record_label",
				is_visible: 1,
			},
			{
				column_name: "company_name",
				is_visible: 1,
			},
			{
				column_name: "email",
				is_visible: 1,
			},
			{
				column_name: "website",
				is_visible: 1,
			},
		],
		instrument: [],
		primaryRoles: [],
		// bizzzzzzzzzzz
		bizContacts: [
			{
				company: "",
				email: "",
				firstname: "",
				lastname: "",
				website_url: "",
				selectedPrimaryRole: "",
			},
		],
		selectedPrimaryRole: "",
		isPreviewOn: false,
	});

	useEffect(() => {
		setState((oldState) => ({
			...oldState,
			artistFirtName: localStorage.getItem("firstName"),
		}));
		// getSubGenre();
		getGenre();
		getMusicRoles();
		getInstruments();
		getPrimaryRoles();
		getArtistDetails();
	}, []);

	const transferClickToInput = () => {
		var fileupload = document.getElementById("uploadImage");
		fileupload.click();
	};

	const getArtistDetails = () => {
		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};
		axios
			.get("app/getartist/?artist_id=" + location.state.id, config)
			.then(function (response) {
				if (response.data.success) {
					setDataIntoStates(response.data.data);
				} else {
					showNotification(response.data.message, "error");
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				//console.log(error);
			});
	};
	const setMusiciansData = (musicians) => {
		var newMusicians = [];
		musicians.forEach((element) => {
			var tt = element;
			var pp = element.instrument.reduce(function (r, a) {
				r[a.musician_instrument.instrument_category.name] =
					r[a.musician_instrument.instrument_category.name] || [];
				//console.log(a);

				r[a.musician_instrument.instrument_category.name].push(a);
				return r;
			}, Object.create(null));
			let abc = [];
			//console.log("PP", pp);

			Object.keys(pp).forEach((e) => {
				var data = {
					name: e,
					arr: pp[e],
				};
				// pp[e].name = e
				// //console.log('kkkp',pp[e])
				abc.push(data);
			});

			//console.log('nnnnnnnn', abc)
			tt.myInstru = abc;
			newMusicians.push(tt);
		});
		setState((oldState) => ({
			...oldState,
			bandMembers: newMusicians,
		}));
		debugger;
	};
	const setDataIntoStates = (response) => {
		debugger;
		var genre, subgenre, role;
		if (response.artist.genre) {
			genre = searchName(response.artist.genre, state.genre, "genre_id").name;
		}
		if (response.artist.sub_genre) {
			subgenre = searchName(
				response.artist.sub_genre,
				state.subGenre,
				"sub_genre_id"
			).name;
		}
		setMusiciansData(response.musician);
		setState((oldState) => ({
			...oldState,
			bio: response.artist.bio,
			dalbumUrl: response.artist.dalbum_url,
			profileImage: response.artist.profile_pic_path,
			selectedGenre: genre,
			selectedSubGenre: subgenre,
			selectedMusicianRole: "",
			location: response.artist.location,
			recordLabel: response.artist.record_label,
			website: response.artist.website_url,
			email: response.artist.email,
			social: response.social,

			// Others
			bizContacts: response.biz_contact,
			selectedPrimaryRole: "",
		}));
	};
	const handleHideFromPublicChange = (name, event) => {
		var number;

		if (name === "sub_genre") {
			number = 0;
		} else if (name === "location") {
			number = 1;
		} else if (name === "recordLabel") {
			number = 2;
		} else if (name === "companyName") {
			number = 3;
		} else if (name === "email") {
			number = 4;
		} else if (name === "website") {
			number = 5;
		}
		// else if (name === 'location')
		// {
		//     number = 1;
		// }

		var hide = state.artistProfilePermissions;

		if (event.target.checked) hide[number].is_visible = 0;
		else hide[number].is_visible = 1;

		setState((oldState) => ({
			...oldState,
			artistProfilePermissions: hide,
		}));
	};
	const deleteMember = (idx = 0) => {
		var bands = state.bandMembers;
		bands.splice(idx, 1);
		setState((oldState) => ({
			...oldState,
			bandMembers: bands,
		}));
	};
	const addMusiciansField = (idx = 0) => {
		var bandOnly = state.bandMembers;
		var band = bandOnly[idx].myInstru;
		band.push({ name: "", arr: [{ musician_instrument_id: "" }] });
		bandOnly[idx].BandMusicRoles = band;
		setState((oldState) => ({
			...oldState,
			bandMembers: bandOnly,
		}));
		var bandOnly = state.bandMembers;
		var band = bandOnly[idx].instrument;
		band.push({ musician_instrument_id: "" });
		bandOnly[idx].instrument = band;
		setState((oldState) => ({
			...oldState,
			bandMembers: bandOnly,
		}));
	};
	const addInstrumentsField = (idx) => {
		var bandOnly = state.bandMembers;
		var band = bandOnly[idx].instrument;
		band.push({ musician_instrument_id: "" });
		bandOnly[idx].instrument = band;
		setState((oldState) => ({
			...oldState,
			bandMembers: bandOnly,
		}));
		var bandOnly = state.bandMembers;
		var band = bandOnly[idx].myInstru;
		band[band.length - 1].arr.push({ musician_instrument_id: "" });
		bandOnly[idx].myInstru = band;
		setState((oldState) => ({
			...oldState,
			bandMembers: bandOnly,
		}));
	};
	const handleBandMemberInputChange = (idx, edx, event, fdx) => {
		if (event.target.name === "firstname") {
			var band = state.bandMembers;
			band[idx].firstname = event.target.value;
			//console.log(band);

			setState((oldState) => ({
				...oldState,
				bandMembers: band,
			}));
		} else if (event.target.name === "lastname") {
			var band = state.bandMembers;
			band[idx].lastname = event.target.value;
			setState((oldState) => ({
				...oldState,
				bandMembers: band,
			}));
		} else if (event.target.name === "MusicianRole") {
			debugger;
			var band = state.bandMembers;
			band[idx].myInstru[edx].name = event.target.value;
			// band[idx].BandMusicRoles[edx].musicianRole = event.target.value;
			setState((oldState) => ({
				...oldState,
				bandMembers: band,
			}));
		} else if (event.target.name === "intrumentsRole") {
			debugger;
			var ldx = 0;
			var band = state.bandMembers;
			for (var i = 0; i < fdx; i++) {
				ldx += band[idx].myInstru[i].arr.length;
			}
			ldx += edx;
			debugger;
			band[idx].instrument[ldx].musician_instrument_id = event.target.value;
			setState((oldState) => ({
				...oldState,
				bandMembers: band,
			}));

			var band = state.bandMembers;
			band[idx].myInstru[fdx].arr[edx].musician_instrument_id =
				event.target.value;
			setState((oldState) => ({
				...oldState,
				bandMembers: band,
			}));
		} else {
			showNotification("Hope you do it Well!");
		}
	};
	const handleBizContactChange = (event, idx = 0) => {
		var biz = state.bizContacts;
		if (!biz.length) {
			biz.push({
				company: "",
				email: "",
				firstname: "",
				lastname: "",
				website_url: "",
				selectedPrimaryRole: "",
			});
		}
		if (event.target.name === "bizFirstName") {
			biz[idx].firstname = event.target.value;
			setState((oldState) => ({
				...oldState,
				bizContacts: biz,
			}));
		} else if (event.target.name === "bizLastName") {
			biz[idx].lastName = event.target.value;
			setState((oldState) => ({
				...oldState,
				bizContacts: biz,
			}));
		} else if (event.target.name === "company") {
			biz[idx].company = event.target.value;
			setState((oldState) => ({
				...oldState,
				bizContacts: biz,
			}));
		} else if (event.target.name === "email") {
			biz[idx].email = event.target.value;
			setState((oldState) => ({
				...oldState,
				bizContacts: biz,
			}));
		} else if (event.target.name === "website_url") {
			biz[idx].website_url = event.target.value;
			setState((oldState) => ({
				...oldState,
				bizContacts: biz,
			}));
		} else if (event.target.name === "selectedPrimaryRole") {
			biz[idx].selectedPrimaryRole = event.target.value;
			setState((oldState) => ({
				...oldState,
				bizContacts: biz,
			}));
		} else {
			showNotification("Error Occured !","error");
		}
	};
	const addBandMember = () => {
		var newMember = state.bandMembers;
		newMember.push({
			firstname: "",
			lastname: "",
			instrument: [],
			myInstru: [],
		});
		setState((oldState) => ({
			...oldState,
			bandMembers: state.bandMembers,
		}));
	};
	const searchName = (nameKey, myArray, whichId) => {
		for (var i = 0; i < myArray.length; i++) {
			if (myArray[i][whichId] === nameKey) {
				return myArray[i];
			}
		}
		return 0;
	};
	const searchId = (nameKey, myArray) => {
		for (var i = 0; i < myArray.length; i++) {
			if (myArray[i].name === nameKey) {
				return myArray[i];
			}
		}
		return 0;
	};
	const getPrimaryRoles = () => {
		axios
			.get("/primary_role?page=1&limit=20", { params: { role_type: "B" } })
			.then(function (response) {
				if (response.data.data.result) {
					setState((oldState) => ({
						...oldState,
						primaryRoles: response.data.data.result,
					}));
				}
			})
			.catch(function (error) {});
	};
	const getInstruments = () => {
		// setState((oldState) => ({
		// ...oldState,data:[1,2,3]}))
		axios
			.get("/instrument?page=1&limit=210")
			.then(function (response) {
				if (response.data.data.result) {
					setState((oldState) => ({
						...oldState,
						instruments: response.data.data.result,
					}));
				}
			})
			.catch(function (error) {});
	};
	const getMusicRoles = () => {
		axios
			.get("/instrument_category?page=1&limit=1200")
			.then(function (response) {
				if (response.data.data.result) {
					setState((oldState) => ({
						...oldState,
						musicianRoles: response.data.data.result,
					}));
				}
			})
			.catch(function (error) {});
	};
	const getGenre = () => {
		// setState((oldState) => ({
		// ...oldState,data:[1,2,3]}))
		axios
			.get("/genre?page=" + 1 + "&limit=" + 1200)
			.then(function (response) {
				if (response.data.data.result) {
					setState((oldState) => ({
						...oldState,
						genre: response.data.data.result,
					}));
				}
			})
			.catch(function (error) {
				showNotification("Network Connection Error", "error");
				//console.log(error);
			});
	};
	const getSubGenr = () => {
		//console.log(state);

		axios
			.get("/sub_genre?page=" + 1 + "&limit=" + 1200)
			.then(function (response) {
				if (response.data.data.result) {
					showNotification("All Entries Loded", "success");
					setState((oldState) => ({
						...oldState,
						subGenre: response.data.data.result,
					}));
				}
			})
			.catch(function (error) {});
	};

	const handleInputChange = (event) => {
		var value;
		const target = event.target;
		const name = target.name;
		if (target.type === "file") {
			// value = target.files[0];
			var meriFile = event.target.files[0];
			value = URL.createObjectURL(meriFile);
			setState((oldState) => ({
				...oldState,
				selectedFile: meriFile,
			}));
		} else value = target.type === "checkbox" ? target.checked : target.value;

		setState((oldState) => ({
			...oldState,
			[name]: value,
		}));
		if (name === "bio") {
			if (state.bio.length > 250) {
				showNotification("Maximum Length is 250");
				setState((oldState) => ({
					...oldState,
					bio: state.bio.substring(0, 250),
				}));
			}
		}
	};
	const handleSubmit = (event) => {
		setState((oldState) => ({
			...oldState,
			isChecked: true,
		}));
		var genreId = searchId(state.selectedGenre, state.genre)
			? searchId(state.selectedGenre, state.genre).genre_id
			: null;

		var subGenreId = searchId(state.selectedSubGenre, state.subGenre)
			? searchId(state.selectedSubGenre, state.subGenre).sub_genre_id
			: null;

		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};
		if (state.selectedFile) {
			var formData = new FormData();
			formData.append(
				"artistAvatar",
				state.selectedFile,
				state.selectedFile.name
			);
			formData.append("artist_id", location.state.id);
			axios.post("app/editartist", formData, config);
		}
		event.preventDefault();

		axios
			.post(
				"app/editartist",
				{
					artist_id: location.state.id,
					bio: state.bio,
					artistAvatar: formData,
					genre: genreId,
					subGenre: subGenreId,
					location: state.location,
					record_label: state.recordLabel,
					dalbum_url: state.dalbumUrl,
					website_url: state.website,
					email: state.email,
					social: state.social,
					musician: state.bandMembers,
					artist_profile_permissions: state.artistProfilePermissions,
					biz_contact: state.bizContacts,
				},
				config
			)
			.then(function (response) {
				if (response.data.success) {
					debugger;
					showNotification("Artist Details Edited Succesfully  !", "success");
					navigate("/artist/dashboard");
				} else {
					showNotification(
						"Invalid Email or Password !" + response.data.message,
						"error");
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				//console.log(error);
			});
	};
	const handSocialLinks = (event) => {
		debugger;
		var value;
		const target = event.target;
		var name = target.name;
		name--;
		var socials = state.social;
		socials[name].link = target.value;
		debugger;
		setState((oldState) => ({
			...oldState,
			social: socials,
		}));
	};

	var dalbumurl = process.env.REACT_APP_MIDDLEWARE_BASE_URL + location.state.name;
	return (
		<div>
			{!state.isPreviewOn && (
				<section id="content" className="pt50">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="artist-profile-heading">
									<h1 className="text-center text-uppercase text-bold text-green big-text mb0 commonSize">
										Artist Profile Page
									</h1>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-8 offset-md-2">
								<div className="artist-profile-content">
									<p className="text-justify">
										This is what fans will see on the public Artist Profile
										Page. This can be edited anytime and should reflect the
										CURRENT status of the Artist regardless of the status on a
										particular Dalbum. Is there a new drummer? A new Manager? A
										new Publicist? This is where that information gets updated.
										We strongly recommend filling out this section as much as
										possible. And make sure to add a cool photo, too!
									</p>
								</div>
							</div>
						</div>

						<div className="row mb10">
							<div className="col-md-6 mb20">
								<div
									className="artist-profile-pic relative" //className to hide :
									style={{
										backgroundImage: `url(${DummyImage})`,
									}}
								>
									{state.file && (
										<img
											src={state.file}
											style={{ height: 479 + "px", width: 100 + "%" }}
										/>
									)}
									{!state.file && (
										<img
											src={url + state.profileImage}
											alt=""
											onClick={transferClickToInput}
											title=""
											style={{ height: 479 + "px", width: 539 + "px" }}
										/>
									)}

									<a>
										<span>
											{" "}
											<input
												type="file"
												id="uploadImage"
												name="file"
												onChange={handleInputChange}
											/>
											<img
												src={require("./../../assets/img/upload-ico.png")}
												alt=""
												onClick={transferClickToInput}
												title=""
											/>
										</span>
									</a>
								</div>
							</div>
							<div className="col-md-6 mb20">
								<div className="bg-green pd15 height480 artist-bio">
									<h4 className="text-white">
										BIO (Must fit in this box, make it <br /> short and sweet
										for your fans)
									</h4>
									<div className="form-group">
										<textarea
											className="form-control"
											id="exampleFormControlTextarea1"
											rows="3"
											name="bio"
											onChange={handleInputChange}
											value={state.bio}
										>
											{state.bio}
										</textarea>
									</div>
								</div>
							</div>
						</div>

						<div className="row mb30">
							<div className="col-md-6">
								<div className="bg-black pd20 height680">
									<div className="row mb20">
										<div className="col-md-12">
											<h3 className="text-white pull-right">
												{location.state.name}
											</h3>
										</div>
									</div>
									<form>
										<div className="form-group row">
											<label
												className="col-sm-3 col-form-label text-right text-white"
												htmlFor="name"
											>
												*Genre:
											</label>
											<select
												className="form-control col-md-7"
												name="selectedGenre"
												onChange={handleInputChange}
											>
												{state.genre.map((genre) => (
													<option key={genre.genre_id}>{genre.name}</option>
												))}
												<option selected> {state.selectedGenre} </option>
											</select>
										</div>
										<div className="form-group row align-items-center">
											<label
												className="col-sm-3 col-form-label text-right text-white"
												htmlFor="name"
											>
												Sub-Genre:
											</label>
											<select
												className="form-control col-md-7"
												name="selectedSubGenre"
												onChange={handleInputChange}
											>
												{state.subGenre.map(
													(subGenre) =>
														state.selectedGenre === subGenre.genre.name && (
															<option>{subGenre.name}</option>
														)
												)}
												<option selected> {state.selectedSubGenre} </option>
											</select>
											<div className="col-sm-2">
												<div className="cust-checkbox">
													<input
														type="checkbox"
														onChange={(e) =>
															handleHideFromPublicChange("sub_genre", e)
														}
													/>
												</div>
											</div>
										</div>
										<div className="form-group row align-items-center">
											<label
												className="col-sm-3 col-form-label text-right text-white"
												htmlFor="name"
											>
												Location:
											</label>
											<input
												type="email"
												className="form-control col-sm-7"
												id=""
												placeholder=""
												name="location"
												value={state.location}
												onChange={handleInputChange}
											/>
											<div className="col-sm-2">
												<div className="cust-checkbox">
													<input
														type="checkbox"
														onChange={(e) =>
															handleHideFromPublicChange("location", e)
														}
													/>
												</div>
											</div>
										</div>
										<div className="form-group row align-items-center">
											<label
												className="col-sm-3 col-form-label text-right text-white"
												htmlFor="name"
											>
												Record Label:
											</label>
											<input
												type="email"
												className="form-control col-sm-7"
												id=""
												placeholder=""
												value={state.recordLabel}
												name="recordLabel"
												onChange={handleInputChange}
											/>
											<div className="col-sm-2">
												<div className="cust-checkbox">
													<input
														type="checkbox"
														onChange={(e) =>
															handleHideFromPublicChange("recordLabel", e)
														}
													/>
												</div>
											</div>
										</div>
									</form>
									<div className="row">
										<div className="col-md-6 offset-md-6">
											<p className="text-white text-right">
												Hide From Public View
											</p>
										</div>
									</div>
									<div className="row mb10">
										<div className="col-md-12">
											<div className="edit-artist-info text-uppercase">
												{/* -------------------Band Members Edit-----------------------------  */}

												<h3
													data-toggle="modal"
													data-target="#add-band-role"
													data-target=".bd-band-modal-lg"
												>
													{location.state.isBand && (
														<a className="text-white">
															<i className="fa fa-plus"></i> Click to Edit Band
															Info{" "}
														</a>
													)}
													{!location.state.isBand && (
														<a className="text-white">
															<i className="fa fa-plus"></i> Click to Edit
															Artist Info{" "}
														</a>
													)}
												</h3>

												<div
													className="modal fade bd-band-modal-lg"
													id="add-band-role"
													tabindex="-1"
													role="dialog"
													aria-labelledby="add-band-role"
													aria-hidden="true"
												>
													<div
														className="modal-dialog modal-dialog-centered modal-lg"
														role="document"
													>
														<div className="modal-content bg-black pd20">
															<div className="modal-body text-white">
																<p className="text-white mb10 text-capitalize text-justify">
																	Please remember thats this information should
																	pertain to the CURRENT lineup and what each
																	Member generally plays.This information may or
																	may not be relevant to who played or what was
																	played on a particular Dalbu. This will appear
																	on the public profilepage or for fans to see.
																</p>
																<h3 className="text-uppercase text-white mb10">
																	Band Members:
																</h3>
																{state.bandMembers.map((bandMember, idx) => (
																	<div className="bg-dark-gray pd15 mb10 add-member">
																		<div className="row">
																			<div className="col-md-6">
																				<div className="row">
																					<div className="col-md-6">
																						<input
																							type="text"
																							name="firstname"
																							className="form-control"
																							placeholder="First"
																							value={bandMember.firstname}
																							onChange={(e) =>
																								handleBandMemberInputChange(
																									idx,
																									0,
																									e,
																									0
																								)
																							}
																						/>
																					</div>

																					<div className="col-md-6">
																						<input
																							type="text"
																							name="lastname"
																							className="form-control"
																							placeholder="Last"
																							value={bandMember.lastname}
																							onChange={(e) =>
																								handleBandMemberInputChange(
																									idx,
																									0,
																									e,
																									0
																								)
																							}
																						/>
																					</div>
																				</div>
																			</div>

																			<div className="col-md-6">
																				{bandMember.myInstru.map(
																					(musicianRoles, fdx) => (
																						<div className="row mb10">
																							<div className="col-md-6">
																								<select
																									className="form-control"
																									name="MusicianRole"
																									value={musicianRoles.name}
																									onChange={(e) =>
																										handleBandMemberInputChange(
																											idx,
																											fdx,
																											e,
																											0
																										)
																									}
																								>
																									{state.musicianRoles.map(
																										(musicianRole) => (
																											<option
																												key={
																													musicianRole.instrument_category_id
																												}
																											>
																												{musicianRole.name}
																											</option>
																										)
																									)}
																								</select>
																							</div>

																							<div className="col-md-6 ">
																								<div className="row">
																									{musicianRoles.arr.map(
																										(
																											_bandMemberInstrument,
																											edx
																										) => (
																											<div className="col-md-12 mb10">
																												<select
																													className="form-control"
																													name="intrumentsRole"
																													value={
																														_bandMemberInstrument.musician_instrument_id
																													}
																													onChange={(e) =>
																														handleBandMemberInputChange(
																															idx,
																															edx,
																															e,
																															fdx
																														)
																													}
																												>
																													{state.instruments.map(
																														(instrumento) => {
																															if (
																																instrumento
																																	.instrument_category
																																	.name ===
																																state
																																	.bandMembers[
																																	idx
																																].myInstru[fdx]
																																	.name
																															) {
																																return (
																																	<option
																																		key={
																																			instrumento.musician_instrument_id
																																		}
																																		value={
																																			instrumento.musician_instrument_id
																																		}
																																	>
																																		{
																																			instrumento.name
																																		}
																																	</option>
																																);
																															}
																														}
																													)}
																												</select>
																											</div>
																										)
																									)}
																								</div>
																							</div>
																						</div>
																					)
																				)}
																			</div>
																		</div>

																		<div className="row justify-content-end">
																			<div className="col-md-3">
																				<a
																					onClick={() => addMusiciansField(idx)}
																					className="text-green"
																				>
																					<i className="fa fa-plus"></i>{" "}
																					Category
																				</a>
																			</div>
																			{/* {state.bandMembers[0].instrument[0]} */}
																			<div className="col-md-3">
																				<a
																					onClick={() =>
																						addInstrumentsField(idx)
																					}
																					className="text-green"
																				>
																					<i className="fa fa-plus"></i>{" "}
																					Instruments
																				</a>
																			</div>
																		</div>
																		<a
																			className="delete-member"
																			onClick={() => deleteMember(idx)}
																		>
																			{" "}
																			Delete Member{" "}
																		</a>
																	</div>
																))}

																{location.state.isBand && (
																	<a onClick={addBandMember}>
																		{" "}
																		<i className="fa fa-plus"></i> Member
																	</a>
																)}
															</div>
															<div className="modal-footer justify-content-center">
																<button
																	type="button"
																	className="btn-cancel"
																	data-dismiss="modal"
																>
																	Cancel
																</button>
																<button
																	type="button"
																	className="btn-save"
																	data-dismiss="modal"
																>
																	Save
																</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="bg-orange height680 pd45">
									<h3 className="text-white text-uppercase text-center mb10 big-text">
										Get Connected
									</h3>
									<form>
										<div className="form-group">
											<input
												type="text"
												className="form-control col-sm-12"
												name="dalbumUrl"
												id=""
												disabled
												value={dalbumurl}
												onChange={handleInputChange}
											/>
										</div>
										<h6 className="text-white mb20">
											The DalbumX URL links to your public Artist Profile page.{" "}
											<br /> Share your links below (optional).
										</h6>
										<div className="form-group row align-items-center">
											<label
												className="col-sm-3 text-white text-right mb0"
												htmlFor="name"
											>
												Website:
											</label>
											<input
												type="text"
												className="form-control col-sm-7"
												name="website"
												id=""
												placeholder=""
												value={state.website}
												onChange={handleInputChange}
											/>
										</div>
										<div className="form-group row align-items-center">
											<label
												className="col-sm-3 text-white text-right mb0"
												htmlFor="name"
											>
												Email:
											</label>
											<input
												type="text"
												className="form-control col-sm-7"
												name="email"
												value={state.email}
												id=""
												placeholder="Email"
												onChange={handleInputChange}
											/>
										</div>
										<div className="form-group row align-items-center">
											<label
												className="col-sm-3 text-white text-right mb0"
												htmlFor="name"
											>
												<img
													src={require("./../../assets/img/fb-white-ico.png")}
												/>
											</label>
											<input
												type="text"
												className="form-control col-sm-7"
												name="1"
												id=""
												placeholder="URl"
												value={state.social[0].link}
												onChange={handSocialLinks}
											/>
										</div>
										<div className="form-group row align-items-center">
											<label
												className="col-sm-3 text-white text-right mb0"
												htmlFor="name"
											>
												<img
													src={require("./../../assets/img/twitter-white-ico.png")}
												/>
											</label>
											<input
												type="text"
												className="form-control col-sm-7"
												name="2"
												value={state.social[1].link}
												id=""
												placeholder="URl"
												onChange={handSocialLinks}
											/>
										</div>
										{/* <div className="form-group row">
                                                <label className="col-sm-3 text-white text-right" htmlFor="name">
                                                    <img src={require('./../../assets/img/google-plus.png')} />
                                                </label>
                                                <input type="text"
                                                    className="form-control col-sm-7"
                                                    name="google"
                                                    value={state.google}
                                                    id=""
                                                    placeholder="URl"
                                                    onChange={handSocialLinks}
                                                />
                                            </div> */}
										<div className="form-group row align-items-center">
											<label
												className="col-sm-3 text-white text-right mb0"
												htmlFor="name"
											>
												<img
													src={require("./../../assets/img/instagram-white-ico.png")}
												/>
											</label>
											<input
												type="text"
												className="form-control col-sm-7"
												name="3"
												value={state.social[2].link}
												id=""
												placeholder="URl"
												onChange={handSocialLinks}
											/>
										</div>
										<div className="form-group row align-items-center">
											<label
												className="col-sm-3 text-white text-right mb0"
												htmlFor="name"
											>
												<img
													src={require("./../../assets/img/viemo-white-ico.png")}
												/>
											</label>
											<input
												type="text"
												className="form-control col-sm-7"
												name="5"
												value={state.social[4].link}
												id=""
												placeholder="URl"
												onChange={handSocialLinks}
											/>
										</div>
										<div className="form-group row align-items-center">
											<label
												className="col-sm-3 text-white text-right mb0"
												htmlFor="name"
											>
												<img
													src={require("./../../assets/img/tumbler-white-ico.png")}
												/>
											</label>
											<input
												type="text"
												className="form-control col-sm-7"
												name="4"
												value={state.social[3].link}
												id=""
												placeholder="URl"
												onChange={handSocialLinks}
											/>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className="row mb30">
							<div className="col-md-12">
								{state.bizContacts.map((element) => (
									<div className="pd20 bg-gray">
										<div className="row mb20">
											<div className="col-md-11 offset-md-1">
												<div className="row align-items-center">
													<div className="col-md-3">
														<select
															className="form-control"
															name="selectedPrimaryRole"
															value={element.primary_role_id}
															onChange={(e) => handleBizContactChange(e)}
														>
															{state.primaryRoles.map((role) => (
																<option value={role.primary_role_id}>
																	{role.name}
																</option>
															))}
														</select>
													</div>
													<div className="col-md-3">
														<input
															type="text"
															name="bizFirstName"
															className="form-control"
															placeholder="First"
															value={element.firstname}
															onChange={(e) => handleBizContactChange(e)}
														/>
													</div>
													<div className="col-md-3">
														<input
															type="text"
															name="bizLastName"
															className="form-control"
															placeholder="Last"
															value={element.lastname}
															onChange={(e) => handleBizContactChange(e)}
														/>
													</div>
													<div className="col-md-2">
														<div className="cust-checkbox">
															<input
																type="checkbox"
																// onChange={(e) => handleHideFromPublicChange("lastName", e)}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="row mb20 align-items-center">
											<div className="col-md-11 offset-md-1">
												<div className="row align-items-center">
													<div className="col-md-6">
														<div className="form-group text-left mb0">
															<input
																type="checkbox"
																className="form-check-input"
																id="hide"
															/>
															<label
																className="form-check-label text-white text-captialize"
																htmlFor="hide"
															>
																Hide All from public view
															</label>
														</div>
													</div>
													<div className="col-md-3">
														<input
															type="text"
															className="form-control"
															placeholder="Company Name"
															name="company"
															value={element.company}
															onChange={(e) => handleBizContactChange(e)}
														/>
													</div>
													<div className="col-md-2">
														<div className="cust-checkbox">
															<input
																type="checkbox"
																onChange={(e) =>
																	handleHideFromPublicChange("companyName", e)
																}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div className="row mb20">
											<div className="col-md-11 offset-md-1">
												<div className="row align-items-center justify-content-end">
													<div className="col-md-3">
														<input
															type="text"
															className="form-control"
															placeholder="Email"
															value={element.email}
															name="email"
															onChange={(e) => handleBizContactChange(e)}
														/>
													</div>
													<div className="col-md-3">
														<div className="cust-checkbox">
															<input
																type="checkbox"
																onChange={(e) =>
																	handleHideFromPublicChange("email", e)
																}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div className="row mb20">
											<div className="col-md-11 offset-md-1">
												<div className="row align-items-center justify-content-end">
													<div className="col-md-3">
														<input
															type="text"
															name="website_url"
															value={element.website_url}
															className="form-control"
															placeholder="Website"
															onChange={(e) => handleBizContactChange(e)}
														/>
													</div>
													<div className="col-md-3">
														<div className="cust-checkbox">
															<input
																type="checkbox"
																onChange={(e) =>
																	handleHideFromPublicChange("website", e)
																}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
						<div className="row justify-content-center fullButton">
							<div className="col-lg-12 text-center">
								<button
									className="btn-gray text-uppercase"
									disabled
									type="button"
								>
									Insider Access{" "}
								</button>
								<button
									className="btn-gray text-uppercase"
									type="button"
									onClick={() => {
										setState((oldState) => ({
											...oldState,
											isPreviewOn: true,
										}));
									}}
								>
									Preview
								</button>
								<button
									className="btn-orange text-uppercase"
									type="button"
									onClick={handleSubmit}
								>
									Save
								</button>
								<button
									className="btn-save text-uppercase"
									type="button"
									onClick={handleSubmit}
								>
									Save and Back
								</button>
							</div>
						</div>
					</div>
				</section>
			)}

			{/* --------------------Preview Section------------------------  */}
			{state.isPreviewOn && (
				<section id="content" className="pt50">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="artist-profile-heading">
									<h1 className="text-center text-uppercase text-bold text-green big-text mb20">
										Artist Preview Page
									</h1>
								</div>
							</div>
						</div>
						<div className="row justify-content-center mb30">
							<button
								className="btn-gray text-uppercase"
								type="button"
								data-toggle="modal"
								data-target=".insider-invite"
							>
								Insider Access
							</button>
							<button
								className="btn-gray text-uppercase"
								type="button"
								onClick={() => {
									setState((oldState) => ({
										...oldState,
										isPreviewOn: false,
									}));
								}}
							>
								Back
							</button>
							<button className="btn-orange text-uppercase" type="button">
								Home
							</button>
						</div>

						<div className="row mb10">
							<div className="col-md-6">
								<div
									className="artist-profile-pic relative mb20"
									style={{ backgroundImage: `url(${DummyImage})` }}
								>
									{state.file && (
										<img
											src={state.file}
											style={{ height: 479 + "px", width: 539 + "px" }}
										/>
									)}
									{!state.file && (
										<img
											src={url + state.profileImage}
											title=""
											style={{ height: 479 + "px", width: 539 + "px" }}
										/>
									)}
								</div>
							</div>
							<div className="col-md-6">
								<div className="bg-green pd20 height480 artist-bio profile-preview-green">
									<h2 className="text-white">{state.bio}</h2>
								</div>
							</div>
						</div>

						<div className="row mb30">
							<div className="col-md-6">
								<div className="bg-black height680 pd45">
									<div className="row mb20">
										<div className="col-md-12 text-right">
											<h3 className="text-white big-text mb20">
												{location.state.name}
											</h3>
											{state.location && (
												<h2 className="text-white mb20">{state.location}</h2>
											)}
											{state.recordLabel && (
												<h2 className="text-white mb20">{state.recordLabel}</h2>
											)}
											{state.selectedGenre && (
												<h2 className="text-white mb40">
													{state.selectedGenre}
												</h2>
											)}
											{state.selectedSubGenre && (
												<h2 className="text-white mb20">
													+ {state.selectedSubGenre}
												</h2>
											)}
											<h1 className="text-white mb5 text-uppercase">
												Band Members
											</h1>
											{state.bandMembers.map((bandMember) => (
												<div>
													<h2 className="text-white mb5">
														{bandMember.firstname}
													</h2>
													{bandMember.instrument.map((instruments) => (
														<div>
															{instruments.musician_instrument.name && (
																<h2 className="text-white mb20">
																	{instruments.musician_instrument.name} +
																</h2>
															)}
														</div>
													))}
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="bg-orange height680 pd45">
									<h3 className="text-white text-uppercase mb10 big-text">
										Connect
									</h3>
									<h2 className="text-white mb20">{state.dalbumUrl}</h2>
									<h2 className="text-white mb20">{state.website}</h2>
									<h2 className="text-white mb20 text-uppercase">FOLLOW ON:</h2>
									<ul className="list-inline mb0">
										{state.facebook && (
											<li className="list-inline-item">
												<a href="#">
													{" "}
													<img src={fb} alt="" title="" />
												</a>
											</li>
										)}
										{state.twitter && (
											<li className="list-inline-item">
												<a href="#">
													{" "}
													<img src={twitter} alt="" title="" />
												</a>
											</li>
										)}
										{state.google && (
											<li className="list-inline-item">
												<a href="#">
													{" "}
													<img src={gp} alt="" title="" />
												</a>
											</li>
										)}
										{state.instagram && (
											<li className="list-inline-item">
												<a href="#">
													{" "}
													<img src={insta} alt="" title="" />
												</a>
											</li>
										)}
										{state.vimeo && (
											<li className="list-inline-item">
												<a href="#">
													{" "}
													<img src={vimeo} alt="" title="" />
												</a>
											</li>
										)}
										{state.tumblr && (
											<li className="list-inline-item">
												<a href="#">
													{" "}
													<img src={tumblr} alt="" title="" />
												</a>
											</li>
										)}
									</ul>
								</div>
							</div>
						</div>

						<div className="row mb30">
							<div className="col-md-12">
								<div className="pd20 bg-gray">
									<div className="row mb20">
										<div className="col">
											<h1 className="text-white text-uppercase mb0">
												Business <br /> Contacts
											</h1>
										</div>
									</div>
									{state.bizContacts.map((element) => (
										<div className="row mb20">
											<div className="col text-right">
												<h2 className="text-white">
													{element.firstname} {element.lastname}{" "}
												</h2>
												<h2 className="text-white">{element.email}</h2>
												<h2 className="text-white">{element.website_url}</h2>
												<h2 className="text-white">{element.company}</h2>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
						<div className="row mb30">
							<div className="col-md-12 download-app">
								<ul className="list-inline">
									<li className="list-inline-item">
										<a href="#">
											<img src="img/app-store.png" alt="" title="" />
										</a>
									</li>
									<li className="list-inline-item">
										<a href="#">
											<img src="img/google-play.png" alt="" title="" />
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</section>
			)}
		</div>
	);
};

export default withRouter(EditArtistDetailsComponent);
