import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import validator from "validator";
import Loader from "../loader/loader";
import {
	sortableContainer,
	sortableElement,
	sortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable as arrayMove } from "array-move";
import { confirmAlert } from "react-confirm-alert";
import { showNotification } from "../showNotification";

const DragHandle = sortableHandle(() => (
	<i className="fa fa-bars" aria-hidden="true"></i>
));

const SortableItem = sortableElement(({ onchange, elem, idx, ondelete }) => (
	<div className="track-list-edit align-items-center mb20">
		<div className="track-item-count">
			<h2 className="din-font">{idx + 1}</h2>
		</div>
		<div className="track-edit-content pd15 light-bg">
			<div className="list-flex align-items-center text-white">
				<div>
					<DragHandle />
				</div>
				<div className="track-name-edit">
					<input
						type="text"
						onChange={(e) => onchange(idx, e)}
						value={elem.name}
						className="form-control"
						placeholder="Lonely Beggar"
					/>
				</div>
				<div className="track-play">{/* <i className="fa fa-play"></i> */}</div>
			</div>
		</div>
		<a
			onClick={() => {
				ondelete(idx);
			}}
		>
			<span className="close music-close">
				<i className="fa fa-times"></i>
			</span>
		</a>
	</div>
));

const SortableContainer = sortableContainer(({ children }) => {
	return <div>{children}</div>;
});

const UploadAudio = ({ artistDetails, uploadContent }) => {
	const [state, setState] = useState({
		files: [],
		items: [],
		link: "",
		loader: false,
		dalbumId: 2,
		dextra_label_id: 4,
	});
	// formData = new FormData();

	useEffect(() => {
		getTracks();
	}, []);

	const onSortEnd = ({ oldIndex, newIndex }) => {
		setState((oldState) => ({
			...oldState,
			items: arrayMove(oldState.items, oldIndex, newIndex),
		}));
		//console.log(state.items)
	};
	const getTracks = () => {
		setState((oldState) => ({
			...oldState,
			loader: true,
		}));
		let body = {
			dalbum_id: artistDetails.dalbum_id,
			type: artistDetails.type,
			label_id: artistDetails.dextra_label_id,
		};
		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};
		axios
			.post("/app/dextra/getDextra", body, config)
			.then(function (response) {
				if (
					artistDetails.type == 4 &&
					response.data.data.data[0] &&
					response.data.data.data[0].link &&
					response.data.data.data[0].link != ""
				) {
					setState((oldState) => ({
						...oldState,
						link: response.data.data.data[0].link,
						loader: false,
					}));
				} else if (response.data.success) {
					setState((oldState) => ({
						...oldState,
						items: response.data.data.data,
						loader: false,
					}));
				} else {
					showNotification("Something Went Wrong", "error");
					setState((oldState) => ({
						...oldState,
						loader: false,
					}));
				}
			})
			.catch(function (error) {
				showNotification("Something Went Wrong", "warning");
				setState((oldState) => ({
					...oldState,
					loader: false,
				}));
			});
	};
	const uploadPreview = (e) => {
		var temp = state.files;
		for (var i = 0; i < e.target.files.length; i++) {
			temp.push(e.target.files[i]);
			// formData.append("dextra", e.target.files[i])
		}
		setState((oldState) => ({
			...oldState,
			link: "",
			files: temp,
		}));
	};

	const deleteTrack = (idx) => {
		setState((oldState) => ({
			...oldState,
			loader: true,
		}));
		var temp_items = state.items;

		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};
		axios
			.post("/app/dextra/delete", temp_items[idx], config)
			.then(function (response) {
				if (response.data.success) {
					setState((oldState) => ({
						...oldState,
						loader: false,
					}));
				} else {
					showNotification(response.data.message, "error");
					setState((oldState) => ({
						...oldState,
						loader: false,
					}));
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				setState((oldState) => ({
					...oldState,
					loader: false,
				}));
			});

		temp_items.splice(idx, 1);

		setState((oldState) => ({
			...oldState,
			items: temp_items,
		}));
	};

	const audioSave = () => {
		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};
		let temp = state.items;
		let toBesent = { type: artistDetails.type, data: temp };

		axios
			.post("app/addalbum/save-extra", toBesent, config)
			.then(function (response) {})
			.catch(function (error) {
				//console.log(error);
				setState((oldState) => ({
					...oldState,
					loader: false,
				}));
			});
	};

	const upload = (event) => {
		let formData = new FormData();
		if (artistDetails.type === 1 && state.files.length === 0) {
			return false;
		}

		if (artistDetails.type === 4) {
			if (state.items.length > 0) {
				return false;
			} else if ((!state.link || state.link === "") && state.items.length > 0) {
				return false;
			}
		}
		if (!state.link) {
			for (let value of event.target.files) {
				formData.append("dextra", value);
			}
		}

		setState((oldState) => ({
			...oldState,
			loader: true,
		}));

		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};
		formData.append("dalbum_id", artistDetails.dalbum_id);
		formData.append("type", artistDetails.type);
		formData.append("dextra_label_id", artistDetails.dextra_label_id);
		formData.append("label_name", artistDetails.label);
		//console.log('ffffffffff', artistDetails.type, state.link)
		if (artistDetails.type === 4 && state.link) {
			formData.append("link", state.link);
		}

		axios
			.post("/app/dextra/saveDextra", formData, config)
			.then(function (response) {
				if (response.data.success) {
					setState((oldState) => ({
						...oldState,
						files: [],
					}));
					getTracks();
					setState((oldState) => ({
						...oldState,
						loader: false,
					}));
					uploadContent();
					// setState({ albumData: response.data.data.labels })
					showNotification("Uploaded", "success");
				} else {
					showNotification("Something Went Wrong", "error");
					setState((oldState) => ({
						...oldState,
						loader: false,
					}));
				}
			})
			.catch(function (error) {
				setState((oldState) => ({
					...oldState,
					loader: false,
				}));
				// showNotification(error.name, 'warning', 1000);
			});
	};
	const handleLink = (e) => {
		setState((oldState) => ({
			...oldState,
			link: e.target.value,
		}));
	};

	const AudioNameChange = (index, e) => {
		let temp = state.items;
		temp[index].name = e.target.value;
		setState((oldState) => ({
			...oldState,
			items: temp,
		}));
	};

	const deleteAud = (index) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="confirm-ui">
						<div className="delete-prompt-box">
							<h2 className="text-uppercase mb10">
								Are You Sure You Would
								<br /> Like to Delete This?
							</h2>
							<div className="d-flex mr-3 justify-content-center">
								<button
									type="button"
									onClick={onClose}
									className="btn-save text-uppercase"
								>
									oops, No
								</button>
								<button
									type="button"
									onClick={() => {
										deleteTrack(index);
										onClose();
									}}
									className="btn-cancel text-uppercase"
								>
									Delete
								</button>
							</div>
						</div>
					</div>
				);
			},
		});
	};

	const previewDelete = (index) => {
		var temp = state.files;
		temp.splice(index, 1);
		setState((oldState) => ({
			...oldState,
			files: temp,
		}));
	};
	const { items } = state;

	return (
		<section>
			<div>
				{artistDetails.type == 4 && (
					<div className="videoUpload">
						<ul className="list-unstyled">
							<li>
								<h1>EMBED VIDEO: </h1>
							</li>
							<li>
								<input
									onChange={handleLink}
									value={state.link}
									disabled={
										state.items.length || state.files.length > 0 ? true : false
									}
									type="text"
								/>
							</li>
						</ul>
						<h1 className="text-center or">OR</h1>
					</div>
				)}
				{state.loader && <Loader />}
				<div className="row mb30 upload-wrapper">
					<div className="col-lg-8 uploadtrackLeft">
						<div className="card">
							<div className="card-header">
								<div className="row align-items-center">
									<div className="col-md-6">
										<div className="input-group">
											<div className="btn-cancel uploadButton">
												Choose file
												<input
													type="file"
													disabled={
														state.link && state.link != "" ? true : false
													}
													onChange={(e) => {
														uploadPreview(e);
														upload(e);
													}}
													className="form-control uploadFile"
													aria-label="Text input with dropdown button"
													multiple
													accept={
														artistDetails.type == 1 ? "audio/mp3" : "video/*"
													}
												/>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="option-list pull-right">
											<ul className="list-inline mb0">
												<li className="list-inline-item">
													<a
														onClick={state.link.length ? upload : audioSave}
														className="text-white text-uppercase btn-agree"
													>
														Save
													</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div className="card-body bg-white pd30 overflow-scroll h350">
								<div className="upload-wrapper-conent">
									<h2 className="mb10 din-font text-uppercase ">
										You have{" "}
										<span className="text-green">
											{state.items.length} Files
										</span>{" "}
										that have been uploaded.
									</h2>
									{/* {state.files.map((elem, index) =>
                                            <div key={`preview-${index}`} className="track-list mb10 pd15">
                                                <div className="row mb10">
                                                    <div className="col-md-12">
                                                        <div className="track-name">
                                                            <h3 className="text-uppercase">{index + 1} {elem.name}</h3>
                                                            <a onClick={() => { previewDelete(index) }}><span className="close"><i className="fa fa-times"></i></span></a>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>)} */}
									<SortableContainer onSortEnd={onSortEnd} useDragHandle>
										{items.map((elem, index) => (
											<SortableItem
												key={`item-${index}`}
												index={index}
												idx={index}
												onchange={AudioNameChange}
												elem={elem}
												ondelete={deleteAud}
											/>
										))}
									</SortableContainer>
								</div>
							</div>
						</div>
					</div>

					<div className="col-lg-4 tabStep4">
						<div className="upload-content-detail pd15 bg-white clearfix">
							<h2 className="text-uppercase din-font mb10">
								Upload {artistDetails.type == 1 ? "Track" : "Video"} To:
							</h2>
							<div className="form-group">
								<input
									type="text"
									name=""
									value={artistDetails.label}
									disabled
									className="form-control"
									placeholder=""
								/>
							</div>
							<div className="album-specification">
								<h3 className="text-uppercase">Specifications</h3>
								<h4>
									Format to Upload: <br />
									{(artistDetails.type == 1 ? "MP3" : "MP4") + " (preferred)"}
								</h4>
								<div className="spacer20"></div>
								<h4>
									METADATA can be included <br />
									with ISRC numbers.{" "}
								</h4>
								<div className="spacer20"></div>
								<h4>
									For more information on ISRC <br />
									numbers, visit:
								</h4>
								<a href="#" className="text-orange brekText">
									http://www.nationwidebarcode.com/purchase-barcodes/barcodes-for-music{" "}
								</a>
								{/* <button type="button" onClick={() => { upload() }} className="btn-save text-uppercase pull-right mt30">Upload and Continue</button> */}
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <div className="row mb30">
                    <div className="col-md-12">
                        <h2> ARE YOU GOING TO CHARGE FOR THIS DEXTRA? </h2>
                    </div>
                </div> */}
		</section>
	);
};

export default UploadAudio;
