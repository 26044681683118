import Autosuggest from "react-autosuggest";
import "./suggestion.css";
import { useEffect, useState } from "react";

const escapeRegexCharacters = (str) =>
	str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

const Autosuggestion = ({ artistname, artistdetail, artist, onBlur }) => {
	const [state, setState] = useState({
		value: "",
		suggestions: [],
	});

	useEffect(() => {
		setState((oldState) => ({
			...oldState,
			value: artistname ?? "",
		}));
	}, [artistname]);

	const getSuggestions = (value) => {
		const escapedValue = escapeRegexCharacters(value.trim());

		if (escapedValue === "") {
			return [];
		}

		const regex = new RegExp("^" + escapedValue, "i");
		const suggestions = artist.filter((language) => regex.test(language.name));

		if (suggestions.length === 0) {
			return [{ isAddNew: true }];
		}

		return suggestions;
	};

	const onChange = (event, { newValue, method }) => {
		setState((oldState) => ({
			...oldState,
			value: newValue ?? "",
		}));
	};

	const getSuggestionValue = (suggestion) => {
		if (suggestion.isAddNew) {
			// artistdetail(state.value)
			return state.value;
		} else {
			// artistdetail(suggestion)
		}
		return suggestion.name;
	};

	const renderSuggestion = (suggestion) => {
		if (suggestion.isAddNew) {
			return (
				<span>
					Add New Artist: <strong>{state.value}</strong>
				</span>
			);
		}

		return suggestion.name + (suggestion.is_band ? " (Band)" : " (Solo)");
	};

	const onSuggestionsFetchRequested = ({ value }) => {
		setState((oldState) => ({
			...oldState,
			suggestions: getSuggestions(value),
		}));
	};

	const onSuggestionsClearRequested = () => {
		setState((oldState) => ({
			...oldState,
			suggestions: [],
		}));
	};

	const onSuggestionSelected = (event, { suggestion }) => {
		if (suggestion.isAddNew) {
			artistdetail(state.value);
			return state.value;
		} else {
			artistdetail(suggestion);
		}
		return suggestion.name;
	};

	const { value, suggestions } = state;
	const inputProps = {
		placeholder: "Artist Name",
		value,
		onChange: onChange,
		onBlur: onBlur,
	};

	return (
		<Autosuggest
			suggestions={suggestions}
			onSuggestionsFetchRequested={onSuggestionsFetchRequested}
			onSuggestionsClearRequested={onSuggestionsClearRequested}
			getSuggestionValue={getSuggestionValue}
			renderSuggestion={renderSuggestion}
			onSuggestionSelected={onSuggestionSelected}
			inputProps={inputProps}
		/>
	);
};

export default Autosuggestion;
