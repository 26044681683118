import Header from "../../shared/FanHeader/header";
import Footer from "../../shared/footer/footer";
import ManageAccountComponent from "../../components/fan/manageAccountComponent";

const ManageAccount = () => {
	return (
		<div>
			<Header />
			<ManageAccountComponent />
			<Footer />
		</div>
	);
};

export default ManageAccount;
