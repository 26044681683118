import Header from "../../shared/ArtistHeader/header";
import Footer from "../../shared/footer/footer";
import AboutComponent from "../../shared/about/aboutComponent";

const About = () => {
	return (
		<div>
			<Header />
			<AboutComponent />
			<Footer />
		</div>
	);
};

export default About;
