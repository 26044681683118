import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { showNotification } from "../../shared/showNotification";

const CartComponent = () => {
	const navigate = useNavigate();

	const [state, setState] = useState({
		albums: [],
		image: {},
		suggestion: [],
	});
	const baseURL = axios.defaults.baseURL;

	useEffect(() => {
		getcart();
	}, []);

	const getcart = () => {
		var config = {
			headers: { Authorization: "Bearer " + localStorage.getItem("tokenFan") },
		};

		axios
			.get("/app/fan/getcart", config)
			.then(function (response) {
				//console.log(response.data.data)

				if (response.data.success) {
					showNotification("Added Successfully", "success");
					setState((oldState) => ({
						...oldState,
						albums: response.data.data.data.data,
						suggestion: response.data.data.data.suggestion,
					}));
				} else {
					showNotification(response.data.message, "error");
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				//console.log(error);
			});
	};

	const decreseCount = () => {
		let count = sessionStorage.getItem("cartCount")
			? Number(sessionStorage.getItem("cartCount"))
			: 0;
		if (count > 0) {
			count -= 1;
		} else {
			count = 0;
		}
		sessionStorage.setItem("cartCount", count);
		window.$(document).trigger("cartUpdate", count);
	};

	const deleteFromCart = (elem) => {
		var config = {
			headers: { Authorization: "Bearer " + localStorage.getItem("tokenFan") },
		};

		axios
			.post("/app/fan/deletefromcart", elem, config)
			.then(function (response) {
				//console.log(response.data.data)

				if (response.data.success) {
					decreseCount();
					getcart();
				} else {
					showNotification(response.data.message, "error");
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				//console.log(error);
			});
	};

	const addToWishlist = (album_id) => {
		var config = {
			headers: { Authorization: "Bearer " + localStorage.getItem("tokenFan") },
		};

		let data = { album_id: album_id };
		axios
			.post("/app/fan/addtowishlist", data, config)
			.then(function (response) {
				//console.log(response.data.data)

				if (response.data.success) {
					showNotification("Added Successfully", "success");
				} else {
					showNotification(response.data.message, "error");
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				//console.log(error);
			});
	};

	const addToCart = (elem) => {
		var config = {
			headers: { Authorization: "Bearer " + localStorage.getItem("tokenFan") },
		};

		let data = { album_id: elem.dalbum_id };
		axios
			.post("/app/fan/addtocart", data, config)
			.then(function (response) {
				//console.log(response.data.data)

				if (response.data.success) {
					showNotification("Added Successfully", "success");
					getcart();
				} else {
					showNotification(response.data.message, "error");
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				//console.log(error);
			});
	};

	const setAlbum = (album_id) => {
		navigate("/fan/album-preview", {
			state: { album_id: album_id },
			// state: {name : response.data.data.firstname}
		});
	};
	const setArtist = (id) => {
		//console.log(id,"test")
		navigate("/fan/artist-preview", {
			state: { artist_id: id },
		});
	};

	let total = 0;
	
	return (
		<section className="shopping-cart">
			<div className="container">
				<div className="row">
					<div className="col-lg-8">
						<div className="shopping-inner">
							<div className="row">
								<div className="col-md-12">
									<div className="shopping-heading">
										<h1 className="m-0">
											SHOPPING CART{" "}
											<Link to={"/fan/search"} className="text-orange">
												CONTINUE SHOPPING
											</Link>
										</h1>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-12">
									<div className="shopping-img">
										<ul className="list-unstyled">
											{state.albums.map((album, index) => (
												<li className="media">
													{album.dalbum.artwork_images.length > 0 &&
														album.dalbum.artwork_images.some((elem) => {
															return elem.at_position == -1;
														}) && (
															<img
																onClick={() => {
																	setAlbum(album.dalbum.dalbum_id);
																}}
																className="mr-5"
																src={
																	baseURL +
																	album.dalbum.artwork_images.find((elem) => {
																		return elem.at_position == -1;
																	}).path +
																	album.dalbum.artwork_images.find((elem) => {
																		return elem.at_position == -1;
																	}).name
																}
																alt="Generic placeholder image"
															/>
														)}
													<div className="media-body">
														<div className="row">
															<div className="col-md-9">
																{/* <p className="mt-0 mb-0"><strong>{album.dalbum.artist.name}</strong></p> */}
																<a
																	onClick={() => {
																		setArtist(album.dalbum.artist.artist_id);
																	}}
																>
																	<p className="mt-0 mb-0">
																		<strong>{album.dalbum.artist.name}</strong>
																	</p>
																</a>
																<p className="mt-0 mb-0">
																	<strong>
																		<a
																			onClick={() => {
																				setAlbum(album.dalbum.dalbum_id);
																			}}
																		>
																			{album.dalbum.name}
																		</a>
																	</strong>
																</p>
															</div>
															<div className="col-md-3 text-right">
																<p className="mt-0 mb-0 price-shop-item">
																	Price:{" "}
																	<span>
																		<i>$</i> {album.dalbum.price}
																	</span>
																</p>
															</div>
														</div>
														<p className="">
															Includes: {album.dalbum.dalbum_tracks.length}{" "}
															Tracks
														</p>
														<p className="mb-0">
															<input
																type="checkbox"
																id="checkbox2"
																className="red-checkbox"
															/>
															<label htmlFor="checkbox2" className="mb-0">
																Gift it.{" "}
																<a href="#" className="text-orange">
																	Learn more
																</a>
															</label>
														</p>
														<p className="mb-0">
															<input
																type="checkbox"
																id="checkbox3"
																className="red-checkbox"
															/>
															<label
																htmlFor="checkbox3"
																className="disable-checkbox enable-checkbox mb-0"
															>
																Receive notifications from this artist for their
																Dalbums.
															</label>
														</p>
														
														<div className="delete-list-shop">
															<a
																onClick={() => {
																	deleteFromCart(album.dalbum);
																}}
																className="text-orange delete"
															>
																Delete
															</a>
															<a
																onClick={() => {
																	addToWishlist(album.dalbum.dalbum_id);
																}}
																className="text-orange add-wish"
															>
																Add to Wish List
															</a>
														</div>
													</div>
												</li>
											))}
											{/* <li className="media">
                                                    <img className="mr-5" src="./img/album-cover-2.png" alt="Generic placeholder image" />
                                                    <div className="media-body">
                                                        <div className="row">
                                                            <div className="col-md-9">
                                                                <p className="mt-0 mb-0"><strong>EI Pintor</strong> List-based media object</p>
                                                            </div>
                                                            <div className="col-md-3 text-right">
                                                                <p className="mt-0 mb-0 price-shop-item">Price: <span><i>$</i> 10.00</span></p>
                                                            </div>
                                                        </div>
                                                        <p className="">Includes: 10 Tracks, 1 Booklet, Behind-the-Scenes Photo</p>
                                                        <p className="mb-0">
                                                            <input type="checkbox" id="checkbox5" className="red-checkbox" />
                                                            <label htmlFor="checkbox5" className="mb-0">
                                                                Gift it. <a href="#" className="text-orange">Learn more</a>
                                                            </label>
                                                        </p>
                                                        <p className="mb-0">
                                                            <input type="checkbox" id="checkbox6" className="red-checkbox" />
                                                            <label htmlFor="checkbox6" className="disable-checkbox mb-0">
                                                                Receive notifications from this artist for their Dalbums.
                                        </label>
                                                        </p>
                                                        <p className="">
                                                            <input type="checkbox" id="checkbox7" className="red-checkbox" />
                                                            <label htmlFor="checkbox7" className="disable-checkbox mb-0">
                                                                Receive notifications from this artist for their Dalbums.
                                        </label>
                                                        </p>
                                                        <div className="delete-list-shop">
                                                            <a href="#" className="text-orange delete">Delete</a>
                                                            <a href="#" className="text-orange add-wish">Add to Wish List</a>
                                                        </div>
                                                    </div>
                                                </li> */}
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="subtotal-main mb-3">
							<div className="subtotal">
								{state.albums.length > 0 && (
									<h3>
										Subtotal ({state.albums.length} Dalbums):
										<br />$
										{state.albums.reduce((total, elem) => {
											return total + Number(elem.dalbum.price);
										}, total)}
									</h3>
								)}
								<Link to="/fan/checkout" state={{ data: state }} className="">
									<button className="form-control btn-orange  width100 mt-4">
										PROCEED TO CHECKOUT
									</button>
								</Link>
							</div>
						</div>
						<div className="cart-selection">
							<div className="cart-content-heading">
								<h3 className="mb-3">
									Based on your cart selections how about these Dalbums:
								</h3>
							</div>
							<div className="cart-list-right">
								<ul className="list-unstyled">
									{state.suggestion.map(
										(album, index) =>
											index < 2 && (
												<li className="media mb-4">
													{/* <img className="mr-3" src="./img/album-pic.png" alt="Generic placeholder image" /> */}
													{album.artwork_images.length > 0 &&
														album.artwork_images.some((elem) => {
															return elem.at_position == -1;
														}) && (
															<img
																className="mr-5"
																src={
																	baseURL +
																	album.artwork_images.find((elem) => {
																		return elem.at_position == -1;
																	}).path +
																	album.artwork_images.find((elem) => {
																		return elem.at_position == -1;
																	}).name
																}
																alt="Generic placeholder image"
															/>
														)}

													<div className="media-body">
														<div className="add-to-cart">
															<h3>{album.name}</h3>
															<p className="mb0">{album.name}</p>
															<h2 className="text-green">${album.price}</h2>
															<button
																className="form-control btn-orange"
																onClick={() => addToCart(album)}
															>
																add to cart
															</button>
														</div>
													</div>
												</li>
											)
									)}
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-lg-12">
						<div className="shopping-bottom">
							<p className="mt-5">
								*DalbumX is not responsible with the artist’s usage of your
								email information and what you receive from them.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CartComponent;
