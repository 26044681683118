import React, { Component, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom'
import { showNotification } from '../../shared/showNotification';

const Home = () => {
    
    const { message } = useParams();

    useEffect(() => {
        if(message){
			showNotification(message, 'success');
		}
    }, [message]);

    return (
        <div>
            <nav className="navbar fixed-top navbar-expand-lg navbar-transparent">
                <div className="container">
                    <a className="navbar-brand homeLogo">

                        <img src={require('./../../assets/img/logo.png')} className="" alt="Dalbum" title="Dalbum" />
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#MainNavbar" aria-controls="MainNavbar" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="MainNavbar">
                        <ul className="navbar-nav ml-auto align-items-center">
                            <li className="nav-item active">
                                <a className="nav-link"><img src={require('./../../assets/img/fb-ico.png')} alt="Facebook" title="Facebook" /></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link"><img src={require('./../../assets/img/twitter-ico.png')} alt="Twitter" title="Twitter" /></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link"><img src={require('./../../assets/img/instagram-ico.png')} alt="Instagram" title="Instagram" /></a>
                            </li>
                            <li className="nav-item about">
                                <a href="/about" className="nav-link">About</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>  
            <section id="home" className="intro bg-cover">
                <div className="container lartabWidth">
                    <div className="row align-self-center justify-content-center align-items-center mb30">
                        <div className="fan-side pd10 bg-orange w250 text-left">
                            <h2 className="text-uppercase mb0">
                                <Link to={'/fan/login'} className="text-white" ><i className="fa fa-angle-left"></i> Fan</Link>
                            </h2>
                        </div>
                        <div className="intro-box">
                            <div className="intro-box-inner text-uppercase text-center">
                                <p className="huge-text">Welcome</p>
                                <p className="small-text">To The Most Profound</p>
                                <p className="medium-text">Connection</p>
                                <p className="small-text">Between Artist and Fan</p>
                                <p className="medium-text">Ever Devised</p>
                            </div>
                        </div>
                        <div className="artist-side pd10 bg-orange w250 text-right">
                            <h2 className="text-uppercase mb0">
                            <Link to={'/artist/login'} className="text-white" >Artist <i className="fa fa-angle-right"></i></Link>
                            </h2>
                        </div>
                    </div>

                    <div className="row justify-content-center mt50">
                        <ul className="list-inline">
                            <li className="list-inline-item">
                                <a><img src={require('./../../assets/img/google-play.png')} alt="Google Play" title="Google Play" /></a>
                            </li>
                            <li className="list-inline-item">
                                <a><img src={require('./../../assets/img/app-store.png')} alt="Google Play" title="Google Play" /></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Home;
