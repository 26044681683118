import Header from "../../shared/header/header";
import Footer from "../../shared/footer/footer";
import ArtistForgetPassword from "../../components/artist/artistforget";

const ArtistForget = () => {
	return (
		<div>
			<Header />
			<ArtistForgetPassword />
			<Footer />
		</div>
	);
};

export default ArtistForget;
