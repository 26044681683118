import Header from "../../shared/header/header";
import Footer from "../../shared/footer/footer";
import RegisterArtist from "./../../components/artist/artistRegister";

const ArtistRegister = () => {
	return (
		<div>
			<Header />
			<RegisterArtist />
			<Footer />
		</div>
	);
};

export default ArtistRegister;
