import Header from "../../shared/FanHeader/header";
import Footer from "../../shared/footer/footer";
import ContactUsComponent from "../../shared/contactUs/contactUsComponent";

const FanContactUs = () => {
	return (
		<div>
			<Header />
			<ContactUsComponent />
			<Footer />
		</div>
	);
};

export default FanContactUs;
