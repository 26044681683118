import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Slider from "react-slick";
import { showNotification } from "../../shared/showNotification";
import { getFrontCoverThumbnailUrl } from "../artworkHelper";

const DashBoardComponentFan = () => {
	const navigate = useNavigate();

	const [state, setState] = useState({
		albums: [],
		genres: [],
	});

	const path = axios.defaults.baseURL;

	useEffect(() => {
		getAlbum();
		getGenre();
		sessionStorage.removeItem("searchInput");
		sessionStorage.removeItem("selectedGenre");
	}, []);

	const getAlbum = () => {
		var config = {
			headers: { Authorization: "Bearer " + localStorage.getItem("tokenFan") },
			// params: { 'genre_id': 7, 'sub_genre_id': 7 }
		};
		axios
			.get("/app/fan/dashboard", config)
			.then(function (response) {
				if (response.data.success) {
					// state.artists = response.data.data
					setState((oldState) => ({
						...oldState,
						albums: response.data.data.result,
					}));
				} else {
					showNotification(response.data.message, "error");
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				//console.log(error);
			});
	};

	const getGenre = () => {
		var config = {
			headers: { Authorization: "Bearer " + localStorage.getItem("tokenFan") },
		};
		axios
			.get("/genre?page=" + 1 + "&limit=" + 1200, config)
			.then(function (response) {
				if (response.data.success) {
					// state.artists = response.data.data
					setState((oldState) => ({
						...oldState,
						genres: response.data.data.result,
					}));
				} else {
					showNotification(response.data.message, "error");
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				//console.log(error);
			});
	};

	const selectedGenre = (genre) => {
		navigate("/fan/search", {
			state: { genre },
			// state: {name : response.data.data.firstname}
		});
		sessionStorage.setItem("selectedGenre", JSON.stringify(genre));
	};

	const searchInputChange = (event) => {
		navigate("/fan/search", {
			state: { search: event.target.value },
			// state: {name : response.data.data.firstname}
		});
		sessionStorage.setItem("searchInput", event.target.value);
	};

	const setAlbum = (album_id) => {
		navigate("/fan/album-preview", {
			state: { album_id: album_id },
			// state: {name : response.data.data.firstname}
		});
	};

	var settings = {
		infinite: false,
		slidesToShow: 4,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					arrows: true,
					centerMode: true,
					// centerPadding: '40px',
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					arrows: false,
					centerMode: true,
					// centerPadding: '40px',
					slidesToShow: 1,
				},
			},
		],
	};
	var recentsettings = {
		centerMode: true,
		// centerPadding: '60px',
		slidesToShow: 6,
		infinite: true,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					arrows: true,
					centerMode: true,
					// centerPadding: '40px',
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 480,
				settings: {
					arrows: false,
					centerMode: true,
					// centerPadding: '40px',
					slidesToShow: 1,
				},
			},
		],
	};
	return (
		<div>
			<section className="home-bg">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="album-experiance">
								<h1 className="text-orange text-center">
								Discover the DalbumXperience
								</h1>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							{/* <div className="search-box experiance">
                                    <input onKeyUp={searchInputChange} type="text" className="form-control" placeholder="search" />
                                    <i className="fa fa-search"></i>
                                </div> */}
						</div>
					</div>
					<div className="experiance-bottom">
						<p className="text-center text-white">
							{" "}
							Everything you love about an album, CD and vinyl - and so much more!
						</p>
					</div>
				</div>
			</section>

			<section className="general-catagory">
				<div className="container">
					<div className="row">
						<div className="col-md-8">
							<div className="catagory-inner">
								<div className="catagory-text text-orange mb-1">
									Looking for something?
									<span className="genre">
										GENRE
										<span className="all-options">
											<ul className="list-inline">
												<li className="active">All</li>
												{state.genres.map((genre, index) => (
													<li
														onClick={() => {
															selectedGenre(genre);
														}}
													>
														{" "}
														<a>{genre.name}</a>
													</li>
												))}
												{/* <li>ALTERNATIVE</li>
                                                    <li>BLUES</li>
                                                    <li>CHILDREN</li>
                                                    <li>CHRISTIAN & GOSPEL</li>
                                                    <li>classical</li>
                                                    <li>comedy</li>
                                                    <li>Country</li>
                                                    <li>dance</li>
                                                    <li>electronic</li>
                                                    <li>FITNESS & WORKOUT</li>
                                                    <li>hip-hop/RAP</li>
                                                    <li>indie</li>
                                                    <li>Jazz</li>
                                                    <li>latin</li>
                                                    <li>METAL</li>
                                                    <li>pop</li>
                                                    <li>r&b/soul</li>
                                                    <li>reggae</li>
                                                    <li>Rock</li>
                                                    <li>SINGER/SONGWRITER</li>
                                                    <li>SOUNDTRACK</li>
                                                    <li>WORLD</li> */}
											</ul>
										</span>
									</span>
									<span className="all-catagory">All</span>
								</div>
								{/* <div className="catagory-text text-orange subgenre custome-disable">
                                        <span className="genre">SUBGENRE
                                            <span className="all-options">
                                                <ul className="list-inline">
                                                    <li className="active">All</li>
                                                    <li><a>ALTERNATIVE</a></li>
                                                    <li>BLUES</li>
                                                    <li>CHILDREN</li>
                                                    <li>CHRISTIAN & GOSPEL</li>
                                                    <li>classical</li>
                                                    <li>comedy</li>
                                                    <li>Country</li>
                                                    <li>dance</li>
                                                    <li>electronic</li>
                                                    <li>FITNESS & WORKOUT</li>
                                                    <li>hip-hop/RAP</li>
                                                    <li>indie</li>
                                                    <li>Jazz</li>
                                                    <li>latin</li>
                                                    <li>METAL</li>
                                                    <li>pop</li>
                                                    <li>r&b/soul</li>
                                                    <li>reggae</li>
                                                    <li>Rock</li>
                                                    <li>SINGER/SONGWRITER</li>
                                                    <li>SOUNDTRACK</li>
                                                    <li>WORLD</li>
                                                </ul>
                                            </span>
                                        </span>
                                        <span className="all-catagory">All</span>
                                    </div> */}
							</div>
						</div>
						<div className="col-md-4">
							<div className="search-box experiance sub-search">
								<input
									onKeyUp={searchInputChange}
									type="text"
									className="form-control"
									placeholder="search"
								/>
								<i className="fa fa-search"></i>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="slick-carousala-wrapper mb30">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="heading-slick mt20">
								<h3>FEATURED</h3>
							</div>
						</div>
						<div className="col-md-6">
							<div className="see-all mt20">
								{/* <p><Link to={{  pathname: '/fan/search',  state: { data: 'featured'  }}}>SEE ALL</Link></p> */}
							</div>
						</div>
					</div>
				</div>

				<div className="featured-carousal slick-carousal">
					<Slider {...settings}>
						{state.albums &&
							state.albums.map((album, index) => (
								<a
									onClick={() => {
										setAlbum(album.dalbum_id);
									}}
									key={index}
									className="most-content"
								>
									<div
										className="fan-thumbnail-big"
										style={
											album.artwork_images.length > 0
											? {
												background: `${getFrontCoverThumbnailUrl(album.artwork_images)}`,
												}
											: {}
										}
									></div>
								</a>
							))}
					</Slider>
				</div>
			</section>

			<section className="slick-carousla-wrapper mb30">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="heading-slick mt20">
								<h3>MOST RECENT</h3>
							</div>
						</div>
						<div className="col-md-6">
							<div className="see-all mt20">
								{/* <p><a href="#">SEE ALL</a></p> */}
								<p>
									<Link
										to={{
											pathname: "/fan/search",
											state: { data: "most-recent" },
										}}
									>
										SEE ALL
									</Link>
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="slick-carousal most-recent">
					<Slider {...settings}>
						{state.albums &&
							state.albums.map((album, index) => (
								<>
									<a
										onClick={() => {
											setAlbum(album.dalbum_id);
										}}
										key={index}
										className="most-content"
									>
										<div
											className="fan-thumbnail-big"
											style={
												album.artwork_images.length > 0
													? {
														background: `${getFrontCoverThumbnailUrl(album.artwork_images)}`,
													  }
													: {}
											}
										></div>
										<div className="most-inner pd">
											<h3>{album.name}</h3>
										</div>
									</a>
									<div className="most-inner pd">
										{album.artist && (
											<Link
												to={"/fan/artist-preview/" + album.artist.artist_id}
											>
												{album.artist.name}
											</Link>
										)}
									</div>
								</>
							))}
					</Slider>
				</div>
			</section>

			{/* Popular  */}
			<section className="slick-carousla-wrapper mb30">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="heading-slick mt20">
								<h3>POPULAR</h3>
							</div>
						</div>
						<div className="col-md-6">
							<div className="see-all mt20">
								{/* <p><a href="#">SEE ALL</a></p> */}
								<p>
									<Link
										to={{ pathname: "/fan/search", state: { data: "popular" } }}
									>
										SEE ALL
									</Link>
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="slick-carousal most-recent">
					<Slider {...settings}>
						{state.albums &&
							state.albums.map((album, index) => (
								<>
									<a
										onClick={() => {
											setAlbum(album.dalbum_id);
										}}
										key={index}
										className="most-content"
									>
										<div
											className="fan-thumbnail-big"
											style={
												album.artwork_images.length > 0
													? {
														background: `${getFrontCoverThumbnailUrl(album.artwork_images)}`
													  }
													: {}
											}
										></div>
										<div className="most-inner pd">
											<h3>{album.name}</h3>
										</div>
									</a>
									<div className="most-inner pd">
										{album.artist && (
											<Link
												to={"/fan/artist-preview/" + album.artist.artist_id}
											>
												{album.artist.name}
											</Link>
										)}
									</div>
								</>
							))}
					</Slider>
				</div>
			</section>

			{/* THE WHOLE STORE */}
			<section className="slick-carousla-wrapper mb30">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="heading-slick mt20">
								<h3>THE WHOLE STORE</h3>
							</div>
						</div>
						<div className="col-md-6">
							<div className="see-all mt20">
								{/* <p><a href="#">SEE ALL</a></p> */}
								<p>
									<Link
										to={{
											pathname: "/fan/search",
											state: { data: "whole-store" },
										}}
									>
										SEE ALL
									</Link>
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="slick-carousal most-recent">
					<Slider {...settings}>
						{state.albums &&
							state.albums.map((album, index) => (
								<>
									<a
										onClick={() => {
											setAlbum(album.dalbum_id);
										}}
										key={index}
										className="most-content"
									>
										<div
											className="fan-thumbnail-big" 
											style={
												album.artwork_images.length > 0
													? {
														background: `${getFrontCoverThumbnailUrl(album.artwork_images)}`
													  }
													: {}
											}
										></div>
										<div className="most-inner pd">
											<h3>{album.name}</h3>
										</div>
									</a>
									<div className="most-inner pd">
										{album.artist && (
											<Link
												to={"/fan/artist-preview/" + album.artist.artist_id}
											>
												{album.artist.name}
											</Link>
										)}
									</div>
								</>
							))}
					</Slider>
				</div>
			</section>
		</div>
	);
};

export default DashBoardComponentFan;
