import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useState,
} from "react";
import axios from "axios";
import { useBlocker, useNavigate } from "react-router";
import { showNotification } from "../../../shared/showNotification";
import { errorModal } from "../../../shared/errorModal";
import { getArtworkUrls } from "../../artworkHelper";

const Credit = ({
	idx,
	roles,
	onchange,
	values,
	ondelete,
	type,
	onCheckboxChange,
	instrumentCategory,
	instruments,
	addCategory,
	handleInput,
	addInstrument,
	validateEmail,
	validateUrl,
}) => {
		return (
		<div className="panel-content-inner">
			<div className="pd10 bg-white">
				<form>
					<div className="form-group mb0">
						<div className="row">
							<div className="col-md-4 mbspace">
								{/* <div className="input-group select-wrapper "> */}
								<input
									autoComplete="off"
									className="form-control col-sm-12"
									name="credit_role_id"
									onChange={(e) => {
										onchange(e, idx);
									}}
									value={values.credit_role_id ?? ""}
									list={"CreditRoles" + idx}
									placeholder="Enter Role or Select from List"
								/>
								<datalist id={"CreditRoles" + idx}>
									{roles.map(
										(elem, index) => 
											elem.credit_category_id === Number(type) && (
												<option key={index}>{elem.name}</option>
											)
									)}
								</datalist>
								{/* <select value={values.credit_role_id ? values.credit_role_id : 'notSelected'} className="form-control col-sm-12"
                                        name="credit_role_id"
                                        onChange={(e) => { onchange(e, idx) }}
                                    >
                                        <option disabled value='notSelected'>Select Role</option>
                                        {roles.map((elem, index) =>
                                            <>{values.credit_category_id === 3 ?
                                                <option key={index} value={elem.credit_role_id} >{elem.name}</option> : (elem.credit_category_id === values.credit_category_id && <option key={index} value={elem.credit_role_id} >{elem.name}</option>)
                                            }</>
                                        )}

                                    </select> */}
								{/* </div> */}
							</div>
							<div className="col-md-4 mbspace">
								<input
									value={values.firstname}
									type="text"
									onChange={(e) => {
										onchange(e, idx);
									}}
									name="firstname"
									className="form-control"
									placeholder="First and Middle"
								/>
							</div>
							<div className="col-md-4 mbspace">
								<input
									value={values.lastname}
									type="text"
									onChange={(e) => {
										onchange(e, idx);
									}}
									name="lastname"
									className="form-control"
									placeholder="Last"
								/>
							</div>
						</div>
					</div>
				</form>
			</div>
			<div className="pd10 header-bg">
				<form>
					<div className="form-group mb0">
						<div className="row align-items-center">
							<div className="col-md-4 text-right">
								<label className="text-uppercase mb0">Website:</label>
							</div>
							<div className="col-md-4">
								<input
									value={values.website_url}
									type="text"
									onChange={(e) => {
										onchange(e, idx);
									}}
									onBlur={(e) => {
										validateUrl(e.target.value);
									}}
									name="website_url"
									className="form-control"
								/>
							</div>
							{/* {values.credit_category_id === 2 &&
                                <div className="col-md-4">
                                    <label>Apply to Following Tracks
                                    <input className="custCheckbox" onChange={(e) => { onCheckboxChange(e, idx, values) }} type="checkbox" />
                                    </label>
                                </div>} */}
						</div>
					</div>
				</form>
			</div>
			<div className="pd10 bg-white">
				<form>
					<div className="form-group mb0">
						<div className="row align-items-center">
							<div className="col-md-4 text-right">
								<label className="text-uppercase mb0">Email:</label>
							</div>
							<div className="col-md-4">
								<input
									value={values.email}
									type="text"
									onChange={(e) => {
										onchange(e, idx);
									}}
									name="email"
									onBlur={(e) => {
										validateEmail(e.target.value);
									}}
									className="form-control"
								/>
							</div>
						</div>
					</div>
				</form>
			</div>
			<div className="pd10 bg-white">
				{type === "2" &&
					values.instrument &&
					values.instrument.map((category, cat_index) => (
						<div className="row" key={cat_index}>
							<div className="col-md-6">
								<div className="row mb10">
									<div className="col-md-12">
										<select
											value={
												category.instrument_category_id &&
												category.instrument_category_id != "null"
													? category.instrument_category_id
													: "default"
											}
											className="form-control"
											name="MusicianRole"
											onChange={(e) => {
												onchange(e, idx, cat_index);
											}}
										>
											<option disabled selected value="default">
												Select Category
											</option>
											{instrumentCategory.map((elem, index) => (
												<option value={elem.instrument_category_id} key={index}>
													{elem.name}
												</option>
											))}
										</select>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								{category.data &&
									category.data.map((inst, inst_idx) => (
										<div className="row mb10" key={inst_idx}>
											<div className="col-md-12">
												<input
													autocomplete="off"
													className="form-control col-sm-12"
													name="Instrument"
													onChange={(e) => {
														onchange(e, idx, cat_index, inst_idx);
													}}
													value={
														inst.musician_instrument_id
															? inst.musician_instrument_id
															: ""
													}
													list={"CreditRoles" + cat_index + inst_idx}
													Placeholder="Enter Instrument"
												/>
												<datalist id={"CreditRoles" + cat_index + inst_idx}>
													{instruments.map(
														(elem, index) =>
															elem.instrument_category_id ===
																category.instrument_category_id && (
																<option key={index}>{elem.name}</option>
															)
													)}
												</datalist>
											</div>
										</div>
									))}
								<div className="col-md-12 mb10">
									<a
										onClick={() => {
											addInstrument(idx, cat_index);
										}}
										className="colorCustom"
									>
										<i className="fa fa-plus"></i> Instruments
									</a>
								</div>
							</div>
							<div className="row justify-content-end">
								<div className="col-md-6 mb10 col-md-offset-6">
									<a
										onClick={() => {
											addCategory(idx);
										}}
										className="colorCustom"
									>
										<i className="fa fa-plus"></i> Category
									</a>
								</div>
							</div>
						</div>
					))}
			</div>
			<a
				onClick={() => {
					ondelete(idx, values);
				}}
			>
				<span className="close">
					<i className="fa fa-times"></i>
				</span>
			</a>
		</div>
	);
};

const Step7AddAlbumComponent = forwardRef(({ changestep, confirmation }, ref) => {
	const navigate = useNavigate();
	const [state, setState] = useState({
		credit: [],
		roles: [],
		loader: false,
		tracks: [],
		embedded: [],
		specialThanks: "",
		preview: false,
		albumData: {},
		imagePath: "",
		imageName: "",
		error: {
			websiteUrl: false,
			email: false
		} 
	});

	

	const album_id = sessionStorage.getItem("album_id");

	useEffect(() => {
		getstepdata();
		gettracks();
		getInstrumentsCategory();
		getInstruments();
		window
			.$("#accordion")
			.on("show.bs.collapse", function (e) {
				window.$(e.target).prev(".collapse-header").addClass("active");
			})
			.on("hide.bs.collapse", function (e) {
				window.$(e.target).prev(".collapse-header").removeClass("active");
			});
	}, []);

	useImperativeHandle(ref, () => ({
		save: save,
	}));

	const [stateChanged, setStateChanged] = useState(false);

	useBlocker(() => {
		if (stateChanged) {
			confirmation(save);
		}
	});

	const handleChange = (e, idx, cat_idx, inst_idx) => {
		setStateChanged(true);
		var temp = state.credit;
		if (
			e.target.name === "credit_role_id" &&
			e.target.value.toLowerCase() === "musician"
		) {
			temp[idx].instrument = [
				{
					instrument_category_id: null,
					data: [{ musician_instrument_id: null }],
				},
			];
		} else if (
			e.target.name === "credit_role_id" &&
			e.target.value.toLowerCase() !== "musician"
		) {
			delete temp[idx].instrument;
		}

		if (inst_idx || inst_idx === 0) {
			temp[idx].instrument[cat_idx].data[inst_idx].musician_instrument_id =
				e.target.value;
		} else if (cat_idx != null && cat_idx !== undefined) {
			temp[idx].instrument[cat_idx].instrument_category_id = e.target.value;
			temp[idx].instrument[cat_idx].data = [{ musician_instrument_id: null }];
		}
		temp[idx][e.target.name] = e.target.value;
		setState((oldState) => ({
			...oldState,
			credit: temp,
		}));
	};

	const back = async () => {
		if(Object.values(state.error).includes(true)) {
			if(state.error.email && state.error.websiteUrl) {
				errorModal("Invalid Email & URL Entered.");
				return
			}

			if(state.error.email) {
				errorModal("Invalid Email Entered.");
			}

			if(state.error.websiteUrl) {
				errorModal("Invalid URL Entered.");
			}

			return
		}
		
		if (stateChanged) { 
			await save();
		}
		changestep(6);
	};

	const save = async () => {
		setState((oldState) => ({
			...oldState,
			loader: true,
		}));

		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};
		var data = {
			album_id: album_id,
			credit: state.credit,
		};
		let validated = true;
		for (var i = 0; i < state.credit.length; i++) {
			let validUrl = true;
			let validEmail = true;
			if (state.credit[i].website_url) {
				validUrl = validateUrl(state.credit[i].website_url);
			}
			if (state.credit[i].email) {
				validEmail = validateEmail(state.credit[i].email);
			}
			if (!validEmail || !validUrl) {
				validated = false;
				break;
			}
		}
		if (validated) {
			await axios
				.post("app/addalbum/step7", data, config)
				.then(function (response) {
				})
				.catch(function (error) {
					setState((oldState) => ({
						...oldState,
						loader: false,
					}));
				});
			
			setStateChanged(false);
		} else {
			showNotification("Please provide valid email and url.", "error");
		}
	};

	const getInstruments = () => {
		axios
			.get("/instrument?page=1&limit=210")
			.then(function (response) {
				if (response.data.data.result) {
					setState((oldState) => ({
						...oldState,
						instruments: response.data.data.result,
					}));
				}
			})
			.catch(function (error) {});
	};
	const getInstrumentsCategory = () => {
		axios
			.get("/instrument_category?page=1&limit=210")
			.then(function (response) {
				if (response.data.data.result) {
					setState((oldState) => ({
						...oldState,
						instrumentCategory: response.data.data.result,
					}));
				}
			})
			.catch(function (error) {});
	};

	const addCredit = (type, elem, type_id) => {
		var temp = state.credit;
		if (elem) {
			if (elem === "forAll") {
				temp.push({
					firstname: "",
					lastname: "",
					credit_role_id: "",
					website_url: "",
					email: "",
					forAll: 1,
					credit_category_id: type,
				});
			} else {
				temp.push({
					firstname: "",
					lastname: "",
					credit_role_id: "",
					website_url: "",
					email: "",
					material_sub_category_id: elem.material_sub_category_id,
					material_id: elem[type_id],
					credit_category_id: type,
				});
			}
		} else {
			temp.push({
				firstname: "",
				lastname: "",
				credit_role_id: "",
				website_url: "",
				email: "",
				credit_category_id: type,
			});
		}
		setState((oldState) => ({
			...oldState,
			credit: temp,
		}));
	};

	const sortIntoMusicianCategory = (temp_music) => {
		let temp_musi = temp_music.map(function (item) {
			return Object.assign({}, item);
		});
		for (let [index, elem] of temp_musi.entries()) {
			if (elem.credit_musician_instruments) {
				var result = elem.credit_musician_instruments.reduce(function (r, a) {
					r[a.instrument_category_id] = r[a.instrument_category_id] || [];
					r[a.instrument_category_id].push(a);
					return r;
				}, Object.create(null));
				var arr = [];
				for (let key in result) {
					arr.push(
						Object.assign({ instrument_category_id: key, data: result[key] })
					);
				}
				temp_musi[index].instrument = arr;
			}
		}
		setState((oldState) => ({
			...oldState,
			credit: temp_musi,
		}));
	};

	const sortRoles = (data) => {
		var result = data.reduce(function (r, a) {
			r[a.credit_category_id] = r[a.credit_category_id] || [];
			r[a.credit_category_id].push(a);
			return r;
		}, Object.create(null));
	};

	const editSpecialThanks = (e, idx) => {
		setStateChanged(true);
		var temp = state.credit;
		temp[idx].special_thanks = e.target.value;
		setState((oldState) => ({
			...oldState,
			credit: temp,
		}));
	};

	const saveAndPreview = async () => {
		await save();
		setState((oldState) => ({
			...oldState,
			preview: true,
		}));
	}

	const dontSaveAndPreview = () => {
		getstepdata();
			
		let tempCred = state.credit;
		for (let [i, item] of tempCred.entries()) {
			if (item.credit_category_id !== 5) {
				if (item.firstname.length < 1 || item.credit_role_id.length < 1) {
					tempCred.splice(i, 1);
				}
			}
		}

		setState((oldState) => ({
			...oldState,
			preview: state.preview ? false : true,
			credit: tempCred,
		}));
	}

	const showpreview = () => {
		if(!state.preview && stateChanged) {
			confirmation(async () => {
				await saveAndPreview();
			}, () => {
				dontSaveAndPreview();
			});
		} else {
			dontSaveAndPreview();
		}
	};

	const sortintoCatagory = (data) => {
		var sorted = data.sort(function (a, b) {
			return a.at_position - b.at_position;
		});
		setState((oldState) => ({
			...oldState,
			tracks: sorted,
		}));
	};

	const deleteCredit = (idx, elem) => {
		setStateChanged(true);
		
		var temp = state.credit;
		temp.splice(idx, 1);
		setState((oldState) => ({
			...oldState,
			credit: temp,
		}));

		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};

		axios
			.get("app/addalbum/step7delete", config)
			.then(function (response) {
				
			})
			.catch(function (error) {
				setState((oldState) => ({
					...oldState,
					loader: false,
				}));
			});
	};

	const getstepdata = () => {
		setState((oldState) => ({
			...oldState,
			loader: true,
		}));

		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};

		axios
			.get(
				"app/addalbum/step7?album_id=" + sessionStorage.getItem("album_id"),
				config
			)
			.then(function (response) {
				var special = response.data.data.credit.filter((elem, index) => {
					return elem.credit_category_id === 5;
				});
				sortRoles(response.data.data.roles);
				var temp = response.data.data.credit;
				if (special.length > 0) {
					setState((oldState) => ({
						...oldState,
						specialThanks: special[0],
					}));
				} else {
					temp.push({ credit_category_id: 5, special_thanks: "" });
				}
				let imgTemp = response.data.data.album.artwork_images.find((elem) => {
					return elem.at_position === 0;
				});
				sortIntoMusicianCategory(temp);
				setState((oldState) => ({
					...oldState,
					roles: response.data.data.roles,
					embedded: response.data.data.hidden,
					loader: false,
					albumData: response.data.data.album,
					imagePath: imgTemp ? axios.defaults.baseURL + imgTemp.path + imgTemp.name : "",
					imageName: imgTemp.name,
				}));
			})
			.catch(function (error) {
				setState((oldState) => ({
					...oldState,
					loader: false,
				}));
			});
	};

	const onCheckboxChange = (e, idx, elem) => {
		if (e.target.checked) {
			let temp = state.credit;
			let flag = false;
			const result = state.tracks.filter((track, index) => {
				if (flag) {
					return track;
				}
				if (elem.material_id === track.track_id) {
					flag = true;
				}
			});
			for (let temp_cred of result) {
				let hh = Object.assign({}, elem);
				hh.material_id = temp_cred.track_id;
				temp.push(hh);
			}
			setState((oldState) => ({
				...oldState,
				credit: temp,
			}));
		}
	};

	const gettracks = () => {
		setState((oldState) => ({
			...oldState,
			loader: true,
		}));

		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};

		axios
			.get(
				"app/addalbum/step4?album_id=" + sessionStorage.getItem("album_id"),
				config
			)
			.then(function (response) {
				setState((oldState) => ({
					...oldState,
					loader: false,
				}));
				sortintoCatagory(response.data.data);
			})
			.catch(function (error) {
				setState((oldState) => ({
					...oldState,
					loader: false,
				}));
			});
	};

	const addInstrument = (musi_idx, cat_idx) => {
		let temp = state.credit;
		let temp2 = temp[musi_idx].instrument;
		temp2[cat_idx].data.push({ musician_instrument_id: null });
		temp[musi_idx].instrument = temp2;
		setState((oldState) => ({
			...oldState,
			credit: temp,
		}));
	};

	const addCategory = (musi_idx) => {
		let temp = state.credit;
		temp[musi_idx].instrument.push({
			instrument_category_id: null,
			data: [{ musician_instrument_id: null }],
		});
		setState((oldState) => ({
			...oldState,
			credit: temp,
		}));
	};

	const submit = async () => {
		if(Object.values(state.error).includes(true)) {
			if(state.error.email && state.error.websiteUrl) {
				errorModal("Invalid Email & URL Entered.");
				return
			}

			if(state.error.email) {
				errorModal("Invalid Email Entered.");
			}

			if(state.error.websiteUrl) {
				errorModal("Invalid URL Entered.");
			}
			return
		}

		setStateChanged(false);
		
		await save();

		navigate("/artist/final-check", {
			state: {
				album_id: sessionStorage.getItem("album_id"),
				artwork: state.imagePath,
				artwork_name: state.imageName
			},
		});
	};

	const validateEmail = (email) => {
		var mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (email && !email.match(mailFormat)) {
			errorModal("Invalid Email Entered.");
			setState((oldstate) => ({
				...oldstate,
				error: {
					...oldstate.error,
					email: true
				},
			}))
			return false;
		}
		setState((oldstate) => ({
				...oldstate,
				error: {
					...oldstate.error,
					email:false
				},
		}))
		return true;
	};

	const validateUrl = (url) => {
		var urlFormat =
			/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
		if (url && !url.match(urlFormat)) {
			errorModal("Invalid URL Entered.")
			setState((oldstate) => ({
				...oldstate,
				error: {
					...oldstate.error,
					websiteUrl: true
				},
			}))
			return false;
		}
		setState((oldstate) => ({
				...oldstate,
				error: {
					...oldstate.error,
					websiteUrl:false
				},
		}))
		return true;
	};

	return (
		<div>
			{/* {state.loader && <Loader />} */}
			{!state.preview && (
				<>
					<div className="row mb30">
						<div className="col-md-12">
							<h3 className="text-justify mb20">
								This last step of your CORE DALBUM is about giving credit where
								credit is due. Once you publish your Dalbum, credits cannot be
								removed.
							</h3>
							<h3>
								Why Credits? Because if someone loves your artwork, they can
								find out who designed it - and maybe even hire them. Or if
								someone loves that trumpet solo on your track, they can find the
								musician’s other credited work - and maybe even hire them, as
								well. Think of it like the music industry's version of IMDB.
								Take your time with this section. Cross reference with your
								liner notes. Look out for typos. If you leave a feld blank, no
								worries, it just won’t appear in the credits. Your collaborators
								will LOVE you for ONE LAST NOTE: These credits pertain to this
								specific Dalbum, they WILL NOT reflect what your current status
								is.
							</h3>
						</div>
					</div>
					<div className="row mb30">
						<div className="credit-step col-md-8 offset-md-4">
							<div className="media align-items-center">
								<div
									className="imgPfofile"
									style={{
										backgroundImage: `${getArtworkUrls({
											name : state.imageName,
											square: true,
										})}`,
										backgroundPosition: "center",
										backgroundSize: "cover",
										backgroundRepeat: "no-repeat",
									}}
								></div>
								<div className="media-body ml-10 m-text-center">
									<h5 className="mt-0 text-uppercase">
										{state.albumData.name}
									</h5>
									<h5 className="mt-0 text-uppercase">
										{state.albumData.copyright_year}
									</h5>
									<h5 className="mt-0 text-uppercase">
										{state.albumData.dalbum_record_lbl}
									</h5>
								</div>
							</div>
						</div>
					</div>
					<div className="row mb30">
						<div className="col-md-12">
							<div className="credit-step-list" id="accordion">
								<div className="collapse-header pd15 header-bg">
									<a
										href="#"
										data-toggle="collapse"
										data-target="#ArtworkCollpase"
										className="text-green text-uppercase"
									>
										Artwork <i className="fa fa-angle-right pull-right"></i>
									</a>
								</div>
								<div
									id="ArtworkCollpase"
									className="panel-collapse collapse"
									aria-labelledby="ArtworkHeading"
									data-parent="#accordion"
								>
									{state.credit.map((elem, index) => (
										<>
											{elem.credit_category_id === 1 && (
												<Credit
													validateUrl={validateUrl}
													validateEmail={validateEmail}
													key={index}
													idx={index}
													type="1"
													ondelete={deleteCredit}
													roles={state.roles}
													onchange={handleChange}
													values={elem}
												/>
											)}
										</>
									))}
									<div className="row">
										<div className="col-md-12">
											<div className="pd10 pull-right">
												<button
													className="btn btn-gradient"
													onClick={() => {
														addCredit(1);
													}}
												>
													+ Add Credit
												</button>
											</div>
										</div>
									</div>
								</div>

								<div className="collapse-header pd15 bg-white">
									<a
										href="#"
										data-toggle="collapse"
										data-target="#TrackCollpase"
										className="text-green text-uppercase"
									>
										Tracks <i className="fa fa-angle-right pull-right"></i>
									</a>
								</div>
								<div
									id="TrackCollpase"
									className="panel-collapse collapse"
									aria-labelledby="TrackHeading"
									data-parent="#accordion"
								>
									<div className="panel-content-inner">
										<div className="sub-header-inner header-bg">
											<a
												href="#"
												data-toggle="collapse"
												data-target={"#trackAll"}
												className="text-green text-uppercase"
											>
												Tracks(All)
												<i className="fa fa-angle-right pull-right"></i>
											</a>
										</div>
										<div
											id={"trackAll"}
											className="pd15 panel-collapse collapse"
											aria-labelledby="ArtworkHeading"
											data-parent="#TrackCollpase"
										>
											{state.credit.map((elem, idx) => (
												<>
													{elem.credit_category_id === 2 &&
														elem.forAll === 1 && (
															<Credit
																validateUrl={validateUrl}
																validateEmail={validateEmail}
																key={idx}
																idx={idx}
																addCategory={state.addCategory}
																addInstrument={addInstrument}
																instrumentCategory={state.instrumentCategory}
																instruments={state.instruments}
																onCheckboxChange={onCheckboxChange}
																type="2"
																ondelete={deleteCredit}
																roles={state.roles}
																onchange={handleChange}
																values={elem}
															/>
														)}
												</>
											))}
											<div className="row">
												<div className="col-md-12">
													<div className="pd10 pull-right">
														<button
															className="btn btn-gradient"
															onClick={() => {
																addCredit(2, "forAll");
															}}
														>
															+ Add Credit
														</button>
													</div>
												</div>
											</div>
										</div>
										{state.tracks.map((track, index) => (
											<div key={index}>
												<div className="sub-header-inner header-bg">
													<a
														href="#"
														data-toggle="collapse"
														data-target={"#track" + index}
														className="text-green text-uppercase"
													>
														Track {index + 1}: {track.name}{" "}
														<i className="fa fa-angle-right pull-right"></i>
													</a>
												</div>
												<div
													id={"track" + index}
													className="pd15 panel-collapse collapse"
													aria-labelledby="ArtworkHeading"
													data-parent="#TrackCollpase"
												>
													{state.credit.map((elem, idx) => (
														<>
															{elem.credit_category_id === 2 &&
																elem.material_id === track.track_id && (
																	<Credit
																		validateUrl={validateUrl}
																		validateEmail={validateEmail}
																		key={idx}
																		idx={idx}
																		addCategory={addCategory}
																		addInstrument={addInstrument}
																		instrumentCategory={
																			state.instrumentCategory
																		}
																		instruments={state.instruments}
																		onCheckboxChange={onCheckboxChange}
																		type="2"
																		ondelete={deleteCredit}
																		roles={state.roles}
																		onchange={handleChange}
																		values={elem}
																	/>
																)}
														</>
													))}
													<div className="row">
														<div className="col-md-12">
															<div className="pd10 pull-right">
																<button
																	className="btn btn-gradient"
																	onClick={() => {
																		addCredit(2, track, "track_id");
																	}}
																>
																	+ Add Credit
																</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										))}
									</div>
								</div>

								<div className="collapse-header pd15 header-bg">
									<a
										href="#"
										data-toggle="collapse"
										data-target="#EmbeddedCollpase"
										className="text-green text-uppercase"
									>
										embedded <i className="fa fa-angle-right pull-right"></i>
									</a>
								</div>
								<div
									id="EmbeddedCollpase"
									className="collapse"
									aria-labelledby="TrackHeading"
									data-parent="#accordion"
								>
									<div className="panel-content-inner">
										{state.embedded.map((embed, index) => (
											<>
												{embed.other_tracks.length > 0 && (
													<>
														{embed.other_tracks.map((track, idx) => (
															<>
																<div className="sub-header-inner header-bg">
																	<a
																		href="#"
																		data-toggle="collapse"
																		data-target={"#embeddedtrack" + idx}
																		className="text-green text-uppercase"
																	>
																		{" "}
																		Audio : {track.name}
																		<i className="fa fa-angle-right pull-right"></i>
																	</a>
																</div>
																<div
																	id={"embeddedtrack" + idx}
																	className="pd15 panel-collapse collapse"
																	aria-labelledby="ArtworkHeading"
																	data-parent="#TrackCollpase"
																>
																	{state.credit.map((elem, idxx) => (
																		<>
																			{elem.credit_category_id === 3 &&
																				elem.material_id ===
																					track.other_track_id &&
																				elem.material_sub_category_id === 1 && (
																					<Credit
																						validateUrl={validateUrl}
																						validateEmail={validateEmail}
																						key={idxx}
																						type="2"
																						idx={idxx}
																						addCategory={addCategory}
																						ondelete={deleteCredit}
																						roles={state.roles}
																						onchange={handleChange}
																						values={elem}
																					/>
																				)}
																		</>
																	))}
																	<div className="row">
																		<div className="col-md-12">
																			<div className="pd10 pull-right">
																				<button
																					className="btn btn-gradient"
																					onClick={() => {
																						addCredit(
																							3,
																							track,
																							"other_track_id"
																						);
																					}}
																				>
																					+ Add Credit
																				</button>
																			</div>
																		</div>
																	</div>
																</div>
															</>
														))}
													</>
												)}

												{embed.other_images.length > 0 && (
													<>
														{embed.other_images.map((image, idx) => (
															<>
																<div className="sub-header-inner header-bg">
																	<a
																		href="#"
																		data-toggle="collapse"
																		data-target={"#embeddedimage" + index + idx}
																		className="text-green text-uppercase"
																	>
																		{" "}
																		Image : {image.name}
																		<i className="fa fa-angle-right pull-right"></i>
																	</a>
																</div>
																<div
																	id={"embeddedimage" + index + idx}
																	className="pd15 panel-collapse collapse"
																	aria-labelledby="ArtworkHeading"
																	data-parent="#TrackCollpase"
																>
																	{state.credit.map((elem, idxx) => (
																		<>
																			{elem.credit_category_id === 3 &&
																				elem.material_id ===
																					image.other_image_id &&
																				(elem.material_sub_category_id === 3 ||
																					elem.material_sub_category_id ==
																						2) && (
																					<Credit
																						validateUrl={validateUrl}
																						validateEmail={validateEmail}
																						key={idxx}
																						idx={idxx}
																						type="1"
																						ondelete={deleteCredit}
																						roles={state.roles}
																						onchange={handleChange}
																						values={elem}
																					/>
																				)}
																		</>
																	))}
																	<div className="row">
																		<div className="col-md-12">
																			<div className="pd10 pull-right">
																				<button
																					className="btn btn-gradient"
																					onClick={() => {
																						addCredit(
																							3,
																							image,
																							"other_image_id"
																						);
																					}}
																				>
																					+ Add Credit
																				</button>
																			</div>
																		</div>
																	</div>
																</div>
															</>
														))}
													</>
												)}
												{embed.videos.length > 0 && (
													<>
														{embed.videos.map((video, idx) => (
															<>
																<div className="sub-header-inner header-bg">
																	<a
																		href="#"
																		data-toggle="collapse"
																		data-target={"#embeddedvideo" + idx}
																		className="text-green text-uppercase"
																	>
																		{" "}
																		Video : {video.name || video.link}
																		<i className="fa fa-angle-right pull-right"></i>
																	</a>
																</div>
																<div
																	id={"embeddedvideo" + idx}
																	className="pd15 panel-collapse collapse"
																	aria-labelledby="ArtworkHeading"
																	data-parent="#TrackCollpase"
																>
																	{state.credit.map((elem, idxx) => (
																		<>
																			{elem.credit_category_id === 3 &&
																				elem.material_id === video.video_id && (
																					<Credit
																						validateUrl={validateUrl}
																						validateEmail={validateEmail}
																						key={idxx}
																						idx={idxx}
																						type="2"
																						ondelete={deleteCredit}
																						roles={state.roles}
																						onchange={handleChange}
																						values={elem}
																					/>
																				)}
																		</>
																	))}
																	<div className="row">
																		<div className="col-md-12">
																			<div className="pd10 pull-right">
																				<button
																					className="btn btn-gradient"
																					onClick={() => {
																						addCredit(3, video, "video_id");
																					}}
																				>
																					+ Add Credit
																				</button>
																			</div>
																		</div>
																	</div>
																</div>
															</>
														))}
													</>
												)}
											</>
										))}
									</div>
								</div>
								<div className="collapse-header pd15 bg-white">
									<a
										href="#"
										data-toggle="collapse"
										data-target="#BusinessCollpase"
										className="text-green text-uppercase"
									>
										Business Credits{" "}
										<i className="fa fa-angle-right pull-right"></i>
									</a>
								</div>
								<div
									id="BusinessCollpase"
									className="collapse"
									aria-labelledby="BusinessCreditHeading"
									data-parent="#accordion"
								>
									{state.credit.map((elem, index) => (
										<>
											{elem.credit_category_id === 4 && (
												<Credit
													validateUrl={validateUrl}
													validateEmail={validateEmail}
													key={index}
													idx={index}
													type="4"
													ondelete={deleteCredit}
													roles={state.roles}
													onchange={handleChange}
													values={elem}
												/>
											)}
										</>
									))}
									<div className="row">
										<div className="col-md-12">
											<div className="pd10 pull-right">
												<button
													className="btn btn-gradient"
													onClick={() => {
														addCredit(4);
													}}
												>
													+ Add Credit
												</button>
											</div>
										</div>
									</div>
								</div>
								<div className="collapse-header pd15 header-bg">
									<a
										href="#"
										data-toggle="collapse"
										data-target="#SpecialCollpase"
										className="text-green text-uppercase"
									>
										Special Thanks{" "}
										<i className="fa fa-angle-right pull-right"></i>
									</a>
								</div>
								<div
									id="SpecialCollpase"
									className="collapse"
									aria-labelledby="SpecialThanksHeading"
									data-parent="#accordion"
								>
									<div className="panel-content-inner">
										<div className="row">
											<div className="col-md-8 offset-md-2">
												<form>
													{state.credit.map(
														(elem, index) =>
															elem.credit_category_id === 5 && (
																<div className="form-group mb10 mt10">
																	<textarea
																		className="form-control"
																		onChange={(e) => {
																			editSpecialThanks(e, index);
																		}}
																		value={elem.special_thanks}
																		rows="7"
																	></textarea>
																</div>
															)
													)}
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="row form-group justify-content-center mblBtn">
						<button
							type="button"
							className="btn-cancel text-uppercase"
							onClick={async () => {
								await back();
							}}
						>
							Back
						</button>
						<button
							type="button"
							className="btn-cancel text-uppercase"
							onClick={showpreview}
						>
							Preview Credits
						</button>
						<button
							type="button"
							className="btn-save text-uppercase"
							onClick={submit}
						>
							Done
						</button>
					</div>
				</>
			)}
			{state.preview && (
				<>
					<div className="row mb30">
						<div className="bg-black pd20 width100">
							<h3 className="text-uppercase text-green text-center mb20 big-text">
								Credit Where Credit Is Due
							</h3>
							<div className="row">
								<div className="col-md-6">
									<div className="credit-left text-white">
										{state.credit.some((item) => {
											return item.credit_category_id === 1;
										}) && <h3 className="text-white">ARTWORK</h3>}
										{state.credit.map(
											(elem, index) =>
												elem.credit_category_id === 1 && (
													<h3 className="text-white">
														{elem.credit_role_id}: {elem.firstname}{" "}
														{elem.lastname}
													</h3>
												)
										)}
										<br />
										{/* <h3 className="text-white mb20">Designer: Jane Smith</h3> */}
										{state.credit.some((item) => {
											return item.forAll === 1;
										}) && <h3 className="text-white">TRACKS (ALL)</h3>}
										{state.credit.map(
											(elem, index) =>
												elem.credit_category_id === 2 &&
												elem.forAll === 1 && (
													<h3 className="text-white">
														{elem.credit_role_id}: {elem.firstname}{" "}
														{elem.lastname}
													</h3>
												)
										)}
										<br />
										{/* <h3 className="text-white">Producer: Interpol, LLC</h3>
                                            <h3 className="text-white">Writer: Daniel Kessler</h3>
                                            <h3 className="text-white">Lead Vocals and Guitar: Paul Banks</h3>
                                            <h3 className="text-white">Piano: Daniel Kessler</h3>
                                            <h3 className="text-white">Drums: Sam Fogarino</h3>
                                            <h3 className="text-white">Engineer: James Brown</h3>
                                            <h3 className="text-white">Assistant Engineer: Dakota Bowman</h3>
                                            <h3 className="text-white">Assistant Engineer: Phil Joy</h3>
                                            <h3 className="text-white">Mixing Engineer: John Catlin</h3>
                                            <h3 className="text-white mb20">Mixing: Alan  Moulder</h3> */}
										{state.credit.some((item) => {
											return item.credit_category_id === 2;
										}) && <h3 className="text-white">ADDITIONAL CREDITS</h3>}

										{state.tracks.map((track, track_index) => (
											<>
												{state.credit.some((item) => {
													return (
														item.credit_category_id === 2 &&
														item.material_id === track.track_id
													);
												}) && <h3 className="text-white">{track.name}</h3>}
												{state.credit.map(
													(elem, index) =>
														elem.credit_category_id === 2 &&
														elem.material_id === track.track_id && (
															<h3 className="text-white">
																{elem.credit_role_id}: {elem.firstname}{" "}
																{elem.lastname}
															</h3>
														)
												)}
												<br />
											</>
										))}
									</div>
								</div>
								<div className="col-md-6">
									<div className="credit-right text-white">
										{state.credit.some((item) => {
											return item.credit_category_id === 3;
										}) && <h3 className="text-white">EMBEDDED MATERIALS</h3>}
										{state.embedded.map((embed, embed_index) => (
											<>
												{embed.other_tracks.length > 0 && (
													<>
														{embed.other_tracks.map((track, idx) => (
															<>
																{state.credit.some((item) => {
																	return (
																		item.material_sub_category_id === 1 &&
																		item.credit_category_id === 3 &&
																		item.material_id === track.other_track_id
																	);
																}) && (
																	<h3 className="text-white">{track.name}</h3>
																)}
																{state.credit.map(
																	(elem, index) =>
																		elem.credit_category_id === 3 &&
																		elem.material_id === track.other_track_id &&
																		elem.material_sub_category_id === 1 && (
																			<h3 className="text-white">
																				{elem.credit_role_id}: {elem.firstname}{" "}
																				{elem.lastname}
																			</h3>
																		)
																)}
															</>
														))}
													</>
												)}
												{embed.other_images.length > 0 && (
													<>
														{embed.other_images.map((image, idx) => (
															<>
																{state.credit.some((item) => {
																	return (
																		(item.material_sub_category_id === 2 ||
																			item.material_sub_category_id === 3) &&
																		item.credit_category_id === 3 &&
																		item.material_id === image.other_image_id
																	);
																}) && (
																	<h3 className="text-white">{image.name}</h3>
																)}
																{state.credit.map(
																	(elem, index) =>
																		elem.credit_category_id === 3 &&
																		elem.material_id === image.other_image_id &&
																		(elem.material_sub_category_id === 2 ||
																			elem.material_sub_category_id === 3) && (
																			<h3 className="text-white">
																				{elem.credit_role_id}: {elem.firstname}{" "}
																				{elem.lastname}
																			</h3>
																		)
																)}
															</>
														))}
													</>
												)}
												{embed.videos.length > 0 && (
													<>
														{embed.videos.map((video, idx) => (
															<>
																{state.credit.some((item) => {
																	return (
																		item.material_sub_category_id === 4 &&
																		item.credit_category_id === 3 &&
																		item.material_id === video.video_id
																	);
																}) && (
																	<h3 className="text-white">{video.name}</h3>
																)}
																{state.credit.map(
																	(elem, index) =>
																		elem.credit_category_id === 3 &&
																		elem.material_id === video.video_id &&
																		elem.material_sub_category_id === 4 && (
																			<h3 className="text-white" key={index}>
																				{elem.credit_role_id}: {elem.firstname}{" "}
																				{elem.lastname}
																			</h3>
																		)
																)}
															</>
														))}
													</>
												)}
											</>
										))}
										<br />

										{state.credit.some((item) => {
											return item.credit_category_id === 4;
										}) && <h3 className="text-white">BUSINESS CREDITS</h3>}
										{state.credit.map(
											(elem, index) =>
												elem.credit_category_id === 4 && (
													<h3 className="text-white" key={index}>
														{elem.credit_role_id}: {elem.firstname}{" "}
														{elem.lastname}
													</h3>
												)
										)}
										<br />

										{state.credit.some((item) => {
											return (
												item.credit_category_id === 5 &&
												item.special_thanks.length > 0
											);
										}) && <h3 className="text-white">SPECIAL THANKS</h3>}
										{state.credit.map(
											(elem, index) =>
												elem.credit_category_id === 5 && (
													<h3 className="text-white" key={index}>
														{elem.special_thanks}
													</h3>
												)
										)}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="row form-group justify-content-center">
						<button
							type="button"
							className="btn-cancel text-uppercase"
							onClick={showpreview}
						>
							Go Back To Edit
						</button>
						<button
							type="button"
							className="btn-save text-uppercase"
							onClick={submit}
						>
							Done
						</button>
					</div>
				</>
			)}
		</div>
	);
});

export default Step7AddAlbumComponent;
