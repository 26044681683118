import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import axios from "axios";
import Loader from "../../../shared/loader/loader";
import { sortableContainer, sortableElement, sortableHandle } from "react-sortable-hoc";
import { arrayMoveImmutable as arrayMove } from "array-move";
import Sound from "react-sound";
import { showNotification } from "../../../shared/showNotification";
import { errorModal } from "../../../shared/errorModal";

const DragHandle = sortableHandle(() => <i className="fa fa-bars" aria-hidden="true"></i>);

const SortableItem = sortableElement(({ onchange, elem, idx, ondelete, play, togglePlay }) => (
  <div className="track-list-edit align-items-center mb20">
    <div className="track-item-count">
      <h2 className="din-font">{idx + 1}</h2>
    </div>
    <div className="track-edit-content pd15 light-bg">
      <div className="list-flex align-items-center text-white">
        <div>
          <DragHandle />
        </div>
        <div className="track-name-edit">
          <input
            type="text"
            onChange={(e) => {
              onchange(e, idx, elem);
            }}
            value={elem.name}
            className="form-control"
            placeholder="Lonely Beggar"
          />
        </div>
        <div className="track-play">
          <i
            onClick={() => {
              togglePlay(idx);
            }}
            className={(play ? "fa-stop" : "fa-play") + " fa"}
          ></i>
        </div>
      </div>
    </div>
    <a
      onClick={() => {
        ondelete(elem);
      }}
    >
      <span className="close music-close">
        <i className="fa fa-times"></i>
      </span>
    </a>
  </div>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

const Step4AddAlbumComponent = forwardRef(({ album_name, updateSize, changestep, total_size }, ref) => {
  const [state, setState] = useState({
    files: [],
    items: [],
    loader: false,
    uploadPercentage: 0,
    playStatus: {},
    playIndex: null,
    totalFilesize: 0,
    totalSize: total_size / (1024 * 1024),
  });
  const [testFiles, setTestFiles] = useState([]);

  let playFlag = "";

  console.log("Total size from prev step: ", state.totalSize);
  console.log("Total file size : ", state.totalFilesize);

  useEffect(() => {
    getstepdata();
	update()
  }, []);

  useImperativeHandle(ref, () => ({
    save: save,
  }));

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setState((oldState) => ({
      ...oldState,
      items: arrayMove(oldState.items, oldIndex, newIndex),
    }));
  };

  const uploadPreview = (e) => {
    var temp = state.files;
    for (var i = 0; i < e.target.files.length; i++) {
      // Check if the file has mp3 or m4a extension
      if (e.target.files[i].name.endsWith(".mp3") || e.target.files[i].name.endsWith(".MP3") || e.target.files[i].name.endsWith(".m4a")) {
        temp.push(e.target.files[i]);
      } else {
        errorModal("Other than mp3 or m4a file will not be uploaded.");
      }
    }

    console.log("MP3 files: ", temp);
    setState((oldState) => ({
      ...oldState,
      files: temp,
    }));
  };
  const previewDelete = (index) => {
    var temp = state.files;
    temp.splice(index, 1);
    setState((oldState) => ({
      ...oldState,
      files: temp,
      uploadPercentage: 0,
    }));
  };

  const handleChange = (e, index, elem) => {
    var temp = state.items;
    temp[index].name = e.target.value;
    setState((oldState) => ({
      ...oldState,
      items: temp,
    }));
  };

  const update = () => {
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
      },
    };

    axios
      .get("app/addalbum/step2?album_id=" + sessionStorage.getItem("album_id"), config)
      .then(function (response) {
        if (response.data.success) {
          setState((oldState) => ({
            ...oldState,
			totalSize: response.data.data.total_size / (1024 * 1024)
          }));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const sortintoCatagory = (data) => {
    var sorted = data.sort(function (a, b) {
      return a.at_position - b.at_position;
    });
    setState((oldState) => ({
      ...oldState,
      items: sorted,
    }));
  };

  const getstepdata = () => {
    setState((oldState) => ({
      ...oldState,
      loader: true,
    }));

    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
      },
    };

    axios
      .get("app/addalbum/step4?album_id=" + sessionStorage.getItem("album_id"), config)
      .then(function (response) {
        let totalFilesize = 0;
        response.data.data.map((item) => {
          totalFilesize += Number(item.size);
        });
        setState((oldState) => ({
          ...oldState,
          items: response.data.data,
          loader: false,
          totalFilesize: totalFilesize / (1024 * 1024),
        }));
        sortintoCatagory(response.data.data);
      })
      .catch(function (error) {
        setState((oldState) => ({
          ...oldState,
          loader: false,
        }));
      });
  };

  const upload = () => {
    if (state.files.length === 0) {
      errorModal("Please upload a file");
      return;
    }
    if (state.totalSize > process.env.REACT_APP_UPLOAD_SIZE_LIMIT) {
      errorModal(process.env.REACT_APP_MSG_FILE_SIZE);
      return;
    }
    setState((oldState) => ({
      ...oldState,
      loader: true,
    }));

    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
      },
      onUploadProgress: function (progressEvent) {
        setState((oldState) => ({
          ...oldState,
          uploadPercentage: parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)),
        }));
      }.bind(this),
    };

    var formData = new FormData();
    formData.append("album_id", sessionStorage.getItem("album_id"));

    var at_position = state.items.length;
    for (var i = 0; i < state.files.length; i++) {
      formData.append("compressed", state.files[i]);
    }

    formData.append("at_position", at_position);
    formData.append("totalFilesize", state.totalFilesize);

    axios
      .post("app/addalbum/step4", formData, config)
      .then(function (response) {
        // setState((oldState) => ({
        //     ...oldState,
        //     files: [],
        //     loader: false,
        //     uploadPercentage: 0,
        // }));
        getstepdata();
        // updateSize();
		update()
        if (response.data.success) {
          setState((oldState) => ({
            ...oldState,
            files: [],
            loader: false,
            uploadPercentage: 0,
          }));
          showNotification("File Uploaded Successfully!", "success");
        } else {
          errorModal(process.env.REACT_APP_MSG_FILE_SIZE);
        }
      })
      .catch(function (error) {
        setState((oldState) => ({
          ...oldState,
          loader: false,
        }));
        setState((oldstate) => ({
          ...oldstate,
          uploadPercentage: 0,
        }));
        errorModal(process.env.REACT_APP_MSG_FILE_SIZE);
        //  showNotification(error.response.data.error,"error")
      });
  };

  const save = () => {
    submit();
  };

  console.log("state items", state.items);

  const validate = async () => {
    if (state.items.length === 0) {
      errorModal("Please upload the track before continuing");
    } else {
      await submit();
      changestep(5);
    }
  };
  const togglePlay = (idx) => {
    let elem = state.items[idx];
    if (state.playIndex === idx) {
      playFlag = playFlag === "PLAYING" ? "STOPPED" : "PLAYING";
    } else {
      playFlag = "PLAYING";
    }
    setState((oldState) => ({
      ...oldState,
      playStatus: elem,
      playIndex: state.playIndex === idx ? null : idx,
    }));
  };

  const submit = () => {
    setState((oldState) => ({
      ...oldState,
      loader: true,
    }));

    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
      },
    };

    axios
      .post("app/addalbum/step4submit", state.items, config)
      .then(function (response) {
        setState((oldState) => ({
          ...oldState,
          loader: false,
        }));
        //console.log(response.data)
      })
      .catch(function (error) {
        setState((oldState) => ({
          ...oldState,
          loader: false,
        }));
        //console.log(error);
      });
  };

  const _delete = (id) => {
    setState((oldState) => ({
      ...oldState,
      loader: true,
    }));

    var index = state.items.findIndex((item) => item.track_id == id.track_id);

    state.items.splice(index, 1);

    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
      },
    };

    axios
      .post(
        "app/addalbum/step4delete",
        {
          id: id,
          data: state.items,
          album_id: sessionStorage.getItem("album_id"),
        },
        config
      )
      .then(function (response) {
        getstepdata();
        // updateSize();
		update()
        setState((oldState) => ({
          ...oldState,
          loader: false,
        }));
      })
      .catch(function (error) {
        setState((oldState) => ({
          ...oldState,
          loader: false,
        }));
      });
  };

  const back = () => {
    changestep(3);
  };

  const { items } = state;

  return (
    <div>
      {state.loader && <Loader />}
      <Sound
        url={axios.defaults.baseURL + state.playStatus.path + state.playStatus.filename}
        playStatus={Sound.status[playFlag]}
        playFromPosition={0 /* in milliseconds */}
        // onLoading={handleSongLoading}
        // onPlaying={handleSongPlaying}
        // onFinishedPlaying={handleSongFinishedPlaying}
      />
      <div className="row mb30">
        <div className="col-lg-12">
          {/* Lalo - 11/20/2020 Changed "h2" to "he3" 
                            Lalo: 03/01/2021 - Changed text as requested on Ste 4 - Row 52
                            <h3 className="text-justify mb20">Please do NOT include ANY bonus or hidden tracks here. This should be your Track List, as originally released and intended. (You can add bonus and hidden tracks later). Once the songs are uploaded, you will be able to check the track names, organize the tracks in proper order and listen to them to make sure they were uploaded properly.</h3>
                        */}
          <h3 className="text-justify mb20">
            This should be your track list, as originally released and intended. Once the songs are uploaded, you will be able to edit the track names, organize them in proper order and listen to them
            to make sure they were uploaded properly. Please check for typos and make sure they are in the correct order. This cannot be changed after your Dalbum has been published.
          </h3>
          {/* Lalo: 03/01/2021 -  Commented out per request step 4 - Row 52
                        <h3 className="text-justify">Make sure your compressed mp3 tracks correspond directly with your uncompressed  tracks.<br /> NOTE: Initially, uncompressed Tracks will not be available on devices.</h3>
                        */}
        </div>
      </div>

      <div className="row mb30 upload-wrapper">
        <div className="col-lg-8 uploadtrackLeft">
          <div className="card">
            <div className="card-header">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div
                    onDrop={(e) => {
                      e.preventDefault();
                    }}
                    className="input-group"
                  >
                    <div className="btn-cancel uploadButton">
                      Choose Files
                      <input type="file" onChange={uploadPreview} className="form-control uploadFile" aria-label="Text input with dropdown button" multiple accept="audio/mp3, audio/m4a,audio/MP3" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body bg-white pd30 m-pd10">
              <div className="upload-wrapper-conent">
                <h2 className="mb10 din-font text-uppercase ">
                  You have <span className="text-green">{state.items.length} Files</span> that have been uploaded.
                </h2>
                {state.files.length > 0 && (
                  <div className="track-list mb10 pd15">
                    <div className="progress">
                      <div className="progress-bar" role="progressbar" style={{ width: state.uploadPercentage + "%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                        <span style={{ color: "red" }}>{state.uploadPercentage}% Uploaded</span>
                      </div>
                    </div>
                  </div>
                )}
                {state.files.map((elem, index) => (
                  <div key={`preview-${index}`} className="track-list mb10 pd15">
                    <div className="row mb10">
                      <div className="col-md-12">
                        <div className="track-name">
                          <h3 className="text-uppercase">
                            {index + 1} {elem.name}
                          </h3>
                          <a
                            onClick={() => {
                              previewDelete(index);
                            }}
                          >
                            <span className="close ">
                              <i className="fa fa-times"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                      {/* <div className="col-md-4">
                                                    <div className="upload-track-progress pull-right">
                                                        <h3>98%</h3>
                                                    </div>
                                                </div> */}
                    </div>
                    {/* <div className="progress">
                                                <div className="progress-bar" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div> */}
                  </div>
                ))}
                <SortableContainer onSortEnd={onSortEnd} useDragHandle>
                  {items.map((elem, index) => (
                    <SortableItem
                      key={`item-${index}`}
                      index={index}
                      idx={index}
                      onchange={handleChange}
                      elem={elem}
                      ondelete={_delete}
                      togglePlay={togglePlay}
                      play={state.playIndex == index ? true : false}
                    />
                  ))}
                </SortableContainer>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 tabStep4">
          <div className="upload-content-detail pd20 bg-white clearfix uploadTract">
            <h2 className="text-uppercase din-font mb10">Upload Track To:</h2>
            <div className="form-group">
              <input type="text" readOnly value={album_name} className="form-control" placeholder="Album Title" />
            </div>
            <div className="album-specification">
              <h3 className="text-uppercase">Specifications</h3>
              <h4>
                Format to Upload: <br />
                MP3 (preferred)
              </h4>
              <div className="spacer20"></div>
              <h4>
                METADATA can be included <br />
                with ISRC numbers.{" "}
              </h4>
              <div className="spacer20"></div>
              <h4>
                For more information on ISRC <br />
                numbers, visit:
              </h4>
              <a href="#" className="text-orange brekText">
                http://www.nationwidebarcode.com/purchase-barcodes/barcodes-for-music{" "}
              </a>
              <button type="button" onClick={upload} className="btn-save text-uppercase pull-right mt30">
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row form-group justify-content-center mblBtn">
        <button
          type="button"
          className="btn-cancel text-uppercase"
          onClick={() => {
            back();
          }}
        >
          Back
        </button>
        {/* <button type="button" className="btn-cancel text-uppercase" onClick={() => { validate() }}>Skip this Step</button> */}
        <button
          type="button"
          className={(state.items.length > 0 ? "btn-save" : "btn-cancel") + " text-uppercase"}
          // disabled={state.items.length > 0 ? false : true}
          onClick={() => {
            validate();
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
});

export default Step4AddAlbumComponent;
