import Header from "../../shared/header/header";
import Footer from "../../shared/footer/footer";
import FanForgetPassword from "../../components/fan/fanforget";

const FanForget = () => {
	return (
		<div>
			<Header />
			<FanForgetPassword />
			<Footer />
		</div>
	);
};

export default FanForget;
