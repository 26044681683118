import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { notify } from "react-notify-toast";
import { Braintree, HostedField } from "react-braintree-fields";
import { showNotification } from "../../shared/showNotification";

var braintree = require("braintree-web/");
var paypal = require("paypal-checkout");

const CheckoutComponent = ({ data }) => {
	const getToken = useRef(null);
	const ccNum = useRef(null);

	const [state, setState] = useState({
		albums: [],
		image: {},
		type: false,
	});

	const amount = 100;
	const baseURL = axios.defaults.baseURL;

	let device_data = undefined;

	useEffect(() => {
		getClientToken();
		calculatePrice();
	}, []);

	const calculatePrice = () => {
		let total = 0;
		let sum = data.albums.reduce((total, elem) => {
			return total + Number(elem.dalbum.price);
		}, total);
		setState((oldState) => ({
			...oldState,
			sum: sum,
			tax: sum * (0.1).toFixed(2),
		}));
	};

	const getClientToken = () => {
		var config = {
			headers: { Authorization: "Bearer " + localStorage.getItem("tokenFan") },
		};

		axios
			.get("app/initialize_dalbum_payment", config)
			.then(function (response) {
				if (response.data) {
					setState((oldState) => ({
						...oldState,
						clientToken: response.data.token,
						amount: response.data.amount,
					}));
				}
				if (state.type) {
					forPaypal();
				}
			})
			.catch(function (error) {});
	};

	const forPaypal = () => {
		braintree.client.create(
			{
				authorization: state.clientToken,
			},
			function (clientErr, clientInstance) {
				// Stop if there was a problem creating the client.
				// This could happen if there is a network error or if the authorization
				// is invalid.
				if (clientErr) {
					console.error("Error creating client:", clientErr);
					return;
				}

				// Create a PayPal Checkout component.
				braintree.paypalCheckout.create(
					{
						client: clientInstance,
					},
					function (paypalCheckoutErr, paypalCheckoutInstance) {
						// Stop if there was a problem creating PayPal Checkout.
						// This could happen if there was a network error or if it's incorrectly
						// configured.
						if (paypalCheckoutErr) {
							console.error(
								"Error creating PayPal Checkout:",
								paypalCheckoutErr
							);
							return;
						}

						// Set up PayPal with the checkout.js library
						paypal.Button.render(
							{
								// env: 'production', // or 'sandbox'
								env: "sandbox", // or 'sandbox'

								payment: function () {
									return paypalCheckoutInstance.createPayment({
										// Your PayPal options here. For available options, see
										// http://braintree.github.io/braintree-web/current/PayPalCheckout.html#createPayment
										flow: "checkout", // Required
										amount: amount, // Required
										currency: "USD", // Required
										// enableShippingAddress: true,
										// shippingAddressEditable: false,
										// shippingAddressOverride: {
										//     recipientName: 'Scruff McGruff',
										//     line1: '1234 Main St.',
										//     line2: 'Unit 1',
										//     city: 'Chicago',
										//     countryCode: 'US',
										//     postalCode: '60652',
										//     state: 'IL',
										//     phone: '123.456.7890'
										// }
									});
								},

								onAuthorize: function (data, actions) {
									return paypalCheckoutInstance.tokenizePayment(
										data,
										function (err, payload) {
											makePurchase(payload.nonce);
											// alert(payload.nonce)
											// Submit `payload.nonce` to your server.
										}
									);
								},

								onCancel: function (data) {
									//console.log('checkout.js payment cancelled', JSON.stringify(data, 0, 2));
								},

								onError: function (err) {
									console.error("checkout.js error", err);
								},
							},
							"#paypal-button"
						).then(function () {
							// The PayPal button will be rendered in an html element with the id
							// `paypal-button`. This function will be called when the PayPal button
							// is set up and ready to be used.
						});
					}
				);
			}
		);
	};

	const changePaymentMethod = (flag) => {
		setState((oldState) => {
			const updatedState = {
				...oldState,
				type: flag,
			};

			if (updatedState.type) {
				forPaypal();
			}

			return updatedState;
		});
	};

	const makePurchase = (nonce) => {
		var config = {
			headers: { Authorization: "Bearer " + localStorage.getItem("tokenFan") },
		};

		axios
			.post("app/fan/make_purchase", { nonce: nonce, album_id: 1 }, config)
			.then(function (response) {
				if (response.data.success) {
					window.location = "/fan/library";
				}
			})
			.catch(function (error) {});
	};

	const onSubmit = () => {
		// could also obtain a reference to the Braintree wrapper element and call `.tokenize()`
		getToken
			.current({ cardholderName: "My Order Name" })
			.then((payload) => {
				makePurchase(payload.nonce);
			})
			.catch((err) => {});
	};

	const onCardTypeChange = (cards) => {
		setState((oldState) => ({
			...oldState,
			card: 1 === cards.length ? cards[0].type : "",
		}));
	};

	const onFocus = (event) => {
		//console.log("number is focused", event);
	};

	const onError = (err) => {
		showNotification(err.message, "error");
		console.warn(err);
		ccNum.current.focus(); // focus number field
	};

	const onAuthorizationSuccess = () => {
		setState((oldState) => ({
			...oldState,
			isBraintreeReady: true,
		}));
	};

	const onDataCollectorInstanceReady = (err, dataCollectorInstance) => {
		if (!err) device_data = dataCollectorInstance.deviceData;
	};

	const proceed = () => {
		setState((oldState) => {
			const updatedState = {
				...oldState,
				proceed: state.proceed ? false : true,
			};

			if (updatedState.type && updatedState.proceed) {
				forPaypal();
			}

			return updatedState;
		});
	};

	return (
		<>
			<section className="shopping-cart">
				<div className="container">
					<div className="row">
						<div className="col-lg-8">
							<div className="shopping-inner">
								<div className="row">
									<div className="col-md-12">
										<div className="shopping-heading">
											<h1 className="m-0">
												CHECKOUT{" "}
												<Link className="text-orange" to="/fan/mycart">
													{" "}
													EDIT CART
												</Link>
											</h1>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12">
										<div className="shopping-img checkout-cart">
											<ul className="list-unstyled">
												{data.albums.map((album, index) => (
													<li className="media" key={index}>
														{album.dalbum.artwork_images.length > 0 && (
															<img
																className="mr-4"
																src={
																	baseURL +
																	album.dalbum.artwork_images[0].path +
																	album.dalbum.artwork_images[0].name
																}
																alt="Generic placeholder image"
															/>
														)}
														<div className="media-body">
															<div className="row">
																<div className="col-md-12">
																	<p className="mt-0 mb-0">
																		<strong>{album.dalbum.name}</strong>
																	</p>
																	{/* <p className="mt-0 mb-0">This is for<strong> Me</strong></p> */}
																</div>
															</div>
															{/* <p className="mb-0">
                                                                <strong>Will be receiving notification from this artist. <a href="#" className="text-orange">EDIT</a></strong>
                                                            </p>
                                                            <p className="mb-0">
                                                                <strong>Will be joining this artist's email list.* <a href="#" className="text-orange">EDIT</a></strong>
                                                            </p> */}
															<p className="mt-0 mb-0">
																Price:{" "}
																<strong>
																	<i>$</i> {album.dalbum.price}
																</strong>
															</p>
														</div>
													</li>
												))}
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="subtotal-main mb-3 summary sum-right">
								<div className="subtotal">
									<h3>Order Summary:</h3>
									<div className="row">
										<div className="col-md-6">
											<div className="summary-item">
												<p className="mb-0">Items ({data.albums.length}):</p>
											</div>
										</div>
										<div className="col-md-6">
											<div className="summary-item-cost">
												<p className="mb-0 text-right">${state.sum}</p>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6">
											<div className="summary-item">
												<p className="mb-0">Estimated tax:*:</p>
											</div>
										</div>
										<div className="col-md-6">
											<div className="summary-item-cost">
												<p className="mb-0 text-right">${state.tax}</p>
											</div>
										</div>
									</div>
									<div className="row order-total no-gutters">
										<div className="col-md-6">
											<div className="summary-item">
												<h2 className="mb-0">Order total:</h2>
											</div>
										</div>
										<div className="col-md-6">
											<div className="summary-item-cost">
												<h2 className="mb-0 text-right">
													${state.sum + state.tax}
												</h2>
											</div>
										</div>
									</div>
									{!state.type && (
										<button
											onClick={onSubmit}
											className="form-control btn-orange  width100 mt-4"
										>
											PLACE YOUR ORDER
										</button>
									)}
									{state.type && (
										<div className="mt-4" id="paypal-button"></div>
									)}
									<div className="row">
										<div className="col-md-12">
											<div className="placing-order">
												<p className="text-center">
													By placing your order, you agree to DalbumX.com's{" "}
													<a className="text-orange">Privacy notice </a>and{" "}
													<a className="text-orange">conditions of use</a>
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="payment-getway">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="payment-inner">
								<div className="row">
									<div className="col-md-6">
										<div className="payment-heading">
											<h1 className="m-0">Please select a payment method:</h1>
										</div>
									</div>
									<div className="col-md-6">
										<div className="payment-image">
											<ul className="list-inline">
												<li className="list-inline-item">
													<label className="payment-choose">
														<img
															src={require("./../../assets/img/discover.gif")}
															alt=""
															className="img-fluid"
														/>
														<input
															type="radio"
															onChange={() => changePaymentMethod(false)}
															checked={!state.type ? true : false}
															name="radio"
															className="form-control"
														/>
														<span className="checkmark"></span>
													</label>
												</li>
												<li className="list-inline-item">
													<label className="payment-choose">
														<img
															src={require("./../../assets/img/paypal.gif")}
															alt=""
															className="img-fluid"
														/>
														<input
															type="radio"
															onChange={() => changePaymentMethod(true)}
															checked={state.type ? true : false}
															name="radio"
															className="form-control"
														/>
														<span className="checkmark"></span>
													</label>
												</li>
											</ul>
										</div>
									</div>
								</div>
								{!state.type && (
									<div className="row px-5 mobile-fan-payment-width">
										<div className="col-md-6">
											<div className="credit-card-heading">
												<h1>Credit Card Details</h1>
											</div>
											<Braintree
												className={state.isBraintreeReady ? "" : "disabled"}
												authorization={state.clientToken}
												onAuthorizationSuccess={onAuthorizationSuccess}
												onDataCollectorInstanceReady={
													onDataCollectorInstanceReady
												}
												onError={onError}
												onCardTypeChange={onCardTypeChange}
												getTokenRef={(ref) => (getToken.current = ref)}
												styles={{
													input: {
														"font-size": "14px",
														"font-family":
															"helvetica, tahoma, calibri, sans-serif",
														color: "#3a3a3a",
														"border-style": "solid !important",
														"border- width": "2px !important",
														"border-radius": "10px !important",
														"border-color": "rgb(39, 140, 142)",
													},
													":focus": {
														color: "black",
													},
												}}
											>
												<div className="fields credit-card pd15 ">
													<div className="form-group">
														<div className="card-number">
															<label htmlFor="usr">
																Card Number <i className="fa fa-lock"></i>
															</label>
															<HostedField
																id="usr"
																className="form-control"
																type="number"
																onFocus={onFocus}
																ref={(element) => (ccNum.current = element)}
															/>
														</div>
													</div>
													<div className="form-group">
														<div className="card-number">
															<label>Expiration Date</label>
															<div className="expire-date">
																<HostedField
																	select
																	placeholder="MM"
																	className="form-control"
																	type="expirationMonth"
																/>
																<HostedField
																	select
																	placeholder="YY"
																	className="form-control"
																	type="expirationYear"
																/>
															</div>
														</div>
													</div>
													<div className="form-group">
														<div className="card-number cvv">
															<label htmlFor="cvv">
																Card Security Code / CVV{" "}
																<img
																	src={require("./../../assets/img/info.png")}
																	alt=""
																/>
															</label>
															<HostedField
																id="cvv"
																className="form-control"
																type="cvv"
															/>
														</div>
													</div>
												</div>
												{/* <button onClick={onSubmit}>Submit</button> */}
											</Braintree>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="subtotal-bottom">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="subtotal-main mb-3 summary">
								<div className="subtotal text-center text-md-left">
									<h3>Order Summary:</h3>
									<div className="row">
										<div className="col-md-3">
											<div className="summary-item">
												<p className="mb-0">
													Items ({data.albums.length}): ${state.sum}
												</p>
											</div>
										</div>
										<div className="col-md-1">
											<div className="summary-item">
												<p className="mb-0 text-center">+</p>
											</div>
										</div>
										<div className="col-md-3 text-right">
											<div className="summary-item">
												<p className="mb-0 text-center text-md-left">
													Estimated tax:*: ${state.tax}
												</p>
											</div>
										</div>
										<div className="col-md-1">
											<div className="summary-item">
												<p className="mb-0 text-center">=</p>
											</div>
										</div>
										<div className="col-md-4">
											<div className="summary-item bottom-total">
												<h2 className="mb-0">
													Order total: <span>${state.sum + state.tax}</span>
												</h2>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-12">
											<div className="placing-order total-bottom">
												{!state.type && (
													<button
														onClick={onSubmit}
														className="form-control btn-orange mt-4"
													>
														PLACE YOUR ORDER
													</button>
												)}
												{state.type && (
													<div className="mt-4" id="paypal-button"></div>
												)}
												<p className="text-center">
													By placing your order, you agree to DalbumX.com's{" "}
													<a className="text-orange">Privacy notice </a>and{" "}
													<a className="text-orange">conditions of use</a>
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default CheckoutComponent;
