import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { showNotification } from "../../shared/showNotification";
import { getFrontCoverThumbnailUrl } from "../artworkHelper";

const FanSearchComponent = ({ type }) => {
	const navigate = useNavigate();

	const searchInput = useRef();

	const [state, setState] = useState({
		albums: [],
		genres: [],
		genre: {},
		allSubGenres: [],
		currentSubGenres: [],
		subGenre: {},
		search: "",
	});

	const path = axios.defaults.baseURL;

	useEffect(() => {
		if (type.data) {
			document.getElementById(type.data).click();
		}
		state.genre = JSON.parse(sessionStorage.getItem("selectedGenre")) || {};
		// setState({currentSubGenres: state.allSubGenres.filter(subGenre => {
		//     if(subGenre.genre_id == state.genre.genre_id) return subGenre;
		// })})
		// state.genre = Object.assign({}, genre);
		// state.search = search || ''
		state.search = sessionStorage.getItem("searchInput");
		searchInput.current.focus();
		getAlbum(state);
		getGenre();
		getSubGenre();
	}, []);

	const sortName = (flag) => {
		if (flag) {
			setState((oldState) => ({
				...oldState,
				albums: state.albums.sort(function (a, b) {
					var nameA = a.name.toLowerCase(),
						nameB = b.name.toLowerCase();
					if (nameA < nameB) return -1;
					if (nameA > nameB) return 1;
					return 0;
				}),
			}));
		}
	};

	const sortRecent = (flag) => {
		if (flag) {
			setState((oldState) => ({
				...oldState,
				albums: state.albums.sort(function (b, a) {
					var nameA = a.created_at,
						nameB = b.created_at;
					if (nameA < nameB) return -1;
					if (nameA > nameB) return 1;
					return 0;
				}),
			}));
		}
	};

	const sortPopular = (flag) => {
		// //console.log(state.albums.sort((a,b)=>{if(a.fan_transactions.length>b.fan_transactions.length)return -1; if(b.fan_transactions.length>a.fan_transactions.length) return 1;return 0;}))
		if (flag) {
			setState((oldState) => ({
				...oldState,
				albums: state.albums.sort(function (a, b) {
					if (a.fan_transactions.length > b.fan_transactions.length) return -1;
					if (b.fan_transactions.length > a.fan_transactions.length) return 1;
					return 0;
				}),
			}));
		}
	};

	const getAlbum = (state) => {
		var config = {
			headers: { Authorization: "Bearer " + localStorage.getItem("tokenFan") },
			params: {
				genre_id: state.genre.genre_id,
				search: state.search,
				sub_genre_id: state.subGenre.sub_genre_id,
			},
		};
		axios
			.get("/app/fan/dashboard", config)
			.then(function (response) {
				if (response.data.success) {
					// state.artists = response.data.data
					setState((oldState) => ({
						...oldState,
						albums: response.data.data.result,
					}));
				} else {
					showNotification(response.data.message, "error");
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				//console.log(error);
			});
	};

	const getGenre = () => {
		var config = {
			headers: { Authorization: "Bearer " + localStorage.getItem("tokenFan") },
		};
		axios
			.get("/genre?page=" + 1 + "&limit=" + 1200, config)
			.then(function (response) {
				if (response.data.success) {
					// state.artists = response.data.data
					setState((oldState) => ({
						...oldState,
						genres: response.data.data.result,
					}));
				} else {
					showNotification(response.data.message, "error");
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				//console.log(error);
			});
	};

	const getSubGenre = () => {
		var config = {
			headers: { Authorization: "Bearer " + localStorage.getItem("tokenFan") },
		};
		axios
			.get("/sub_genre?page=" + 1 + "&limit=" + 1200, config)
			.then(function (response) {
				if (response.data.success) {
					// state.artists = response.data.data
					setState((oldState) => ({
						...oldState,
						allSubGenres: response.data.data.result,
					}));
				} else {
					showNotification(response.data.message, "error");
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				//console.log(error);
			});
	};

	const selectedGenre = (genre) => {
		selectedSubGenre({});
		if (genre == {}) {
			setState((oldState) => ({
				...oldState,
				currentSubGenres: [],
			}));
		}
		//console.log(genre)
		setState((oldSate) => {
			const updatedState = { ...oldSate, genre: genre };
			getAlbum(updatedState);
			return updatedState;
		});
		// setState({currentSubGenres: state.allSubGenres.filter(subGenre => {
		//     if(subGenre.genre_id == genre.genre_id) return subGenre;
		// })})
		// state.currentSubGenres = state.allSubGenres.filter(subGenre => {
		//     subGenre.genre_id == genre.genre_id
		// })
		sessionStorage.setItem("selectedGenre", JSON.stringify(genre));
	};

	const selectedSubGenre = (subGenre) => {
		if (subGenre == {}) {
			setState((oldState) => ({
				...oldState,
				subGenre: null,
			}));
			return;
		}

		setState((oldState) => {
			const updatedState = { ...oldState, subGenre: subGenre };

			getAlbum(updatedState);

			return updatedState;
		});
	};

	const searchInputChange = (event) => {
		setState((oldState) => {
			const updatedState = {
				...oldState,
				search: event.target.value,
			};

			getAlbum(updatedState);

			return updatedState;
		});
		sessionStorage.setItem("searchInput", event.target.value);
	};

	const setAlbum = (album_id) => {
		navigate("/fan/album-preview", {
			state: { album_id: album_id },
			// state: {name : response.data.data.firstname}
		});
	};

	const goToArtist = (artist_id) => {
		navigate("/fan/artist-preview/", {
			state: { artist_id: artist_id }
		});
	}

	var settings = {
		infinite: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					arrows: true,
					centerMode: true,
					// centerPadding: '40px',
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					arrows: false,
					centerMode: true,
					// centerPadding: '40px',
					slidesToShow: 1,
				},
			},
		],
	};
	var recentsettings = {
		centerMode: true,
		// centerPadding: '60px',
		slidesToShow: 6,
		infinite: true,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					arrows: true,
					centerMode: true,
					// centerPadding: '40px',
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 480,
				settings: {
					arrows: false,
					centerMode: true,
					// centerPadding: '40px',
					slidesToShow: 1,
				},
			},
		],
	};

	const setStyle = (album) => {
		if (
			album.artwork_images.length > 0
		) {
			return {
				background:
					`${getFrontCoverThumbnailUrl(album.artwork_images)} no-repeat center center / cover`,
			};
		} else {
			return {};
		}
	};
	return (
		<div className="container">
			<section className="general-catagory">
				<div className="container">
					<div className="row d-flex align-items-center">
						<div className="col-md-4">
							<div className="general">
								{/* <!-- <p>General catagoru</p> --> */}
								<div className="catagory-text text-orange mb-1">
									<span className="genre">
										GENRE
										<span className="all-catagory">
											{state.genre.name || "All"}
										</span>
										<span className="all-options">
											<ul className="list-inline" style={{ maxHeight: "20vw", overflowY: "auto" }}>
												<li
													onClick={() => {
														selectedGenre({});
													}}
													className="active"
												>
													All
												</li>
												{state.genres.map((genre, index) => (
													<li
														key={index}
														onClick={() => {
															selectedGenre(genre);
														}}
													>
														{" "}
														<a>{genre.name}</a>
													</li>
												))}
											</ul>
										</span>
									</span>
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="sub-genral">
								<div
									className={
										(Object.keys(state.genre).length == 0
											? "custome-disable"
											: "") + " catagory-text text-orange subgenre"
									}
								>
									<span className="genre">
										SUBGENRE
										<span className="all-catagory">
											{state.subGenre.name || "All"}
										</span>
										<span className="all-options sub-op">
											<ul className="list-inline" style={{ maxHeight: "20vw", overflowY: "auto" }}>
												<li
													onClick={() => {
														selectedSubGenre({});
													}}
													className="active"
												>
													All
												</li>
												{state.allSubGenres.map(
													(genre, index) =>
														genre.genre_id === state.genre.genre_id && (
															<li
																key={index}
																onClick={() => {
																	selectedSubGenre(genre);
																}}
															>
																{" "}
																<a>{genre.name}</a>
															</li>
														)
												)}
											</ul>
										</span>
									</span>
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="search-box">
								<input
									ref={(input) => {
										searchInput.current = input;
									}}
									type="text"
									value={state.search ?? ""}
									onChange={searchInputChange}
									className="form-control"
									placeholder="search"
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!-- Tabs --> */}
			<section id="tabs" className="catagory-tabs">
				<div className="container-fluid">
					<div className="row">
						<div className="col-12">
							<nav className="container">
								<div
									className="nav row nav-tabs nav-fill nav-items-tab"
									id="nav-tab"
									role="tablist"
								>
									<a
										className="nav-item nav-link"
										id="most-recent"
										onClick={() => sortRecent(1)}
										data-toggle="tab"
										href="#nav-home"
										role="tab"
										aria-controls="nav-home"
										aria-selected="true"
									>
										MOST RECENT
									</a>
									<a
										className="nav-item nav-link"
										id="popular"
										onClick={() => sortPopular(1)}
										data-toggle="tab"
										href="#nav-profile"
										role="tab"
										aria-controls="nav-profile"
										aria-selected="false"
									>
										POPULAR
									</a>
									<a
										className="nav-item nav-link"
										onClick={() => sortName(1)}
										id="whole-store"
										data-toggle="tab"
										href="#nav-contact"
										role="tab"
										aria-controls="nav-contact"
										aria-selected="false"
									>
										THE WHOLE STORE
									</a>
								</div>
							</nav>
							<div className="tab-content px-3 px-sm-0" id="nav-tabContent">
								<div
									className="tab-pane fade show active"
									id="nav-home"
									role="tabpanel"
									aria-labelledby="most-recent"
								>
									<ul className="list-inline">
										{state.albums &&
											state.albums.map((album, index) => (
												<li key={index}>
													<a
														onClick={() => {
															setAlbum(album.dalbum_id);
														}}
														key={index}
														className="most-content"
													>
														<div
															className="slick-img fan-search-img"
															style={setStyle(album)}
														></div>
														<div className="most-inner pd">
															<h3>{album.name}</h3>
														</div>
													</a>
													<div className="most-inner pd">
														{album.artist && (
															<a  style={{
																color: "#278c8e"
															}}
																onClick={() => {
																	goToArtist(album.artist.artist_id);
																}}
															>
																{album.artist.name}
															</a>
														)}
													</div>
												</li>
											))}
									</ul>
								</div>
								<div
									className="tab-pane fade"
									id="nav-profile"
									role="tabpanel"
									aria-labelledby="popular"
								>
									<ul className="list-inline">
										{state.albums &&
											state.albums.map((album, index) => (
												<li key={index}>
													<a
														onClick={() => {
															setAlbum(album.dalbum_id);
														}}
														key={index}
														className="most-content"
													>
														<div
															className="slick-img fan-search-img"
															style={setStyle(album)}
														></div>
														<div className="most-inner pd">
															<h3>{album.name}</h3>
														</div>
													</a>
													<div className="most-inner pd">
														{album.artist && (
															<Link
																to={
																	"/fan/artist-preview/" +
																	album.artist.artist_id
																}
															>
																{album.artist.name}
															</Link>
														)}
													</div>
												</li>
											))}
									</ul>
								</div>
								<div
									className="tab-pane fade"
									id="nav-contact"
									role="tabpanel"
									aria-labelledby="whole-store"
								>
									<ul className="list-inline">
										{state.albums &&
											state.albums.map((album, index) => (
												<li key={index}>
													<a
														onClick={() => {
															setAlbum(album.dalbum_id);
														}}
														key={index}
														className="most-content"
													>
														<div
															className="slick-img fan-search-img"
															style={setStyle(album)}
														></div>
														<div className="most-inner pd">
															<h3>{album.name}</h3>
														</div>
													</a>
													<div className="most-inner pd">
														{album.artist && (
															<Link
																to={
																	"/fan/artist-preview/" +
																	album.artist.artist_id
																}
															>
																{album.artist.name}
															</Link>
														)}
													</div>
												</li>
											))}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default FanSearchComponent;
