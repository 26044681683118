import React, { useEffect, useState } from "react";
import {
	arrayMove,
	sortableContainer,
	sortableElement,
	sortableHandle,
} from "react-sortable-hoc";
import { confirmAlert } from "react-confirm-alert";
import Tooltip from "../../../shared/toolTip";

const SortableContainer = sortableContainer(({ children }) => {
	return <div>{children}</div>;
});

const DragHandle = sortableHandle(() => (
	<i className="fa fa-bars" aria-hidden="true"></i>
));

const SortableItem = sortableElement(
	({
		musician_no,
		handleInput,
		deleteAlert,
		addInstrument,
		addCategory,
		instruments,
		instrumentCategory,
		musician,
		musician_index,
	}) => (
		<div>
			<div className="menu-icon">
				<DragHandle />
			</div>
			<div className="row mb20">
				<div className="col-md-3">
					<input
						type="text"
						name="firstname"
						className="form-control"
						placeholder="First"
						// readOnly={musician.musician_id ? true : false}
						onChange={(e) => {
							handleInput(e, musician_index);
						}}
						value={musician.firstname}
					/>
					{musician_no > 1 && (
						<label
							onClick={() => {
								deleteAlert(musician_index);
							}}
							className="error"
						>
							{" "}
							Delete Member{" "}
						</label>
					)}
				</div>

				<div className="col-md-3">
					<input
						type="text"
						name="lastname"
						className="form-control"
						placeholder="Last"
						// readOnly={musician.musician_id ? true : false}
						onChange={(e) => {
							handleInput(e, musician_index);
						}}
						value={musician.lastname}
					/>
				</div>
				<div className="col-md-6">
					{musician.instrument.map((category, cat_index) => (
						<div key={cat_index} className="row tooltip-absolute">
							<div className="col-md-6">
								<div className="row mb10">
									<div className="col-md-12">
										<select
											value={
												category.instrument_category_id &&
												category.instrument_category_id != "null"
													? category.instrument_category_id
													: "default"
											}
											className="form-control"
											name="MusicianRole"
											onChange={(e) => {
												handleInput(e, musician_index, cat_index);
											}}
										>
											<option disabled value="default">
												Select Category
											</option>
											{instrumentCategory.map((elem, index) => (
												<option key={index} value={elem.instrument_category_id}>
													{elem.name}
												</option>
											))}
										</select>
										{/* {musician.instrument.length > 1 && <span className="delete" title="Delete Role"><i onClick={() => { deleteInstrument() }} className="fa fa-times colorCustom"></i></span>} */}
									</div>
								</div>
							</div>
							<div className="col-md-6">
								{category.data &&
									category.data.map((inst, inst_idx) => (
										<div key={inst_idx} className="row mb10">
											<div className="col-md-12">
												<input
													autoComplete="off"
													className="form-control col-sm-12"
													name="credit_role_id"
													onChange={(e) => {
														handleInput(e, musician_index, cat_index, inst_idx);
													}}
													value={
														inst.musician_instrument_id
															? inst.musician_instrument_id
															: ""
													}
													list={"CreditRoles" + cat_index + inst_idx}
													placeholder="Enter Instrument"
												/>
												<datalist id={"CreditRoles" + cat_index + inst_idx}>
													{instruments.map(
														(elem, index) =>
															elem.instrument_category_id ==
																category.instrument_category_id && (
																<option key={index}>{elem.name}</option>
															)
													)}
												</datalist>
												{/* <select value={inst.musician_instrument_id ? inst.musician_instrument_id : "default"} className="form-control"
                                            name="intrumentsRole"
                                            onChange={(e) => { handleInput(e, musician_index, cat_index, inst_idx) }}
                                        >
                                            <option disabled selected value="default" >Select Instrument</option>
                                            {instruments.map((elem, index) =>
                                                elem.instrument_category_id == category.instrument_category_id &&
                                                <option value={elem.musician_instrument_id}>{elem.name}</option>)}
                                        </select> */}
											</div>
										</div>
									))}
								<div className="col-md-12 mb10">
									<a
										onClick={() => {
											addInstrument(musician_index, cat_index);
										}}
										className="colorCustom"
									>
										<i className="fa fa-plus"></i> Instruments
									</a>
								</div>
							</div>
							<Tooltip
								message={
									<>
										CATEGORY is required, but will NOT be displayed in your
										public profile.
										<br />
										INSTRUMENT is optional and WILL appear in your public
										profile. You can be as general or specific as you please.
										<br />
										General Examples: Vocals, Backup Vocals, Guitar, Keyboards,
										etc.
										<br />
										Specific Examples: Lead Soprano, Tenor, Rickenbacker 325,
										Moog Subsequent 37 Analog Synthesizer, etc.
										<br />
										PLEASE CHECK FOR TYPOS!
										<br />
										This information will also be listed under your name in the
										DMIC.
									</>
								}
								color="#d95335"
							/>
						</div>
					))}
				</div>
			</div>
			<div className="row justify-content-end">
				<div className="col-md-6 mb10 col-md-offset-6">
					<a
						onClick={() => {
							addCategory(musician_index);
						}}
						className="colorCustom"
					>
						<i className="fa fa-plus"></i> Category
					</a>
				</div>
			</div>
		</div>
	)
);

const EditMusician = (props) => {
	const { is_band, instrument, instrumentCategory, musician, update } = props;
	const [state, setState] = useState({
		error: [],
		temp_musician: [],
	});

	useEffect(() => {
		if (musician && musician.length > 0) {
			sortIntoCategory();
		}
	}, [musician]);

	const handleInput = (e, musi_idx, cat_idx, inst_idx) => {
		let temp = state.temp_musician;
		if (inst_idx || inst_idx == 0) {
			temp[musi_idx].instrument[cat_idx].data[inst_idx].musician_instrument_id =
				e.target.value;
			setState((oldState) => ({
				...oldState,
				temp_musician: temp,
			}));
		} else if (cat_idx != null && cat_idx != undefined) {
			temp[musi_idx].instrument[cat_idx].instrument_category_id =
				e.target.value;
			temp[musi_idx].instrument[cat_idx].data = [
				{ musician_instrument_id: null },
			];
			setState((oldState) => ({
				...oldState,
				temp_musician: temp,
			}));
		} else {
			temp[musi_idx][e.target.name] = e.target.value;
			setState((oldState) => ({
				...oldState,
				temp_musician: temp,
			}));
		}
	};

	const onSortEnd = ({ oldIndex, newIndex }) => {
		setState((oldState) => ({
			...oldState,
			temp_musician: arrayMove(oldState.temp_musician, oldIndex, newIndex),
		}));
	};

	const addInstrument = (musi_idx, cat_idx) => {
		let temp = state.temp_musician;
		let temp2 = temp[musi_idx].instrument;
		temp2[cat_idx].data.push({ musician_instrument_id: null });
		temp[musi_idx].instrument = temp2;
		setState((oldState) => ({
			...oldState,
			temp_musician: temp,
		}));
	};
	const reset = () => {
		sortIntoCategory(musician);
	};

	const validation = (sss) => {
		let temp = sss;
		let err = state.error;
		for (let [index, elem] of temp.entries()) {
			if ((elem.firstname = "" || !elem.firstname)) {
				err.splice(index, 0, "Lene");
			}
			for (let [idx, inst] of elem.instrument.entries()) {
				if (!inst.musician_instrument_id) {
					err.splice(index, 0, "inst");
					break;
				}
			}
		}

		setState((oldState) => ({
			...oldState,
			error: {},
		}));
	};

	const save = () => {
		let temp = JSON.parse(JSON.stringify(state.temp_musician));
		update(temp);
	};

	const addCategory = (musi_idx) => {
		let temp = state.temp_musician;
		temp[musi_idx].instrument.push({
			instrument_category_id: null,
			data: [{ musician_instrument_id: null }],
		});
		setState((oldState) => ({
			...oldState,
			temp_musician: temp,
		}));
	};

	const addMusician = () => {
		let temp = state.temp_musician;
		temp.push({ firstname: "", lastname: "", instrument: [] });
		setState((oldState) => ({
			...oldState,
			temp_musician: temp,
		}));
	};

	const deleteMusician = (idx) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="confirm-ui">
						<div className="delete-prompt-box">
							<h2 className="text-uppercase mb10">
								Are You Sure You Would
								<br /> Like to Delete This?
							</h2>
							<div className="d-flex mr-3 justify-content-center">
								<button
									type="button"
									onClick={onClose}
									className="btn-save text-uppercase"
								>
									oops, No
								</button>
								<button
									type="button"
									onClick={() => {
										let temp = JSON.parse(JSON.stringify(state.temp_musician));
										temp.splice(idx, 1);
										setState((oldState) => ({
											...oldState,
											temp_musician: temp,
										}));
										onClose();
									}}
									className="btn-cancel text-uppercase"
								>
									Delete
								</button>
							</div>
						</div>
					</div>
				);
			},
		});
	};

	const sortIntoCategory = (temp_music = musician) => {
		let temp_musi = temp_music.map(function (item) {
			return Object.assign({}, item);
		});
		for (let [index, elem] of temp_musi.entries()) {
			var result = elem.instrument.reduce(function (r, a) {
				r[a.instrument_category_id] = r[a.instrument_category_id] || [];
				r[a.instrument_category_id].push(a);
				return r;
			}, Object.create(null));
			var arr = [];
			for (let key in result) {
				arr.push(
					Object.assign({ instrument_category_id: key, data: result[key] })
				);
			}
			temp_musi[index].instrument = arr;
		}
		setState((oldState) => ({
			...oldState,
			temp_musician: temp_musi,
		}));
	};

	return (
		<div>
			<div
				className="modal fade bd-band-modal-lg"
				id="add-band-role"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="add-band-role"
				aria-hidden="true"
			>
				<div
					className="modal-dialog modal-dialog-centered modal-lg"
					role="document"
				>
					<div className="modal-content bg-black pd20">
						<div className="modal-body text-white">
							<SortableContainer onSortEnd={onSortEnd} useDragHandle>
								{state.temp_musician.map((musician, index) => (
									<SortableItem
										key={`item-${index}`}
										index={index}
										deleteAlert={deleteMusician}
										musician_no={state.temp_musician.length}
										musician_index={index}
										musician={musician}
										instruments={instrument}
										instrumentCategory={instrumentCategory}
										addInstrument={addInstrument}
										addCategory={addCategory}
										handleInput={handleInput}
									/>
								))}
							</SortableContainer>
							{/* <button onClick={addMusician}> Add Musician</button> */}
							{is_band == 1 && (
								<a onClick={addMusician} className="text-green">
									<i className="fa fa-plus"></i> Musician
								</a>
							)}
						</div>
						<div className="modal-footer">
							<button
								onClick={save}
								type="button"
								className="btn-save"
								data-dismiss="modal"
							>
								Save
							</button>
							<button
								onClick={reset}
								type="button"
								className="btn-cancel"
								data-dismiss="modal"
							>
								Cancel
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EditMusician;
