import React from "react";
import Select from "react-select";

const BizContact = (props) => {

	const options = props.roles.map((item) => ({
			value: item.primary_role_id,
			label: item.name,
			name: "primary_role_id"
  		}));
  
  return (
    <div className="row mb30">
      <div className="col-md-12">
        <div className="pd20 bg-gray hrColor">
          <div className="col-md-12">
            <div className="form-group text-right mb10">
              <input type="checkbox" name="checkboxHideAll" onChange={(e) => props.onCheckboxChange(e, 1, 1)} className="form-check-input" id="hide" />
              <label className="form-check-label text-white mbl-text-check" htmlFor="hide">
                Hide All from public view
              </label>
            </div>
          </div>
          {options.length > 0 && props.biz_contact.map((element, index) => (
            <div key={index}>
              <div className="row mb20">
                <div className="col-md-11 offset-md-1">
                  <div className="row align-items-center">
                    <div className="col-md-3 col-10">
                      {/* <select
                        defaultValue={element.primary_role_id ? element.primary_role_id : "default"}
                        className="form-control botomformspace"
                        name="primary_role_id"
                        onChange={(e) => {
							console.log("ELE: ", element.primary_role_id);
                          props.onInputChange(e, index);
                        }}
                      >
                        <option disabled value="default">
                          Select
                        </option>
                        {props.roles.map((elem, index) => (
                          <option value={elem.primary_role_id}>{elem.name}</option>
                        ))}
                      </select> */}
                      <Select
                        placeholder="Select"
                        defaultValue={options?.find(item => item.value === element.primary_role_id)}
                        onChange={(e) => {
                          props.onInputChange(e, index);
                        }}
                        options={options}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused ? '#278c8e' : '#278c8e',
                            borderRadius: "10px",
                            borderWidth: "2px"
                          }),
                        }}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: 'lightgrey',
                            primary: '#278c8e',
                          },
                        })}
                      />
                    </div>
                    <div className="col-md-3 col-10">
                      <input
                        type="text"
                        name="firstname"
                        className="form-control botomformspace"
                        value={element.firstname}
                        placeholder="First"
                        onChange={(e) => {
                          props.onInputChange(e, index);
                        }}
                      />
                    </div>
                    <div className="col-md-3 col-10">
                      <input
                        type="text"
                        name="lastname"
                        className="form-control"
                        value={element.lastname}
                        placeholder="Last"
                        onChange={(e) => {
                          props.onInputChange(e, index);
                        }}
                      />
                    </div>
                    <div className="col-md-1 col-2">
                      <div className="cust-checkbox">
                        <input
                          name="lastname"
                          onChange={(e) => props.onCheckboxChange(e, index)}
                          type="checkbox"
                          checked={element.artist_profile_permissions.some((x) => {
                            return x.column_name == "lastname";
                          })}
                        />
                      </div>
                    </div>
                    <div className="col-md-2 col-2">
                      <div className="white-color">Hide from public view</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb20 align-items-center">
                <div className="col-md-11 offset-md-1">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      {props.biz_contact.length > 1 && (
                        <a
                          onClick={() => {
                            props.deleteBiz(index);
                          }}
                          className="deleteBizCon"
                        >
                          <i className="fa fa-times"></i> Delete
                        </a>
                      )}
                    </div>
                    <div className="col-md-3 col-10">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Company Name"
                        value={element.company}
                        onChange={(e) => {
                          props.onInputChange(e, index);
                        }}
                        name="company"
                      />
                    </div>
                    <div className="col-md-1 col-2">
                      <div className="cust-checkbox">
                        <input
                          name="company"
                          onChange={(e) => props.onCheckboxChange(e, index)}
                          type="checkbox"
                          checked={element.artist_profile_permissions.some((x) => {
                            return x.column_name == "company";
                          })}
                        />
                      </div>
                    </div>
                    <div className="col-md-2 col-2">{/* <div className="white-color">  Hide from public view</div> */}</div>
                  </div>
                </div>
              </div>

              <div className="row mb20">
                <div className="col-md-11 offset-md-1">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      {/* Lalo 09/14/2021 - Change "Business Contacts" to "Contacts" - "BC Section" - L.-60 */}
                      <div className="bus-font">{index == 0 ? "CONTACTS" : ""}</div>
                    </div>
                    <div className="col-md-3 col-10">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        value={element.email}
                        onChange={(e) => {
                          props.onInputChange(e, index);
                        }}
                        name="email"
                      />
                    </div>
                    <div className="col-md-2 col-2">
                      <div className="cust-checkbox">
                        <input
                          name="email"
                          onChange={(e) => props.onCheckboxChange(e, index)}
                          type="checkbox"
                          checked={element.artist_profile_permissions.some((x) => {
                            return x.column_name == "email";
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb20">
                <div className="col-md-11 offset-md-1">
                  <div className="row align-items-center justify-content-end">
                    <div className="col-md-3 col-10">
                      <input
                        type="text"
                        name="website_url"
                        className="form-control"
                        placeholder="Website"
                        onChange={(e) => {
                          props.onInputChange(e, index);
                        }}
                        value={element.website_url}
                      />
                    </div>
                    <div className="col-md-3 col-2">
                      <div className="cust-checkbox">
                        <input
                          name="website_url"
                          onChange={(e) => props.onCheckboxChange(e, index)}
                          type="checkbox"
                          checked={element.artist_profile_permissions.some((x) => {
                            return x.column_name == "website_url";
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          ))}
          <div className="row">
            <div className="col-md-8 text-right">
              <a onClick={props.addBiz} className="text-white text-captialize">
                + BIZ CONTACT
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BizContact;
