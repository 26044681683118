import { useEffect, useState } from "react";
import axios from "axios";
import url from "../../defaults";
import Loader from "../loader/loader";
import {
	sortableContainer,
	sortableElement,
	sortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable as arrayMove } from "array-move";
import { showNotification } from "../showNotification";

const DragHandle = sortableHandle(() => (
	<i className="fa fa-bars" aria-hidden="true"></i>
));

const SortableItem = sortableElement(({ onchange, elem, idx, ondelete }) => (
	<li className="list-inline-item mb20 dextraAlbumPic">
		<div
			className="dextra-ablum-item"
			style={{ backgroundImage: `url(` + url + elem.path + `)` }}
		>
			<DragHandle />
		</div>
		<span
			className="close"
			onClick={() => {
				ondelete(idx, 1);
			}}
		>
			<i className="fa fa-times"></i>
		</span>
	</li>
));

const SortableContainer = sortableContainer(({ children }) => {
	return <ul className="list-inline">{children}</ul>;
});

const ImageShared = ({ artistDetails, uploadContent, isImage, type }) => {
	const [state, setState] = useState({
		file: "",
		// dalbumId: 2,
		// dextra_label_id: 4,
		images: [],
	});

	// formData = new FormData();

	useEffect(() => {
		// setDataToState('albumData', artistDetails)
		getImages();
	}, []);

	const handleInputChange = (event) => {
		if (event.target.type === "file") {
			if (artistDetails.type == 2) {
				if (state.images.length > 0) {
					return false;
				}
			}

			var value = [];
			for (var i = 0; i < event.target.files.length; i++) {
				var meriFile = event.target.files[i];
				// formData.append("dextra", meriFile);
				value.push(meriFile);
			}
			setState((oldState) => ({
				...oldState,
				file: value,
			}));
		}
	};

	const upload = (e) => {
		setState((oldState) => ({
			...oldState,
			loader: true,
		}));
		let formData = new FormData();
		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};
		for (let value of e.target.files) {
			formData.append("dextra", value);
		}

		formData.append("dalbum_id", artistDetails.dalbum_id);
		formData.append("type", artistDetails.type);
		formData.append("dextra_label_id", artistDetails.dextra_label_id);
		formData.append("label_name", artistDetails.label);

		axios
			.post("/app/dextra/saveDextra", formData, config)
			.then(function (response) {
				if (response.data.success) {
					showNotification("Uploaded", "success");
					setState((oldState) => ({
						...oldState,
						loader: false,
					}));
					uploadContent();
					getImages();
				} else {
					showNotification(response.data.message, "error");
					setState((oldState) => ({
						...oldState,
						loader: false,
					}));
				}
			})
			.catch(function (error) {
				//console.log("Error occured Catched in catch block", error);
				setState((oldState) => ({
					...oldState,
					loader: false,
				}));
			});
	};

	const getImages = () => {
		setState((oldState) => ({
			...oldState,
			loader: true,
		}));

		let body = {
			dalbum_id: artistDetails.dalbum_id,
			type: artistDetails.type,
			label_id: artistDetails.dextra_label_id,
		};
		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};
		axios
			.post("/app/dextra/getDextra", body, config)
			.then(function (response) {
				if (response.data.success) {
					setState((oldState) => ({
						...oldState,
						images: response.data.data.data,
						loader: false,
					}));
				} else {
					showNotification(response.data.message, "error");
					setState((oldState) => ({
						...oldState,
						loader: false,
					}));
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				setState((oldState) => ({
					...oldState,
					loader: false,
				}));
			});
	};

	const onSortEnd = ({ oldIndex, newIndex }) => {
		let temp = state.images;
		temp = arrayMove(temp, oldIndex, newIndex);
		setState((oldState) => ({
			...oldState,
			images: temp,
		}));
	};

	const Save = (type) => {
		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};
		let temp = state.images;

		let tobeSent = { type: type, data: temp };
		axios
			.post("app/addalbum/save-extra", tobeSent, config)
			.then(function (response) {})
			.catch(function (error) {
				//console.log(error);
				setState((oldState) => ({
					...oldState,
					loader: false,
				}));
			});
	};

	const setDataToState = (ToState, fromVariable) => {
		setState((oldState) => ({
			...oldState,
			[ToState]: fromVariable,
		}));
	};
	const deleteMember = (idx, deleteFrom) => {
		if (deleteFrom === 2) {
			var imageFiles = state.file;
			imageFiles.splice(idx, 1);
			setState((oldState) => ({
				...oldState,
				file: imageFiles,
			}));
		} else if (deleteFrom === 1) {
			var imageFiles = state.images;

			setState((oldState) => ({
				...oldState,
				loader: true,
			}));

			var config = {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
				},
			};
			axios
				.post("/app/dextra/delete", imageFiles[idx], config)
				.then(function (response) {
					if (response.data.success) {
						setState((oldState) => ({
							...oldState,
							loader: false,
						}));
					} else {
						showNotification("Something Went Wrong", "error");
						setState((oldState) => ({
							...oldState,
							loader: false,
						}));
					}
				})
				.catch(function (error) {
					showNotification(error.name, "warning");
					setState((oldState) => ({
						...oldState,
						loader: false,
					}));
				});

			imageFiles.splice(idx, 1);

			setState((oldState) => ({
				...oldState,
				images: imageFiles,
			}));
		}
		// else
		//console.log("Something Wrong happened");
	};
	return (
		<section>
			{state.loader && <Loader />}
			<div className="row mb30 upload-wrapper">
				<div className="col-lg-8 uploadtrackLeft">
					<div className="card">
						<div className="card-header">
							<div className="row align-items-center">
								<div className="col-md-6">
									<div className="input-group">
										<div className="btn-cancel uploadButton">
											Choose file
											{artistDetails.type == 2 ? (
												<input
													className="form-control uploadFile"
													type="file"
													disabled={state.images.length}
													onChange={(e) => {
														upload(e, type);
													}}
													accept="image/*"
												/>
											) : (
												<input
													className="form-control uploadFile"
													type="file"
													onChange={(e) => {
														upload(e, type);
													}}
													multiple
													accept="image/*"
												/>
											)}
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="option-list pull-right">
										<ul className="list-inline mb0">
											<li className="list-inline-item">
												<a
													onClick={() => Save(3)}
													className="text-white text-uppercase btn-agree"
												>
													Save
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="card-body bg-white pd20 overflow-scroll h350">
							<div className="upload-wrapper-conent">
								<div className="row mb10">
									<div className="col-md-12">
										<h2 className="mb10 din-font text-uppercase ">
											You have{" "}
											<span className="text-green">
												{state.images.length} Files
											</span>{" "}
											that have been uploaded.
										</h2>
									</div>
								</div>
								<div className="row mb20">
									<div className="col-md-12">
										<div className="dextra-album-list">
											<ul className="list-inline">
												{state.images && (
													<>
														<SortableContainer
															axis="xy"
															onSortEnd={onSortEnd}
															useDragHandle
														>
															{state.images.map((elem, index) => (
																<SortableItem
																	key={`item-${index}`}
																	index={index}
																	idx={index}
																	elem={elem}
																	ondelete={deleteMember}
																/>
															))}
														</SortableContainer>
														{/* {state.images.map((element, idx) =>
                                                                <li className="list-inline-item mb20 ">
                                                                    <div className="dextra-ablum-item" style={{ backgroundImage: `url(` + url + element.path + `)` }} ></div>
                                                                    <span className="close" onClick={() => { deleteMember(idx, 1) }} ><i className="fa fa-times"></i></span>
                                                                </li>
                                                            )}  */}
													</>
												)}
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-lg-4 tabStep4">
					<div className="upload-content-detail pd20 bg-white clearfix">
						{isImage && (
							<h2 className="text-uppercase din-font mb10">
								Upload 1 Image To:
							</h2>
						)}

						{!isImage && (
							<h2 className="text-uppercase din-font mb10">
								Upload Images To:
							</h2>
						)}
						<div className="form-group">
							<input
								type="text"
								name=""
								disabled
								value={artistDetails.label}
								className="form-control"
								placeholder=""
							/>
						</div>
						<div className="album-specification">
							<h3 className="text-uppercase">Specifications</h3>
							<h4>Format to Upload: </h4>
							<div className="spacer20"></div>
							<h4>
								PNG (preferred) and JPEG <br />
								Dynamic Images: GIF <br />
								Minimum sizes: 12" x 12" at 72 dpi Pixels: 1500 x 1500 ppi
							</h4>
							<div className="spacer20"></div>
							{/* <h4>For more information on ISRC <br />numbers, visit:</h4>
                                <a className="text-orange brekText">http://www.nationwidebarcode.com/purchase-barcodes/barcodes-for-music/</a> */}
							{/* <button type="button" className="btn-save text-uppercase pull-right mt30" onClick={(e) => upload(e, type)}>Upload</button> */}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ImageShared;
