import Header from "../../shared/FanHeader/header";
import Footer from "../../shared/footer/footer";
import DmicComponent from "../../shared/Dmic/DmicComponent";

const FanDmic = () => {
	return (
		<div>
			<Header />
			<DmicComponent />
			<Footer />
		</div>
	);
};

export default FanDmic;
