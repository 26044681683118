import { Link } from "react-router-dom";

const Header = () => {
	return (
		<div>
			<header className="header-inner">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-2 navbar-header">
							<Link to={"/"}>
								<div className="">
									<img
										href="#"
										src={require("./../../assets/img/logo.png")}
										alt="logo"
										className="logo-text-dark"
									/>
								</div>
							</Link>

							<button
								className="navbar-toggler d-block d-lg-none"
								type="button"
								data-toggle="collapse"
								data-target="#navbar-header"
								aria-controls="navbar-header"
								aria-expanded="false"
								aria-label="Toggle navigation"
							>
								<span className="navbar-toggler-icon"></span>
							</button>
						</div>

						{/* <div className="col-lg-6 navbar-wrapper">
                                    <div id="navigation" className="navbar navbar-expand-lg shrink mbl-pb-0">
                                        <div className="navbar-collapse collapse" id="navbar-header">
                                            <ul className="navbar-nav">
                                                <li className="nav-item active">
                                                    <a className="nav-link">Home</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link">About</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link">Sell</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link">DMIC</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> */}

						{/* <div className="col-lg-4 navbar-wrapper d-flex justify-content-end">
                                    <div id="navigation" className="navbar navbar-expand-lg shrink fullWidth">
                                        <div className="navbar-collapse collapse" id="navbar-header">
                                            <ul className="navbar-nav">
                                                <li className="nav-item">
                                                    <a className="nav-link">Support <img src="img/arrow-right.png" alt="" title="" /></a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link">Fan Side</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> */}
					</div>
				</div>
			</header>
		</div>
	);
};

export default Header;
