import ReCAPTCHA from "react-google-recaptcha";
import validator from "validator";
import axios from "axios";
import { useState } from "react";
import { showNotification } from "../showNotification";

const FeedbackComponent = ({ artist }) => {
	const [state, setState] = useState({
		maxChar: 250,
		error: {},
		email: "",
		name: "",
		description: "",
		catcha: "",
	});

	const handleInput = (e) => {
		let name = e.target.name;
		if (e.target.name === "description") {
			setState((oldState) => ({
				...oldState,
				description:
					e.target.value.length <= state.maxChar
						? e.target.value
						: e.target.value.substring(0, 250),
				chars:
					e.target.value.length <= state.maxChar ? e.target.value.length : 250,
			}));
			errorValidation(name);
		} else {
			setState((oldState) => ({
				...oldState,

				[e.target.name]: e.target.value,
			}));
			errorValidation(name);
		}
	};

	const errorValidation = (name, flag) => {
		let required = {
			email: "Email is Invalid",
			name: "Name is required",
			description: "Description in required",
			captcha: "Captcha Verification is required",
		};
		var err = state.error;
		if (flag) {
			for (var elem in required) {
				errorValidation(elem);
			}
			return;
		}

		if (required[name]) {
			if (name === "email") {
				// alert(validator.isEmail(state.email)||validator.isEmpty(state.email))
				if (!validator.isEmail(state.email) || validator.isEmpty(state.email)) {
					err.email = required.email;
				} else {
					delete err[name];
				}
			} else if (name === "name") {
				if (validator.isEmpty(state.name)) {
					err.name = required.name;
				} else {
					delete err[name];
				}
			} else if (name === "description") {
				if (validator.isEmpty(state.description)) {
					err.description = required.description;
				} else {
					delete err[name];
				}
			} else {
				if (!state.captcha) {
					err.captcha = required.captcha;
				}
			}
		}

		setState((oldState) => ({
			...oldState,
			error: err,
		}));
	};

	const validate = async () => {
		errorValidation("temp", true);
		if (Object.keys(state.error).length > 0) {
			return false;
		}
		await sendRequest();
		return true;
	};

	const sendRequest = () => {
		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};

		axios
			.post("app/feedback", state, config)
			.then(function (response) {
				if (response.data.success) {
					//console.log(response.data.data)
					setState((oldState) => ({
						...oldState,
						stepdata: response.data.data,
						loader: false,
					}));
					showNotification("Data Loaded", "success");
				} else {
					showNotification("Server Error" + response.data.message, "error");
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				//console.log(error);
			});
	};

	const Captcha = (value) => {
		//console.log("Captcha value:", value);
		let temp = state.error;
		delete temp.captcha;
		setState((oldState) => ({
			...oldState,
			captcha: value,
			error: temp,
		}));
	};

	return (
		<div>
			<section
				id="content"
				className={
					(artist ? "artist-side-bg" : "fan-side-bg") + " flex-wrapper"
				}
			>
				<div className="container">
					<div className="row mt20">
						<div className="col-md-8 mr-auto ml-auto col-sm-12">
							<div className="privacy-policy-heading">
								<h1 className="text-green din-font text-uppercase text-center font-large">
									SAY WHAT YOU NEED TO SAY
								</h1>
							</div>
							<h2 className="mb30">
								Our intention is to make DalbumX the absolute BEST experience
								possible for Artists and Fans alike! So, if you like something,
								we want to hear about it! And, if you don’t like something or
								have suggestions on how to improve the Dalbum Xperience, we
								definitely want to hear about it! Please keep it clean! Our
								system filters out any foul language! <b>We love you, too!</b>
							</h2>

							<div className="register-wrapper">
								<div className="register-form">
									<div className="form-group row no-margin-lr">
										<label
											className="col-sm-4 col-form-label text-uppercase mobile-text-left"
											htmlFor="name"
										>
											<span className="red">*</span>Name
										</label>
										<div className="col-sm-4 pd0">
											<input
												value={state.name}
												onChange={handleInput}
												name="name"
												type="text"
												className="form-control"
												id=""
												placeholder=""
											/>
											{state.error.name && (
												<span id="error" className="error-text">
													{state.error.name}{" "}
												</span>
											)}
										</div>
									</div>
									<div className="form-group row no-margin-lr">
										<label
											className="col-sm-4 col-form-label text-uppercase mobile-text-left"
											htmlFor="name"
										>
											<span className="red">*</span> Email:
										</label>
										<div className="col-sm-4 pd0">
											<input
												value={state.email}
												onChange={handleInput}
												name="email"
												type="email"
												className="form-control"
												id=""
												placeholder=""
											/>
											{state.error.email && (
												<span id="error" className="error-text">
													{state.error.email}{" "}
												</span>
											)}
										</div>
									</div>
									<div className="form-group row no-margin-lr">
										<label className="col-sm-4 col-form-label text-uppercase mobile-text-left">
											<span className="red">*</span>Description
										</label>
										<div className="col-sm-7 pd0">
											<textarea
												value={state.description}
												onChange={handleInput}
												name="description"
												className="form-control "
												rows="8"
											></textarea>
											<span className="">
												Characters Left:{" "}
												{state.maxChar - (state.chars ? state.chars : 0)}/
												{state.maxChar}
											</span>
											{state.error.description && (
												<span id="error" className="error-text">
													{state.error.description}{" "}
												</span>
											)}
										</div>
									</div>

									{/* <div className="form-group row no-margin-lr">
                                            <label className="col-sm-5 col-form-label text-uppercase mobile-text-left" htmlFor="name">please enter the text below</label>
                                            <input type="email" className="form-control col-sm-4" id="" placeholder="" />
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <div className="captcha-box">

                                                </div>
                                            </div>
                                            <label className="col-sm-6 col-form-label text-uppercase" htmlFor="name">can’t read the text?<br /><a href="">click here for another code.</a></label>
                                        </div> */}
									<div className="form-group row mblRow">
										<label className="col-lg-4 col-form-label" htmlFor="name">
											<span className="red">*</span>Security Verification:
										</label>
										<div className="col-lg-4 lpadding">
											<ReCAPTCHA
												sitekey="6Lcz84gUAAAAAMeBz2hhk_0vSQqOkoHV1AmuVkS4"
												onChange={Captcha}
											/>
											{state.error.captcha && (
												<span id="error" className="error-text">
													{state.error.captcha}{" "}
												</span>
											)}
										</div>
									</div>

									<div className="form-group text-center mblBtn">
										<button
											type="button"
											className="btn-cancel text-uppercase w200"
										>
											Cancel
										</button>
										<button
											type="button"
											onClick={validate}
											className="btn-save text-uppercase w200"
										>
											Send
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default FeedbackComponent;
