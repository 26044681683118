import { notify } from "react-notify-toast";

export const showNotification = (message,type='success',timeout=2000) => {
	let buttonColor;

    // Set button color based on type
    switch (type) {
        case 'success':
            buttonColor = "rgb(85, 202, 146)";
            break;
        case 'error':
            buttonColor = "rgb(232, 87, 66)"; 
            break;
        case 'warning':
            buttonColor = "rgb(245, 226, 115)"; 
            break;
        default:
            buttonColor = "rgb(85, 202, 146)"; 
            break;
    }

	return notify.show(<div>
    						<button style={{"background":buttonColor,"color":"white","border":"none","outline":"none"}} onClick={notify.hide}>{message}</button>
  						</div>, type,timeout);
}