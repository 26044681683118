import { forwardRef, useEffect, useRef, useState } from "react";
import axios from "axios";
import { notify } from "react-notify-toast";
import UploadAudio from "./uploadaudio";
import UploadImage from "./uploadimage";
import UploadText from "./uploadText";
import Loader from "../../../shared/loader/loader";
import "./suggestion.css";
import { arrayMoveImmutable as arrayMove } from "array-move";
import UploadVideo from "./uploadVideo";
import { showNotification } from "../../../shared/showNotification";
import getImageAspectRatio from "../../ImageAspectRatio";
import useArtwork from "../../useArtwork";
import { errorModal } from "../../../shared/errorModal";

const HiddenMenu = ({ onclose, material, onchange }) => {
    return (
        <div className="hidden-track-detail">
            <div className="input-group select-wrapper">
                <select
                    className="form-control col-sm-12"
                    value="notSelected"
                    onChange={onchange}
                >
                    <option disabled value="notSelected">
                        Select Category
                    </option>
                    {material.map((elem, index) => (
                        <option key={"opt" + index} value={elem.material_sub_category_id}>
                            {elem.name !== "Audio" ? elem.name : "Hidden Tracks"}
                        </option>
                    ))}
                </select>
            </div>
            <a onClick={onclose}>
                <span className="fa fa-times"></span>
            </a>
        </div>
    );
};

const OopsMenu = ({ close, hh }) => {
    return (
        <div className="hidden-track-delete-msg bg-white pd10">
            <h2 className="text-uppercase mb10">
                Are You Sure You Would
                <br /> Like to Delete This?
            </h2>
            <div className="d-flex mr-3">
                <button
                    type="button"
                    onClick={close}
                    className="btn-save text-uppercase"
                >
                    oops, No
                </button>
                <button
                    type="button"
                    onClick={hh}
                    className="btn-cancel text-uppercase"
                >
                    Delete
                </button>
            </div>
        </div>
    );
};

const ItemsMenu = ({ items, closeMenu, deleteItems, edit }) => {
	console.log("Items in ItemsMenu: ", items);
    return (
        <div
            className="hidden-track-detail"
            style={{ maxHeight: "240px", overflowY: "auto" }}
        >
            <div className="hidden-track-list">
                <div className="text-right outer-close">
                    <i onClick={closeMenu} class="fa fa-times" aria-hidden="true"></i>
                </div>
                <h2 className="text-uppercase">Hidden {items.type}:</h2>
                <ul className="list-unstyled mb0">
                    {items.items.map((elem, index) => (
                        <li key={"items" + index}>
                            <a>
                                <span>{!elem.text_string && index + 1 + ": "}</span>
                                {elem.name || elem.link}
                            </a>
                        </li>
                    ))}
                    {/* <li><a href="#"><span>X</span> 02 Lose without you</a></li> */}
                    {/* <li><a href="#"><span>X</span> 03 To0 Much Noise</a></li> */}
                </ul>
                <div className="d-flex mr-3">
                    {/* <button onClick={closeMenu} type="button" className="btn-cancel text-uppercase">close</button> */}
                    <button
                        onClick={edit}
                        type="button"
                        className="btn-cancel text-uppercase"
                    >
                        Edit
                    </button>
                    <button
                        onClick={deleteItems}
                        type="button"
                        className="btn-cancel text-uppercase"
                    >
                        Delete
                    </button>
                </div>
            </div>
        </div>
    );
};

const Step6AddAlbumComponent = forwardRef(
    ({ album_name, changestep, updateSize }, ref) => {
        const canvasRef = useRef([]);
        const [state, setState] = useState({
            divs: [],
            artwork: [],
            rawArtwork: [],
            drawFlag: 0,
            material: [],
            view: "main",
            embeddata: {},
            deleteEmbed: false,
            initDone: false,
        });

        const [deleteIndex, setDeleteIndex] = useState(null);
        const [currentActive, setCurrentActive] = useState(0);

        useEffect(() => {
            window.$("#HiddenTracks").carousel("pause");
            window.$("#HiddenTracks").carousel({
                interval: false,
                keyboard: false,
            });
            getstepdata();
        }, []);

        const { isEnriched } = useArtwork(state.rawArtwork, 500);

        useEffect(() => {
            const filteredArtworks = state.rawArtwork.filter(art => art.label !== 'Front Cover Still');

            setState(oldState => ({
                ...oldState,
                artwork: filteredArtworks,
                initDone: true,
            }))

        }, [isEnriched])

        useEffect(() => {
            if (state.initDone && state.artwork.length > 0 && canvasRef.current.length === state.artwork.length) {
                edit(0);
                setState(oldState => ({
                    ...oldState,
                }))
            }
        }, [state.initDone, state.artwork]);

        useEffect(() => { 
            if (state.closeHiddenMenuFlag) { 
                edit(currentActive);
                getstepdata();

                setState((oldState) => ({ 
                    ...oldState,
                    closeHiddenMenuFlag: false
                }))
            }
        }, [state.closeHiddenMenuFlag]);

        const setCanvasRef = (index, element) => {
            canvasRef.current[index] = element;
        };

        const enableCanvasDraw = (index) => {
            const object = canvasRef.current[index];
                        if (object) {
                draw(object, index);
            }
        };

        const edit = (ref = 0) => {
            window.$("#HiddenTracks").carousel(ref);
            window.$("#HiddenTracks").carousel("pause");
            window.$("#HiddenTracks").carousel({
                interval: false,
                keyboard: false,
            });
            enableCanvasDraw(ref);
        };

        const test = (i) => {
            setDeleteIndex(i);
            if (i !== undefined) {
                let items = state.divs[i].hidden_dimension;
                if (items.material_sub_category_id === 1) {
                    setState((oldState) => ({
                        ...oldState,
                        embedItems: { type: "tracks", items: items.other_tracks },
                        drawFlag: 2,
                        currentActiveDimention: items.embedded_hidden_dimension_id,
                    }));
                }
                if (items.material_sub_category_id === 2) {
                    setState((oldState) => ({
                        ...oldState,
                        embedItems: { type: "image", items: items.other_images },
                        drawFlag: 2,
                        currentActiveDimention: items.embedded_hidden_dimension_id,
                    }));
                }
                if (items.material_sub_category_id === 3) {
                    setState((oldState) => ({
                        ...oldState,
                        embedItems: { type: "images", items: items.other_images },
                        drawFlag: 2,
                        currentActiveDimention: items.embedded_hidden_dimension_id,
                    }));
                }
                if (items.material_sub_category_id === 4) {
                    setState((oldState) => ({
                        ...oldState,
                        embedItems: { type: "videos", items: items.videos },
                        drawFlag: 2,
                        currentActiveDimention: items.embedded_hidden_dimension_id,
                    }));
                }
                if (items.material_sub_category_id === 5) {
                    setState((oldState) => ({
                        ...oldState,
                        embedItems: { type: "text", items: items.texts },
                        drawFlag: 2,
                        currentActiveDimention: items.embedded_hidden_dimension_id,
                    }));
                } else {
                }
                // setState(oldState => ({
                // ...oldState,
                //     deleteEmbed: true,
                //     drawFlag: 2
                // })
            }
        };

        const deleteEmbedMaterial = (elem) => {
            var temp = state.divs;

            setState((oldState) => ({
                ...oldState,
                loader: true,
            }));

            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
                },
            };
            // alert(78)
            axios
                .post(
                    "app/addalbum/step6delete",
                    elem ? elem : temp[deleteIndex].hidden_dimension,
                    config
                )
                .then(function (response) {
                    setState((oldState) => ({
                        ...oldState,
                        loader: false,
                    }));
                    updateSize();
                })
                .catch(function (error) {
                    setState((oldState) => ({
                        ...oldState,
                        loader: false,
                    }));
                });
            temp.splice(deleteIndex, 1);
            setState((oldState) => ({
                ...oldState,
                divs: temp,
                deleteEmbed: false,
                drawFlag: 0,
            }));
        };

        const closeHiddenMenu = () => {
            if (state.drawFlag !== 0) {
                var temp = state.divs;
                // temp.pop()

                setState((oldState) => ({
                    ...oldState,
                    divs: temp,
                    drawFlag: 0,
                    view: "main",
                    closeHiddenMenuFlag: true
                }));
            }
        };

        const nextArtwork = () => {
            setCurrentActive((prev) => {
                const current = (prev + 1) % state.artwork.length;
                edit(current);
                closeHiddenMenu();
                return current;
            });
        };

        const previousArtwork = () => {
            setCurrentActive((prev) => {
                const current =
                    (prev - 1 + state.artwork.length) % state.artwork.length;
                edit(current);
                closeHiddenMenu();
                return current;
            });
        };

        const addMaterial = (e) => {
            // alert(e.target.value)
            e.persist();
            let tempValue = e.target.value;

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
                },
            };

            let dimension = {};

            dimension.artwork_image_id =
                state.artwork[currentActive].artwork_image_id;
            dimension.dalbum_id = sessionStorage.getItem("album_id");
            dimension.top = state.divs[state.divs.length - 1].hidden_dimension.top;
            dimension.left = state.divs[state.divs.length - 1].hidden_dimension.left;
            dimension.height =
                state.divs[state.divs.length - 1].hidden_dimension.height;
            dimension.width =
                state.divs[state.divs.length - 1].hidden_dimension.width;
            dimension.material_sub_category_id = tempValue;

            axios
                .post("app/addalbum/step6", dimension, config)
                .then(function (response) {
                    // alert(tempValue)
                    // getstepdata(1)
                    setState((oldState) => ({
                        ...oldState,
                        loader: false,
                        currentActiveDimention:
                            response.data.data.embedded_hidden_dimension_id,
                        view: tempValue,
                        embedItems: { items: [] },
                    }));
                })
                .catch(function (error) {
                    setState((oldState) => ({
                        ...oldState,
                        loader: false,
                    }));
                });
            // setState(oldState => ({
            // ...oldState,
            //     view: e.target.value,
            //     embedItems: null
            // })
        };

        const save = () => {};

        const onSortEnd = ({ oldIndex, newIndex }) => {
            let temp = state.embedItems;
            temp.items = arrayMove(temp.items, oldIndex, newIndex);
            setState((oldState) => ({
                ...oldState,
                embedItems: temp,
            }));
        };

        const Upload = (e) => {
            // return
            setState((oldState) => ({
                ...oldState,
                loader: true,
            }));

            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
                },
            };

            var formData = new FormData();
            formData.append("dalbum_id", sessionStorage.getItem("album_id"));

            if (e.files) {
                for (var i = 0; i < e.files.length; i++) {
                    formData.append("embedded", e.files[i]);
                }
            }
            if (e.text) {
                formData.append("text", e.text);
            }

            formData.append("material_sub_category", e.type);

            if (e.link || e.link === "") {
                formData.append("link", e.link);
            }

            if (e.at_position || e.at_position === 0) {
                formData.append("at_position", e.at_position);
            }

            formData.append(
                "artwork_image_id",
                state.artwork[currentActive].artwork_image_id
            );

            // if (!e.hidden_dimension_id) {
            //     formData.append(
            //         'top',
            //         state.divs[state.divs.length - 1].hidden_dimension.top
            //     )
            //     formData.append(
            //         'left',
            //         state.divs[state.divs.length - 1].hidden_dimension.left
            //     )
            //     formData.append(
            //         'height',
            //         state.divs[state.divs.length - 1].hidden_dimension.height
            //     )
            //     formData.append(
            //         'width',
            //         state.divs[state.divs.length - 1].hidden_dimension.width
            //     )
            // }
            // alert(e.hidden_dimension_id)
            if (e.hidden_dimension_id) {
                // alert(99)
                formData.append("embedded_hidden_dimension_id", e.hidden_dimension_id);
            }
            //console.log("===>>", formData);
            // alert(e.hidden_dimension_id)

            axios
                .post("app/addalbum/step6", formData, config)
                .then(function (response) {
                    getstepdata(1);
                    updateSize();
                    setState((oldState) => ({
                        ...oldState,
                        loader: false,
                    }));
				  	showNotification("Data saved","success")
                })
                .catch(function (error) {
                    setState((oldState) => ({
                        ...oldState,
                        loader: false,
                    }));
					errorModal(error)
                });

            // setState(oldState => ({
            // ...oldState,
            //     drawFlag: 0,
            //     view: 'main'
            // }, () => { edit() })
            // //console.log(e)
        };

        const getstepdata = (flag) => {
            setState((oldState) => ({
                ...oldState,
                loader: true,
            }));

            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
                },
            };

            axios
                .get(
                    "app/addalbum/step6?album_id=" + sessionStorage.getItem("album_id"),
                    config
                )
                .then(function (response) {
                    var temp = [];
                    for (let elem of response.data.data.hidden_dimension) {
                        if (
                            elem.other_tracks.length === 0 &&
                            elem.other_images.length === 0 &&
                            elem.videos.length === 0 &&
                            elem.texts.length === 0
                        ) {
                            deleteEmbedMaterial(elem);
                        } else {
                            temp.push({
                                hidden_dimension: elem,
                                artwork_image_id: elem.artwork_image_id,
                            });
                        }
                    }
                    if (flag) {
                        let temp_hidden = {};
                        for (let elem of response.data.data.hidden_dimension) {
                            if (
                                elem.embedded_hidden_dimension_id ===
                                state.currentActiveDimention
                            ) {
                                temp_hidden = elem;
                            }
                        }
                        let temp2 = {};
                        if (temp_hidden.material_sub_category_id === 1) {
                            temp2 = { items: temp_hidden.other_tracks };
                        } else if (temp_hidden.material_sub_category_id === 2) {
                            temp2 = { items: temp_hidden.other_images };
                        } else if (temp_hidden.material_sub_category_id === 3) {
                            temp2 = { items: temp_hidden.other_images };
                        } else if (temp_hidden.material_sub_category_id === 4) {
                            temp2 = { items: temp_hidden.videos };
                            //console.log("=>v", temp2);
                        } else if (temp_hidden.material_sub_category_id === 5) {
                            temp2 = { items: temp_hidden.texts };
                        }
                        temp2.hidden_dimension_id =
                            temp_hidden.embedded_hidden_dimension_id;

                        setState((oldState) => ({
                            ...oldState,
                            embedItems: temp2,
                        }));
                    }

                    sortintoCatagory(response.data.data.artwork);

                    setState((oldState) => ({
                        ...oldState,
                        // artwork: response.data.data.artwork,
                        material: response.data.data.material_sub_category,
                        divs: temp,
                        loader: false,
                    }));

                    edit(currentActive);

                })
                .catch(function (error) {
                    setState((oldState) => ({
                        ...oldState,
                        loader: false,
                    }));
                });
        };

        const sortintoCatagory = (data) => {
            var sorted = data.sort(function (a, b) {
                return a.at_position - b.at_position;
            });
            
            setState((oldState) => ({
                ...oldState,
                rawArtwork: sorted,
            }));
        };

        const closeOopsMenu = () => {
            setDeleteIndex(null);
            setState((oldState) => ({
                ...oldState,
                deleteEmbed: false,
                drawFlag: 0,
            }));
        };

        const closeitemsMenu = () => {
            setState((oldState) => ({
                ...oldState,
                drawFlag: 0,
            }));
        };
        const proceedDelete = () => {
            setState((oldState) => ({
                ...oldState,
                drawFlag: 3,
            }));
        };

        const AudioNameChange = (index, e) => {
            let temp = state.embedItems;
            temp.items[index].name = e.target.value;
            setState((oldState) => ({
                ...oldState,
                embedItems: temp,
            }));
        };

        const AudioDelete = (index, type) => {
            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
                },
            };
            let temp = state.embedItems;

            axios
                .post(
                    "app/addalbum/delete-extra",
                    { elem: temp.items[index], type: type },
                    config
                )
                .then(function (response) {})
                .catch(function (error) {
                    setState((oldState) => ({
                        ...oldState,
                        loader: false,
                    }));
                });

            temp.items.splice(index, 1);
            setState((oldState) => ({
                ...oldState,
                embedItems: temp,
            }));
        };

        const audioSave = (type) => {
            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
                },
            };
            let temp = state.embedItems;

            let tobeSent = { type: type, data: temp.items };
            axios
                .post("app/addalbum/save-extra", tobeSent, config)
                .then(function (response) {
                    showNotification("Data Saved", "success");
                })
                .catch(function (error) {
                    setState((oldState) => ({
                        ...oldState,
                        loader: false,
                    }));
                });
        };

        const linkSave = () => {
            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
                },
            };
            let temp = state.embedItems;

            axios
                .post("app/addalbum/save-extra", temp.items, config)
                .then(function (response) {
                    notify.show("Data Saved", "success", 1800);
                })
                .catch(function (error) {
                    setState((oldState) => ({
                        ...oldState,
                        loader: false,
                    }));
                });
        };

        const editItem = () => {
            let view;
            if (state.embedItems.type === "tracks") {
                view = "1";
            } else if (state.embedItems.type === "image") {
                view = "2";
            } else if (state.embedItems.type === "images") {
                view = "3";
            } else if (state.embedItems.type === "videos") {
                view = "4";
            } else if (state.embedItems.type === "text") {
                view = "5";
            }

            setState((oldState) => ({
                ...oldState,
                view: view,
            }));
        };

        const handleLink = (e) => {
            let temp = state.embedItems ? state.embedItems : {};
            temp.items = [];
            temp.items.push({ link: e.target.value });
            // temp[0].link = e.target.value
            setState((oldState) => ({
                ...oldState,
                embedItems: temp,
            }));
        };

        const draw = (canvas, idx) => {
            function setMousePosition(e) {
                var ev = e; //Moz || IE
                if (ev.pageX) {
                    //Moz
                    mouse.x =
                        ((ev.pageX -
                            canvas.getBoundingClientRect().left -
                            window.pageXOffset) /
                            state.artwork[idx].width) *
                        100;
                    mouse.y =
                        ((ev.pageY -
                            canvas.getBoundingClientRect().top -
                            window.pageYOffset) /
                            500) *
                        100;
                }
                // else if (ev.clientX) { //IE
                //     mouse.x = ev.clientX + document.body.scrollLeft;
                //     mouse.y = ev.clientY + document.body.scrollTop;
                // }
            }

            var mouse = {
                x: 0,
                y: 0,
                startX: 0,
                startY: 0,
            };
            var element = null;
            let shouldDraw = state.drawFlag === 0;

            canvas.onmousemove = function (e) {
                setMousePosition(e);
                if (element !== null) {
                    element.style.width = Math.abs(mouse.x - mouse.startX) + "%";
                    element.style.height = Math.abs(mouse.y - mouse.startY) + "%";
                    element.style.left =
                        mouse.x - mouse.startX < 0 ? mouse.x + "%" : mouse.startX + "%";
                    element.style.top =
                        mouse.y - mouse.startY < 0 ? mouse.y + "%" : mouse.startY + "%";
                }
            };

            canvas.onmouseup = function (e) {
                if (shouldDraw) {
                    if (element !== null) {
                        canvas.removeChild(canvas.childNodes[0]);
                        var divStyle = {
                            hidden_dimension: {
                                left: element.style.left,
                                top: element.style.top,
                                height: element.style.height,
                                width: element.style.width,
                            },
                            type: "",
                            artwork_image_id: state.artwork[idx].artwork_image_id,
                        };
                        

                        setState((oldState) => { 
                            const temp = oldState.divs;
                            temp.push(divStyle);

                            const updatedState = {
                                ...oldState,
                                divs: temp,
                                drawFlag: 1,
                            };
                            
                            return updatedState;
                        });
                        element = null;
                        canvas.style.cursor = "default";
                        shouldDraw = false;
                    }
                }
            };
            canvas.onmousedown = function (e) {
                if (shouldDraw) {
                    if (element === null) {
                        mouse.startX = mouse.x;
                        mouse.startY = mouse.y;
                        element = document.createElement("div");
                        element.className = "rectangle-embedded";
                        element.style.left = mouse.x + "%";
                        element.style.top = mouse.y + "%";
                        element.onClick = test();
                        canvas.appendChild(element);
                        canvas.style.cursor = "crosshair";
                    }
                }
            };
        };

        return (
            <div>
                {state.loader && <Loader />}
                {state.view === "1" && (
                    <>
                        <UploadAudio
                            save={audioSave}
                            AudioDelete={AudioDelete}
                            AudioNameChange={AudioNameChange}
                            onSortEnd={onSortEnd}
                            album_name={album_name}
                            dimension_id={state.currentActiveDimention}
                            items={state.embedItems ? state.embedItems : null}
                            type={1}
                            data={state.embeddata}
                            onupload={Upload}
                        />
                        <div className="row form-group justify-content-center">
                            <button
                                type="button"
                                className="btn-cancel text-uppercase"
                                onClick={() => {
                                    closeHiddenMenu();
                                }}
                            >
                                Back
                            </button>
                        </div>
                    </>
                )}
                {state.view === "2" && (
                    <>
                        <UploadImage
                            AudioDelete={AudioDelete}
                            album_name={album_name}
                            dimension_id={state.currentActiveDimention}
                            items={state.embedItems ? state.embedItems : null}
                            isImage={true}
                            type={2}
                            onupload={Upload}
                        />
                        <div className="row form-group justify-content-center">
                            <button
                                type="button"
                                className="btn-cancel text-uppercase"
                                onClick={() => {
                                    closeHiddenMenu();
                                }}
                            >
                                Back
                            </button>
                        </div>
                    </>
                )}
                {state.view === "3" && (
                    <>
                        <UploadImage
                            save={audioSave}
                            AudioDelete={AudioDelete}
                            album_name={album_name}
                            onSortEnd={onSortEnd}
                            dimension_id={state.currentActiveDimention}
                            items={state.embedItems ? state.embedItems : null}
                            type={3}
                            onupload={Upload}
                        />
                        <div className="row form-group justify-content-center">
                            <button
                                type="button"
                                className="btn-cancel text-uppercase"
                                onClick={() => {
                                    closeHiddenMenu();
                                }}
                            >
                                Back
                            </button>
                        </div>
                    </>
                )}
                {state.view === "4" && (
                    <>
                        <UploadVideo
                            save={linkSave}
                            AudioDelete={AudioDelete}
                            handleLink={handleLink}
                            onSortEnd={onSortEnd}
                            album_name={album_name}
                            dimension_id={state.currentActiveDimention}
                            items={state.embedItems ? state.embedItems : null}
                            type={4}
                            onupload={Upload}
                        />
                        <div className="row form-group justify-content-center">
                            <button
                                type="button"
                                className="btn-cancel text-uppercase"
                                onClick={() => {
                                    closeHiddenMenu();
                                }}
                            >
                                Back
                            </button>
                        </div>
                    </>
                )}
                {state.view === "5" && (
                    <>
                        <UploadText
                            album_name={album_name}
                            dimension_id={state.currentActiveDimention}
                            items={state.embedItems ? state.embedItems : null}
                            type={5}
                            onupload={Upload}
                        />
                        <div className="row form-group justify-content-center">
                            <button
                                type="button"
                                className="btn-cancel text-uppercase"
                                onClick={() => {
                                    closeHiddenMenu();
                                }}
                            >
                                Back
                            </button>
                        </div>
                    </>
                )}
                {state.view === "main" && (
                    <>
                        <div className="row mb30">
                            <div className="col-md-12">
                                {/* Lalo - 11/21/2020 - Changed <h2> to <h3> and Materials to Content
                                    <h3 className="text-justify mb20">Embedded Content (for example, hidden tracks) are part of your CORE DALBUM and will be FOREVER embedded anywhere you want in your Artwork - something else that makes the DALBUM Experience completely unique! You will later have the option of including Bonus Tracks  and other Extras in the “Dextras” Section after your CORE DALBUM has been uploaded, so make sure that whatever you Embed is something that you really want to have hidden. These are like Easter Eggs that your fans can discover just by finding the right place to press in your Artwork!</h3>
                                 */}
                                <h3 className="text-justify mb20">
                                    Embedded Content is part of your CORE DALBUM and will FOREVER
                                    be embedded wherever you place it in your artwork. Simply
                                    create a box on whatever part of the image you would like to
                                    embed your hidden content and follow the prompts. This could
                                    include audio tracks, video, photos or text. Your fans will
                                    have to find the exact place to press on your artwork in order
                                    to activate this content. Another option is that your artwork
                                    could be designed to prompt fans to touch a specific area to
                                    see the content (E.G. - “Touch this button to see our Concert
                                    Poster”). It’s just something else that makes the
                                    DalbumXperience completely unique. Embedding Hidden Content is
                                    completely optional - but we think it could be a lot of fun!
                                </h3>
                            </div>
                        </div>

                        <div className="row mb30">
                            <div
                                id="HiddenTracks"
                                className="carousel ml-auto mr-auto slider-custom-step6"
                            >
                                <div className="emb-metrial">
                                    {/* Lalo - 11/21/2020 - Changed "MATERIAL" to "CONTENT" */}
                                    <div className="head1">EMBEDDED CONTENT</div>
                                    <div className="contents">
                                        {" "}
                                        TOTAL: {state.divs.length}{" "}
                                        <span>
                                            <br />
                                        </span>{" "}
                                    </div>
                                </div>
                                <div className="carousel-inner" style={{ maxWidth: "100% !important"}}>
                                    {state.artwork.map((art, idx) => (
                                        <div
                                            key={"element" + idx}
                                            className={(idx === 0 ? "active " : "") + "carousel-item"}
                                            style={{ width: art.width ?? 500 + "px !important"}}
                                        >
                                            <div
                                                ref={(element) => setCanvasRef(idx, element)}
                                                className="step6-slider"
                                                style={{
                                                    backgroundImage:
                                                    `${art.urls}`,
                                                    backgroundPosition: "center",
                                                    backgroundSize: `${art.square ? "cover" : "100% 100%"}`,
                                                    backgroundRepeat: "no-repeat",
                                                }}
                                            ></div>

                                            {state.divs.map((elem, index) => (
                                                <div key={index}>
                                                    {elem.artwork_image_id === art.artwork_image_id && (
                                                        <div
                                                            key={"innerelem" + index}
                                                            className="rectangle-embedded"
                                                            style={elem.hidden_dimension}
                                                            onClick={() => {
                                                                test(index);
                                                            }}
                                                        ></div>
                                                    )}
                                                </div>
                                            ))}

                                            {state.drawFlag === 1 && (
                                                <HiddenMenu
                                                    onchange={addMaterial}
                                                    material={state.material}
                                                    onclose={closeHiddenMenu}
                                                />
                                            )}
                                            {state.drawFlag === 2 && (
                                                <ItemsMenu
                                                    edit={() => {
                                                        editItem(currentActive);
                                                    }}
                                                    items={state.embedItems}
                                                    closeMenu={closeitemsMenu}
                                                    deleteItems={proceedDelete}
                                                />
                                            )}

                                            {state.drawFlag === 3 && (
                                                <OopsMenu
                                                    hh={() => {
                                                        deleteEmbedMaterial();
                                                    }}
                                                    close={closeOopsMenu}
                                                />
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <a
                                    className="carousel-control-prev"
                                    onClick={previousArtwork}
                                    href="javascript:void(0);"
                                    role="button"
                                    data-slide="prev"
                                >
                                    <span
                                        className="carousel-control-prev-icon"
                                        aria-hidden="true"
                                    ></span>
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a
                                    className="carousel-control-next"
                                    onClick={nextArtwork}
                                    href="javascript:void(0);"
                                    role="button"
                                    data-slide="next"
                                >
                                    <span
                                        className="carousel-control-next-icon"
                                        aria-hidden="true"
                                    ></span>
                                    <span className="sr-only">Next</span>
                                </a>
                            </div>
                        </div>

                        <div className="row form-group justify-content-center">
                            <button
                                type="button"
                                className="btn-cancel text-uppercase"
                                onClick={() => {
                                    changestep(5);
                                }}
                            >
                                Back
                            </button>
                            <button
                                type="button"
                                className="btn-save text-uppercase"
                                onClick={() => {
                                    changestep(7);
                                }}
                            >
                                Next
                            </button>
                        </div>
                    </>
                )}
            </div>
        );
    }
);

export default Step6AddAlbumComponent;