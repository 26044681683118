import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import Notifications, { notify } from "react-notify-toast";
import ReCAPTCHA from "react-google-recaptcha";
import validator from "validator";
import { showNotification } from "../../shared/showNotification";

const RegisterFan = () => {
	const [state, setState] = useState({
		name: "",
		email: "",
		password: "",
		confirmPassword: "",
		gender: "",
		language: "",
		language_name: "",
		city: "",
		state: "",
		country: "",
		languages: [],
		isMailSent: false,
		error: {},
		flag: process.env.REACT_APP_ENABLE_CAPTCHA !== "true",
		errors: {},
	});

	const [validationRequired, setValidationRequired] = useState([]);

	useEffect(() => {
		if (validationRequired) {
			validate(validationRequired)
			setValidationRequired(undefined);
		}
	 }, [validationRequired, state]);

	useEffect(() => {
		getLanguage();
	}, []);

	const validate = (fields) => {
		var errors = { ...state.errors };

		if (Array.isArray(fields))
			fields.map(function (val) {
				errors = _validate(val, errors);
			});
		else errors = _validate(fields, errors);

		setState((oldState) => ({
			...oldState,
			errors: errors,
		}));

		return Object.keys(errors).length;
	};

	const _validate = (field, errors) => {
		if (field === "email") {
			if(validator.isEmpty(state.email)) {
				errors.email = "email is required";
			} else if (!validator.isEmail(state.email)) {
				errors.email = "Invalid email";
			} else {
				delete errors.email;
			}
			// Resetting server error
			setState((oldState) => ({
				...oldState,
				error: {}
			}));
		}

		if (field === "name") {
			if (validator.isEmpty(state.name))
				errors.name = "name is required";
			else delete errors.name;
		}

		if (field === "password") {
			if (!validator.isLength(state.password, { min: 6, max: 20 }))
				errors.password = "Password must be between 6 to 20 characters";
			else delete errors.password;
		}

		if (field === "confirmPassword") {
			if (state.password !== state.confirmPassword)
				errors.confirmPassword = "Confirm Password doesn't match password";
			else delete errors.confirmPassword;
		}

		if (field === "language_name") {
			if (validator.isEmpty(state.language_name)) {
				errors.language_name = "Language required";
			} else delete errors.language_name;
		}

		return errors;
	};

	const onCaptcha = () => {
		setState((oldState) => ({
			...oldState,
			flag: true,
		}));
	};

	const getLanguage = () => {
		axios
			.get("language/?page=1&limit=1000")
			.then(function (response) {
				if (response.data.success) {
					setState((oldState) => ({
						...oldState,
						languages: response.data.data.result,
					}));
				}
			})
			.catch(function (error) {});
	};
	const handleInputChange = (event) => {
		const target = event.target;
		const value =
			target.type === "anchor" ? target.attributes.value.value : target.value;
		const name = target.name;
		setState((oldState) => ({
			...oldState,
			[name]: value,
		}));

		setValidationRequired(target.name);

		if (name === "language") {
			const names = "language_name";
			setState((oldState) => ({
				...oldState,
				[names]: target.innerText,
			}));
			setValidationRequired(names);
		}
	};
	const handleSubmit = (event) => {
		event.preventDefault();
		var errors = 0;
		errors = validate([
			"name",
			"email",
			"password",
			"confirmPassword",
			"language_name",
		]);

		if (errors) return;

		if (!state.flag) {
			showNotification("Please Verify Captcha", "error");
			return false;
		}

		axios
			.post("auth/fan/register", {
				name: state.name,
				email: state.email,
				password: state.password,
				gender: state.gender,
				language_id: state.language,
				city: state.city,
				state: state.state,
				country: state.country,
			})
			.then(function (response) {
				if (response.data.success) {
					setState((oldState) => ({
						...oldState,
						isMailSent: true,
					}));
					showNotification(
						"Register Successfull Verify your account with email !",
						"success"
					);
				} else {
					// var err = state.error
					setState((oldState) => ({
						...oldState,
						error: response.data.error,
					}));
					showNotification(response.data.message, "error");
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
			});
	};

	return (
		<div>
			{!state.isMailSent && (
				<section id="content" className="fan-side-bg flex-wrapper">
					<Notifications options={{ zIndex: 99000 }} />
					<div className="container">
						<div className="row mt20">
							<div className="col-lg-12">
								<h5 className="pull-left require-field mb30">
									[<span className="red">*</span>] Indicates required Field
								</h5>
							</div>
							<div className="col-md-8 mr-auto ml-auto col-sm-12">
								<div className="register-wrapper">
									<div className="register-form">
										<div className="form-group row mblRow">
											<label className="col-lg-4 col-form-label" htmlFor="name">
												<span className="red">*</span>Name:
											</label>
											<div className="col-lg-4 p0">
												<input
													type="email"
													className="form-control"
													placeholder=""
													name="name"
													onBlur={handleInputChange}
												/>
												{(state.errors.name) && (
													<span id="error" className="form-text error-text">
														{state.errors.name}{" "}
													</span>
												)}
											</div>
										</div>
										<div className="form-group row mblRow">
											<label className="col-lg-4 col-form-label" htmlFor="name">
												<span className="red">*</span>Password:
											</label>
											<div className="col-lg-4 p0">
												<input
													type="password"
													className="form-control"
													name="password"
													placeholder="Password"
													onBlur={handleInputChange}
												/>
												{(state.error.password || state.errors.password) && (
													<span id="error" className="form-text error-text">
														{state.error.password || state.errors.password}{" "}
													</span>
												)}
											</div>
										</div>
										<div className="form-group row mblRow">
											<label className="col-lg-4 col-form-label" htmlFor="name">
												<span className="red">*</span>Confirm Password:
											</label>
											<div className="col-lg-4 defaultSpace">
												<input
													type="password"
													className="form-control"
													placeholder=""
													name="confirmPassword"
													onBlur={handleInputChange}
												/>
												{state.errors.confirmPassword && (
													<span id="error" className="error-text">
														{state.errors.confirmPassword}{" "}
													</span>
												)}
											</div>
										</div>
										<div className="form-group row mblRow">
											<label className="col-lg-4 col-form-label" htmlFor="name">
												<span className="red">*</span>Email:
											</label>
											<div className="col-lg-4 p0">
												<input
													type="email"
													className="form-control"
													placeholder=""
													name="email"
													onChange={handleInputChange}
												/>
												{(state.error.email || state.errors.email)&& (
													<span id="error" className="form-text error-text">
														{state.error.email || state.errors.email }{" "}
													</span>
												)}
											</div>
										</div>
										{/* <div className="form-group row mblRow">
											<label className="col-lg-4 col-form-label" htmlFor="name">
												Gender{" "}
												<i>
													<small>(optional):</small>
												</i>
											</label>
											<div className="col-lg-4 lpadding pt10">
												<div className="d-inline form-group text-center">
													<input
														type="radio"
														className="form-check-input"
														id="Male"
														name="gender"
														value="male"
														onBlur={handleInputChange}
													/>
													<label className="form-check-label" htmlFor="Male">
														Male
													</label>
												</div>
												<div className="d-inline form-group form-check text-center">
													<input
														type="radio"
														className="form-check-input"
														id="Female"
														name="gender"
														value="female"
														onBlur={handleInputChange}
													/>
													<label className="form-check-label" htmlFor="Female">
														Female
													</label>
												</div>
											</div>
										</div> */}
										<div className="form-group row mblRow">
											<label className="col-lg-4 col-form-label" htmlFor="name">
												<span className="red">*</span>Language:
											</label>
											<div className="input-group select-wrapper col-lg-4 lpadding rpadding">
												<input
													type="dropdown"
													className="form-control"
													aria-label="Text input with dropdown button"
													disabled
													value={state.language_name}
												/>
												<div className="input-group-append">
													<button
														className="btn btn-default btn-md m-0 px-3 py-2 z-depth-0 dropdown-toggle btn-gradient"
														type="button"
														data-toggle="dropdown"
														aria-haspopup="true"
														aria-expanded="false"
													></button>
													<div className="dropdown-menu dropdown-menu-right">
														{state.languages.map((language) => (
															<a
																className="dropdown-item"
																type="anchor"
																onClick={handleInputChange}
																key={language.language_id}
																name="language"
																value={language.language_id}
															>
																{language.name}
															</a>
														))}
													</div>
													{state.errors.language_name && (
														<span id="error" className="error-text">
															{state.errors.language_name}{" "}
														</span>
													)}
												</div>
											</div>
										</div>
										<div className="form-group row mblRow">
											<label className="col-lg-4 col-form-label" htmlFor="name">
												City:
											</label>
											<input
												type="text"
												className="form-control col-lg-4"
												placeholder=""
												name="city"
												onBlur={handleInputChange}
											/>
										</div>
										<div className="form-group row mblRow">
											<label className="col-lg-4 col-form-label" htmlFor="name">
												State:
											</label>
											<input
												type="text"
												className="form-control col-lg-4"
												placeholder=""
												name="state"
												onBlur={handleInputChange}
											/>
										</div>
										<div className="form-group row mblRow">
											<label className="col-lg-4 col-form-label" htmlFor="name">
												Country:
											</label>
											<input
												type="text"
												className="form-control col-lg-4"
												placeholder=""
												name="country"
												onBlur={handleInputChange}
											/>
										</div>
										<div className="form-group row mblRow">
											<label className="col-lg-4 col-form-label" htmlFor="name">
												Security Verification:
											</label>
											<div className="col-lg-4 lpadding">
												<ReCAPTCHA
													sitekey="6LeqFKwZAAAAAKRDkpF40jLNDqbYX6Ji9119tqIR"
													onChange={(_) => onCaptcha()}
												/>
											</div>
										</div>
										<div className="form-group rowmblRow row jsutify-content-center pt40">
											<div className="col-lg-4 mblHide"></div>
											<div className="col-lg-8">
												<button
													type="submit"
													className="btn btn-gradient btn-sm w200 pd15 m-auto"
													onClick={handleSubmit}
												>
													Register
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			)}

			{state.isMailSent && (
				<section id="content" className="flex-wrapper">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="success-msg bg-orange pd30">
									<div className="success-msg-inner bg-green pd30">
										<h2 className="text-white text-uppercase">
											A Message <br /> in a Bottle*
											<br /> has Been sent
											<br /> to Your Mail
										</h2>
										<h3 className="text-white">
											Please click on the link in your message to activate{" "}
											<br /> your account and enjoy your Dalbum experience.
										</h3>
										<p className="text-white text-right">
											*ok, not really a bottle.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			)}
		</div>
	);
};

export default RegisterFan;
