const ToolTip = ({ claz, color, message }) => {
	return (
		<div className="tooltip">
			<img src={require("../assets/img/info.png")} alt="" />
			<span
				className={claz ? claz : "tooltiptext"}
				style={{
					backgroundColor: color ? color : "#d95335",
				}}
			>
				{" "}
				{message}{" "}
			</span>
		</div>
	);
};

export default ToolTip;
