import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { withRouter } from "./../../shared/withRouter";
import axios from "axios";

const Header = (props, ref) => {
	const location = useLocation();

	const [cartCount, setCartCount] = useState(0);

	const navItem = [
		{ name: "home", path: "/fan/dashboard" },
		{ name: "library", path: "/fan/library" },
		{ name: "browse", path: "/fan/search" },
		{ name: "about", path: "/fan/about" },
		{ name: "dmic", path: "/fan/dmic" },
	];

	useEffect(() => {
		getcart();
		window.$(document).on("cartUpdate", {}, function (event, arg) {
			setCartCount(
				sessionStorage.getItem("cartCount")
					? sessionStorage.getItem("cartCount")
					: 0
			);
		});
	}, []);

	const calCount = (data) => {
		setCartCount(data.length);
		sessionStorage.setItem("cartCount", data.length);
	};

	const getcart = () => {
		var config = {
			headers: { Authorization: "Bearer " + localStorage.getItem("tokenFan") },
		};

		axios
			.get("/app/fan/getcart", config)
			.then(function (response) {
				//console.log(response.data.data)

				if (response.data.success) {
					// notify.show("Added Successfully", 'success', 1200);
					calCount(response.data.data.data.data);
				} else {
					// notify.show(response.data.message, 'error', 1200);
				}
			})
			.catch(function (error) {
				// notify.show(error.name, 'warning', 1000);
				//console.log(error);
			});
	};

	const changeCount = () => {
		alert("nitin");
	};

	const removeToken = () => {
		localStorage.clear();
		window.location = "/fan/login";
	};

	return (
		<div>
			<header className="header-inner">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-2 navbar-header">
							<Link to={"/"}>
								<span className="">
									<img
										src={require("./../../assets/img/logo.png")}
										alt="logo"
										className="logo-text-dark"
									/>
								</span>
							</Link>

							<button
								className="navbar-toggler d-block d-lg-none"
								type="button"
								data-toggle="collapse"
								data-target="#navbar-header"
								aria-controls="navbar-header"
								aria-expanded="false"
								aria-label="Toggle navigation"
							>
								<span className="navbar-toggler-icon"></span>
							</button>
						</div>

						<div className="col-lg-5 navbar-wrapper">
							<div
								id="navigation"
								className="navbar navbar-expand-lg shrink mbl-pb-0"
							>
								<div
									className="navbar-collapse collapse topSpace"
									id="navbar-header"
								>
									<ul className="navbar-nav">
										{navItem.map((item, index) => (
											<li
												key={"nav-item" + index}
												className={
													(location.pathname === item.path ? "active" : "") +
													" nav-item"
												}
											>
												<Link to={item.path} className="nav-link">
													{item.name}
												</Link>
											</li>
										))}
									</ul>
								</div>
							</div>
						</div>

						<div className="col-lg-5 navbar-wrapper d-flex justify-content-end">
							<div
								id="navigation"
								className="navbar navbar-expand-lg shrink fullWidth"
							>
								<div className="navbar-collapse collapse" id="navbar-header">
									<ul className="navbar-nav my-account">
										<li className="nav-item">
											{/* <a className="nav-link">Support <img src="img/arrow-right.png" alt="" title="" /></a> */}
											<a className="nav-link">Support</a>
											<div
												className="dropdown-menu"
												aria-labelledby="navbarDropdown"
											>
												<Link to={"/faq"} className="dropdown-item">
													FAQ
												</Link>
												{/* <Link to={'/artist/help'} className="dropdown-item">Need Help?</Link> */}
												{/* <Link to={'/fan/support'} className="dropdown-item">Support</Link> */}
												<Link to={"/fan/contactUs"} className="dropdown-item">
													Contact US
												</Link>
												<Link to={"/fan/feedback"} className="dropdown-item">
													Feedback
												</Link>
											</div>
										</li>

										<li className="nav-item dropdown">
											<a href="#" className="nav-link">
												My Account
											</a>
											<div
												className="dropdown-menu"
												aria-labelledby="navbarDropdown"
											>
												{/* <a className="dropdown-item" href="">Manage Account</a> */}
												<Link to={"/fan/myaccount"} className="dropdown-item">
													Manage Account
												</Link>
												<Link to={"/fan/mywishlist"} className="dropdown-item">
													My Wishlist
												</Link>
												{/* <a className="dropdown-item" href="">My Wishlist</a> */}
												<Link
													to={"/fan/redeem-coupon"}
													className="dropdown-item"
												>
													Redeem Gift
												</Link>

												<a
													className="dropdown-item"
													onClick={(_) => removeToken()}
												>
													Log Out
												</a>
											</div>
										</li>
										<li className="nav-item wallet-section">
											<Link to={"/fan/mycart"}>
												{" "}
												<img src={require("../../assets/img/wallet.png")} />
											</Link>
											<div className="number-circle">{cartCount}</div>
											{/* <Link to={'/fan/mycart'} className="nav-link"><i className="cart-custom fa fa-shopping-cart" aria-hidden="true"></i></Link> */}
											{/* <a href="#" className="nav-link"><i className="cart-custom fa fa-shopping-cart" aria-hidden="true"></i></a> */}
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="social-links header-social">
					<div className="container">
						<ul className="list-inline">
							<li className="list-inline-item">
								<a href="#" alt="facebook" title="facebook">
									<div className="social-icon facebook">
										<i className="fa fa-facebook"></i>
									</div>
								</a>
							</li>
							<li className="list-inline-item">
								<a href="#" alt="Twitter" title="Twitter">
									<div className="social-icon twitter">
										<i className="fa fa-twitter"></i>
									</div>
								</a>
							</li>
							<li className="list-inline-item">
								<a href="#" alt="Instagram" title="Instagram">
									<div className="social-icon instgm">
										<i className="fa fa-instagram"></i>
									</div>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</header>
		</div>
	);
};

export default withRouter(Header);
