import React, { useEffect, useState } from "react";
import axios from "axios";
import Tooltip from "../../shared/toolTip";
import { showNotification } from "../../shared/showNotification";

var voucher_codes = require("voucher-code-generator");


const AlbumPricing = ({ albumData }) => {
	const [state, setState] = useState({
		imagePath: "",
		loader: false,
		price: "",
		password: "",
	});

	useEffect(() => {
		let temp = albumData.artwork_images[0];
		setState((oldState) => ({
			...oldState,
			imagePath: axios.defaults.baseURL + temp.path + temp.name,
			price: albumData.price ?? "",
		}));
	}, []);

	const handleInputChange = (e) => {
		setState((oldState) => ({
			...oldState,
			[e.target.name]: e.target.value,
		}));
	};

	const errorhandle = () => {
		const re = /^[1-9]\d*(\.\d+)?$/;
		if (state.price === "" || !re.test(state.price)) {
			showNotification("Price must be Numeric Value", "error");
			return false;
		}
		return true;
	};

	const generateCoupon = () => {
		setState((oldState) => ({
			...oldState,
			password: voucher_codes.generate({
				length: 8,
				prefix: albumData.dalbum_id,
				count: 5,
			})[0],
		}));
	};

	const addZeroes = () => {
		var value = Number(state.price);
		var res = String(value).split(".");
		// if(res.length == 1 || (res[1].length < 3)) {
		value = value.toFixed(2);
		// }
		setState((oldState) => ({
			...oldState,
			price: value,
		}));
		// return value
	};

	const goToPreviewPage = () => {
		window.history.back();
	}

	const submit = () => {
		setState((oldState) => ({
			...oldState,
			loader: true,
		}));
		let valid = errorhandle();
		if (!valid) {
			return false;
		}
		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};
		let albumPriceData = {
			price: state.price,
			password: state.password,
			album_id: albumData.dalbum_id,
		};
		axios
			.post("app/addalbum/updateprice", albumPriceData, config)
			.then(function (response) {
				if (response.data.success) {
					setState((oldState) => ({
						...oldState,
						loader: false,
					}));
					showNotification("Price Saved", "success");
				} else {
					showNotification("Server Error " + response.data.message, "error");
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
			});
	};

	
	return (
		<>
			<div className="row mb30">
				<div className="col-md-12">
					<h1 className="text-center text-uppercase text-green">Pricing</h1>
				</div>
			</div>

			<div className="row mb30">
				<div className="credit-step col-md-8 offset-md-4">
					<div className="row align-items-center">
						<div className="col-md-6">
							<div className="media align-items-center">
								<div
									className="imgPfofile"
									style={{
										backgroundImage: `url(` + state.imagePath + `)`,
										backgroundPosition: "center",
										backgroundSize: "100%",
										backgroundRepeat: "no-repeat",
									}}
								></div>
								{/* <img className="mr-3" src="img/album-pic.png" alt="image" height="200" width="200" /> */}
							</div>
						</div>
						<div className="col-md-6 pl-0">
							<div className="media align-items-center">
								<div className="media-body ml-10 m-text-center">
									<h5 className="mt-0 text-uppercase">{albumData.name}</h5>
									<h5 className="mt-0 text-uppercase">
										{albumData.copyright_year}
									</h5>
									<h5 className="mt-0 text-uppercase">
										{albumData.dalbum_record_lbl}
									</h5>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="row mb30">
				<div className="col-md-12">
					<div className="pricing-content mx-custome">
						<h3 className="mb15 text-center">
							Please choose a price for your Dalbum.
						</h3>
						<h2 className="mb20 text-center">Remember, You're Worth it.</h2>
						<div className="mt20 mb20 price-input-wrapper d-flex align-items-center justify-content-center">
							<span>
								<i className="fa fa-dollar"></i>
							</span>
							<input
								type="text"
								autoComplete="off"
								name="price"
								onBlur={addZeroes}
								onChange={handleInputChange}
								value={state.price ?? ""}
								className="form-control"
							/>
							<span className="text-uppercase">USD</span>
						</div>
						<h3 className="text-uppercase text-center mb20">(Minimum TBD)</h3>
						<div className="mb20">
							<div className="media align-items-center iphone">
								{/* <img
									className="mr-3"
									src={require("./../../assets/img/calculator.png")}
									alt=""
								/> */}
								<div className="media-body">
									{/* <h4 className="mt-0">
										more to sell at
										this price to recoup your investment.
										<Tooltip
											message={
												"You keep 100% of net revenue until your publishing fee is completely recouped. This calculation helps you keep track of these numbers. Credit Card fees are also taken into account. For more information on the economics"
											}
											color="#a9adac"
										/>
									</h4> */}

									{/* <h4 className="mt-0">
										more you can give
										away for free.
										<Tooltip
											message={
												"You are allowed to give away X Dalbums before making a sale, after which you will be charged $X for each additional free Dalbum given away. For every sale @ $X you are allowed to give away X More Albums for free"
											}
											color="#a9adac"
										/>
									</h4> */}
								</div>
							</div>
						</div>
						<div className="text-center mb20">
							{/* <h3 className="mb10">
								Create Password below for fans to receive Dalbum for FREE:
							</h3>
							<h4>
								Dalbum is not responsible for lost passwords so please change
								your password often. You will be notified via email every time
								someone uses this password.
							</h4> */}
						</div>
						<div className="col-md-6 offset-md-3 mb20">
							{/* <input
								name="password"
								readOnly
								value={state.password ?? ""}
								className="form-control"
								placeholder=""
							/> */}
						</div>
						{/* <div className="row form-group justify-content-center">
							<button
								className="btn-save text-uppercase mr-10"
								onClick={generateCoupon}
							>
								Generate Coupon
							</button>
							<button
								className="btn-cancel text-uppercase mr-0"
								onClick={() =>
									setState((oldState) => ({
										...oldState,
										password: "",
									}))
								}
							>
								Delete Coupon
							</button>
						</div> */}
						<div className="mb20 text-center">
							<h3>
								You can sell your PUBLISHED Dalbum directly to fans <br /> with
								a simple URL or a QR Code.
							</h3>
						</div>
					</div>
				</div>
			</div>

			<div className="row form-group justify-content-center">
				<button onClick={goToPreviewPage} className="btn-save text-uppercase mr-5">
					Back to Preview
				</button>
				<button
					type="button"
					// onClick={submit}
					className="btn-save text-uppercase mr-0 disabled"
				>
					Feature Coming Soon
				</button>
			</div>
		</>
	);
};

export default AlbumPricing;
