import Header from "../../shared/ArtistHeader/header";
import Footer from "../../shared/footer/footer";
import FeedbackComponent from "../../shared/feedback/feedbackComponent";

const Feedback = () => {
	return (
		<div>
			<Header />
			<FeedbackComponent artist />
			<Footer />
		</div>
	);
};

export default Feedback;
