import { useRef, useState } from "react";
import {
	sortableContainer,
	sortableElement,
	sortableHandle,
} from "react-sortable-hoc";
import { showNotification } from "../../../shared/showNotification";
import { errorModal } from "../../../shared/errorModal";

const DragHandle = sortableHandle(() => (
	<i className="fa fa-bars" aria-hidden="true"></i>
));

const SortableItem = sortableElement(({ onchange, elem, idx, ondelete }) => (
	<div className="track-list-edit align-items-center mb20">
		<div className="track-item-count">
			<h2 className="din-font">{idx + 1}</h2>
		</div>
		<div className="track-edit-content pd15 light-bg">
			<div className="list-flex align-items-center text-white">
				<div>
					<DragHandle />
				</div>
				<div className="track-name-edit">
					<input
						type="text"
						onChange={(e) => {
							onchange(idx, e);
						}}
						value={elem.name}
						className="form-control"
						placeholder="Enter Track Name"
					/>
				</div>
				<div className="track-play">
					<i className="fa fa-play"></i>
				</div>
			</div>
		</div>
		<a
			onClick={() => {
				ondelete(idx, 1);
			}}
		>
			<span className="close">
				<i className="fa fa-times"></i>
			</span>
		</a>
	</div>
));

const SortableContainer = sortableContainer(({ children }) => {
	return <div>{children}</div>;
});


const UploadVideo = ({
	save,
	AudioDelete,
	handleLink,
	onSortEnd,
	album_name,
	dimension_id,
	items,
	type,
	onupload,
	AudioNameChange,
}) => {
	const [state, setState] = useState({
		files: [],
		elements: [],
		loader: false,
	});

	const fileInputRef = useRef(null);
	// onSortEnd = ({ oldIndex, newIndex }) => {
	//     setState(({ items }) => ({
	//         items: arrayMove(items, oldIndex, newIndex),
	//     }));
	//     console.log(state.items)
	// };

	const uploadPreview = (e) => {
		var temp = [];
	
		if (e.target.files) {
			for (var i = 0; i < e.target.files.length; i++) {
				// Check if the file type is mp3, mp4
				if (e.target.files[i].name.endsWith('.mp4') || e.target.files[i].name.endsWith('.MP4') ) {
                    temp.push(e.target.files[i]);
                } else {
					// Check if the file type is not empty
					if (e.target.files[i].type) {
						errorModal("Invalid file. Only MP4 files are allowed")
						return
					}
				}
			}

			setState((oldstate) => ({
				...oldstate,
				files: temp,
			}))

		}
	
		var returndata = {
			hidden_dimension_id: dimension_id,
			type: type,
			files: temp.length > 0 ? temp : undefined,
			at_position: items.items ? items.items.length : 0, // Add null check here
        	link: items.items && items.items.length > 0 ? items.items[0].link : "", // Add null check here
		};

		console.log("Upload Preview Return data: ", returndata);
		
		onupload(returndata);
		fileInputRef.current.value = '';
	};

	const isValidAndAllowedLink = (link) => {
		var youtubeRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
		var vimeoRegex = /(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com\/)((channels\/[A-z]+\/)|(?:groups\/[A-z]+\/videos\/))?([0-9]+)/;
		
		console.log(youtubeRegex.test(link))

		return youtubeRegex.test(link) || vimeoRegex.test(link);
	}

	const areValidLinks = (items) => {
		for (const item of items.items) {
			if (!isValidAndAllowedLink(item.link)) {
				return false;
			}
		}
	
		return true;
	}

	const handleSave = () => {
		if (!areValidLinks(items)) {
			errorModal("Invalid video link(s). Only links from YouTube and Vimeo are allowed at the moment.");
			return;
		}
		var returndata = {
			hidden_dimension_id: dimension_id,
			type: type,
			files: state.files.length > 0 ? state.files : undefined,
			at_position: items.items ? items.items.length : 0, // Add null check here
        	link: items.items && items.items.length > 0 ? items.items[0].link : "", // Add null check here
		};
		onupload(returndata);
		console.log(" Handle save Return data: ",returndata);
	}

	const { elements } = state;

	return (
		<div>
			{/* <div className="row mb30">
                    <h2 className="text-justify mb20">Please do NOT include ANY bonus or hidden tracks here. This should be your Track List, as originally released and intended. (You can add bonus and hidden tracks later). Once the songs are uploaded, you will be able to check the track names, organize the tracks in proper order and listen to them to make sure they were uploaded properly.</h2>
                    <h2 className="text-justify">Make sure your compressed mp3 tracks correspond directly with your uncompressed  tracks.<br /> NOTE: Initially, uncompressed Tracks will not be available on devices.</h2>
                </div> */}
			{type == 4 && (
				<div className="videoUpload">
					<ul className="list-unstyled">
						<li>
							<h1>EMBED VIDEO: </h1>
						</li>
						<li>
							<input
								placeholder="http://copy and paste embedded video link here "
								onChange={handleLink}
								value={
									items.items && items.items[0] && items.items[0].link
										? items.items[0].link
										: ""
								}
								disabled={
									items.items && items.items[0]
										? items.items[0].name
											? true
											: false
										: false
								}
								type="text"
							/>
						</li>
					</ul>
					<h1 className="text-center or">OR</h1>
				</div>
			)}

			<div className="row mb30 upload-wrapper">
				<div className="col-md-8 rpadding border-right">
					<div className="card">
						<div className="card-header">
							<div className="row align-items-center">
								<div className="col-md-6">
									<div className="input-group select-wrapper">
										<input
											type="file"
											disabled={state.link && state.link.length > 0}
											onChange={uploadPreview}
											className="form-control uploadFile"
											aria-label="Text input with dropdown button"
											multiple
											accept=".mp4"
											ref={fileInputRef}
										/>
									</div>
								</div>
								       <div className="col-md-6">
                                    <div className="option-list pull-right">
                                        <ul className="list-inline mb0">
                                            <li className="list-inline-item">
                                                <a
                                                    onClick={
                                                        handleSave
                                                    }
                                                    className="text-white text-uppercase btn-agree"
                                                >
                                                    Save
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
									
									
                                </div>
							</div>
							<div className="video-text-embedded">
								<p>Videos would be uploaded and saved automatically once selected. To save video URL, please click on save.</p>
							</div>
						</div>
						<div className="card-body bg-white pd30">
							<div className="upload-wrapper-conent">
								{/* <h2 className="mb10 din-font text-uppercase ">You have <span className="text-green">{state.items.length} Files</span> that have been uploaded.</h2> */}
								{type === 4 && items.items &&
									items.items.map(
										(elem, index) =>
											elem.name && (
												<div
													key={`preview-${index}`}
													className="track-list mb10 pd15"
												>
													<div className="row mb10">
														<div className="col-md-12">
															<div className="track-name">
																<h3 className="text-uppercase">
																	{index + 1} {elem.name}
																</h3>
																<a
																	onClick={() => {
																		AudioDelete(index, 4);
																	}}
																>
																	<span className="close">
																		<i className="fa fa-times"></i>
																	</span>
																</a>
															</div>
														</div>
													</div>
												</div>
											)
									)}
								{type === 1 && (
									<SortableContainer onSortEnd={onSortEnd} useDragHandle>
										{items && items.items &&
											items.items.map((elem, index) => (
												<SortableItem
													key={`item-${index}`}
													index={index}
													idx={index}
													onchange={AudioNameChange}
													elem={elem}
													ondelete={AudioDelete}
												/>
											))}
									</SortableContainer>
								)}
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-4 lpadding">
					<div className="upload-content-detail pd20 bg-white clearfix">
						<h2 className="text-uppercase din-font mb10">
							Upload {type == 1 ? "Track" : "Video"} To:
						</h2>
						<div className="form-group">
							<input
								value={album_name}
								readOnly
								type="text"
								name=""
								className="form-control"
								placeholder="Album Title"
							/>
						</div>
						<div className="album-specification">
							<h3 className="text-uppercase">Specifications</h3>
							<h4>
								Format to Upload: <br />
								{(type === 1 ? "MP3" : "MP4") + " (preferred)"}
							</h4>
							<div className="spacer20"></div>
							<h4>
								METADATA can be included <br />
								with ISRC numbers.{" "}
							</h4>
							<div className="spacer20"></div>
							<h4>
								For more information on ISRC <br />
								numbers, visit:
							</h4>
							<a href="#" className="text-orange brekText">
								http://www.nationwidebarcode.com/purchase-barcodes/barcodes-for-music{" "}
							</a>
							{/* <button type="button" onClick={() => { upload() }} className="btn-save text-uppercase pull-right mt30">Upload and Continue</button> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UploadVideo;
