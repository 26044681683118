import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { showNotification } from "../../shared/showNotification";
import {getFrontCoverThumbnail, getFrontCoverThumbnailUrl}  from "../artworkHelper"

const WishlistComponent = () => {
	const navigate = useNavigate();

	const searchInput = useRef(null);

	const [state, setState] = useState({
		albums: [],
		tempAlbums: [],
		image: {},
		genres: [],
		genre: {},
		allSubGenres: [],
		currentSubGenres: [],
		subGenre: {},
		search: "",
	});

	const path = axios.defaults.baseURL;
	const baseURL = axios.defaults.baseURL;

	useEffect(() => {
		getGenre();
		getSubGenre();
		getcart();
	}, []);


	const getcart = () => {
		var config = {
			headers: { Authorization: "Bearer " + localStorage.getItem("tokenFan") },
		};

		axios
			.get("/app/fan/getlibrary", config)
			.then(function (response) {
				if (response.data.success) {
					showNotification("Added Successfully", "success");
					setState((oldState) => ({
						...oldState,
						albums: response.data.data.data,
						tempAlbums: response.data.data.data,
					}));
				} else {
					showNotification(response.data.message, "error");
				}
			})
			.catch(function (error) {
				showNotification(error.name, 'warning', 1000);
				//console.log(error);
			});
	};

	const getGenre = () => {
		var config = {
			headers: { Authorization: "Bearer " + localStorage.getItem("tokenFan") },
		};
		axios
			.get("/genre?page=" + 1 + "&limit=" + 1200, config)
			.then(function (response) {
				if (response.data.success) {
					// state.artists = response.data.data
					setState((oldState) => ({
						...oldState,
						genres: response.data.data.result,
					}));
				} else {
					showNotification(response.data.message, "error");
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				//console.log(error);
			});
	};

	const getSubGenre = () => {
		var config = {
			headers: { Authorization: "Bearer " + localStorage.getItem("tokenFan") },
		};
		axios
			.get("/sub_genre?page=" + 1 + "&limit=" + 1200, config)
			.then(function (response) {
				if (response.data.success) {
					// state.artists = response.data.data
					setState((oldState) => ({
						...oldState,
						allSubGenres: response.data.data.result,
					}));
				} else {
					showNotification(response.data.message, "error");
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				//console.log(error);
			});
	};
	const selectedGenre = (genre) => {
		if (genre == {}) {
			setState((oldState) => ({
				...oldState,
				currentSubGenres: [],
				subGenre: {},
			}));
		}
		setState((oldState) => ({
			...oldState,
			genre: genre,
			subGenre: {},
		}));
		sortAlbum();
	};
	const selectedSubGenre = (subGenre) => {
		if (subGenre == {}) return;

		setState((oldState) => ({
			...oldState,
			subGenre: subGenre,
		}));
		sortAlbum();
	};
	const sortAlbum = () => {
		let params = {
			genre_id: state.genre.genre_id,
			search: state.search,
			sub_genre_id: state.subGenre.sub_genre_id,
		};
		let temp = state.tempAlbums;
		if (params.genre_id) {
			if (params.sub_genre_id) {
				temp = temp.filter((elem) => {
					return (
						elem.dalbum.sub_genre_id === params.sub_genre_id &&
						elem.dalbum.name.includes(params.search)
					);
				});
			} else {
				temp = temp.filter((elem) => {
					return (
						elem.dalbum.genre_id === params.genre_id &&
						elem.dalbum.name.includes(params.search)
					);
				});
			}
		} else {
			temp = temp.filter((elem) => {
				return elem.dalbum.name.includes(params.search);
			});
		}

		setState((oldState) => ({
			...oldState,
			albums: temp,
		}));
	};
	const searchInputChange = (e) => {
		setState((oldState) => ({
			...oldState,
			search: e.target.value,
		}));
		sortAlbum();
	};

	const setAlbum = (album_id) => {
		navigate("/fan/album-preview", {
			state: { album_id: album_id },
			// state: {name : response.data.data.firstname}
		});
	};
	const setArtist = (id) => {
		navigate("/fan/artist-preview", {
			state: { artist_id: id },
		});
	};

	let total = 0;

	return (
		<section className="shopping-cart">
			<div className="container">
				<section className="general-catagory">
					<div className="container">
						<div className="row d-flex align-items-center">
							<div className="col-md-4">
								<div className="general">
									{/* <!-- <p>General catagoru</p> --> */}
									<div className="catagory-text text-orange mb-1">
										<span className="genre">
											GENRE
											<span className="all-catagory">
												{state.genre.name || "All"}
											</span>
											<span className="all-options">
												<ul className="list-inline" style={{ maxHeight: "20vw", overflowY: "auto" }}>
													<li
														onClick={() => {
															selectedGenre({});
														}}
														className="active"
													>
														All
													</li>
													{state.genres.map((genre, index) => (
														<li
															key={index}
															onClick={() => {
																selectedGenre(genre);
															}}
														>
															{" "}
															<a>{genre.name}</a>
														</li>
													))}
												</ul>
											</span>
										</span>
									</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className="sub-genral">
									<div
										className={
											(Object.keys(state.genre).length == 0
												? "custome-disable"
												: "") + " catagory-text text-orange subgenre"
										}
									>
										<span className="genre">
											SUBGENRE
											<span className="all-catagory">
												{state.subGenre.name || "All"}
											</span>
											<span className="all-options sub-op">
												<ul className="list-inline" style={{ maxHeight: "20vw", overflowY: "auto" }}>
													<li
														onClick={() => {
															selectedSubGenre({});
														}}
														className="active"
													>
														All
													</li>
													{state.allSubGenres.map(
														(genre, index) =>
															genre.genre_id === state.genre.genre_id && (
																<li
																	key={index}
																	onClick={() => {
																		selectedSubGenre(genre);
																	}}
																>
																	{" "}
																	<a>{genre.name}</a>
																</li>
															)
													)}
												</ul>
											</span>
										</span>
									</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className="search-box">
									<input
										ref={(input) => {
											searchInput.current = input;
										}}
										type="text"
										value={state.search}
										onChange={searchInputChange}
										className="form-control"
										placeholder="search"
									/>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* <!-- Tabs --> */}
				<section id="tabs" className="catagory-tabs">
					<div className="container-fluid">
						<div className="row">
							<div className="col-12">
								<div className="tab-content px-3 px-sm-0" id="nav-tabContent">
									<div
										className="tab-pane fade show active"
										id="nav-home"
										role="tabpanel"
										aria-labelledby="most-recent"
									>
										<ul className="list-inline">
											{state.albums &&
												state.albums.filter(album => album && album.dalbum).map((album, index) => (
													<li key={index}>
														<a key={index} className="most-content">
															<div
																onClick={() => {
																	setAlbum(album.dalbum_id);
																}}
															>
																<div
																	className="slick-img fan-search-img"
																	style={
																		album.dalbum.artwork_images.length > 0 &&
																		{background:
																						`${getFrontCoverThumbnailUrl(album.dalbum.artwork_images)}`,
																					backgroundSize: "cover",
																					backgroundRepeat: "no-repeat",
																		}
																	}
																></div>
															</div>
															<div className="most-inner pd">
																<div
																	onClick={() =>
																		setArtist(album.dalbum.artist.artist_id)
																	}
																>
																	<h3>{album.dalbum.artist?.name}</h3>
																</div>
																<div
																	onClick={() => {
																		setAlbum(album.dalbum_id);
																	}}
																>
																	<p>{album.dalbum.name}</p>
																</div>
															</div>
														</a>
													</li>
												))}
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</section>
	);
};

export default WishlistComponent;
