import { useEffect, useState } from "react";
import Header from "../../shared/ArtistHeader/header";
import Footer from "../../shared/footer/footer";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import { getArtworkUrls } from "../../components/artworkHelper";

const PromoteAlbum = () => {
	const location = useLocation();

	const [state, setState] = useState({
		imagePath: "",
		size: 0,
		screen: 0,
		// type:null,
		elem: {},
		// index:null
	});

	useEffect(() => {
		shout();
		//console.log(1111,axios.defaults.baseURL + props.location.state.data.artwork_images[0].path+props.location.state.data.artwork_images[0].name)
		setState({
			imagePath:
				axios.defaults.baseURL +
				location.state.data.artwork_images[0].path +
				location.state.data.artwork_images[0].name,
		});
		// if (!props.location.state || !sessionStorage.getItem('album_id')) {
		//     var pathArray = window.location.href.split('/');
		//     var protocol = pathArray[0];
		//     var host = pathArray[2];
		//     var url = protocol + '//' + host;
		//     window.location = url + '/artist/dashboard'
		// }
	}, []);

	const shout = (index) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="confirm-ui">
						<div className="delete-prompt-box cusAlert">
							<h1 className="text-uppercase mb10 text-green din-font">
								SHOUT, SHOUT! LET IT ALL OUT!
							</h1>
							<h3 className="text-uppercase mb30 text-green din-font">
								PROMOTE YOUR DALBUM & SHARE IT WITH ALL YOUR FRIENDS
							</h3>
							<div className="share-album-list">
								<div className="d-flex justify-content-center mb20">
									<a
										href="#"
										className="pd20 border-green-5 mr-2 d-block w180 height200"
									>
										<img
											className="mb20 mt30"
											src={require("../../assets/img/fb.png")}
											alt=""
											title=""
										/>
										<h3 className="text-uppercase">Share IT</h3>
									</a>
									<a
										href="#"
										className="pd20 border-green-5 mr-2 d-block w180 height200"
									>
										<img
											className="mb20 mt30"
											src={require("../../assets/img/fb.png")}
											alt=""
											title=""
										/>
										<h3 className="text-uppercase">Tweet IT</h3>
									</a>
									<a
										href="#"
										className="pd20 border-green-5 mr-2 d-block w180 height200"
									>
										<img
											className="mb20 mt30"
											src={require("../../assets/img/fb.png")}
											alt=""
											title=""
										/>
										<h3 className="text-uppercase">Promote IT</h3>
									</a>
									<a
										href="#"
										className="pd20 border-green-5 mr-2 d-block w180 height200"
									>
										<img
											className="mb20 mt30"
											src={require("../../assets/img/fb.png")}
											alt=""
											title=""
										/>
										<h3 className="text-uppercase">Email IT</h3>
									</a>
								</div>
							</div>

							<div className="d-flex mr-3 justify-content-center">
								<button
									type="button"
									onClick={onClose}
									className="btn-save text-uppercase"
								>
									Not Right Now
								</button>
							</div>
						</div>
					</div>
				);
			},
		});
	};

	return (
		<div>
			<Header />
			<section id="content" className="pt50">
				<div className="container">
					<div className="row mb20">
						<div className="col-md-12 congrats-wrapper align-items-center">
							<div className="ipad-holder">
								<div className="ipad-holder-inner">
									<div
										className="AlbumimgProfile"
										style={{
											backgroundImage: `${getArtworkUrls({
												name: location.state.data.artwork_images[0].name,
												square: true,
											})}`,
											backgroundPosition: "center",
											backgroundRepeat: "no-repeat",
										}}
									></div>
									{/* <img src="img/album-pic.png" alt="" title="" /> */}
								</div>
							</div>
						</div>
					</div>
					<div className="row form-group justify-content-center">
						<button type="button" className="btn-cancel text-uppercase">
							<Link to="/artist/dashboard">Dashboard</Link>
						</button>
					</div>
					<div className="row justify-content-center mt50">
						<ul className="list-inline">
							<li className="list-inline-item">
								<a href="#">
									<img
										src={require("../../assets/img/google-play.png")}
										alt="Google Play"
										title="Google Play"
									/>
								</a>
							</li>
							<li className="list-inline-item">
								<a href="#">
									<img
										src={require("../../assets/img/app-store.png")}
										alt="Google Play"
										title="Google Play"
									/>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
};

export default PromoteAlbum;
