import Header from "../../shared/FanHeader/header";
import Footer from "../../shared/footer/footer";
import RedeemCouponComponent from "../../components/fan/redeemCoupon";

const RedeemCoupon = () => {
	return (
		<div>
			<Header />
			<RedeemCouponComponent />
			<Footer />
		</div>
	);
};

export default RedeemCoupon;
