import { useEffect, useState } from "react";
import Header from "../../shared/ArtistHeader/header";
import Footer from "../../shared/footer/footer";
import AlbumPricingComponent from "../../components/artist/albumPricing";
import { useLocation } from "react-router";

const ManagePrice = () => {
	const location = useLocation();

	const [state, setState] = useState({
		artwork: [],
		size: 0,
		albumData: {},
	});

	useEffect(() => {
		if (!location.state) {
			var pathArray = window.location.href.split("/");
			var protocol = pathArray[0];
			var host = pathArray[2];
			var url = protocol + "//" + host;
			window.location = url + "/artist/dashboard";
		}
		setState((oldState) => ({
			...oldState,
			size: location.state.data.total_size,
		}));
	}, []);

	return (
		<div>
			<Header />
			<section id="content" className="pt50">
				<div className="container">
					<AlbumPricingComponent albumData={location.state.data} />
				</div>
			</section>
			<Footer />
		</div>
	);
};

export default ManagePrice;
