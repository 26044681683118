import { NavLink } from "react-router-dom";
import Header from "../../shared/header/header";
import Footer from "../../shared/footer/footer";

const Team = () => {
	return (
		<div>
			<Header />
			<section id="content" className="happy-bg">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="how-to-sell-content text-center">
								<h1 className="text-white text-uppercase din-font mb30">
									SHINY HAPPY PEOPLE
								</h1>
							</div>
						</div>
					</div>
					<div className="row mb30">
						<div className="col-md-4 no-padding mbl-padding-ppl">
							<div id="f1_container">
								<div id="f1_card" className="shadow">
									<div className="bio-outer2 front face">
										<img src={require("../../assets/img/hp1.png")} />
									</div>
									<div className="back face center bg-green">
										<NavLink to="/teammember">
											<h1 className="text-white din-font">
												"And in the end,
												<br />
												the love you take <br /> is equal to the love <br /> you
												make."
											</h1>
										</NavLink>
									</div>
								</div>
							</div>
							<div className="caption-below">
								<h3 className="din-font text-white text-left pointer">
									MANOLO CELI
								</h3>
								<h4 className="din-font text-white text-left">
									Co-Founder / President
								</h4>
							</div>
						</div>
						<div className="col-md-4 no-padding mbl-padding-ppl">
							<div id="f1_container">
								<div id="f1_card" className="shadow">
									<div className="bio-outer2 front face">
										<img src={require("../../assets/img/hp2.png")} />
									</div>
									<div className="back face center bg-orange">
										<NavLink to="/teammember1">
											{" "}
											<h1 className="text-white din-font">
												"But through it all, when there was doubt, i ate it up
												and split <br /> it out. I faced it all
												<br />
												and i stood tal;
												<br /> And did it my way."
											</h1>
										</NavLink>
									</div>
								</div>
							</div>
							<div className="caption-below">
								<h3 className="din-font text-white text-left pointer">
									IVETTE CELI
								</h3>
								<h4 className="din-font text-white text-left">
									Co-Founder / Creative
								</h4>
							</div>
						</div>
						<div className="col-md-4 no-padding mbl-padding-ppl">
							<div id="f1_container">
								<div id="f1_card" className="shadow">
									<div className="bio-outer2 front face">
										<img src={require("../../assets/img/hp3.png")} />
									</div>
									<div className="back face center dark-bg">
										<NavLink to="/teammember2">
											<h1 className="text-white din-font">
												"This is what you get
												<br /> This is what you get
												<br /> This is what you get
												<br />
												when you mess with us."
											</h1>
										</NavLink>
									</div>
								</div>
							</div>
							<div className="caption-below">
								<h3 className="din-font text-white text-left pointer">
									JAMES REID
								</h3>
								<h4 className="din-font text-white text-left">
									Co-Founder / Back End Development
								</h4>
							</div>
						</div>
					</div>
					<div className="row mb30">
						<div className="col-md-4 no-padding mbl-padding-ppl">
							<div id="f1_container">
								<div id="f1_card" className="shadow">
									<div className="bio-outer2 front face">
										<img src={require("../../assets/img/hp4.png")} />
									</div>
									<div className="back face center bg-dark-gray">
										<NavLink to="/teammember3">
											<h1 className="text-white din-font">
												"I have seen the writing on the wall.
												<br />
												Don't think I need anything at all.
												<br />
												No!Don't think i'll need
												<br />
												anything at all."
											</h1>
										</NavLink>
									</div>
								</div>
							</div>
							<div className="caption-below">
								<h3 className="din-font text-white text-left pointer">
									MANOLO CELI
								</h3>
								<h4 className="din-font text-white text-left">
									Co-Founder / President
								</h4>
							</div>
						</div>
						<div className="col-md-4 no-padding mbl-padding-ppl">
							<div id="f1_container">
								<div id="f1_card" className="shadow">
									<div className="bio-outer2 front face">
										<img src={require("../../assets/img/hp5.png")} />
									</div>
									<div className="back face center dark-bg">
										<NavLink to="/teammember4">
											<h1 className="text-white din-font">
												"I have seen the writing on the wall.
												<br />
												Don't think I need anything at all.
												<br />
												No!Don't think i'll need
												<br />
												anything at all."
											</h1>
										</NavLink>
									</div>
								</div>
							</div>
							<div className="caption-below">
								<h3 className="din-font text-white text-left pointer">
									IVETTE CELI
								</h3>
								<h4 className="din-font text-white text-left">
									Co-Founder / Creative
								</h4>
							</div>
						</div>
						<div className="col-md-4 no-padding mbl-padding-ppl">
							<div id="f1_container">
								<div id="f1_card" className="shadow">
									<div className="bio-outer2 front face">
										<img src={require("../../assets/img/hp6.png")} />
									</div>
									<div className="back face center bg-orange">
										<NavLink to="/teammember5">
											{" "}
											<h1 className="text-white din-font">
												"I wanna
												<br />
												dance
												<br />
												with my baby
												<br />
												And when
												<br />
												the music starts
												<br />I never wanna stop."
											</h1>
										</NavLink>
									</div>
								</div>
							</div>
							<div className="caption-below">
								<h3 className="din-font text-white text-left pointer">
									JAMES REID
								</h3>
								<h4 className="din-font text-white text-left">
									Co-Founder / Back End Development
								</h4>
							</div>
						</div>
					</div>
					<div className="row mb30">
						<div className="col-md-4 no-padding mbl-padding-ppl">
							<div id="f1_container">
								<div id="f1_card" className="shadow">
									<div className="bio-outer2 front face">
										<img src={require("../../assets/img/hp7.png")} />
									</div>
									<div className="back face center bg-orange">
										<NavLink to="/teammember6">
											{" "}
											<h1 className="text-white din-font">
												"I wanna
												<br />
												dance
												<br />
												with my baby
												<br />
												And when
												<br />
												the music starts
												<br />I never wanna stop."
											</h1>
										</NavLink>
									</div>
								</div>
							</div>
							<div className="caption-below">
								<h3 className="din-font text-white text-left pointer">
									MANOLO CELI
								</h3>
								<h4 className="din-font text-white text-left">
									Co-Founder / President
								</h4>
							</div>
						</div>
						<div className="col-md-4 no-padding mbl-padding-ppl">
							<div id="f1_container">
								<div id="f1_card" className="shadow">
									<div className="bio-outer2 front face">
										<img src={require("../../assets/img/hp8.png")} />
									</div>
									<div className="back face center bg-dark-gray">
										<NavLink to="/teammember7">
											{" "}
											<h1 className="text-white din-font">
												"I wanna
												<br />
												dance
												<br />
												with my baby
												<br />
												And when
												<br />
												the music starts
												<br />I never wanna stop."
											</h1>
										</NavLink>
									</div>
								</div>
							</div>
							<div className="caption-below">
								<h3 className="din-font text-white text-left pointer">
									IVETTE CELI
								</h3>
								<h4 className="din-font text-white text-left">
									Co-Founder / Creative
								</h4>
							</div>
						</div>
						<div className="col-md-4 no-padding mbl-padding-ppl">
							<div id="f1_container">
								<div id="f1_card" className="shadow">
									<div className="bio-outer2 front face">
										<img src={require("../../assets/img/hp9.png")} />
									</div>
									<div className="back face center bg-green">
										<NavLink to="/teammember8">
											{" "}
											<h1 className="text-white din-font">
												"I wanna
												<br />
												dance
												<br />
												with my baby
												<br />
												And when
												<br />
												the music starts
												<br />I never wanna stop."
											</h1>
										</NavLink>
									</div>
								</div>
							</div>
							<div className="caption-below">
								<h3 className="din-font text-white text-left pointer">
									JAMES REID
								</h3>
								<h4 className="din-font text-white text-left">
									Co-Founder / Back End Development
								</h4>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="bg-orange mbl-padding-ppl">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-md-12 m-auto about-fan-content">
							<p className="text-white mb20">
								The <b>DalbumX team</b> is a group of guys and girls with an
								INTENSE PASSION for MUSIC and MUSICIANS. We formed practically
								"out of thin air", meaning: without VC funding, previous
								bestselling apps, or ties to big business or the music industry.
								Simply, we all remember the AMAZING feeling of curling up on our
								beds, sofas, or floors with a brand new Vinyl or CD as we
								enjoyed the music of our favorite Artists - studying the
								Artwork, reading the liner notes and lyrics. Really experiencing
								an Album FULLY! This was the <b>“Album Experience”!</b> For each
								of us, a new Album was an EVENT, and we realized that this
								experience, with rare exceptions, has practically been lost. We
								also realized that there were a couple of generations who had
								never even experienced it! So, we banded together to recreate
								the “Album Experience”, <b>AMPLIFIED</b> with new technology.
								Our aim was to produce the{" "}
								<b>
									“most profound connection between Artist and Fan ever
									devised.”
								</b>{" "}
								What if every Album could be presented as if it were a ‘Box Set’
								- with even more capabilities?
							</p>
							<h3 className="text-white mb20">
								<b>We are so proud to say that DalbumX is the result.</b>
							</h3>
							<p className="text-white mb20">
								To acheive the Dalbum Xperience, the entire team worked in their
								spare time, around our day jobs, in our own living rooms and
								neighborhood cafes, in two different U.S. states and two
								countries, while no one got paid. <b>Why?</b>
							</p>

							<p className="text-white mb20">
								To create the ULTIMATE way an ARTISTS and LABELS can present
								their work, and the most PROFOUND way that a FAN can enjoy it.
								We also wanted to create a distribution model where Artists and
								Labels RETAIN CONTROL and make money with their work again. Yes,
								we realize we have entered an industry ruled by deeply
								entrenched international behemoth corporations. But, we are
								committed to providing both Artists, Labels and Fans with the
								ABSOLUTELY MOST SATISFYING (and PROFITABLE) Xperience possible -
								while maintaining total transparency throughout.{" "}
								<b>
									DalbumX is a "win win win" situation for EVERYONE - for the
									Artists, the Fans and the Labels (and for us, too, we hope!).
								</b>{" "}
							</p>
							<h3 className="text-white mb20">
								<b>We sincerely hope you LOVE it.</b>
							</h3>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
};

export default Team;
