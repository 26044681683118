import Header from "../../shared/ArtistHeader/header";
import Footer from "../../shared/footer/footer";
import EditArtistDetailsComponent from "../../components/artist/manageProfileComponent";

const EditArtistDetails = () => {
	return (
		<div>
			<Header />
			<EditArtistDetailsComponent location={this.props.location} />
			<Footer />
		</div>
	);
};

export default EditArtistDetails;
