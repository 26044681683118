import Header from "../../shared/header/header";
import Footer from "../../shared/footer/footer";
import LoginComponent from "../../components/artist/artistLoginComponent";

const FanLogin = () => {
	return (
		<div>
			<Header />
			<LoginComponent />
			<Footer />
		</div>
	);
};

export default FanLogin;
