import axios from "axios";
import Loader from "../../shared/loader/loader";
import {
	sortableContainer,
	sortableElement,
	sortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable as arrayMove } from "array-move";
import { useEffect, useState } from "react";
import { showNotification } from "../../shared/showNotification";

const DragHandle = sortableHandle(() => (
	<i className="fa fa-bars" aria-hidden="true"></i>
));

const SortableItem = sortableElement(
	({
		handleInputChange,
		element,
		idx,
		deleteExtraPlease,
		deleteOOps,
		albumData,
		uploadContent,
		openBoxFor,
	}) => (
		<div className="edit-dalbum-list mb10">
			<div className="delete-option">
				{/* delete */}
				{deleteOOps[idx] && (
					<OopsMenu
						hh={() => {
							deleteExtraPlease(idx, element.dextra_label_id);
						}}
						close={() => {
							openBoxFor(idx);
						}}
					/>
				)}
				<a
					onClick={() => {
						openBoxFor(idx);
					}}
				>
					{" "}
					<i className="fa fa-times"></i>
				</a>
			</div>
			<div className="bg-black border-white">
				<div className="dalbum-extra-inner-list">
					<div className="option pd15">
						<DragHandle />
					</div>
					<div className="option">
						<div className="form-group mb0">
							<input
								type="text"
								value={element.label}
								onChange={(e) => {
									handleInputChange(e, idx, "label", element.dextra_label_id);
								}}
								className="form-control"
								name="labelName"
								id=""
								placeholder="Dextra Label"
							/>
						</div>
					</div>
					{!albumData[idx].type && (
						<div className="option pd10 bg-green last-option">
							<div className="add text-center">
								<a className="text-white">
									<i className="fa fa-plus d-block"></i>Add
								</a>
								<div className="list-hover-dropdown bg-green text-left">
									<ul className="list-unstyled mb0">
										<li>
											<a
												onClick={(e) => {
													handleInputChange(e, idx, 2, element.dextra_label_id);
												}}
												className="d-block pd10 text-white text-uppercase"
											>
												One Image
											</a>
										</li>
										<li>
											<a
												onClick={(e) => {
													handleInputChange(e, idx, 3, element.dextra_label_id);
												}}
												className="d-block pd10 text-white text-uppercase"
											>
												Image Gallery
											</a>
										</li>
										<li>
											<a
												onClick={(e) => {
													handleInputChange(e, idx, 1, element.dextra_label_id);
												}}
												className="d-block pd10 text-white text-uppercase"
											>
												Audio
											</a>
										</li>
										<li>
											<a
												onClick={(e) => {
													handleInputChange(e, idx, 4, element.dextra_label_id);
												}}
												className="d-block pd10 text-white text-uppercase"
											>
												Video
											</a>
										</li>
										<li>
											<a
												onClick={(e) => {
													handleInputChange(e, idx, 5, element.dextra_label_id);
												}}
												className="d-block pd10 text-white text-uppercase"
											>
												Enter Text
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					)}
					{albumData[idx].type && (
						<div className="option pd10 bg-orange last-option">
							<div className="add text-center">
								<a className="text-white">
									<img
										src={require("../../assets/img/extras-arrow-small.png")}
									/>
								</a>
								<div className="list-hover-dropdown bg-orange text-left">
									<ul className="list-unstyled mb0">
										<li>
											<a
												className="d-block pd10 text-white text-uppercase"
												onClick={() => uploadContent(albumData[idx])}
											>
												Manage
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	)
);

const SortableContainer = sortableContainer(({ children }) => {
	return <div>{children}</div>;
});

const OopsMenu = ({ close, hh }) => {
	return (
		<div className="delete-dextra-popup bg-white pd10 text-center">
			<h2 className="text-uppercase mb10">
				Are You Sure You Would
				<br /> Like to Delete This?
			</h2>
			<div className="d-flex justify-content-center">
				<button
					type="button"
					onClick={close}
					className="btn-save text-uppercase"
				>
					oops, No
				</button>
				<button
					type="button"
					onClick={hh}
					className="btn-cancel text-uppercase"
				>
					Delete
				</button>
			</div>
		</div>
	);
};

const DextraComponent = ({ artistDetails, uploadContent, _delete }) => {
	const [state, setState] = useState({
		albumData: [],
		items: [],
		deleteOOps: [],
		size: 0,
	});

	useEffect(() => {
				// setDataToState('albumData', artistDetails)
		if (artistDetails.dalbum_id) {
			getLabels();
		}
	}, [artistDetails.dalbum_id]);

	const openBoxFor = (idx) => {
		var oop = state.deleteOOps;
		oop[idx] = !oop[idx]
		setDataToState("deleteOOps", oop);
	};
	const saveLabels = () => {
				setState((oldState) => ({
			...oldState,
			loader: true,
		}));
		//console.log(props)
		let body = {
			dalbumid: artistDetails.dalbum_id,
			labels: state.albumData,
		};
		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};
		axios
			.post("/app/dextra/saveLabel", body, config)
			.then(function (response) {
				if (response.data.success) {
					setState((oldState) => ({
						...oldState,
						loader: false,
					}));
					getLabels();
					// setState((oldState) => ({
					// ...oldState, albumData: response.data.data.labels })
					// notify.show("Dextra Added, Please add abel of Dextra ", 'success', 1800);
				} else {
					showNotification(response.data.message, "error");
					setState((oldState) => ({
						...oldState,
						loader: false,
					}));
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				setState((oldState) => ({
					...oldState,
					loader: false,
				}));
			});
	};

	const sortintoCatagory = (data) => {
		let sorted = data.sort(function (a, b) {
			return a.at_position - b.at_position;
		});

		let oopsData = data.map(() => false);

		setState((oldState) => ({
			...oldState,
			loader: false,
			albumData: sorted,
			deleteOOps: oopsData,
		}));
		if (data.length == 0) {
			addMoreExtra();
		}
	};

	const getLabels = () => {
				setState((oldState) => ({
			...oldState,
			loader: true,
		}));
		let body = { dalbum_id: artistDetails.dalbum_id };
		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};
		axios
			.post("/app/dextra/getLabel", body, config)
			.then(function (response) {
				if (response.data.success) {
					sortintoCatagory(response.data.data.labels);
					// setState((oldState) => ({
					// ...oldState,
					//     loader: false,
					//     albumData: response.data.data.labels
					// }, () => { if (response.data.data.labels.length == 0) { addMoreExtra() } })
				} else {
					setState((oldState) => ({
						...oldState,
						loader: false,
					}));
					// notify.show(response.data.message, 'error', 1200);
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				setState((oldState) => ({
					...oldState,
					loader: false,
				}));
			});
	};

	const addMoreExtra = () => {
		var album = state.albumData;
		album.push({ label: "", type: null });
		setDataToState("albumData", album);
		saveLabels();
	};

	const onSortEnd = ({ oldIndex, newIndex }) => {
		setState((oldState) => ({
			...oldState,
			albumData: arrayMove(oldState.albumData, oldIndex, newIndex),
		}));
		//console.log(state.albumData)
	};

	const setDataToState = (ToState, fromVariable) => {
		setState((oldState) => ({
			...oldState,
			[ToState]: fromVariable,
		}));
	};

	const handleInputChange = (event, idx, type, labelId) => {
		var labelValue = event.target.value;
		var data = state.albumData;
		if (type === "label") data[idx].label = labelValue;
		else {
			data[idx].type = type;
			setState((oldState) => ({
				...oldState,
				albumData: data,
			}));
			uploadContent(state.albumData[idx]);
		}
		setDataToState("albumData", data);
	};
	const deleteExtraPlease = (idx, dextra_id) => {
				openBoxFor(idx);
		var album = state.albumData;
		// var arr = album.
		if (!dextra_id) album.splice(idx, 1);
		else {
			setState((oldState) => ({
				...oldState,
				loader: true,
			}));
			var config = {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
				},
			};
			axios
				.post("/app/dextra/deleteLabel", { dextra_label_id: dextra_id }, config)
				.then(function (response) {
					if (response.data.success) {
						setState((oldState) => ({
							...oldState,
							loader: false,
						}));
						getLabels();
					} else {
						showNotification(response.data.message, "error");
						setState((oldState) => ({
							...oldState,
							loader: false,
						}));
					}
				})
				.catch(function (error) {
					showNotification(error.name, "warning");
					setState((oldState) => ({
						...oldState,
						loader: false,
					}));
				});
		}
		setDataToState("albumData", album);
	};

	return (
		<section>
			{state.loader && <Loader />}

			<div className="container">
				<div className="row">
					<div className="col-md-12 save-dextra">
						<button
							type="button"
							className="btn-cancel text-white bg-orange text-uppercase pull-right"
							onClick={saveLabels}
						>
							Save
						</button>
					</div>
				</div>
				<div className="row">
					<div className="col-md-7 offset-md-2">
						<SortableContainer onSortEnd={onSortEnd} useDragHandle>
							{state.albumData &&
								state.albumData.map(
									(element, idx) => (
										<SortableItem
											key={`item-${idx}`}
											index={idx}
											idx={idx}
											handleInputChange={handleInputChange}
											deleteExtraPlease={deleteExtraPlease}
											element={element}
											ondelete={_delete}
											openBoxFor={openBoxFor}
											deleteOOps={state.deleteOOps}
											albumData={state.albumData}
											uploadContent={uploadContent}
										/>
									)
									// <div className="edit-dalbum-list mb10">
									//     <div className="delete-option">
									//         {/* delete */}
									//         {state.deleteOOps[idx] &&
									//             <OopsMenu hh={() => { deleteExtraPlease(idx, element.dextra_label_id) }} close={() => { openBoxFor(idx) }} />}
									//         <a onClick={() => { openBoxFor(idx) }} > <i className="fa fa-times"></i></a>
									//     </div>
									//     <div className="bg-black border-white">
									//         <div className="dalbum-extra-inner-list">
									//             <div className="option pd15">
									//                 {/* <a href=""><i className="fa fa-bars"></i></a> */}
									//             </div>
									//             <div className="option">
									//                 <div className="form-group mb0">
									//                     <input type="text"
									//                         value={element.label}
									//                         onChange={(e) => { handleInputChange(e, idx, 'label', element.dextra_label_id) }}
									//                         className="form-control"
									//                         name="labelName"
									//                         id=""
									//                         placeholder="Dextra Label" />
									//                 </div>
									//             </div>
									//             {!state.albumData[idx].type &&
									//                 <div className="option pd10 bg-green last-option">
									//                     <div className="add text-center">
									//                         <a className="text-white">
									//                             <i className="fa fa-plus d-block"></i>Add
									//         </a>
									//                         <div className="list-hover-dropdown bg-green text-left">
									//                             <ul className="list-unstyled mb0">
									//                                 <li>
									//                                     <a onClick={(e) => { handleInputChange(e, idx, 2, element.dextra_label_id) }} className="d-block pd10 text-white text-uppercase">One Image</a>
									//                                 </li>
									//                                 <li>
									//                                     <a onClick={(e) => { handleInputChange(e, idx, 3, element.dextra_label_id) }} className="d-block pd10 text-white text-uppercase">Image Gallery</a>
									//                                 </li>
									//                                 <li>
									//                                     <a onClick={(e) => { handleInputChange(e, idx, 1, element.dextra_label_id) }} className="d-block pd10 text-white text-uppercase">Audio</a>
									//                                 </li>
									//                                 <li>
									//                                     <a onClick={(e) => { handleInputChange(e, idx, 4, element.dextra_label_id) }} className="d-block pd10 text-white text-uppercase">Video</a>
									//                                 </li>
									//                                 <li>
									//                                     <a onClick={(e) => { handleInputChange(e, idx, 5, element.dextra_label_id) }} className="d-block pd10 text-white text-uppercase">Enter Text</a>
									//                                 </li>
									//                             </ul>
									//                         </div>
									//                     </div>
									//                 </div>}
									//             {state.albumData[idx].type &&
									//                 <div className="option pd10 bg-orange last-option">
									//                     <div className="add text-center">
									//                         <a className="text-white">
									//                             <img src={require('../../assets/img/extras-arrow-small.png')} />
									//                         </a>
									//                         <div className="list-hover-dropdown bg-orange text-left">
									//                             <ul className="list-unstyled mb0">
									//                                 <li>
									//                                     <a className="d-block pd10 text-white text-uppercase" onClick={() =>
									//                                         uploadContent(state.albumData[idx])
									//                                     }>Manage</a>
									//                                 </li>
									//                             </ul>
									//                         </div>
									//                     </div>
									//                 </div>}
									//         </div>
									//     </div>
									// </div>
								)}
						</SortableContainer>

						<div className="edit-dalbum-list mb10 flex-row-reverse">
							<div className="add-extra">
								<a
									onClick={addMoreExtra}
									className="text-uppercase cusdextraBut"
								>
									<i className="fa fa-plus"></i> Add DExtras
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default DextraComponent;
