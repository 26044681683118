import Header from "../../shared/FanHeader/header";
import Footer from "../../shared/footer/footer";
import CartComponent from "../../components/fan/cart";

const Cart = () => {
	return (
		<div>
			<Header />
			<CartComponent />
			<Footer />
		</div>
	);
};

export default Cart;
