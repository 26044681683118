const About = () => {
	return (
		<div>
			<section id="content" className="about-bg">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-xl-12">
							<div className="Banner-content pt40">
								<h3 className="text-white mb10">
									With a Dalbum, an Artist can present their music in the most
									fully expressed manner possible.
								</h3>
								<h1 className="text-white din-font text-uppercase mt-0 mb10 din-font top">
									{" "}
									The Album Experience...
								</h1>
								<h3 className="text-white text-justify mb10">
									A Dalbum includes the Tracks and Full Artwork as originally
									intended. The audio is presented in compressed and
									uncompressed formats (for mobile devices and home listening
									respectively). Artists can embed actual hidden materials (like
									hidden tracks) within their artwork. And, they can add
									“Dextras” (Bonus material like videos, photos, bonus tracks
									and so much more). Plus, Artists and Labels finally have
									control of their work. They can charge what they want (within
									limits) and keep most of the revenue of what they worked so
									hard to produce.{" "}
								</h3>
								<h1 className="text-white din-font text-uppercase mt-0 mb10 ampl-font">
									Amplified!
								</h1>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="bg-orange">
				<div className="container-fluid">
					<div className="row align-items-center">
						<div className="col-md-6 lpadding rpadding aboutbg1">
							<div className="content-left-img"></div>
						</div>
						<div className="col-md-12 col-xl-6 ">
							<div className="about-fan-content d-pd30-m-pd0 mtb-p30">
								<h2 className="text-white mb20">
									And, FANS are allowed access to the Artist’s work, like never
									before. They can zoom into the artwork, turn the pages, find
									embedded material like hidden tracks, if the Artist has
									provided it, as well as look at all the Dextras that the
									DALBUM and the individual tracks bring.{" "}
								</h2>
								<h2 className="text-white mb20">
									It doesn’t matter what device you are on, or if you switch
									devices. DALBUMS play across all device platforms on the
									DALBUM Player. Finally, the Album has once again become an
									EVENT – as it should be.
								</h2>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="bg-dark-gray about-gray d-pd30-m-pd0 mtb-p30 m-p-lr0">
				<div className="container">
					<div className="row align-items-end">
						<div className="col-md-12 col-lg-7">
							<h3 className="text-white text-justify">
								OH, and Artists and Labels have TOTAL control over how much they
								sell their Dalbum. Did we also mention that they keep 90% of all
								net revenue? Artists DESERVE to be able to live off of their
								work. And Fans DESERVE to experience as much of the Artist’s
								vision as possible.
							</h3>
							<h1 className="text-white text-uppercase din-font mt5 mb0">
								Artists win, Labels win, AND Fans win!
							</h1>
						</div>
						<div className="col-md-5">
							<div className="get-our-app text-right">
								<div className="get-app-list">
									<ul className="list-inline mb0 display-flex">
										<li className="list-inline-item">
											<a href="#">
												<img
													src={require("./../../assets/img/app-store-large.png")}
													alt=""
													title=""
												/>
											</a>
										</li>
										<li className="list-inline-item">
											<a href="#">
												<img
													src={require("./../../assets/img/google-play-large.png")}
													alt=""
													title=""
												/>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="about-last">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="about-last-content text-center d-mt90 pt20">
								<h1 className="text-white text-uppercase din-font">
									Welcome to the most profound connection
									<br />
									between artist and fan ever devised. ENJOY.
								</h1>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default About;
