import Header from "../../shared/ArtistHeader/header";
import Footer from "../../shared/footer/footer";
import EditArtist from "../../components/artist/editArtist/editArtist";

const EditArtistView = () => {
	return (
		<div>
			<Header />
			<section id="content" className="pt50">
				<div>
					<EditArtist />
				</div>
			</section>
			<Footer />
		</div>
	);
};

export default EditArtistView;
