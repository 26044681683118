import { Link, useLocation, useNavigate } from "react-router-dom";
import { withRouter } from "./../../shared/withRouter";

const Header = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const navItem = [
		{ name: "dashboard", path: "/artist/dashboard" },
		{ name: "about", path: "/artist/about" },
		{ name: "sell", path: "/artist/how-to-sell" },
		// { name: "dmic", path: "/artist/dmic" },
	];

	const removeToken = () => {
		localStorage.clear();
		navigate("/artist/login");
	};

	return (
		<div>
			<header className="header-inner">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-2 navbar-header">
							<Link to={"/"}>
								<span className="">
									<img
										src={require("./../../assets/img/logo.png")}
										alt="logo"
										className="logo-text-dark"
									/>
								</span>
							</Link>

							<button
								className="navbar-toggler d-block d-lg-none"
								type="button"
								data-toggle="collapse"
								data-target="#navbar-header"
								aria-controls="navbar-header"
								aria-expanded="false"
								aria-label="Toggle navigation"
							>
								<span className="navbar-toggler-icon"></span>
							</button>
						</div>

						<div className="col-lg-5 navbar-wrapper">
							<div
								id="navigation"
								className="navbar navbar-expand-lg shrink mbl-pb-0"
							>
								<div
									className="navbar-collapse collapse topSpace"
									id="navbar-header"
								>
									<ul className="navbar-nav ">
										{navItem.map((item, index) => (
											<li
												key={"nav-item" + index}
												className={
													(location.pathname === item.path ? "active" : "") +
													" nav-item"
												}
											>
												<Link to={item.path} className="nav-link">
													{item.name}
												</Link>
											</li>
										))}
									</ul>
								</div>
							</div>
						</div>

						<div className="col-lg-5 navbar-wrapper d-flex justify-content-end">
							<div
								id="navigation"
								className="navbar navbar-expand-lg shrink fullWidth"
							>
								<div className="navbar-collapse collapse" id="navbar-header">
									<ul className="navbar-nav my-account">
										<li className="nav-item">
											{/* <a className="nav-link">Support <img src="img/arrow-right.png" alt="" title="" /></a> */}
											<a className="nav-link">Support</a>
											<div
												className="dropdown-menu"
												aria-labelledby="navbarDropdown"
											>
												<Link to={"/faq"} className="dropdown-item">
													FAQ
												</Link>
												{/*                                                    <Link to={'/artist/help'} className="dropdown-item">Tutorials</Link>   */}
												<Link
													to={"/artist/contactUs"}
													className="dropdown-item"
												>
													NEED ASSISTANCE?
												</Link>
												<Link to={"/artist/feedback"} className="dropdown-item">
													Feedback
												</Link>
											</div>
										</li>
										<li className="nav-item dropdown">
											<a href="#" className="nav-link">
												My Account
											</a>
											<div
												className="dropdown-menu"
												aria-labelledby="navbarDropdown"
											>
												<Link to={"/artist/me"} className="dropdown-item">
													{" "}
													Manage Profile
												</Link>
												<a
													className="dropdown-item"
													onClick={(_) => removeToken()}
												>
													Log Out
												</a>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>
		</div>
	);
};

export default withRouter(Header);
