import { useEffect, useState } from "react";
import axios from "axios";
import QRCode from "qrcode.react";

const QrCode = ({ albumData, change }) => {
	const [state, setState] = useState({
		artwork: [],
		size: 0,
		imagePath: "",
		name: "",
		dalbumUrl: "",
	});

	useEffect(() => {
		//console.log(albumData.album)

		let temp = albumData.album.artwork_images.some((elem) => {
			return elem.at_position === -1;
		})
			? albumData.album.artwork_images.find((elem) => {
					return elem.at_position === -1;
			  })
			: {};
		setState({
			imagePath: axios.defaults.baseURL + temp.path + temp.name,
			dalbumUrl: albumData.album.dalbum_url ? albumData.album.dalbum_url : "",
			dalbumUrl:
				window.location.origin +
				"/fan/album-preview/" +
				albumData.album.dalbum_id,
		});
	}, []);

	const copyToClip = () => {
		var temp = document.getElementById("toCopy");
		temp.select();
		document.execCommand("copy");
	};

	const print = () => {
		var content = document.getElementById("qrcontents");
		var pri = document.getElementById("ifmcontentstoprint").contentWindow;
		pri.document.open();
		content.style.width = "800px";
		content.style.height = "800px";
		pri.document.write(content.outerHTML);
		pri.document.close();
		pri.focus();
		//console.log((pri))
		pri.print();
	};

	// const getref = () => {
	// 	return refs["7768"];
	// };

	return (
		<div className="qr-cpde-content">
			<div className="container">
				<div className="row mb30">
					<div className="col-md-12">
						<div className="privacy-policy-heading text-center">
							<h1 className="text-green din-font text-uppercase">
								QR CODE AND URL
							</h1>
							<h3>
								You can use this URL and/or QR Code to direct Fans to your
								DalbumX Page from where they can buy your Dalbum. Paste in email
								lists or on social media or print out the QR Code and display it
								during live shows or add it to your posters or flyers! Also,
								while it should be correct, please double check that the URL and
								the QR Code correctly correspond to your Dalbum. If you have any
								issues, please contact Tech Support.
							</h3>
						</div>
					</div>
				</div>
				<div className="row mb30">
					<div className="col-md-5 col-lg-3 offset-md-1 text-right mb30">
						<div
							className="imgPfofile qrcode-profile"
							style={{
								backgroundImage: `url(` + state.imagePath + `)`,
								backgroundPosition: "center",
								backgroundSize: "100%",
								backgroundRepeat: "no-repeat",
							}}
						></div>
					</div>
					<div className="col-md-6 col-lg-6 mb30">
						<h2 className="text-uppercase text-green text-left mb20 din-font mt50">
							Copy and Paste or share THIS URL using buttons below
						</h2>
						<div>
							<input
								id="toCopy"
								className="form-control mb20"
								readOnly
								value={state.dalbumUrl}
								placeholder="http://www.dalbumx.com/"
							/>
						</div>
						<div className="links-section">
							<button
								onClick={copyToClip}
								className="btn btn-gradient btn-sm w200 m-auto float-left"
							>
								Copy URL
							</button>
							<div className="social-links float-right">
								<ul className="list-inline">
									<li className="list-inline-item text-uppercase">
										<a href="#">SHARE ON</a>
									</li>
									<li className="list-inline-item">
										<a
											href={
												"https://www.facebook.com/sharer/sharer.php?u=" +
												state.dalbumUrl
											}
											target="_blank"
											alt="facebook"
											title="facebook"
										>
											<div className="social-icon social-icon2">
												<i className="fa fa-facebook" url={"jjjj.com"}></i>
											</div>
										</a>
									</li>
									<li className="list-inline-item">
										<a
											href={"https://twitter.com/share?url=" + state.dalbumUrl}
											target="_blank"
											alt="Twitter"
											title="Twitter"
										>
											<div className="social-icon social-icon2">
												<i className="fa fa-twitter"></i>
											</div>
										</a>
									</li>
									<li className="list-inline-item">
										<a href="#" alt="Instagram" title="Instagram">
											<div className="social-icon social-icon2">
												<i className="fa fa-instagram"></i>
											</div>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>

					{/* <iframe
						id="ifmcontentstoprint"
						style={{ height: "0px", width: "0px", margin: "0 auto" }}
					></iframe> */}
					<div className="col-md-4 mg-auto text-center">
						<div style={{ textAlign: "center" }}>
							<QRCode
								id="qrcontents"
								renderAs="svg"
								includeMargin={true}
								value={state.dalbumUrl}
							/>
						</div>
						<button
							type="submit"
							onClick={print}
							className="text-uppercase btn btn-gradient btn-sm w150 mt30 mb60"
						>
							Print...
						</button>
						<button
							onClick={() => {
								change(0);
							}}
							type="button"
							className="btn-cancel w150 text-uppercase text-uppercase mg-auto"
						>
							BACK
						</button>
					</div>
				</div>
				<div className="row mb50 mt50 barcode-social">
					<div className="col-md-4 mg-auto">
						<div className="row text-center">
							<div className="col-md-6 col-6">
								<a href="">
									<img src={require("../../../assets/img/app-store.png")} />
								</a>
							</div>
							<div className="col-md-6 col-6">
								<a href="">
									<img src={require("../../../assets/img/google-play.png")} />
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default QrCode;
