import Header from "../../shared/header/header";
import Footer from "../../shared/footer/footer";

const Mem3 = () => {
	return (
		<div>
			<Header />
			<section id="content" className="pt50">
				<div className="container">
					<div className="row mb10">
						<div className="col-md-6">
							<div id="song-list">
								<div className="fan-albumb-song team-member-profile-img mb20">
									{/* <img src={require("../../assets/img/hp1.png")} /> */}
									<img src={require("../../assets/img/hp3.png")} />
								</div>
							</div>
							<div className="bg-black height680 fan-libry">
								<div className="row mb20">
									<div className="col-md-12 text-left fan-art-prfl">
										<div className="dalbum-content">
											<h2 className="text-white mb20">
												What DalbumX means to me:
											</h2>
											<p className="font14 text-white">
												Lorem Ipsum is simply dummy text of the printing and
												typesetting industry. Lorem Ipsum has been the
												industry's standard dummy text ever since the 1500s,
												when an unknown printer took a galley of type and
												scrambled it to make a type specimen book. It has
												survived not only five centuries, but also the leap into
												electronic typesetting, remaining essentially unchanged.
												It was popularised in the 1960s with the release of
												Letraset sheets containing Lorem Ipsum passages, and
												more recently with desktop publishing software like
												Aldus PageMaker including versions of Lorem Ipsum.
											</p>
											<p className="font14 text-white">
												Lorem Ipsum is simply dummy text of the printing and
												typesetting industry. Lorem Ipsum has been the
												industry's standard dummy text ever since the 1500s,
												when an unknown printer took a galley of type and
												scrambled it to make a type specimen book. It has
												survived not only five centuries, but also the leap into
												electronic typesetting, remaining essentially unchanged.
												It was popularised in the 1960s with the release of
												Letraset sheets containing Lorem Ipsum passages, and
												more recently with desktop publishing software like
												Aldus PageMaker including versions of Lorem Ipsum.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 clearfix">
							{/*  <section className="general-catagory">
                     <div className="row">
                      <div className="col-md-12 clearfix">
                        <div className="search-box">
                          <input type="text" className="form-control fan-search" placeholder="search">
                        </div>
                      </div>
                     </div>
                   </section> */}
							<div className="song-list-inner team-member-detail mt0">
								<h2 className="din-font text-uppercase medium-text text-green mb10">
									JAMES REID
								</h2>
								<h1 className="din-font text-uppercase font40 text-green">
									Co-Founder / Back End Development
								</h1>
								<p className="font14">
									Lorem Ipsum is simply dummy text of the printing and
									typesetting industry. Lorem Ipsum has been the industry's
									standard dummy text ever since the 1500s, when an unknown
									printer took a galley of type and scrambled it to make a type
									specimen book. It has survived not only five centuries, but
									also the leap into electronic typesetting, remaining
									essentially unchanged. It was popularised in the 1960s with
									the release of Letraset sheets containing Lorem Ipsum
									passages, and more recently with desktop publishing software
									like Aldus PageMaker including versions of Lorem Ipsum.
								</p>
								<p className="font14">
									Lorem Ipsum is simply dummy text of the printing and
									typesetting industry. Lorem Ipsum has been the industry's
									standard dummy text ever since the 1500s, when an unknown
									printer took a galley of type and scrambled it to make a type
									specimen book. It has survived not only five centuries, but
									also the leap into electronic typesetting, remaining
									essentially unchanged. It was popularised in the 1960s with
									the release of Letraset sheets containing Lorem Ipsum
									passages, and more recently with desktop publishing software
									like Aldus PageMaker including versions of Lorem Ipsum.
								</p>
								<p className="font14">
									Lorem Ipsum is simply dummy text of the printing and
									typesetting industry. Lorem Ipsum has been the industry's
									standard dummy text ever since the 1500s, when an unknown
									printer took a galley of type and scrambled it to make a type
									specimen book. It has survived not only five centuries, but
									also the leap into electronic typesetting, remaining
									essentially unchanged. It was popularised in the 1960s with
									the release of Letraset sheets containing Lorem Ipsum
									passages, and more recently with desktop publishing software
									like Aldus PageMaker including versions of Lorem Ipsum.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
};

export default Mem3;
