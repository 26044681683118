import Header from "../../shared/header/header";
import Footer from "../../shared/footer/footer";
import RegisterFan from "./../../components/fan/fanRegister";

const FanRegister = () => {
	return (
		<div>
			<Header />
			<RegisterFan />
			<Footer />
		</div>
	);
};

export default FanRegister;
