const { useState, useEffect } = require("react");
const { default: Cropper } = require("react-easy-crop");
const { default: getCroppedImg } = require("./cropImage");

const ImageCropperModal = ({ image, onCropImage, onCropImageCancel }) => {
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

	let imageUrl;
	try {
		imageUrl = URL.createObjectURL(image);
	} catch {
		imageUrl = image;
	}

	useEffect(() => {
		document.getElementById("image_cropper").click();
	}, []);

	const onCropComplete = (croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels);
	};

	const cropImage = async () => {
		try {
			const croppedImage = await getCroppedImg(imageUrl, croppedAreaPixels);
			await onCropImage(croppedImage);
		} catch (e) {
			console.error(e);
		}
	};

	const cancelCropImage = () => {
		onCropImageCancel();
	};

	return (
		<>
			{
				<>
					<button
						style={{ visibility: "hidden" }}
						id="image_cropper"
						type="button"
						className="btn btn-primary"
						data-toggle="modal"
						data-target="#popup"
					></button>
					<div
						className="modal fade"
						id="popup"
						data-backdrop="static"
						data-keyboard={true}
					>
						<div className="modal-dialog modal-dialog-centered">
							<div
								className="modal-content text-center"
								style={{ width: "100%" }}
							>
								<div
									style={{
										fontSize: "5vh",
										fontWeight: "bolder",
										color: "rgb(39, 140, 142)",
										lineHeight: "4vh",
										texttransform: "uppercase",
										fontFamily: "'DINNeuGroBolCon', sans-serif",
										padding: "2vh",
									}}
								>
									Let's crop image
								</div>
								<div className="text-bold" style={{ marginBottom: "2vh", marginTop: "2vh" }}>
									<span style={{ fontSize: "18px", margin: "0 10px" }}>
										Please use mouse scroll / pinch to zoom in or zoom out.
										You may also drag image around to adjust according to
										defined 1:1 aspect ratio frame for crop.
									</span>
								</div>
								<div className="border-top"></div>
								<div className="modal-body din-font" style={{ height: "60vh" }}>
									<div className="content2">
										<Cropper
											image={imageUrl}
											crop={crop}
											zoom={zoom}
											rotation={0}
											aspect={1 / 1}
											onCropChange={setCrop}
											onCropComplete={onCropComplete}
											onZoomChange={setZoom}
										/>
									</div>
								</div>
								<div className="border-top"></div>
								<div className="form">
									<div className="form-group">
										<button
											type="button"
											style={{ marginTop: "2vh" }}
											onClick={cropImage}
											className="btn-save"
											data-dismiss="modal"
										>
											Save
										</button>
										<button
											type="button"
											style={{ marginTop: "2vh" }}
											onClick={cancelCropImage}
											className="btn-save"
											data-dismiss="modal"
										>
											Cancel
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			}
		</>
	);
};

export default ImageCropperModal;
