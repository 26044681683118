import { useEffect, useState } from "react";
import axios from "axios";
import { showNotification } from "../../shared/showNotification";

const ArtistVerifyConfirm = () => {
	const [state, setState] = useState({
		token: "",
	});

	useEffect(() => {
		extractUrlToken();
	}, []);
	const extractUrlToken = (event) => {
		// let url = ;
		var urlsplit = window.location.href.split("/");
		var urlToken = urlsplit[urlsplit.length - 1];

		setState((oldState) => {
			const updatedState = {
				...oldState,
				token: urlToken,
			};
			handleSubmit(updatedState);
			return updatedState;
		});
	};
	const handleSubmit = (state) => {
		axios
			.post("/auth/admin/verify_email", {
				token: state.token,
			})
			.then(function (response) {
				if (response.data.status === 200 || response.data.status === 400) {
					debugger;
					window.location = "/artist/login";
				} else {
					showNotification("Wrong Move", "warning");
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				//console.log(error);
			});
	};

	return <div>{/* Verified */}</div>;
};

export default ArtistVerifyConfirm;
