import Header from "../../shared/ArtistHeader/header";
import Footer from "../../shared/footer/footer";
import axios from "axios";
import Step7AddAlbumComponent from "../../components/artist/addAlbumSteps/Step7AddAlbum";
import Step6AddAlbumComponent from "../../components/artist/addAlbumSteps/Step6AddAlbum";
import Step5AddAlbumComponent from "../../components/artist/addAlbumSteps/Step5AddAlbum";
import Step4AddAlbumComponent from "../../components/artist/addAlbumSteps/Step4AddAlbum";
import Step3AddAlbumComponent from "../../components/artist/addAlbumSteps/Step3AddAlbum";
import Step2AddAlbumComponent from "../../components/artist/addAlbumSteps/Step2AddAlbum";
import Step1AddAlbumComponent from "../../components/artist/addAlbumSteps/Step1AddAlbum";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { confirmAlert } from "react-confirm-alert";

const AddAlbum = () => {
	const location = useLocation();

	const stepsRef = useRef([]);

	const [state, setState] = useState({
		stepNumber:
			sessionStorage.getItem("currentStep") !== null
				? parseInt(sessionStorage.getItem("currentStep"))
				: 1,
		size: 0,
		copyright_year: "",
		name: "",
	});

	const stepElements = [
		{
			step: 1,
			data: (
				<>
					<span className="step-no-xs">
						Artist <br /> Info
					</span>
					<span className="step-yes-xs">Artist Info</span>
				</>
			),
		},
		{
			step: 2,
			data: (
				<>
					<span className="step-no-xs">
						Dalbum <br /> Info
					</span>
					<span className="step-yes-xs">Dalbum Info</span>{" "}
				</>
			),
		},
		{
			step: 3,
			data: (
				<>
					{" "}
					<span className="step-no-xs">
						Upload <br /> Artwork
					</span>
					<span className="step-yes-xs">Upload Artwork</span>{" "}
				</>
			),
		},
		{
			step: 4,
			data: (
				<>
					<span className="step-no-xs">
						Upload <br /> Compressed
						<br /> Tracks
					</span>
					<span className="step-yes-xs">Upload Compressed Tracks</span>
				</>
			),
		},
		{
			step: 5,
			data: (
				<>
					<span className="step-no-xs">
						Upload <br /> UnCompressed <br />
						Tracks
					</span>
					<span className="step-yes-xs">Upload UnCompressed Tracks</span>
				</>
			),
		},
		{
			step: 6,
			data: (
				<>
					<span className="step-no-xs">
						Embed
						<br /> Hidden
						<br /> Content
					</span>
					<span className="step-yes-xs">Embed Hidden Material</span>
				</>
			),
		},
		{ step: 7, data: <>Credits</> },
	];

	useEffect(() => {
		if (sessionStorage.getItem("album_id")) {
			update();
		}

		if (location.state && location.state.page) {
			console.log(`Step : ${location.state.page}`);
			setState((oldState) => ({
				...oldState,
				stepNumber: location.state.page,
			}));
		} else {
			console.log(`No Step set. Default Step : ${state.stepNumber}`);
		}
	}, []);

	const changeComponent = (no) => {
		setState((oldState) => ({ ...oldState, stepNumber: no }));
		sessionStorage.setItem("currentStep", no);
	};

	const confirmNoSave = (save, cancel = () => {}) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="confirm-ui">
						<div className="delete-prompt-box">
							<h2 className="text-uppercase mb10">
								WHOA, BEFORE YOU EXIT PAGE, WOULD YOU LIKE TO SAVE YOUR CHANGES?
							</h2>
							<div className="d-flex mr-3 justify-content-center">
								<button
									type="button"
									onClick={async () => {
										onClose();
										await save();
									}}
									className="btn-save text-uppercase"
								>
									Yes, please save!
								</button>
								<button
									type="button"
									onClick={() => {
										onClose();
										cancel();
									}}
									className="btn-cancel text-uppercase"
								>
									No, I'm good. Delete changes.
								</button>
							</div>
						</div>
					</div>
				);
			},
		});
	};

	const savedata = async () => {
		await stepsRef.current[state.stepNumber].save();
	};

	const update = () => {
		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};

		axios
			.get(
				"app/addalbum/step2?album_id=" + sessionStorage.getItem("album_id"),
				config
			)
			.then(function (response) {
				if (response.data.success) {
					setState((oldState) => ({
						...oldState,
						size: response.data.data.total_size,
						copyright_year: response.data.data.copyright_year,
						name: response.data.data.name,
					}));
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const noop = () => {};

	return (
		<div>
			<Header />
			<section id="content" className="pt50">
				<div className="container">
					<div className="row mb30 justify-content-center">
						<div className="steps-counter custom-step-counter bg-white">
							{stepElements.map((elem, index) => (
								<div
									key={index}
									className={
										(elem.step === state.stepNumber
											? "active light-bg"
											: elem.step > state.stepNumber
											? "light-bg"
											: "completed") +
										" steps pd25 text-center " +
										(elem.step === 7 ? "last-child" : "")
									}
								>
									<a>
										<div
											onClick={() => {
												elem.step < state.stepNumber
													? changeComponent(elem.step)
													: noop();
											}}
											className="step-content"
										>
											<h2 className="text-uppercase text-white mb10">
												Step {elem.step}
											</h2>
											<h2 className="text-uppercase text-white">
												<div className="step-info">{elem.data}</div>
											</h2>
										</div>
									</a>
								</div>
							))}
						</div>
					</div>
					<div className="row mb30">
						<div className="col-md-6">
							<div className="steps-progress-bar">
								<div className="progress" style={{ height: 40 + "px" }}>
									<div
										className="progress-bar"
										role="progressbar"
										style={{
											width:
												(((state.size / (1024 * 1024)) * 100) / 750).toFixed(
													1
												) + "%",
										}}
										aria-valuenow="0"
										aria-valuemin="0"
										aria-valuemax="100"
									>
										<span>
											{(((state.size / (1024 * 1024)) * 100) / 750).toFixed(1)}%
											of 750 MB has been used
										</span>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="pull-right-lg">
								{state.stepNumber !== 6 && (
									<button
										type="button"
										onClick={async () => {
											await savedata();
										}}
										className="btn-gradient btn-agree text-white text-uppercase w250"
									>
										Save...
									</button>
								)}
							</div>
						</div>
					</div>
					{state.stepNumber === 1 && (
						<Step1AddAlbumComponent
							ref={(element) => (stepsRef.current[1] = element)}
							changestep={changeComponent}
							confirmation={confirmNoSave}
						/>
					)}
					{state.stepNumber === 2 && (
						<Step2AddAlbumComponent
							ref={(element) => (stepsRef.current[2] = element)}
							updateSize={update}
							changestep={changeComponent}
							confirmation={confirmNoSave}
						/>
					)}
					{state.stepNumber === 3 && (
						<Step3AddAlbumComponent
							ref={(element) => (stepsRef.current[3] = element)}
							copyright_year={state.copyright_year}
							changestep={changeComponent}
							updateSize={update}
						/>
					)}
					{state.stepNumber === 4 && (
						<Step4AddAlbumComponent
							ref={(element) => (stepsRef.current[4] = element)}
							album_name={state.name}
							changestep={changeComponent}
							updateSize={update}
							total_size = {state.size}
						/>
					)}
					{state.stepNumber === 5 && (
						<Step5AddAlbumComponent
							ref={(element) => (stepsRef.current[5] = element)}
							album_name={state.name}
							changestep={changeComponent}
							updateSize={update}
						/>
					)}
					{state.stepNumber === 6 && (
						<Step6AddAlbumComponent
							ref={(element) => (stepsRef.current[6] = element)}
							album_name={state.name}
							changestep={changeComponent}
							updateSize={update}
						/>
					)}
					{state.stepNumber === 7 && (
						<Step7AddAlbumComponent
							ref={(element) => (stepsRef.current[7] = element)}
							changestep={changeComponent}
							confirmation={confirmNoSave}
						/>
					)}
				</div>
			</section>
			<Footer />
		</div>
	);
};

export default AddAlbum;
