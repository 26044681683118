import React, { useEffect, useRef, useState } from "react";
import Header from "../../shared/ArtistHeader/header";
import Footer from "../../shared/footer/footer";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import { useLocation, useNavigate } from "react-router";
import validator from "validator";
import { showNotification } from "../../shared/showNotification";
import useArtwork from "../../components/useArtwork";

const CustomTool = ({ message }) => {
  return (
    <div className="mobileTooltip">
      <div className="tooltip">
        <img src={require("./../../assets/img/info.png")} alt="" />
        <span className="tooltiptext" style={{ backgroundColor: "#a9adac" }}>
          {" "}
          {message}{" "}
        </span>
      </div>
    </div>
  );
};

const PrePublishPreview = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const canvasRef = useRef({});

  const [state, setState] = useState({
    artwork: [],
    size: 0,
    albumData: {},
    name: "",
    modalVisible: false,
    inputText: "",
    items: [],
  });

  useArtwork(state.artwork, 500);

  useEffect(() => {
    if (!location.state || !sessionStorage.getItem("album_id")) {
      const pathArray = window.location.href.split("/");
      const protocol = pathArray[0];
      const host = pathArray[2];
      const url = protocol + "//" + host;
      window.location = url + "/artist/dashboard";
    }

    window.$("#HiddenTracks").carousel({
      interval: false,
      pause: false,
      keyboard: false,
    });
    getalbum();
	getEmails();
  }, []);

  const handleOpenModal = () => {
    setState((oldstate) => ({ ...oldstate, modalVisible: true }));
  };

  const handleCloseModal = () => {
    setState((oldstate) => ({ ...oldstate, modalVisible: false }));
  };

  const handleInputChange = (e) => {
    setState((oldstate) => ({ ...oldstate, inputText: e.target.value }));
  };

  const handleAddItem = async () => {
	const email = state.inputText;
	const dalbum_id = sessionStorage.getItem("album_id")

	if( validator.isEmpty(email) || !validator.isEmail(email) ) {
		showNotification("Enter a valid email","error")
		return
	} 


	if(state.items.includes(email)) {
			showNotification("Email already exists in the list", "error")
			return
	}

	const config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			}
	}

	const user = await axios.get(`/app/album/gift/checkEmail?email=${email}`,config)

	// Check if email exists 
	if(!user.data.success) {
		showNotification(user.data.error,"error")
		return
	}

	const fanData = {
		email,
		dalbum_id
	}

	const response = await axios.post("/app/album/gift",fanData,config)

	if(response.data.success) {
		showNotification("Fan added to list","success")
	}

    if (state.inputText.trim() !== "") {
      setState((oldstate) => ({ ...oldstate, items: [...state.items, state.inputText], inputText: "" }));
    }
  };

  const redirect = (type, no) => {
    if (type === "before") {
      navigate("/artist/addalbum", {
        state: { page: no },
      });
    }
    if (type === "after") {
      if (no === "price") {
        navigate("/artist/managepricing", {
          state: {
            data: state.albumData,
          },
        });
      }
      if (no === "dextra") {
        navigate("/artist/dextra", {
          state: {
            data: state.albumData,
          },
        });
      }
      if (no === "trackdextra") {
        navigate("/artist/track-dextra", {
          state: {
            data: state.albumData,
          },
        });
      }
      if (no === "payment") {
        if (!state.albumData.price || state.albumData.price === "") {
          priceAlert();
        } else {
          confirmPublish();
        }
      }
    }
  };

  const priceAlert = (index) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-ui">
            <div className="delete-prompt-box">
              <h2 className="text-uppercase mb10">
                You Didn't set Price For Album
                <br /> Please Set Price of Album
              </h2>
              <div className="d-flex mr-3 justify-content-center">
                <button type="button" onClick={onClose} className="btn-save text-uppercase">
                  Ok
                </button>
              </div>
            </div>
          </div>
        );
      },
    });
  };
  const confirmPublish = (index) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-ui">
            <div className="delete-prompt-box">
              <h2 className="text-uppercase mb10">
                <p>
                  REMEMBER, ONCE YOU PUBLISH, YOU CANNOT UNDO OR EDIT YOUR CORE DALBUM' (MAIN ARTWORK, TRACKS EMBEDDED MATERIAL AND CREDITS). PLEASE MAKE SURE THERE ARE NO TYPOS AND THAT YOUR MATERIAL
                  WAS UPLOADED PROPERLY
                </p>
                <p>YOU CAN EDIT YOUR DEXTRAS AT ANY TIME. IF YOU NEED ANY HELP, PLEASE CONTACT THE DALBUMX SUPPORT TEAM.</p>
                {/* <p>YOU CAN EDIT YOUR DEXTRAS AT ANYTIME AND YOU CAN ONLY ADD TO YOUR CREDITS LATER. IF YOU NEED ANY HELP, CONTACT THE DALBUMX SUPPORT TEAM</p> */}
              </h2>
              <div className="d-flex mr-3 justify-content-center">
                <button type="button" onClick={onClose} className="btn-cancel text-uppercase">
                  Back
                </button>
                <button
                  type="button"
                  onClick={() => {
                    checkout();
                    onClose();
                  }}
                  className="btn-save text-uppercase"
                >
                  I'M SURE
                </button>
              </div>
            </div>
          </div>
        );
      },
    });
  };
  const checkout = () => {
    navigate("/artist/checkout", {
      state: {
        data: state.albumData,
      },
    });
  };
  const sortintoCatagory = (data) => {
    var sorted = data.sort(function (a, b) {
      return a.at_position - b.at_position;
    });
    setState((oldState) => ({
      ...oldState,
      artwork: sorted,
    }));
  };

  const getalbum = () => {
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
      },
    };

    axios
      .get("app/addalbum/prepublishpreview?album_id=" + location.state.album_id, config)
      .then(function (response) {
        if (response.data.success) {
          let temp = response.data.data;
          sortintoCatagory(response.data.data.artwork_images);
          setState((oldState) => ({
            ...oldState,
            // artwork: response.data.data.artwork_images,
            albumData: response.data.data,
            size: response.data.data.total_size,
            name: (
              <h3>
                {temp.name}
                <br />
                {temp.copyright_year}
                <br />
                {temp.dalbum_record_lbl}
              </h3>
            ),
          }));
        } else {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getEmails = async () => {
    const config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
      },
    };

	const response = await axios.get(`/app/album/gift/emails?dalbum_id=${sessionStorage.getItem("album_id")}`,config)

	if(response.data.success) {
		setState((oldstate) => ({...oldstate, items: [...response.data.data]}))
	} 

  }

  return (
    <div>
      <Header />
      <section id="content" className="pt50">
        <div className="container">
          <div className="row mb30">
            <div className="col-md-12">
              <h1 className="text-center text-uppercase">Pre-Publishing Preview</h1>
            </div>
          </div>

          <div className="row mb30 justify-content-center">
            <div className="col-md-6">
              <div className="steps-progress-bar">
                <div className="progress" style={{ height: "40px" }}>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: (((state.size / (1024 * 1024)) * 100) / 750).toFixed(1) + "%",
                    }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {(((state.size / (1024 * 1024)) * 100) / 750).toFixed(1)}% of 750 MB has been used
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row align-items-end">
            <div className="col-md-7">
              <div className="publish-album-edit pull-right">
                <button type="button" className="btn-edit">
                  Edit
                </button>
                <div className="publish-album-dropdown">
                  <div className="before-publish bg-orange pd15">
                    <ul className="list-unstyled mb0">
                      <li className="list-heading">
                        <a href="#" className="text-uppercase text-white">
                          Only Before Publishing
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => {
                            redirect("before", 3);
                          }}
                          className="text-uppercase text-white"
                        >
                          {" "}
                          <img src={require("../../assets/img/right-arr.png")} />
                          Artwork
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => {
                            redirect("before", 4);
                          }}
                          className="text-uppercase text-white"
                        >
                          <img src={require("../../assets/img/right-arr.png")} />
                          Tracks
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => {
                            redirect("before", 6);
                          }}
                          className="text-uppercase text-white"
                        >
                          <img src={require("../../assets/img/right-arr.png")} />
                          Embedded Content
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => {
                            redirect("before", 7);
                          }}
                          className="text-uppercase text-white"
                        >
                          <img src={require("../../assets/img/right-arr.png")} />
                          Credits
                        </a>
                      </li>
                      {/* <li>
                        <a
                          onClick={() => {
                            handleOpenModal();
                          }}
                          className="text-uppercase text-white"
                        >
                          <img src={require("../../assets/img/right-arr.png")} />
                          Add Gift
                        </a> 
						</li> */}
                    </ul>
                  </div>
                  {state.modalVisible && (
                    <div style={{ display: "block", position: "fixed", zIndex: "1", left: "0", top: "0", width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", textAlign: "center" }}>
                      <div style={{ backgroundColor: "white", margin: "15% auto", padding: "20px", borderRadius: "5px", width: "50%", position: "relative" }}>
                        <h2 className="text-center text-uppercase" style={{ marginBottom: "10px", fontWeight: "600" }}>
                          Add Gift
                        </h2>
                        <button onClick={handleCloseModal} type="button" class="modal-default-close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                        <div style={{ marginTop: "10px", borderTop: "1px solid black", paddingTop: "10px", display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                          <input className="react-autosuggest__input gift-fan-username-field" type="text" value={state.inputText} onChange={handleInputChange} placeholder="Fan username" />
                          <button className="btn-save" onClick={handleAddItem}>
                            Add
                          </button>
                        </div>
                        <div style={{ marginTop: "10px", borderTop: "1px solid black", paddingTop: "10px", display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                          {state.items.map((item, index) => (
                            <React.Fragment key={index}>
                              <span style={{ display: "inline-block", marginRight: "5px" }}>{item}</span>
                              {index !== state.items.length - 1 && <span style={{ marginRight: "5px", marginLeft: "5px" }}>|</span>}
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="edit-publish bg-black pd15">
                    <ul className="list-unstyled">
                      <li className="list-heading">
                        <a href="#" className="text-uppercase text-white">
                          Can Be edited Anytime
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => {
                            redirect("after", "dextra");
                          }}
                          className="text-uppercase text-white"
                        >
                          <img src={require("../../assets/img/right-arr.png")} />
                          Dalbum Dextras
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => {
                            redirect("after", "trackdextra");
                          }}
                          className="text-uppercase text-white"
                        >
                          <img src={require("../../assets/img/right-arr.png")} />
                          TRACK DEXTRAS
                        </a>
                      </li>
                      <li>{/* <a href="#" className="text-uppercase text-white"><img src={require("../../assets/img/right-arr.png")} />Xchange Display</a> */}</li>
                      <li>
                        <a
                          onClick={() => {
                            redirect("after", "price");
                          }}
                          className="text-uppercase text-white"
                        >
                          <img src={require("../../assets/img/right-arr.png")} />
                          Manage Price
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="album-name text-right text-uppercase">
                {state.name}
                {/* <h3>Interpol <br /> El Pintor <br /> 2014 <br /> matador Records</h3> */}
              </div>
            </div>
          </div>

          <div className="row mb30">
            <div id="HiddenTracks" className="publish-album-slider carousel slide ml-auto mr-auto" data-ride="carousel">
              <div className="carousel-inner">
                {state.artwork
                  .filter(art => art.label !== "Front Cover Still")
                  .map((art, idx) => (
                  <div key={idx} className={(idx === 0 ? "active " : "") + "carousel-item"}>
                    <div
                      ref={(element) => (canvasRef.current["canvas" + idx] = element)}
                      className="step6-slider"
                      style={{
                        backgroundImage: `${art.urls}`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        width: "500px"
                      }}
                    ></div>
                  </div>
                ))}
                {/* <div className="carousel-item active">
                                            <img src={require("../../assets/img/album-image.png")} className="d-block" alt="album-image" />
                                        </div> */}
              </div>
              <a className="carousel-control-prev" href="#HiddenTracks" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a className="carousel-control-next" href="#HiddenTracks" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
          </div>

          <div className="row form-group justify-content-center mblBtn review-help-icon">
            <button type="button" className="btn-save text-uppercase  disabled">
              Preview
            </button>
            <button
              onClick={() => {
                redirect("after", "payment");
              }}
              type="button"
              className="btn-cancel text-uppercase"
            >
              Ready to Publish
            </button>
            {/* <CustomTool  message={'kapdsddciddcl'} /> */}
          </div>

          {/* <AlbumPricingComponent/> */}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default PrePublishPreview;
