import axios from "axios";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { showNotification } from "../../shared/showNotification";
import { getFrontCoverThumbnailUrl }  from "../artworkHelper";

const AlbumPreviewComponent = ({ album_id }) => {
	const location = useLocation();
	const navigate = useNavigate();

	const params = useParams();

	const [state, setState] = useState({
		play: "STOPPED",
		album: { artist: {} },
		tracks: [],
		albumDextra: [],
		musician: [],
		suggestion: [],
		libFlag: 0,
		cartFlag: 0,
		wishFlag: 0,
		artist: [],
	});

	useEffect(() => {
		if (album_id) {
			getAlbum(album_id);
		} else {
			getAlbum();
		}
	}, []);

	const getAlbum = (album_id) => {
		var config = {
			headers: { Authorization: "Bearer " + localStorage.getItem("tokenFan") },
			params: {
				album_id: album_id ? album_id : location.state.album_id,
			},
		};
		axios
			.get("/app/fan/getalbum", config)
			.then(function (response) {
				//console.log(response.data.data)

				if (response.data.success) {
					// state.artists = response.data.data
					setState((oldState) => ({
						...oldState,
						album: response.data.data.data.album,
						tracks: response.data.data.data.tracks,
						musician: response.data.data.data.musician,
						albumDextra: response.data.data.data.albumDextra,
						suggestion: response.data.data.data.suggestion,
						libFlag: response.data.data.data.libFlag,
						cartFlag: response.data.data.data.cartFlag,
						wishFlag: response.data.data.data.wishFlag,
					}));
				} else {
					showNotification(response.data.message, "error");
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				//console.log(error);
			});
	};

	const increaseCartCount = () => {
		let count = sessionStorage.getItem("cartCount")
			? Number(sessionStorage.getItem("cartCount"))
			: 0;
		count += 1;
		sessionStorage.setItem("cartCount", count);
		window.$(document).trigger("cartUpdate", count);
		// props.changeCartCount()
	};

	const addToCart = () => {
		var config = {
			headers: { Authorization: "Bearer " + localStorage.getItem("tokenFan") },
		};

		let data = { album_id: state.album.dalbum_id };

		axios
			.post("/app/fan/addtocart", data, config)
			.then(function (response) {
				//console.log(response.data.data)

				if (response.data.success) {
					if (response.data.data.data) {
						increaseCartCount();
					}

					showNotification("Added Successfully", "success");
				} else {
					showNotification(response.data.message, "error");
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				//console.log(error);
			});
	};

	const addToWishlist = () => {
		var config = {
			headers: { Authorization: "Bearer " + localStorage.getItem("tokenFan") },
		};

		let data = { album_id: state.album.dalbum_id };
		axios
			.post("/app/fan/addtowishlist", data, config)
			.then(function (response) {
				//console.log(response.data.data)

				if (response.data.success) {
					showNotification("Added Successfully", "success");
				} else {
					showNotification(response.data.message, "error");
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				//console.log(error);
			});
	};

	const setAlbum = (album_id) => {
		getAlbum(album_id);
	};

	const setArtist = () => {
		navigate("/fan/artist-preview", {
			state: { artist_id: state.album.artist.artist_id },
		});
	};

	

	return (
		<section id="content" className="pt50">
			{/* {(state.activeTrack || state.activeTrack == 0) && <Sound
                    url={axios.defaults.baseURL + state.tracks[state.activeTrack].path + state.tracks[state.activeTrack].filename}
                    playStatus={Sound.status[state.play]}
                    playFromPosition={0 }
                
                />} */}
			<div className="container">
				<div className="row mb10">
					<div className="col-md-6">
						<div id="song-list" className="carousel slide" data-ride="carousel">
							{/* Indicators */}
							<ul className="carousel-indicators">
								{/* {state.album.artwork_images && state.album.artwork_images.map((elem, index) => elem.at_position &&
                                        <li data-target="#song-list" data-slide-to={index} className={(index == 0 ? "active " : "")} />)} */}
								{/* <li data-target="#song-list" data-slide-to={1} />  */}
							</ul>
							{/* The slideshow */}
							<div className="carousel-inner fan-albumb-song mb20">
								{state.album.artwork_images && state.album.artwork_images.map((elem, index) => {
									return (
										<div key={index} className={(index === 0 ? "active" : "") + "carousel-item"}>
											<div
												className="bacSlider"
												style={{
													backgroundImage:
														`${getFrontCoverThumbnailUrl(state.album.artwork_images)}`,
												}}
											></div>
										</div>
									)
								})}
								{/* {state.album.artwork_images &&
									state.album.artwork_images.map(
										(elem, index) =>
											elem.at_position && (
												<div
													key={index}
													className={
														(index === 0 ? "active " : "") + "carousel-item"
													}
												>
													<div
														className="bacSlider"
														style={{
															backgroundImage:
																`url(` +
																axios.defaults.baseURL +
																elem.path +
																elem.name +
																`)`,
														}}
													></div>
												</div>
											)
									)} */}
							</div>
							{/* <div className="carousel-inner fan-albumb-song mb20">
                                    {state.album.artwork_images && state.album.artwork_images.map((elem, index) => elem.at_position &&
                                        <div className={(index == 0 ? "active " : "") + "carousel-item"}>
                                            <div className="bacSlider" style={{ backgroundImage: `url(` + axios.defaults.baseURL + elem.path + elem.name + `)` }}>
                                            </div>
                                        </div>)}
                                </div> */}
						</div>
						<div className="bg-black height680 fan-libry">
							<div className="row mb20">
								<div className="col-md-12 text-right fan-art-prfl">
									<div className="library-btn ">
										{state.libFlag == 1 && (
											<a className="btn btn-block mb10 disabled">IN LIBRARY</a>
										)}
										{state.cartFlag == 1 && (
											<a className={"btn btn-block mb10 disabled"}>
												Add to Cart
											</a>
										)}
										{state.cartFlag != 1 && state.libFlag != 1 && (
											<a onClick={addToCart} className={"btn btn-block mb10"}>
												Add to Cart
											</a>
										)}
										<div className="wish-list-btns mb30 cus-wish-lish">
											{state.wishFlag == 1 && (
												<a className="btn wishlist disabled">wishlist</a>
											)}
											{state.wishFlag != 1 && (
												<a onClick={addToWishlist} className="btn wishlist">
													wishlist
												</a>
											)}
											{/* <a  className="btn gift">gift</a> */}
											<Link
												to={{
													pathname: "/fan/gift-album",
													state: { data: state.album },
												}}
												className="btn gif"
											>
												gift
											</Link>
										</div>
									</div>
									<h3
										onClick={setArtist}
										className="text-white big-text mb20 pointer"
									>
										{state?.album?.artist?.name}
									</h3>
									{/* <h2 className="text-white">New York, NY</h2> */}
									<h2 className="text-white">
										{state.album.dalbum_record_lbl}
									</h2>
									{state.album.genre && (
										<h2 className="text-white mb20">
											{state?.album?.genre?.name} +{" "}
											{state?.album?.sub_genre?.name}
										</h2>
									)}
									<h1 className="text-white mb5 text-uppercase mb20">
										Band Members
									</h1>
									{state.musician.map((elem, index) => (
										<div key={index}>
											<div className="band-member-list">
												<h2 className="text-white">
													{elem.musician.firstname} {elem.musician.lastname}
												</h2>
												<h2 className="text-white">
													{elem.dalbum_musician_instruments.map((inst, idx) => (
														<div key={idx}>
															{idx !== 0 && <> + </>}
															{inst.musician_instrument_id}
														</div>
													))}
												</h2>
											</div>
										</div>
									))}
									<ul className="list-inline mb0">
										{/* <li className="list-inline-item">
                                                <a href="#"> <img src="img/fb.png" alt title /></a>
                                            </li>
                                            <li className="list-inline-item">
                                                <a href="#"> <img src="img/twitter.png" alt title /></a>
                                            </li>
                                            <li className="list-inline-item">
                                                <a href="#"> <img src="img/google-plus.png" alt title /></a>
                                            </li>
                                            <li className="list-inline-item">
                                                <a href="#"> <img src="img/instagram.png" alt title /></a>
                                            </li>
                                            <li className="list-inline-item">
                                                <a href="#"> <img src="img/vue.png" alt title /></a>
                                            </li>
                                            <li className="list-inline-item">
                                                <a href="#"> <img src="img/tumbler.png" alt title /></a>
                                            </li> */}
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-6 clearfix">
						{/*  <section className="general-catagory">
                     <div className="row">
                      <div className="col-md-12 clearfix">
                        <div className="search-box">
                          <input type="text" className="form-control fan-search" placeholder="search">
                        </div>
                      </div>
                     </div>
                   </section> */}
						<div className="song-list-inner">
							<ol className="list-inline clearfix">
								{/* <li className="active">
                                        <div className="list-inner">
                                            <div className="active-music">
                                                <img className="play" src={require("./../../assets/img/PauseButtonPreferred.png")} />
                                                <img className="pause" src="img/PlayButtonPreferred.png"/>
                                                <img className="running" src={require("./../../assets/img/music.png")} />
                                            </div>
                                            <p><span>1. all the page back home all the page back home </span>  <img className="icon-black" src={require('./../../assets/img/icon-plus-black.png')} />
                                                <img className="icon-white" src={require('./../../assets/img/icon-plus.png')} /></p>
                                        </div>
                                        <div className="slick-inner-hover">
                                            <ul className="list-inline">
                                                <li>
                                                    <h4>INTERPOL</h4>
                                                    <p>EI Pintor</p>
                                                    <p>Matador Records ©2014</p>
                                                </li>
                                                <li>
                                                    <p>CORE DALBUM:</p>
                                                    <p>10 TRACKS &gt; RUNNING TIME: 43 MIN 1 BOOKLET</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </li> */}
								{state.tracks.map((track, index) => (
									<li
										key={index}
										className={
											state.activeTrack == index
												? "active list-inline-item"
												: " list-inline-item"
										}
									>
										<div
											onClick={(e) => {
												setState((oldState) => ({
													...oldState,
													activeTrack: index,
													play: "STOPPED",
												}));
											}}
											className="list-inner"
										>
											{state.activeTrack == index && (
												<div className="active-music">
													{state.play == "PLAYING" && (
														<img
															onClick={(e) => {
																e.stopPropagation();
																setState((oldState) => ({
																	...oldState,
																	play: "STOPPED",
																}));
															}}
															className="play"
															src={require("./../../assets/img/PauseButtonPreferred.png")}
														/>
													)}
													{state.play == "STOPPED" && (
														<img
															onClick={(e) => {
																e.stopPropagation();
																setState((oldState) => ({
																	...oldState,
																	play: "PLAYING",
																}));
															}}
															className="pause"
															src={require("./../../assets/img/PlayButtonPreferred.png")}
														/>
													)}
													{state.play == "PLAYING" && (
														<img
															className="running"
															src={require("./../../assets/img/musicdynamic.gif")}
														/>
													)}
												</div>
											)}
											<p>
												<span>
													{index + 1} {track.name}
												</span>
											</p>
											{track.dextra_labels.length > 0 && (
												<div className="imghover">
													<img
														className="icon-black"
														src={require("./../../assets/img/icon-plus-black.png")}
													/>
													<img
														className="icon-white"
														src={require("./../../assets/img/icon-plus.png")}
													/>
													<div className="slick-inner-hover">
														<ul className="list-unstyled">
															<li className="">
																<h4>{state.album.name}</h4>
																<h4>{state.album.artist.name}</h4>
																{/* <p>EI Pintor</p> */}
																{/* <p>Matador Records ©2014</p> */}
															</li>
															<li className="">
																<p>Track {index + 1}:</p>
																<p>{track.name}</p>
															</li>
															<li className="">
																<p>Dextras:</p>
																<p>
																	{track.dextra_labels.map((label, idx) => (
																		<div key={idx}>{label.label}</div>
																	))}
																</p>
															</li>
														</ul>
													</div>
												</div>
											)}
										</div>
									</li>
								))}
							</ol>
						</div>
						<div className="bg-orange pd20 largiBox">
							<div className="row text-white">
								<div className="col-2 text-center">
									<img src={require("./../../assets/img/icon-plus.png")} />
								</div>
								<div className="col-8">
									<div className="head">DEXTRAS</div>
									<div className="sub-head">IN THIS DALBUM</div>
									{/* <p>BEhind-the-Scenes Gallery, EPK, Band Bios, Bonus Tracks, Dalbum Credits, Lyrics, Personal Message from Band</p> */}
									<p>
										{state.albumDextra.map(
											(elem, index) =>
												elem.label != "" && <>{elem.label + ", "}</>
										)}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row mb30">
					<div className="col-md-12">
						<div className="artist-tab-heading bg-white">
							<a
								data-toggle="collapse"
								data-target="#collapseAlbum"
								className="text-uppercase ml-10 text-orange"
							>
								DAlbum({state.suggestion.length - 1})
							</a>
							<a href="#" className="pull-right">
								<i className="fa fa-angle-right text-orange" />
							</a>
						</div>
						<div
							id="collapseAlbum"
							className="collapse show artist-tab-content pd15 bg-white"
						>
							<div className="row">
								<div className="col-md-12">
									<div className="h400 overflow-scroll">
										{/* <div className="album-list-flex mb30">
                                                <ul className="list-inline">
                                                    <li>
                                                        <a href="#" className="most-content" style={{ width: '100%', display: 'inline-block' }} tabIndex={-1}>
                                                            <div className="slick-img" style={{ background: 'url(../../assets/img/album-cover-2.png) no-repeat 100%/cover' }} />
                                                            <div className="most-inner pd">
                                                                <h3>Interpol and the Cool Kids</h3>
                                                                <p>The Colleboration</p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="most-content">
                                                            <div className="slick-img" style={{ background: 'url(../../img/album-cover-3.png) no-repeat 100%/cover' }} />
                                                            <div className="most-inner pd">
                                                                <h3>Interpol and the Cool Kids</h3>
                                                                <p>The Colleboration</p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="most-content">
                                                            <div className="slick-img" style={{ background: 'url(../../img/album-cover-2.png) no-repeat 100%/cover' }} />
                                                            <div className="most-inner pd">
                                                                <h3>Interpol and the Cool Kids</h3>
                                                                <p>The Colleboration</p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="most-content">
                                                            <div className="slick-img" style={{ background: 'url(../../img/album-cover.png) no-repeat 100%/cover' }} />
                                                            <div className="most-inner pd">
                                                                <h3>Interpol and the Cool Kids</h3>
                                                                <p>The Colleboration</p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="most-content">
                                                            <div className="slick-img" style={{ background: 'url(../../img/album-cover-4.png) no-repeat 100%/cover' }} />
                                                            <div className="most-inner pd">
                                                                <h3>Interpol and the Cool Kids</h3>
                                                                <p>The Colleboration</p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="most-content">
                                                            <div className="slick-img" style={{ background: 'url(../../img/album-cover-3.png) no-repeat 100%/cover' }} />
                                                            <div className="most-inner pd">
                                                                <h3>Interpol and the Cool Kids</h3>
                                                                <p>The Colleboration</p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="most-content">
                                                            <div className="slick-img" style={{ background: 'url(../../img/album-cover-2.png) no-repeat 100%/cover' }} />
                                                            <div className="most-inner pd">
                                                                <h3>Interpol and the Cool Kids</h3>
                                                                <p>The Colleboration</p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div> */}
										<div className="album-list-flex">
											<div className="business-contect-album">
												<p className="text-orange">
													If you love this artist, you may also like...{" "}
												</p>
											</div>
											<ul className="list-inline business-contect-album-listing">
												{state.suggestion.map(
													(album, index) =>
														album.dalbum_id != state.album.dalbum_id && (
															<li>
																<a
																	onClick={() => {
																		setAlbum(album.dalbum_id);
																	}}
																	className="most-content"
																	style={{
																		width: "100%",
																		display: "inline-block",
																	}}
																	tabIndex={-1}
																>
																	{!album.artwork_images[0] && (
																		<div
																			className="slick-img"
																			style={{
																				background:
																					"url(../../img/album-cover-2.png) no-repeat 100%/cover",
																			}}
																		/>
																	)}
																	{album.artwork_images[0] && (
																		<div
																			className="slick-img"
																			style={{
																				backgroundImage:
																					`url(` +
																					axios.defaults.baseURL +
																					album.artwork_images[0].path +
																					album.artwork_images[0].name +
																					`)`,
																			}}
																		/>
																	)}
																	<div className="most-inner pd">
																		<h3>{album.name}sss</h3>
																		<p>{album.dalbum_record_lbl}</p>
																	</div>
																</a>
															</li>
														)
												)}
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default AlbumPreviewComponent;
