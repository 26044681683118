import {
	Route,
	useNavigate,
	useLocation,
	Outlet,
	createBrowserRouter,
	createRoutesFromElements,
	RouterProvider,
} from "react-router-dom";
import Home from "./views/Home/home.js";
import FanLogin from "./views/login/FanLogin.js";
import ArtistLogin from "./views/login/ArtistLogin.js";
import ArtistRegister from "./views/register/artistRegister.js";
import FanRegister from "./views/register/fanRegister.js";
import Notifications from "react-notify-toast";
import FanForget from "./views/forgetPassword/fanForgetPass.js";
import ArtistForget from "./views/forgetPassword/artistForgetPass.js";
import FanNewPasswordConfirm from "./views/resetPassword/fanResetPassword.js";
import ArtistNewPasswordConfirm from "./views/resetPassword/artistResetPassword.js";
import ArtistDashboard from "./views/dashboard/artistDashboard.js";
import FanDashboard from "./views/dashboard/fanDashboard.js";
import AddArtist from "./views/artistAfterLogin/addArtist.js";
import AddAlbum from "./views/addAlbum/AddAlbum.js";
import AddArtistDetails from "./views/artistAfterLogin/addArtistDetails.js";
import ArtistVerifyConfirm from "./views/register/artistVerify.js";
import FanVerifyConfirm from "./views/register/fanVerify.js";
import EditArtistDetails from "./views/artistAfterLogin/manageArtist.js";
import Dextra from "./views/dextra/Dextra.js";
import PrePublishPreview from "./views/addAlbum/prePublishPreview.js";
import ManageAlbumPrice from "./views/addAlbum/manageAlbumPrice.js";
import TrackDextra from "./views/trackDextra/trackDextra.js";
import ImageShared from "./shared/dextra/imageUpload.js";
import AccountView from "./views/accountDetails/accountView.js";
import EditArtist from "./views/editArtist/editArtist.js";
import About from "./views/about/about.js";
import FinalCheck from "./views/finalCheck/finalCheck.js";
import Checkout from "./views/purchase/checkout.js";
import ManageAlbum from "./views/manageAlbum/manageAlbum.js";
import HowToSell from "./views/howToSell/howToSell.js";
import Dmic from "./views/dmic/dmic.js";

import FAQReadJson from "./views/FAQ/faqReadJson.js";

import PrivacyPolicy from "./views/privacyPolicy/privacyPolicy.js";
import TermsAndConditions from "./views/termsAndCondition/termsAndCondition.js";
import Help from "./views/help/help.js";
import ContactUs from "./views/contactUs/contactUs.js";
import Feedback from "./views/feedback/feedback.js";

import FanSearch from "./views/fan/searchAlbum.js";
import AlbumPreview from "./views/fan/albumPreview.js";
import Cart from "./views/fan/cart.js.js";
import FanContactUs from "./views/contactUs/FancontactUs.js";
import FanFeedback from "./views/feedback/Fanfeedback.js";
import FanDmic from "./views/dmic/Fandmic.js";
import FanAbout from "./views/about/Fanabout.js";
import Wishlist from "./views/fan/Wishlist.js";
import Library from "./views/fan/Library.js";
import FanCheckout from "./views/fan/checkout.js";
import Blog from "./views/blogs/blog.js";
import ManageAccount from "./views/fan/manageAccount.js";
import Gift from "./views/fan/gift.js";
import RedeemCoupon from "./views/fan/redeemCoupon.js";
import AboutCommon from "./views/about/aboutCommon.js";
import PromoteAlbum from "./views/promote/promote.js";
import ArtistPreview from "./views/fan/artistPreview.js";
import Charity from "./views/charity/charity.js";
import Team from "./views/team/team.js";
import Mem1 from "./views/teammember/member1.js";
import Mem2 from "./views/teammember/member2.js";
import Mem3 from "./views/teammember/member3.js";
import Mem4 from "./views/teammember/member4.js";
import Mem5 from "./views/teammember/member5.js";
import Mem6 from "./views/teammember/member6.js";
import Mem7 from "./views/teammember/member7.js";
import Mem8 from "./views/teammember/member8.js";
import Mem9 from "./views/teammember/member9.js";
import ShareDalbum from "./views/share/ShareDalbum.js"

const ArtistAuthRequired = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const token = localStorage.getItem("tokenArtist");
	const Auth = {
		isAuthenticated: token !== "null" && token ? true : false,
	};
	return Auth.isAuthenticated ? (
		<Outlet />
	) : (
		navigate("/artist/login", {
			replace: true,
			state: {
				from: location,
			},
		})
	);
};

const FanAuthRequired = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const token = localStorage.getItem("tokenFan");
	const Auth = {
		isAuthenticated: token !== "null" && token ? true : false,
	};
	return Auth.isAuthenticated ? (
		<Outlet />
	) : (
		navigate("/fan/login", {
			replace: true,
			state: {
				from: location,
			},
		})
	);
};

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route>
			<Route exact path="/" element={<Home />} />
			<Route exact path="/home" element={<Home />} />
			<Route exact path="/home/:message" element={<Home />} />
			<Route exact path="/about" element={<AboutCommon />} />
			{/* <Route exact path="/about" element={About} /> */}
			{/* <Route exact path="/faq" element={FAQ} />s */}
			<Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
			<Route
				exact
				path="/terms-and-condition"
				element={<TermsAndConditions />}
			/>

			{/* Fan Routes  */}

			<Route path="/blog" element={<Blog />} />
			<Route path="/charity" element={<Charity />} />
			<Route path="/team" element={<Team />} />
			<Route path="/teammember" element={<Mem1 />} />
			<Route path="/teammember1" element={<Mem2 />} />
			<Route path="/teammember2" element={<Mem3 />} />
			<Route path="/teammember3" element={<Mem4 />} />
			<Route path="/teammember4" element={<Mem5 />} />
			<Route path="/teammember5" element={<Mem6 />} />
			<Route path="/teammember6" element={<Mem7 />} />
			<Route path="/teammember7" element={<Mem8 />} />
			<Route path="/teammember8" element={<Mem9 />} />

			{/* ==================================================
                    <Route path="/faq" element={FAQ} />
                    */}
			{/* ================================================= */}
			{/* Lalo: December 24, 2020 - Testing for Read Json   */}
			{/* ================================================= */}
			<Route path="/faq" element={<FAQReadJson />} />
			{/* ================================================= */}

			{/* <FanRoute path="/myaccount" component={ManageAccount} /> */}

			{/* Artist Routes  */}
			<Route exact path="/artist/login" element={<ArtistLogin />} />
			<Route path="/artist/login/:message" element={<ArtistLogin />} />
			<Route exact path="/artist/register" element={<ArtistRegister />} />
			<Route
						exact
						path="/artist/forgetpassword"
						element={<ArtistForget />}
					/>
			<Route
				exact
				path="/admin/reset-password/:Id"
				element={<ArtistNewPasswordConfirm />}
			/>
			<Route
				exact
				path="/admin/verify-email/:Id"
				element={<ArtistVerifyConfirm />}
			/>

			<Route path="/artist" element={<ArtistAuthRequired />}>
				{/* <Route path="/artist" element={ArtistValidator} /> */}
				<Route path="dashboard" element={<ArtistDashboard />} />
				<Route path="me" element={<AccountView />} />
				<Route path="addArtist" element={<AddArtist />} />
				<Route path="artistDetails" element={<AddArtistDetails />} />
				<Route path="editArtistDetails" element={<EditArtistDetails />} />
				<Route path="editArtist" element={<EditArtist />} />
				<Route path="addAlbum" element={<AddAlbum />} />
				<Route path="dextra" element={<Dextra />} />
				<Route path="upload" element={<ImageShared />} />
				<Route path="final-check" element={<FinalCheck />} />
				<Route path="pre-publish-preview" element={<PrePublishPreview />} />
				<Route path="managepricing" element={<ManageAlbumPrice />} />
				<Route path="track-dextra" element={<TrackDextra />} />
				<Route exact path="checkout" element={<Checkout />} />
				<Route path="manage-album" element={<ManageAlbum />} />
				<Route path="how-to-sell" element={<HowToSell />} />
				<Route path="dmic" element={<Dmic />} />
				<Route path="about" element={<About />} />
				<Route path="help" element={<Help />} />
				<Route path="contactUs" element={<ContactUs />} />
				<Route path="feedback" element={<Feedback />} />
				<Route path="share" element={<ShareDalbum />} />
				<Route exact path="promote" element={<PromoteAlbum />} />

				{/* <ArtistRoute path="**" element={OOps} /> */}
			</Route>

			{/* Fan Routes  */}
			<Route exact path="/fan/login" element={<FanLogin />} />
			<Route exact path="/fan/login/:message" element={<FanLogin />} />
			<Route exact path="/fan/register" element={<FanRegister />} />
			<Route exact path="/fan/forgetpassword" element={<FanForget />} />
			<Route
				exact
				path="/fan/reset-password/:Id"
				element={<FanNewPasswordConfirm />}
			/>
			<Route
				exact
				path="/fan/verify-email/:Id"
				element={<FanVerifyConfirm />}
			/>

			<Route path="/fan/album-preview/:id" element={<AlbumPreview />} />

			<Route path="/fan" element={<FanAuthRequired />}>
				{/* <Route path="/fan" component={FanValidator} /> */}
				<Route exact path="dashboard" element={<FanDashboard />} />
				<Route exact path="search" element={<FanSearch />} />
				{/* <Route exact path="album-preview" element={AlbumPreview} /> */}
				<Route exact path="mycart" element={<Cart />} />
				<Route exact path="mywishlist" element={<Wishlist />} />
				<Route path="contactUs" element={FanContactUs} />
				<Route path="feedback" element={FanFeedback} />
				<Route path="dmic" element={<FanDmic />} />
				<Route path="about" element={<FanAbout />} />
				<Route path="library" element={<Library />} />
				<Route path="checkout" element={<FanCheckout />} />
				<Route path="myaccount" element={<ManageAccount />} />
				<Route path="gift-album" element={<Gift />} />
				<Route path="redeem-coupon" element={<RedeemCoupon />} />
				<Route exact path="artist-preview" element={<ArtistPreview />} />
				<Route path="artist-preview/:id" element={<ArtistPreview />} />
				<Route exact path="album-preview" element={<AlbumPreview />} />
			</Route>
		</Route>
	)
);

const AppRoutes = () => {
	return (
		<>
			<RouterProvider router={router} />
			<Notifications />
		</>
	);
};
export default AppRoutes;
