import ArtistHeader from "../../shared/ArtistHeader/header";
import FanHeader from "../../shared/FanHeader/header";
import Header from "../../shared/header/header";
import Footer from "../../shared/footer/footer";
import DmicComponent from "../../shared/Dmic/DmicComponent";

const Charity = () => {
	return (
		<div>
			{localStorage.getItem("tokenFan") ? (
				<FanHeader />
			) : localStorage.getItem("tokenArtist") ? (
				<ArtistHeader />
			) : (
				<Header />
			)}
			<DmicComponent />
			<Footer />
		</div>
	);
};

export default Charity;
