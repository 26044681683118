import Header from "../../shared/header/header";
import Footer from "../../shared/footer/footer";
import FanNewPassword from "../../components/fan/fanResetPassword";

const FanNewPasswordConfirm = () => {
	return (
		<div>
			<Header />
			<FanNewPassword />
			<Footer />
		</div>
	);
};

export default FanNewPasswordConfirm;
