import Header from "../../shared/FanHeader/header";
import Footer from "../../shared/footer/footer";
import ArtistPreviewComponent from "../../components/fan/artistPreviewComponent";
import { useEffect } from "react";
import { useLocation } from "react-router";

const ArtistPreview = () => {
	const location = useLocation();

	useEffect(() => {
		if (!location.state) {
			const pathArray = window.location.href.split("/");
			const protocol = pathArray[0];
			const host = pathArray[2];
			const url = protocol + "//" + host;
			window.location = url + "/fan/dashboard";
		}
	}, []);

	return (
		<div>
			<Header />
			<ArtistPreviewComponent />
			<Footer />
		</div>
	);
};

export default ArtistPreview;
