import { useEffect, useState } from "react";
import Header from "../../shared/ArtistHeader/header";
import Footer from "../../shared/footer/footer";
import DextraComponent from "../../components/artist/artistDextraComponent";
import ImageShared from "./../../shared/dextra/imageUpload";
import AudioUploadShared from "../../shared/dextra/audioUpload";
import axios from "axios";
import { notify } from "react-notify-toast";
import TextUploadShared from "../../shared/dextra/textUpload";
import Axios from "axios";
import { withRouter } from "./../../shared/withRouter";
import Loader from "../../shared/loader/loader";
import { useLocation, useNavigate } from "react-router";
import { showNotification } from "../../shared/showNotification";
import { getArtworkUrls } from "../../components/artworkHelper";

const Dextra = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const [state, setState] = useState({
		what: "list",
		artistDetails: {
			dalbum_id: null,
		},
		url: "",
		size: 0,
	});

	useEffect(() => {
		if (!location.state) {
			alert("Url not accessable ");
			navigate("/artist/dashboard");
		}
		getAlbumDetails();
		setState((oldState) => ({
			...oldState,
			size: location.state?.data.total_size,
		}));
	}, []);

	const updateSize = () => {
		setState((oldState) => ({
			...oldState,
			loader: true,
		}));
		let body = { dalbum_id: sessionStorage.getItem("album_id") };
		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};
		axios
			.post("/app/dextra/getLabel", body, config)
			.then(function (response) {
				if (response.data.success) {
					setState((oldState) => ({
						...oldState,
						loader: false,
						size: response.data.data.album.total_size,
					}));
				} else {
					setState((oldState) => ({
						...oldState,
						loader: false,
					}));
					// notify.show(response.data.message, 'error', 1200);
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				setState((oldState) => ({
					...oldState,
					loader: false,
				}));
			});
	};
	const uploadContent = (dextraLabelData) => {
		setState((oldState) => ({
			...oldState,
			what: dextraLabelData.type,
			artistDetails: dextraLabelData,
		}));
	};

	const back = () => {
		if (state.what === "list") {
			window.history.back();
		} else {
			setState((oldState) => ({
				...oldState,
				what: "list",
			}));
		}
	};
	const getAlbumDetails = () => {
		var Data = {
			url:
				Axios.defaults.baseURL +
				location.state?.data.artwork_images[0].path +
				location.state?.data.artwork_images[0].name,
			artwork_name: location.state?.data.artwork_images[0].name,
			name: location.state?.data.name,
			year: location.state?.data.copyright_year,
			record: location.state?.data.dalbum_record_lbl,
			dalbum_id: location.state?.data.dalbum_id,
		};
		setState((oldState) => ({
			...oldState,
			Data: Data,
		}));
		var temp = state.artistDetails;
		temp.dalbum_id = location.state?.data.dalbum_id;
		setState((oldState) => ({
			...oldState,
			artistDetails: temp,
		}));
	};

	return (
		<div>
			{state.loader && <Loader />}
			<Header />
			<section id="content" className="pt50">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							{state.what === "list" && (
								<h1 className="text-center text-uppercase text-green">
									Editing Dalbum 'DEXTRAS'
								</h1>
							)}
							{state.what === 2 && (
								<h1 className="text-center text-uppercase text-green">
									Upload One Image
								</h1>
							)}
							{state.what === 3 && (
								<h1 className="text-center text-uppercase text-green">
									Upload Images
								</h1>
							)}
							{state.what === 1 && (
								<h1 className="text-center text-uppercase text-green">
									Upload Audio
								</h1>
							)}
							{state.what === 4 && (
								<h1 className="text-center text-uppercase text-green">
									Upload Video
								</h1>
							)}
						</div>
					</div>
					<div className="row mb30 justify-content-center">
						<div className="col-md-6">
							<div className="steps-progress-bar">
								<div className="progress" style={{ height: 40 + "px" }}>
									<div
										className="progress-bar"
										role="progressbar"
										style={{
											width:
												(((state.size / (1024 * 1024)) * 100) / 750).toFixed(
													1
												) + "%",
										}}
										aria-valuenow="25"
										aria-valuemin="0"
										aria-valuemax="100"
									>
										{(((state.size / (1024 * 1024)) * 100) / 750).toFixed(1)}%
										of 750 MB has been used
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="row mb30">
						<div className="col-md-12">
							{state.what === "list" && (
								<h2 className="text-justify mb20">
									‘Dextras’ are “Dalbum Extras” - the extra stuff that isn‘t
									part of your Core Dalbum. See suggestions below. You can add
									and delete Dextras at any time. Here, you should upload
									Dextras that pertain to the ENTIRE Album, NOT individual
									tracks (you’ll be able to do that later). If a section is left
									empty, it will simply NOT appear in the Dalbum. Fans can
									access these from the Dalbum Header on their devices.
								</h2>
							)}
							{state.what === 2 && (
								<h2 className="text-justify mb20">
									{" "}
									When a fan opens this image, the one image will appear full
									screen. Make sure the name of the image is correct in the
									window on the right below.
								</h2>
							)}
							{state.what === 3 && (
								<h2 className="text-justify mb20">
									Upload a set of images here. Fans will see a gallary of photos
									on their devices, and them they can scan through them
									individually. Please make sure the name of the gallary is
									correct in the window on the right below.
								</h2>
							)}
							{state.what === 1 && (
								<h2 className="text-justify mb20">
									{" "}
									Please remember that these Audio Dextra pertain to the ENTIRE
									Dalbum, NOT individual tracks (you'll be able to do that
									later).So, for example, this is a great place for BONUS TRACKS
									or other audio files that are related to the OVERALL story of
									yourDalbum. Note: Be discerning whether you want to upload
									uncompressed versions of these,too. If it is a simple audio
									message, maybe the compresses mp3 is enough. But, if it is a
									Bonus track, maybe you do want to give your fans the
									uncompressed version, as well. It's all up to you! Dextra can
									always be added or deleted
								</h2>
							)}
							{state.what === 4 && (
								<h2 className="text-justify mb20">
									It is recommended that you embed a video kink as opposed to
									upload a video file. You can also easily upload a video file,
									but please remember that it will eat into your availabel
									space. Dextra can always be added or deleted.
								</h2>
							)}
							{state.what === 5 && (
								<h2 className="text-justify mb20">
									Why text? Maybe you want to send a quick message to your fans.
									Or maybe you have a special promotion or a clue for them to
									find some tickets in some city bathroom stall(gross, but super
									cool). Or maybe you want to write some lyrics. Whatever you
									want! Remember, Dextra can be added or deleted.
								</h2>
							)}
						</div>
					</div>
					<div className="row mb30">
						<div className="credit-step col-md-8 offset-md-4">
							<div className="media align-items-center">
								<div
									className="profile-img"
									style={{
										backgroundImage: `${state.Data?.artwork_name && getArtworkUrls({
											name: state.Data?.artwork_name,
											square: true,
										})}`,
										backgroundPosition: "center",
										backgroundSize: "cover",
										backgroundRepeat: "no-repeat",
									}}
								></div>
								<div className="media-body ml-10">
									<h5 className="mt-0 text-uppercase">
										{state.Data?.name ? state.Data?.name : "No Data"}
									</h5>
									<h5 className="mt-0 text-uppercase">{state.Data?.singer}</h5>
									<h5 className="mt-0 text-uppercase">{state.Data?.year}</h5>
									<h5 className="mt-0 text-uppercase">{state.Data?.record}</h5>
								</div>
							</div>
						</div>
					</div>

					{state.what === "list" && (
						<DextraComponent
							uploadContent={uploadContent}
							artistDetails={state.artistDetails}
						/>
					)}

					{state.what === 2 && (
						<ImageShared
							uploadContent={updateSize}
							artistDetails={state.artistDetails}
						/>
					)}

					{state.what === 3 && (
						<ImageShared
							uploadContent={updateSize}
							artistDetails={state.artistDetails}
						/>
					)}
					{state.what === 1 && (
						<AudioUploadShared
							uploadContent={updateSize}
							artistDetails={state.artistDetails}
						/>
					)}
					{state.what === 4 && (
						<AudioUploadShared
							uploadContent={updateSize}
							artistDetails={state.artistDetails}
						/>
					)}
					{state.what === 5 && (
						<TextUploadShared
							uploadContent={uploadContent}
							artistDetails={state.artistDetails}
							label={state.setLabel}
						/>
					)}
					{/* {!state.what === 'list' && */}
					<div className="row mb20 justify-content-center">
						<button
							type="button"
							className="btn-cancel text-uppercase"
							onClick={back}
						>
							{state.what === "list" ? "Back to preview" : "Back To Dextras"}
						</button>
					</div>
					<Footer />
				</div>
			</section>
		</div>
	);
};

export default withRouter(Dextra);
