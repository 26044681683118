import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Braintree, HostedField } from "react-braintree-fields";
import { showNotification } from "../../../shared/showNotification";
import { getArtworkUrls } from "../../artworkHelper";

const braintree = require("braintree-web/");
const paypal = require("paypal-checkout");

const CheckOut = ({ albumData }, context) => {
	const navigate = useNavigate();

	const getTokenRef = useRef(null);
	const ccNum = useRef(null);

	const [state, setState] = useState({
		cards: [],
		clientToken: "",
		type: 1,
		amount: 100,
		termsAccepted: false,
		addressLine1: "",
		addressLine2: "",
		town: "",
		postalCode: "",
		state: "",
		zip: "",
	});

	const amount = 100;
	let device_data = undefined;

	useEffect(() => {
		getClientToken();
		// getArtistForDalbum()
		const temp = albumData.artwork_images[0];
		setState((oldState) => ({
			...oldState,
			imagePath: axios.defaults.baseURL + temp.path + temp.name,
		}));
	}, []);

	const acceptTerms = (e) => {
		setState((oldState) => ({
			...oldState,
			termsAccepted: e.target.checked,
		}));
	};

	const handleAddress = (e) => {
		setState((oldState) => ({
			...oldState,
			[e.target.name]: e.target.value,
		}));
	};

	const getArtistForDalbum = async () => {
		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};
		const response = await axios
			.get("app/getartistfordalbum/?dalbum_id=" + sessionStorage.getItem("album_id"), config)

		try {
			if (response.data.success) {
				return response.data.data.artist.artist_id
			} else {
				showNotification(response.data.message, "error");
			}
		} catch(error) {
			showNotification(error.name, "warning");
		}
			// .then(function (response) {
			// 	if (response.data.success) {
			// 		return response.data.data.artist.artist_id
			// 	} else {
			// 		showNotification(response.data.message, "error");
			// 	}
			// })
			// .catch(function (error) {
			// 	showNotification(error.name, "warning");
			// 	//console.log(error);
			// });
	}

	const getClientToken = () => {
		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};

		axios
			.get("app/initialize_payment", config)
			.then(function (response) {
				if (response.data) {
					setState((oldState) => {
						const updatedState = {
							...oldState,
							clientToken: response.data.token,
							amount: response.data.amount,
						};

						if (updatedState.type === 6) {
							forPaypal();
						}

						return updatedState;
					});
				}
			})
			.catch(function (error) {});
	};

	const makePurchase = async (nonce) => {

		const artistId = await getArtistForDalbum()

		const data = {
			adminId: localStorage.getItem("artistid"),
			artistId,
			dalbumId: sessionStorage.getItem("album_id")
		}

		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};

		axios
			.post(
				"app/make_payment",
				{
					nonce: nonce,
					album_id: albumData.dalbum_id,
					address:
						state.addressLine1 +
						" " +
						state.addressLine2 +
						" " +
						state.town +
						" " +
						state.state +
						" " +
						state.zip,
					data
				},
				config
			)
			.then(function (response) {
				if (response.data.success) {
					navigate("/artist/promote", {
						state: {
							data: albumData,
						},
					});
				} else {
					showNotification(response.data.error, "error" )
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const onSubmit = () => {
		if (!state.termsAccepted) {
			showNotification("Please Accept Terms and Conditions", "error");

			return;
		}
		// could also obtain a reference to the Braintree wrapper element and call `.tokenize()`
		getTokenRef
			.current({ cardholderName: "My Order Name" })
			.then((payload) => {
				makePurchase(payload.nonce);
			})
			.catch((err) => {});
	};

	const onCardTypeChange = (cards) => {
		setState((oldState) => ({
			...oldState,
			card: 1 === cards.length ? cards[0].type : "",
		}));
	};

	const onFocus = (event) => {
		//console.log("number is focused", event);
	};

	const onError = (err) => {
		showNotification(err.message, "error");
		console.warn(err);
		ccNum.current.focus(); // focus number field
	};

	const onAuthorizationSuccess = () => {
		setState((oldState) => ({
			...oldState,
			isBraintreeReady: true,
		}));
	};

	const onDataCollectorInstanceReady = (err, dataCollectorInstance) => {
		if (!err) {
			device_data = dataCollectorInstance.deviceData;
		}
	};

	const proceed = () => {
		setState((oldState) => {
			const updatedState = {
				...oldState,
				proceed: state.proceed ? false : true,
			};

			if (updatedState.type && updatedState.proceed) {
				forPaypal();
			}

			return updatedState;
		});
	};

	const changePaymentMethod = (flag) => {
		setState((oldState) => {
			const updatedState = {
				...oldState,
				type: flag,
			};

			if (updatedState.type === 6) {
				forPaypal();
			}

			return updatedState;
		});
	};

	const forPaypal = () => {
		braintree.client.create(
			{
				authorization: state.clientToken,
			},
			function (clientErr, clientInstance) {
				// Stop if there was a problem creating the client.
				// This could happen if there is a network error or if the authorization
				// is invalid.
				if (clientErr) {
					console.error("Error creating client:", clientErr);
					return;
				}

				// Create a PayPal Checkout component.
				braintree.paypalCheckout.create(
					{
						client: clientInstance,
					},
					function (paypalCheckoutErr, paypalCheckoutInstance) {
						// Stop if there was a problem creating PayPal Checkout.
						// This could happen if there was a network error or if it's incorrectly
						// configured.
						if (paypalCheckoutErr) {
							console.error(
								"Error creating PayPal Checkout:",
								paypalCheckoutErr
							);
							return;
						}

						// Set up PayPal with the checkout.js library
						paypal.Button.render(
							{
								// env: 'production', // or 'sandbox'
								env: "sandbox", // or 'sandbox'

								payment: function () {
									return paypalCheckoutInstance.createPayment({
										// Your PayPal options here. For available options, see
										// http://braintree.github.io/braintree-web/current/PayPalCheckout.html#createPayment
										flow: "checkout", // Required
										amount: amount, // Required
										currency: "USD", // Required
										// enableShippingAddress: true,
										// shippingAddressEditable: false,
										// shippingAddressOverride: {
										//     recipientName: 'Scruff McGruff',
										//     line1: '1234 Main St.',
										//     line2: 'Unit 1',
										//     city: 'Chicago',
										//     countryCode: 'US',
										//     postalCode: '60652',
										//     state: 'IL',
										//     phone: '123.456.7890'
										// }
									});
								},

								onAuthorize: function (data, actions) {
									return paypalCheckoutInstance.tokenizePayment(
										data,
										function (err, payload) {
											makePurchase(payload.nonce);
											// alert(payload.nonce)
											// Submit `payload.nonce` to your server.
										}
									);
								},

								onCancel: function (data) {
									//console.log('checkout.js payment cancelled', JSON.stringify(data, 0, 2));
								},

								onError: function (err) {
									console.error("checkout.js error", err);
								},
							},
							"#paypal-button"
						).then(function () {
							// The PayPal button will be rendered in an html element with the id
							// `paypal-button`. This function will be called when the PayPal button
							// is set up and ready to be used.
						});
					}
				);
			}
		);
	};

	return (
		<div className="container">
			<div className="row mb30">
				<div className="col-md-12">
					<h1 className="text-center text-uppercase mb20 text-green">
						Dalbum Publishing Fee
					</h1>
					<h3 className="text-center mb20">
						Please remember that once you publish your CORE Dalbum, it cannot be
						changed (original artwork, main tracks and embedded materials).
						Credits can be added, but not deleted.
						<br />
						<a href="">Why a publishing fee?</a>
					</h3>
				</div>
			</div>

			<div className="row mb20">
				<div className="col-md-12 dalbum-publish-fee-wrapper">
					<div className="publish-box pd15 mb20">
						<div className="flex-publish-list">
							<div className="row align-items-center">
								<div className="col-md-5">
									<div className="title">
										<div className="publishHeading">
											1) Publish this Dalbum:
										</div>
									</div>
								</div>
								<div className="col-md-7">
									<div className="album-preview pd10 bg-white">
										<div className="media align-items-center customMedia">
											<div
												className="img"
												style={{
													width: "200px",
													height: "200px",
													backgroundImage: `${getArtworkUrls({
														name: albumData.artwork_images[0]?.name,
														square: true,
													})}`,
													backgroundPosition: "center",
													backgroundSize: "cover",
													backgroundRepeat: "no-repeat",
												}}
											></div>
											{/* <img className="mr-3" src="img/album-pic.png" alt="image" height="200" width="200" /> */}
											<div className="media-body ml-10">
												<h5 className="mt-0 text-uppercase">
													{albumData.name}
												</h5>
												<h5 className="mt-0 text-uppercase">
													{albumData.copyright_year}
												</h5>
												<h5 className="mt-0 text-uppercase">
													{albumData.dalbum_record_lbl}
												</h5>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* {!state.proceed && <> */}

					<div className="hide publish-box pd15 mb20">
						<div className="flex-publish-list">
							<div className="row">
								<div className="col-md-12">
									<div className="payment-inner">
										<div className="row">
											<div className="col-md-6">
												<div className="payment-heading">
													<div className="publishHeading">
														2) Please select a payment method:
													</div>
												</div>
											</div>
											<div className="col-md-6">
												<div className="payment-image">
													<ul className="list-inline">
														<li className="list-inline-item">
															<label className="payment-choose">
																<img
																	src={require("../../../assets/img/visa.gif")}
																	alt=""
																	className="img-fluid"
																/>
																<input
																	type="radio"
																	name="radio"
																	onChange={() => changePaymentMethod(1)}
																	checked={state.type === 1 ? true : false}
																	className="form-control"
																/>
																<span className="checkmark"></span>
															</label>
														</li>
														<li className="list-inline-item">
															<label className="payment-choose">
																<img
																	src={require("../../../assets/img/master-card.gif")}
																	alt=""
																	className="img-fluid"
																/>
																<input
																	type="radio"
																	name="radio"
																	onChange={() => changePaymentMethod(2)}
																	checked={state.type === 2 ? true : false}
																	className="form-control"
																/>
																<span className="checkmark"></span>
															</label>
														</li>
														<li className="list-inline-item">
															<label className="payment-choose">
																<img
																	src={require("../../../assets/img/maestro-card.gif")}
																	alt=""
																	className="img-fluid"
																/>
																<input
																	type="radio"
																	name="radio"
																	onChange={() => changePaymentMethod(3)}
																	checked={state.type === 3 ? true : false}
																	className="form-control"
																/>
																<span className="checkmark"></span>
															</label>
														</li>
														<li className="list-inline-item">
															<label className="payment-choose">
																<img
																	src={require("../../../assets/img/amarican.gif")}
																	alt=""
																	className="img-fluid"
																/>
																<input
																	type="radio"
																	name="radio"
																	onChange={() => changePaymentMethod(4)}
																	checked={state.type === 4 ? true : false}
																	className="form-control"
																/>
																<span className="checkmark"></span>
															</label>
														</li>
														<li className="list-inline-item">
															<label className="payment-choose">
																<img
																	src={require("../../../assets/img/discover.gif")}
																	alt=""
																	className="img-fluid"
																/>
																<input
																	type="radio"
																	name="radio"
																	onChange={() => changePaymentMethod(5)}
																	checked={state.type === 5 ? true : false}
																	className="form-control"
																/>
																<span className="checkmark"></span>
															</label>
														</li>
														<li className="list-inline-item">
															<label className="payment-choose">
																<img
																	src={require("../../../assets/img/paypal.gif")}
																	alt=""
																	className="img-fluid"
																/>
																<input
																	type="radio"
																	name="radio"
																	onChange={() => changePaymentMethod(6)}
																	checked={state.type === 6 ? true : false}
																	className="form-control"
																/>
																<span className="checkmark"></span>
															</label>
														</li>
													</ul>
												</div>
											</div>
										</div>
										{state.type < 6 && (
											<div className="row">
												<div className="col-md-6">
													<div className="credit-card-heading">
														<div className="publishHeading1">
															Credit Card Details
														</div>
													</div>
													<Braintree
														className={state.isBraintreeReady ? "" : "disabled"}
														authorization={state.clientToken}
														onAuthorizationSuccess={onAuthorizationSuccess}
														onDataCollectorInstanceReady={
															onDataCollectorInstanceReady
														}
														onError={onError}
														onCardTypeChange={onCardTypeChange}
														getTokenRef={(ref) => (getTokenRef.current = ref)}
														styles={{
															input: {
																"font-size": "14px",
																"font-family":
																	"helvetica, tahoma, calibri, sans-serif",
																color: "#3a3a3a",
																"border-style": "solid !important",
																"border- width": "2px !important",
																"border-radius": "10px !important",
																"border-color": "rgb(39, 140, 142)",
															},
															":focus": {
																color: "black",
															},
														}}
													>
														<div className="fields credit-card pd15 bg-white">
															<div className="form-group">
																<div className="card-number">
																	<label htmlFor="usr">
																		Card Number <i className="fa fa-lock"></i>
																	</label>
																	<HostedField
																		id="usr"
																		className="form-control"
																		type="number"
																		onFocus={onFocus}
																		ref={(element) => (ccNum.current = element)}
																	/>
																</div>
															</div>
															<div className="form-group">
																<div className="card-number">
																	<label>Expiration Date</label>
																	<div className="expire-date">
																		<HostedField
																			select
																			placeholder="MM"
																			className="form-control"
																			type="expirationMonth"
																		/>
																		<HostedField
																			select
																			placeholder="YY"
																			className="form-control"
																			type="expirationYear"
																		/>
																	</div>
																</div>
															</div>
															<div className="form-group">
																<div className="card-number cvv">
																	<label htmlFor="cvv">
																		Card Security Code / CVV{" "}
																		<img
																			src={require("../../../assets/img/info.png")}
																			alt=""
																		/>
																	</label>
																	<HostedField
																		id="cvv"
																		className="form-control"
																		type="cvv"
																	/>
																</div>
															</div>
														</div>
														{/* <button onClick={onSubmit}>Submit</button> */}
													</Braintree>
													{/* <div id="paypal-button"></div> */}
												</div>
												<div className="col-md-6">
													<div className="credit-card-heading">
														<div className="publishHeading1">
															Billing Information
														</div>
													</div>
													<form className="credit-card pd15 bg-white">
														<div className="form-group">
															<div className="card-number">
																<label htmlFor="add1">Address Line 1</label>
																<input
																	onChange={handleAddress}
																	name="addressLine1"
																	type="text"
																	className="form-control"
																	id="add1"
																/>
															</div>
														</div>
														<div className="form-group">
															<div className="card-number">
																<label htmlFor="add2">Address Line 2</label>
																<input
																	type="text"
																	onChange={handleAddress}
																	name="addressLine2"
																	className="form-control"
																	id="add2"
																/>
															</div>
														</div>
														<div className="form-group">
															<div className="row">
																<div className="col-md-9">
																	<div className="card-number">
																		<label htmlFor="town">Town/City</label>
																		<input
																			onChange={handleAddress}
																			name="town"
																			type="text"
																			className="form-control"
																			id="town"
																		/>
																	</div>
																</div>
																<div className="col-md-3 lpadding nopadingMobile">
																	<div className="card-number">
																		<label htmlFor="town">State</label>
																		<input
																			onChange={handleAddress}
																			name="state"
																			type="text"
																			className="form-control"
																			id="town"
																		/>
																	</div>
																</div>
															</div>
														</div>
														<div className="form-group">
															<div className="card-number">
																<label htmlFor="zip">ZIP / Postal Code</label>
																<input
																	type="number"
																	onChange={handleAddress}
																	name="zip"
																	className="form-control"
																	id="zip"
																/>
															</div>
														</div>
													</form>
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="publish-box pd15 mb20">
						<div className="flex-publish-list">
							<div className="row align-items-center mb20">
								<div className="col-md-3">
									<div className="publishHeading">3) Referral:</div>
								</div>
								<div className="col-md-9">
									<h3>Were You reffered by another Dalbum Artist?</h3>
								</div>
							</div>
							<div className="row mb20">
								<div className="col-md-8 offset-md-3">
									<div className="row align-items-center mb10">
										<div className="col-md-5">
											<h3 className="text-right">
												If So, add their email here:
											</h3>
										</div>
										<div className="col-md-7">
											<input type="text" className="form-control" name="" />
										</div>
									</div>
									<div className="row align-items-center mb10">
										<div className="col-md-5">
											<h3 className="text-right">Confirm Email:</h3>
										</div>
										<div className="col-md-7">
											<input type="text" className="form-control" name="" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="publish-box pd15 mb20">
						<div className="flex-publish-list">
							<div className="row mb20">
								<div className="col-md-12">
									<div className="publish-heading">
										<div className="publishHeading">
											4) Terms &amp; Conditions:
										</div>
									</div>
								</div>
							</div>
							<div className="row mb20">
								<div className="col-md-12">
									<div className="form-group form-check text-capitalize">
										<input
											type="checkbox"
											onClick={acceptTerms}
											className="form-check-input"
											id="accept"
										/>
										<label className="form-check-label" htmlFor="accept">
											I have read and agree to be bound by the{" "}
											<Link to="/terms-and-condition">
												Terms &amp; Conditions
											</Link>{" "}
											and the <Link to="/privacy-policy">Privacy Policy.</Link>
										</label>
									</div>
									<div className="form-group form-check text-capitalize">
										<input
											type="checkbox"
											className="form-check-input"
											id="newsletter"
										/>
										<label className="form-check-label" htmlFor="newsletter">
											I would like to receive special offers and announcements
											from DalbumX via email.
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="publish-box pd15 mb20">
						<div className="flex-publish-list">
							<div className="row mb20">
								<div className="col-md-12">
									<div className="publish-heading">
										<div className="publishHeading">5) Review Payment:</div>
									</div>
								</div>
								<div className="col-md-12 albumPayAmt">Amount: $500</div>
							</div>
							<div className="row mb20">
								<div className="col-md-8 offset-md-3">
									<div className="row align-items-center mb10">
										<div className="col-md-5">
											<h3 className="text-right">Email Receipt:</h3>
										</div>
										<div className="col-md-7">
											<input type="text" className="form-control" name="" />
										</div>
									</div>
									<div className="row align-items-center mb10">
										<div className="col-md-5">
											<h3 className="text-right">Verify Email:</h3>
										</div>
										<div className="col-md-7">
											<input type="text" className="form-control" name="" />
										</div>
									</div>
								</div>
							</div>
							<div className="row mb20">
								<div className="col-md-12">
									<p>
										Once payment has been received, you will receive 100% of net
										revenue of sales until 100% of your initial investment has
										been recouped. Afterwards, you keep 90% of net revenue.
										Please read our payment info as it will explain all of the
										details and credit card fees that you are also responsible
										for. Also, remember “Referral Revenue”! After you publish a
										Dalbum, if you refer anyone who Publishes their own Dalbum,
										you receive $25 ($50?) from us! (It could really add up!)
									</p>
								</div>
							</div>
						</div>
					</div>
					{/* </>} */}

					{/* {state.proceed && <div className="row mb20">
                            <div className="col-md-12 dalbum-publish-fee-review-wrapper">
                                <div className="publish-box pd30 mb20">
                                    <div className="row mb20">
                                        <div className="col-md-9">
                                            <div className="summary-preview-box">
                                                <ul className="list-unstyled mb0">
                                                    <li className="mb20">
                                                        <div className="left-title">
                                                            <h3>Dalbum:</h3>
                                                        </div>
                                                        <div className="right-title">
                                                            <h3><strong>Interpol -El Pintor</strong></h3>
                                                        </div>
                                                    </li>
                                                    <li className="mb20">
                                                        <div className="left-title">
                                                            <h3>Credit Card:</h3>
                                                        </div>
                                                        <div className="right-title">
                                                            <h3><strong>Visa</strong></h3>
                                                        </div>
                                                    </li>
                                                    <li className="mb20">
                                                        <div className="left-title">
                                                            <h3>Credit Number:</h3>
                                                        </div>
                                                        <div className="right-title">
                                                            <h3><strong>xxxxxxxxxx5647</strong></h3>
                                                        </div>
                                                    </li>
                                                    <li className="mb20">
                                                        <div className="left-title">
                                                            <h3>Exp:</h3>
                                                        </div>
                                                        <div className="right-title">
                                                            <h3><strong>05/17</strong></h3>
                                                        </div>
                                                    </li>
                                                    <li className="mb20">
                                                        <div className="left-title">
                                                            <h3>Billing Address:</h3>
                                                        </div>
                                                        <div className="right-title">
                                                            <h3><strong>575 Main Street <br /> New York,<br /> NY 11212</strong></h3>
                                                        </div>
                                                    </li>
                                                    <li className="mb20">
                                                        <div className="left-title">
                                                            <h3>Reffered By:</h3>
                                                        </div>
                                                        <div className="right-title">
                                                            <h3><strong>musiclover@hotmail.com</strong></h3>
                                                        </div>
                                                    </li>
                                                    <li className="mb20">
                                                        <div className="left-title">
                                                            <h3>Total Price:</h3>
                                                        </div>
                                                        <div className="right-title">
                                                            <h3><strong>$500</strong></h3>
                                                        </div>
                                                    </li>
                                                    <li className="mb20">
                                                        <div className="left-title">
                                                            <h3>Send Receipt to:</h3>
                                                        </div>
                                                        <div className="right-title">
                                                            <h3><strong>interpolinfo@music.com</strong></h3>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="publish-album-preview">
                                                <img src="img/album-pic.png" alt="" title="" height="200" width="200" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb20">
                                        <div className="col-md-12">
                                            <p>Once payment has been received, you will receive 100% of net revenue of sales until 100% of your initial investment has been recouped. Afterwards, you keep 90% of net revenue. Please read our <a href="#">payment info</a> as it will explain all of the details and credit card fees that you are also responsible for. Also, remember “Referral Revenue”! After you publish a Dalbum, if you refer anyone who Publishes their own Dalbum, you receive $25 ($50?) from us! (It could really add up!)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>} */}
				</div>
			</div>

			<div className="row form-group justify-content-center">
				{/* <button type="button" className={(state.proceed ? "btn-cancel" : "btn-save") + " text-uppercase"} onClick={proceed}>{(state.proceed ? "Go Back to Edit" : "Make Payment")}</button> */}
				{state.type < 6 && (
					<button
						type="button"
						className="btn-save text-uppercase"
						onClick={onSubmit}
					>
						Make Payment
					</button>
				)}
				{state.type == 6 && <div id="paypal-button"></div>}
			</div>
		</div>
	);
};

export default CheckOut;
