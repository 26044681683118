import React, { Component, useEffect, useRef, useState } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import validator from "validator";
 import Notifications, { notify } from "react-notify-toast";
import { showNotification } from "../../shared/showNotification";

const RegisterArtist = () => {
	const _reCaptchaRef = useRef(null);

	const [state, setState] = useState({
		Zemail: "",
		email: "",
		password: "",
		Zpassword: "",
		firstName: "",
		lastName: "",
		gender: "",
		language: "",
		language_name: "",
		city: "",
		state: "",
		country: "",
		phone: "",
		primaryRole_name: "",
		primaryRole: "",
		languages: [],
		primaryRoles: [],
		isChecked: false,
		isEmailSame: true,
		isCaptchaVerified: false,
		emailSent: false,
		errors: {},
		flag: process.env.REACT_APP_ENABLE_CAPTCHA !== "true",
	});

	const [validationRequired, setValidationRequired] = useState([]);

	useEffect(() => {
		if (validationRequired) {
			validate(validationRequired)
		}
	 }, [validationRequired]);

	useEffect(() => {
		getLanguage();
		getPrimaryRole();
	}, []);

	const validate = (fields) => {
		var errors = { ...state.errors };

		if (Array.isArray(fields))
			fields.map(
				function (val) {
					errors = _validate(val, errors);
				}.bind(this)
			);
		else errors = _validate(fields, errors);

		setState((oldState) => ({
			...oldState,
			errors: errors,
		}));

		return Object.keys(errors).length;
	};

	const _validate = (field, errors) => {
		if (field == "Zemail") {
			if (!validator.isEmail(state.Zemail)) errors.Zemail = "Invalid email";
			else delete errors.Zemail;
		}

		if (field == "email") {
			if (state.Zemail != state.email)
				errors.email = "Confirm email doesn't match email";
			else delete errors.email;
		}

		if (field == "Zpassword") {
			if (!validator.isLength(state.Zpassword, { min: 6, max: 20 }))
				errors.Zpassword = "Password must be between 6 to 20 characters";
			else delete errors.Zpassword;
		}

		if (field == "password") {
			if (state.Zpassword != state.password)
				errors.password = "Confirm Password doesn't match password";
			else delete errors.password;
		}

		if (field == "firstName") {
			if (!validator.isAlpha(state.firstName)) {
				errors.firstName = "First Name is required";
			} else delete errors.firstName;
		}

		if (field == "lastName") {
			if (!validator.isAlpha(state.lastName)) {
				errors.lastName = "Last Name is required";
			} else delete errors.lastName;
		}

		if (field == "language_name") {
			if (validator.isEmpty(state.language_name)) {
				errors.language_name = "Language required";
			} else delete errors.language_name;
		}

		if (field == "primaryRole_name") {
			if (!validator.isAlphanumeric(state.primaryRole_name.replace(" ", ""))) {
				errors.primaryRole_name = "Primary Role is required";
			} else delete errors.primaryRole_name;
		}

		return errors;
	};

	const onChanges = () => {
		setState((oldState) => ({
			...oldState,
			isCaptchaVerified: true,
		}));
	};

	const onCaptcha = () => {
		setState((oldState) => ({
			...oldState,
			flag: true,
		}));
	};
	const getLanguage = () => {
		axios
			.get("language/?page=1&limit=1000")
			.then(function (response) {
				if (response.data.success) {
					setState((oldState) => ({
						...oldState,
						languages: response.data.data.result,
					}));
				}
			})
			.catch(function (error) {});
	};
	const getPrimaryRole = () => {
		// Lalo: 09/18/2021 - Added filter of role_type from changes made on primary_role
		//                    table as it is used for both, registration and Bus COntacts
		axios
			.get("/primary_role?page=1&limit=2000", { params: { role_type: "P" } })
			.then(function (response) {
				if (response.data.success) {
					setState((oldState) => ({
						...oldState,
						primaryRoles: response.data.data.result,
					}));
				}
			})
			.catch(function (error) {});
	};

	const handleInputChange = (event) => {
		const target = event.target;
		const value =
			target.type === "anchor" ? target.attributes.value.value : target.value;
		const name = target.name;
		setState((oldState) => {
			const updatedState = {
				...oldState,
				[name]: value,
			};
			return updatedState;
		});
		setValidationRequired(target.name);
		if (name === "language" || name === "primaryRole") {
			const names = name === "language" ? "language_name" : "primaryRole_name";
			setState((oldState) => {
				const updatedState = {
					...oldState,
					[names]: target.innerText,
				};
				return updatedState;
			});
			setValidationRequired(names);
		}
	};
	const handleSubmit = (event) => {
		event.preventDefault();
		var errors = 0;
		errors = validate([
			"Zemail",
			"email",
			"password",
			"Zpassword",
			"firstName",
			"lastName",
			"language_name",
			"primaryRole_name",
		]);

		if (errors) return;

		if (!state.flag) {
			showNotification("Please Verify Captcha", "error");
			return false;
		}

		// if (!state.isChecked) {
		//     notify.show("Fields are required", "warning", 2000)
		//     return
		// }
		// if(state.isCaptchaVerified){
		axios
			.post("/auth/admin/register", {
				email: state.email,
				password: state.password,
				firstname: state.firstName,
				lastname: state.lastName,
				gender: state.gender,
				language_id: state.language,
				city: state.city,
				state: state.state,
				country: state.country,
				phone: state.phone,
				primary_role_id: state.primaryRole,
			})
			.then(function (response) {
				if (response.data.success) {
					setState((oldState) => ({
						...oldState,
						emailSent: true,
					}));
					showNotification("Email Sent. Please Verify !", "success");
				} else {
					showNotification(response.data.message, "error");
					setState((oldState) => ({
						...oldState,
						errors: { Zemail: "This email is already registered" },
					}));
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
			});
	};

	return (
		<div>
			{!state.emailSent && (
				<section id="content" className="artist-side-bg flex-wrapper">
					<Notifications options={{ zIndex: 99000 }} />
					<div className="container">
						<div className="row mt20">
							<div className="col-lg-12">
								<h5 className="pull-right require-field mb30">
									[<span className="red">*</span>] Indicates required Field
								</h5>
							</div>
							<div className="col-md-8 mr-auto ml-auto col-sm-12">
								<div className="register-wrapper">
									<div className="register-form">
										<div className="form-group row mblRow">
											<label className="col-lg-4 col-form-label" htmlFor="name">
												<span className="red">*</span>Email:
											</label>
											<div className="col-lg-4 lpadding rpadding">
												<input
													type="email"
													className="form-control"
													id="mail"
													placeholder=""
													name="Zemail"
													onBlur={handleInputChange}
												/>
												{state.errors.Zemail && (
													<span id="error" className="error-text">
														{state.errors.Zemail}{" "}
													</span>
												)}
											</div>
										</div>
										<div className="form-group row mblRow">
											<label className="col-lg-4 col-form-label" htmlFor="name">
												<span className="red">*</span>Confirm Email:
											</label>
											<div className="col-lg-4 lpadding rpadding">
												<input
													type="email"
													className="form-control"
													id="email"
													placeholder=""
													name="email"
													onBlur={handleInputChange}
												/>
												{state.errors.email && (
													<span id="error" className="error-text">
														{state.errors.email}{" "}
													</span>
												)}
											</div>
										</div>
										<div className="form-group row mblRow">
											<label className="col-lg-4 col-form-label" htmlFor="name">
												<span className="red">*</span>Password:
											</label>
											<div className="col-lg-4 lpadding rpadding">
												<input
													type="password"
													className="form-control"
													id=""
													placeholder=""
													name="Zpassword"
													onBlur={handleInputChange}
												/>
												{state.errors.Zpassword && (
													<span id="error" className="error-text">
														{state.errors.Zpassword}{" "}
													</span>
												)}
											</div>
										</div>

										<div className="form-group row mblRow">
											<label className="col-lg-4 col-form-label" htmlFor="name">
												<span className="red">*</span>Confirm Password:
											</label>
											<div className="col-lg-4 lpadding rpadding">
												<input
													type="password"
													className="form-control"
													id=""
													placeholder=""
													name="password"
													onBlur={handleInputChange}
												/>
												{state.errors.password && (
													<span id="error" className="error-text">
														{state.errors.password}{" "}
													</span>
												)}
											</div>
										</div>

										<div className="form-group row mblRow">
											<label className="col-lg-4 col-form-label" htmlFor="name">
												<span className="red">*</span>First Name:
											</label>
											<div className="col-lg-4 lpadding rpadding">
												<input
													type="text"
													className="form-control"
													id=""
													placeholder=""
													name="firstName"
													onBlur={handleInputChange}
												/>
												{state.errors.firstName && (
													<span id="error" className="error-text">
														{state.errors.firstName}
													</span>
												)}
											</div>
										</div>
										<div className="form-group row mblRow">
											<label className="col-lg-4 col-form-label" htmlFor="name">
												<span className="red">*</span>Last Name:
											</label>
											<div className="col-lg-4 lpadding rpadding">
												<input
													type="text"
													className="form-control"
													id=""
													placeholder=""
													name="lastName"
													onBlur={handleInputChange}
												/>
												{state.errors.lastName && (
													<span id="error" className="error-text">
														{state.errors.lastName}
													</span>
												)}
											</div>
										</div>
										{/* <div className="form-group row mblRow"> 
											<label className="col-lg-4 col-form-label" htmlFor="name">
												Gender{" "}
												<i>
													<small>(optional):</small>
												</i>
											</label>
											<div className="col-lg-4 lpadding pt10">
												<div className="d-inline form-group text-center">
													<input
														type="radio"
														className="form-check-input"
														id="isMale"
														name="gender"
														value="male"
														onBlur={handleInputChange}
													/>
													<label className="form-check-label" htmlFor="isMale">
														Male
													</label>
												</div>
												<div className="d-inline form-group form-check text-center">
													<input
														type="radio"
														className="form-check-input"
														id="isFemale"
														name="gender"
														value="female"
														onBlur={handleInputChange}
													/>
													<label
														className="form-check-label"
														htmlFor="isFemale"
													>
														Female
													</label>
												</div>
											</div>
										</div> */}
										<div className="form-group row mblRow">
											<label className="col-lg-4 col-form-label" htmlFor="name">
												<span className="red">*</span>Language:
											</label>
											<div className="input-group select-wrapper col-lg-4 lpadding rpadding">
												<input
													type="dropdown"
													className="form-control"
													aria-label="Text input with dropdown button"
													disabled
													value={state.language_name}
												/>
												<div className="input-group-append">
													<button
														className="btn btn-default btn-md m-0 px-3 py-2 z-depth-0 dropdown-toggle btn-gradient"
														type="button"
														data-toggle="dropdown"
														aria-haspopup="true"
														aria-expanded="false"
													></button>
													<div className="dropdown-menu dropdown-menu-right">
														{state.languages.map((language) => (
															<a
																className="dropdown-item"
																type="anchor"
																onClick={handleInputChange}
																key={language.language_id}
																name="language"
																value={language.language_id}
															>
																{language.name}
															</a>
														))}
													</div>
												</div>
												{state.errors.language_name && (
													<span id="error" className="error-text">
														{state.errors.language_name}{" "}
													</span>
												)}
											</div>
										</div>
										<div className="form-group row mblRow">
											<label className="col-lg-4 col-form-label" htmlFor="name">
												City:
											</label>
											<div className="col-lg-4 lpadding rpadding">
												<input
													type="text"
													className="form-control"
													id=""
													placeholder=""
													name="city"
													onBlur={handleInputChange}
												/>
											</div>
										</div>
										<div className="form-group row mblRow">
											<label className="col-lg-4 col-form-label" htmlFor="name">
												State:
											</label>
											<div className="col-lg-4 lpadding rpadding">
												<input
													type="text"
													className="form-control"
													id=""
													placeholder=""
													name="state"
													onBlur={handleInputChange}
												/>
											</div>
										</div>
										<div className="form-group row mblRow">
											<label className="col-lg-4 col-form-label" htmlFor="name">
												Country:
											</label>
											<div className="col-lg-4 lpadding rpadding">
												<input
													type="text"
													className="form-control"
													id=""
													placeholder=""
													name="country"
													onBlur={handleInputChange}
												/>
											</div>
										</div>
										<div className="form-group row mblRow">
											<label className="col-lg-4 col-form-label" htmlFor="name">
												Phone
											</label>
											<div className="col-lg-4 lpadding rpadding">
												<input
													type="number"
													className="form-control"
													id=""
													placeholder=""
													name="phone"
													onBlur={handleInputChange}
												/>
											</div>
										</div>
										<div className="form-group row mblRow">
											<label className="col-lg-4 col-form-label" htmlFor="name">
												<span className="red">*</span>Primary Role:
											</label>
											<div className="input-group select-wrapper col-lg-4 lpadding rpadding">
												<input
													type="text"
													className="form-control"
													aria-label="Text input with dropdown button"
													name="primaryRole"
													disabled
													value={state.primaryRole_name}
												/>
												<div className="input-group-append">
													<button
														className="btn btn-default btn-md m-0 px-3 py-2 z-depth-0 dropdown-toggle btn-gradient"
														type="button"
														data-toggle="dropdown"
														aria-haspopup="true"
														aria-expanded="false"
													></button>
													<div className="dropdown-menu dropdown-menu-right">
														{state.primaryRoles.map((primaryRole) => (
															<a
																className="dropdown-item"
																type="anchor"
																onClick={handleInputChange}
																key={primaryRole.primary_role_id}
																name="primaryRole"
																value={primaryRole.primary_role_id}
															>
																{primaryRole.name}
															</a>
														))}
													</div>
												</div>
												{state.errors.primaryRole_name && (
													<span id="error" className="error-text">
														{state.errors.primaryRole_name}{" "}
													</span>
												)}
											</div>
										</div>

										<div className="form-group row mblRow">
											<label className="col-lg-4 col-form-label" htmlFor="name">
												Security Verification:
											</label>
											<div className="col-lg-4 lpadding">
												<ReCAPTCHA
													sitekey="6LeqFKwZAAAAAKRDkpF40jLNDqbYX6Ji9119tqIR"
													onChange={(_) => onCaptcha()}
												/>
											</div>
										</div>
										<div className="form-group row mblRow jsutify-content-center pt40">
											<button
												type="submit"
												className="btn btn-gradient btn-sm w200 m-auto pd10"
												onClick={handleSubmit}
											>
												Register
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			)}
			{state.emailSent && (
				<section id="content" className="flex-wrapper">
					<div className="container">
						<div className="row mt20">
							<div className="col-md-9 mr-auto ml-auto">
								<div className="success-msg bg-orange pd15">
									<div className="success-msg-inner bg-green pd15">
										<h2 className="text-white text-uppercase">
											A Message <br /> in a Bottle*
											<br /> has Been sent
											<br /> to Your Mail
										</h2>
										<h3 className="text-white">
											Please click on the link in your message to activate{" "}
											<br /> your account and enjoy your Dalbum experience.
										</h3>
										<p className="text-white text-right">
											*ok, not really a bottle.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			)}
		</div>
	);
};
export default RegisterArtist;
