import { useState } from "react";
import validator from "validator";

const SocialLink = ({ social, findto, onLinkChange }) => {
	const [state, setState] = useState({
		error: {},
		inputErrors: {},
	});

	const handleInput = (e) => {
		const name = "social_link" + e.target.name;
		const INVALID_URL_ERROR = "Please enter valid URL";
		const value = e.target.value;

		if (value === "") {
			setState((oldState) => ({
				...oldState,
				inputErrors: {},
			}));
			return;
		}

		if (!validator.isURL(value)) {
			setState((oldState) => ({
				...oldState,
				inputErrors: { [name]: [INVALID_URL_ERROR] },
			}));
		} else {
			setState((oldState) => ({
				...oldState,
				inputErrors: "",
			}));
		}
	};

	return (
		<>
			<div className="form-group row formInpiutSpace">
				<label className="col-sm-3 col-3 text-white text-right" htmlFor="name">
					<img
						className="media-icon"
						src={require("../../../assets/img/fb-white-ico.png")}
					/>
				</label>
				<input
					type="text"
					className="form-control col-sm-7 col-9"
					name="1"
					id=""
					placeholder="ex: http://www.facebook.com/myband"
					value={findto(1)}
					onChange={(e) => {
						onLinkChange(e, 1);
						handleInput(e);
					}}
				/>
				{state.inputErrors.social_link1 && (
					<div id="email-error" className="form-error-text">
						{state.inputErrors.social_link1}{" "}
					</div>
				)}
			</div>
			<div className="form-group row formInpiutSpace">
				<label className="col-sm-3 col-3 text-white text-right" htmlFor="name">
					<img
						className="media-icon"
						src={require("../../../assets/img/twitter-white-ico.png")}
					/>
				</label>
				<input
					type="text"
					className="form-control col-sm-7 col-9"
					name="2"
					id=""
					placeholder="ex: http://twitter.com/myband"
					value={findto(2)}
					onChange={(e) => {
						onLinkChange(e, 2);
						handleInput(e);
					}}
				/>
				{state.inputErrors.social_link2 && (
					<div id="email-error" className="form-error-text">
						{state.inputErrors.social_link2}{" "}
					</div>
				)}
			</div>

			<div className="form-group row formInpiutSpace">
				<label className="col-sm-3 col-3 text-white text-right" htmlFor="name">
					<img
						className="media-icon"
						src={require("../../../assets/img/instagram-white-ico.png")}
					/>
				</label>
				<input
					type="text"
					className="form-control col-sm-7 col-9"
					name="3"
					id=""
					placeholder="ex: https://www.instagram.com/myband"
					value={findto(3)}
					onChange={(e) => {
						onLinkChange(e, 3);
						handleInput(e);
					}}
				/>
				{state.inputErrors.social_link3 && (
					<div id="email-error" className="form-error-text">
						{state.inputErrors.social_link3}{" "}
					</div>
				)}
			</div>
		</>
	);
};

export default SocialLink;
