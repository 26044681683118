import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useState,
} from "react";
import { useBlocker, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Autosuggestion from "./autosuggestion";
import {
	sortableContainer,
	sortableElement,
	sortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable as arrayMove } from "array-move";
import { confirmAlert } from "react-confirm-alert";
import { showNotification } from "../../../shared/showNotification";

const OopsMenu = ({ close, hh }) => {
	return (
		<div className="hidden-track-delete-msg bg-white pd10">
			<h2 className="text-uppercase mb10">
				Are You Sure You Would
				<br /> Like to Delete This?
			</h2>
			<div className="d-flex mr-3">
				<button
					type="button"
					onClick={close}
					className="btn-save text-uppercase"
				>
					oops, No
				</button>
				<button
					type="button"
					onClick={hh}
					className="btn-cancel text-uppercase"
				>
					Delete
				</button>
			</div>
		</div>
	);
};

// const OopsMenu = ({ close, hh }) => {
//     return (
//         <div className="delete-dextra-popup bg-white pd10 text-center">
//             <h2 className="text-uppercase mb10">Are You Sure You Would<br /> Like to Delete This?</h2>
//             <div className="d-flex justify-content-center">
//                 <button type="button" onClick={close} className="btn-save text-uppercase">oops, No</button>
//                 <button type="button" onClick={hh} className="btn-cancel text-uppercase">Delete</button>
//             </div>
//         </div>
//     )
// }

const SortableContainer = sortableContainer(({ children }) => {
	return <div>{children}</div>;
});

// --------------------------------------------------
// Lalo: 03/22/2021 - trying to disable the icon
// --------------------------------------------------
const DragHandle = sortableHandle(() => (
	<i className="fa fa-bars" aria-hidden="true"></i>
));

const SortableItem = sortableElement(
	({
		musician_no,
		handleInput,
		deleteMusician,
		addInstrument,
		addCategory,
		instruments,
		instrumentCategory,
		musician,
		musician_index,
	}) => (
		<div>
			<div className="menu-icon">
				<DragHandle />
			</div>

			<div className="row mb20">
				{/* Lalo: -3/23/2021 - Commented readOnly  */}
				<div className="col-md-3">
					<input
						type="text"
						name="firstname"
						className="form-control"
						placeholder="First"
						//  readOnly={musician.musician_id ? true : false}
						onChange={(e) => {
							handleInput(e, musician_index);
						}}
						value={musician.firstname}
					/>
					{musician_no > 1 && (
						<label
							onClick={() => {
								deleteMusician(musician_index);
							}}
							className="error"
						>
							{" "}
							Delete Member{" "}
						</label>
					)}
				</div>

				{/*Lalo: -3/23/2021 - commented readOnly */}
				<div className="col-md-3">
					<input
						type="text"
						name="lastname"
						className="form-control"
						placeholder="Last"
						//  readOnly={musician.musician_id ? true : false}
						onChange={(e) => {
							handleInput(e, musician_index);
						}}
						value={musician.lastname}
					/>
				</div>
				<div className="col-md-6 col-12">
					{musician.instrument.map((category, cat_index) => (
						<div className="row">
							<div className="col-md-6">
								<div className="row mb10">
									<div className="col-md-12">
										<select
											value={
												category.instrument_category_id &&
												category.instrument_category_id != "null"
													? category.instrument_category_id
													: "default"
											}
											className="form-control"
											name="MusicianRole"
											onChange={(e) => {
												handleInput(e, musician_index, cat_index);
											}}
										>
											<option disabled selected value="default">
												Select Category
											</option>
											{instrumentCategory.map((elem, index) => (
												<option value={elem.instrument_category_id}>
													{elem.name}
												</option>
											))}
										</select>
										{/* {musician.instrument.length > 1 && <span className="delete" title="Delete Role"><i onClick={() => { deleteInstrument() }} className="fa fa-times colorCustom"></i></span>} */}
									</div>
								</div>
							</div>
							<div className="col-md-6">
								{category.data &&
									category.data.map((inst, inst_idx) => (
										<div className="row mb10">
											<div className="col-md-12">
												<input
													autocomplete="off"
													className="form-control col-sm-12"
													name="credit_role_id"
													onChange={(e) => {
														handleInput(e, musician_index, cat_index, inst_idx);
													}}
													value={
														inst.musician_instrument_id
															? inst.musician_instrument_id
															: ""
													}
													list={"CreditRoles" + cat_index + inst_idx}
													Placeholder="Enter Instrument"
												/>
												<datalist id={"CreditRoles" + cat_index + inst_idx}>
													{instruments.map(
														(elem, index) =>
															elem.instrument_category_id ==
																category.instrument_category_id && (
																<option key={index}>{elem.name}</option>
															)
													)}
												</datalist>
												{/* <select value={inst.musician_instrument_id ? inst.musician_instrument_id : "default"} className="form-control"
                                            name="intrumentsRole"
                                            onChange={(e) => { handleInput(e, musician_index, cat_index, inst_idx) }}
                                        >
                                            <option disabled selected value="default" >Select Instrument</option>
                                            {instruments.map((elem, index) =>
                                                elem.instrument_category_id == category.instrument_category_id &&
                                                <option value={elem.musician_instrument_id}>{elem.name}</option>)}
                                        </select> */}
											</div>
										</div>
									))}
								<div className="col-md-12 mb10">
									<a
										onClick={() => {
											addInstrument(musician_index, cat_index);
										}}
										className="colorCustom"
									>
										<i className="fa fa-plus"></i> Instruments
									</a>
								</div>
							</div>
						</div>
					))}
					<div className="col-md-6 mb10 custom-cat">
						<a
							onClick={() => {
								addCategory(musician_index);
							}}
							className="colorCustom"
						>
							<i className="fa fa-plus"></i> Category
						</a>
					</div>
				</div>
			</div>
		</div>
	)
);

const Step1AddAlbumComponent = forwardRef(({ changestep, musician, confirmation }, ref) => {
	const location = useLocation();
	const navigate = useNavigate();

	const [state, setState] = useState({
		artist: [],
		addNew: 0,
		artistData: {},
		artist_id: null,
		instruments: [],
		stepdata: {},
		artistname: "",
		albumData: {},
		loader: false,
		artistError: "",
		error: [],
		temp_musician: [],
		instrumentCategory: [],
	});

	useEffect(() => {
		if (!sessionStorage.getItem("album_id") && !localStorage.getItem("dont")) {
			document.getElementById("forstep1").click();
		}

		if (sessionStorage.getItem("album_id") !== null) {
			getalbum();
		} else if (location && location.state && location.state.id) {
			setArtist({ artist_id: location.state.id });
		}
		getData();
	}, []);

	useImperativeHandle(ref, () => ({
		save: save,
	}));

	const [stateChanged, setStateChanged] = useState(false);

	useBlocker(() => { 
		if (stateChanged) {
			confirmation(save);
		}
	});

	const getalbum = () => {
		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};

		axios
			.get(
				"app/addalbum/step2?album_id=" + sessionStorage.getItem("album_id"),
				config
			)
			.then(function (response) {
				if (response.data.success) {
					setState((oldState) => {
						const updatedState = {
							...oldState,
							albumData: response.data.data,
						};
						setArtist(updatedState.albumData, 1);
						return updatedState;
					});
					showNotification("Data Loaded", "success");
				} else {
					showNotification("Server Error" + response.data.message, "error");
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
			});
	};

	const onSortEnd = ({ oldIndex, newIndex }) => {
		setState((oldState) => ({
			...oldState,
			temp_musician: arrayMove(oldState.temp_musician, oldIndex, newIndex),
		}));
		setStateChanged(true);
	};

	const next = async () => {
		if (await save()) {
			changestep(2);
		}
	};

	const save = async () => {
		let temp = JSON.parse(JSON.stringify(state.temp_musician));

		for (let [index, elem] of temp.entries()) {
			let instrument = [];
			let musicianFirstName = "";
			let musicianLastName = "";
			let musician = [];

			musicianFirstName = elem.firstname;
			musicianLastName = JSON.stringify(elem.lastname);

			musician.push({
				firstname: musicianFirstName,
				lastname: musicianLastName,
			});

			setState((oldState) => ({
				...oldState,
				[state.artistData.musician.firstname]: musicianFirstName,
			}));
			for (let inst of elem.instrument) {
				for (let value of inst.data) {
					if (value.musician_instrument_id)
						instrument.push({
							musician_instrument_id: value.musician_instrument_id,
							instrument_category_id: inst.instrument_category_id,
						});
				}
			}
			temp[index].instrument = instrument;
			temp[index].musician = musician;
		}
		let temp_data = state.artistData;
		temp_data.musician = temp;
		setState((oldState) => ({
			...oldState,
			artistData: temp_data,
		}));
		if (await handleSubmit()) {
			return true;
		}
	};

	const save1 = async () => {
		let temp = state.artistData.musician;
		let flag;

		if (temp) {
			temp.forEach(function (element, index) {
				if (element.firstname === "" || !element.firstname) {
					showNotification("Please Fill First Name", "error");
					flag = false;
					return;
				}
				element.instrument.forEach(function (elem, idx) {
					if (
						!elem.musician_instrument_id ||
						elem.musician_instrument_id === ""
					) {
						showNotification("Please Fill All Instrument", "error");
						flag = false;
						return;
					}
				});
			});
			if (!flag) {
				return false;
			}
		}
		if (state.stepdata.artist_id) {
			await handleSubmit();
			return true;
		} else {
			showNotification("Please Select Artist", "error");
			setState((oldState) => ({
				...oldState,
				artistError: "Please Select Artist",
			}));
			return false;
		}
	};

	const redirect = (name) => {
		navigate("/artist/addArtist", {
			state: { name: name },
		});
	};
	const getInstruments = () => {
		axios
			.get("/instrument?page=1&limit=210")
			.then(function (response) {
				if (response.data.data.result) {
					setState((oldState) => ({
						...oldState,
						instruments: response.data.data.result,
					}));
				}
			})
			.catch(function (error) {});
	};
	const getInstrumentsCategory = () => {
		axios
			.get("/instrument_category?page=1&limit=210")
			.then(function (response) {
				if (response.data.data.result) {
					setState((oldState) => ({
						...oldState,
						instrumentCategory: response.data.data.result,
					}));
				}
			})
			.catch(function (error) {});
	};

	const dontShowAgain = (e) => {
		localStorage.setItem("dont", e.target.checked);
	};

	const setArtist = (artist, flag) => {
		if (!artist.artist_id) {
			redirect(artist);
			return false;
		}
		getInstrumentsCategory();
		getInstruments();
		var customdata = {
			artist_id: artist.artist_id,
			album_id: state.albumData.dalbum_id ? state.albumData.dalbum_id : null,
		};
		setState((oldState) => ({
			...oldState,
			addNew: 2,
			artist_id: artist.artist_id,
			stepdata: customdata,
			loader: true,
		}));

		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};

		axios
			.get("app/getartist/?artist_id=" + artist.artist_id, config)
			.then(function (response) {
				if (response.data.success) {
					// If it is new dalbum only then set musicians from artist else use musicians from dalbum itself
					if (sessionStorage.getItem("album_id") === null) {
						// Removing musician_id field, so it does not de-link musician from artist but create new for dalbum
						response.data.data.musician.forEach(musician => {
							delete musician.musician_id;
						});
						
						sortIntoCategory(response.data.data?.musician);
					}

					if (flag) {
						// if (!response.data.data.musician) {
						// 	response.data.data.musician = state.artistData.musician;
						// }
					} else {
						setStateChanged(true);
					}
					
					setState((oldState) => {
						const updatedState = {
							...oldState,
							artistData: response.data.data,
							artistname: response.data.data?.artist?.name,
							loader: false,
							artistError: "",
						};

						return updatedState;
					});
					showNotification("Artist Loaded", "success");
				} else {
					showNotification("Server Error" + response.data.message, "error");
					setState((oldState) => ({
						...oldState,
						loader: false,
					}));
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				setState((oldState) => ({
					...oldState,
					loader: false,
				}));
			});
	};

	const getData = () => {
		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};
		setState((oldState) => ({
			...oldState,
			loader: true,
		}));

		axios
			.get(
				"app/addalbum/step1?album_id=" + sessionStorage.getItem("album_id"),
				config
			)
			.then(function (response) {
				if (response.data.success) {
					if (response.data.data.musician.length > 0) {
						let temp = {
							musician: response.data.data.musician,
						};
						setState((oldState) => ({
							...oldState,
							artistData: temp,
						}));
					}
					setState((oldState) => ({
						...oldState,
						loader: false,
						artist: response.data.data.artist,
					}));
					sortIntoCategory(response.data.data?.musician);
					showNotification("Data Loaded", "success");
				} else {
					showNotification("Server Error" + response.data.message, "error");
					setState((oldState) => ({
						...oldState,
						loader: false,
					}));
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				setState((oldState) => ({
					...oldState,
					loader: false,
				}));
			});
	};

	const handleInputChange = (no, event, e) => {
		if (e) {
			var customdata = state.artistData;
			var custmusician = customdata.musician;
			var custinst = custmusician[no].instrument;
			custinst[event].musician_instrument_id = parseInt(e.target.value);
			custmusician[no].instrument = custinst;
			customdata.musician = custmusician;
			setState((oldState) => ({
				...oldState,
				artistData: customdata,
			}));
		} else {
			var name = event.target.name;
			var value = event.target.value;

			var data = state.artistData;
			var musician = data.musician;
			musician[no][name] = value;
			data.musician = musician;
			setState((oldState) => ({
				...oldState,
				artistData: data,
			}));
		}
	};

	const handleSubmit = (event) => {
		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};
		setState((oldState) => ({
			...oldState,
			loader: true,
		}));
		state.artistData.album_id = state.albumData.dalbum_id;

		return axios
			.post("app/addalbum/step1", state.artistData, config)
			.then(function (response) {
				if (response.data.success) {
					showNotification("Dalbum Updated", "success");
					if (response.data.data.dalbum_id) {
						sessionStorage.setItem("album_id", response.data.data.dalbum_id);
					}
					setState((oldState) => ({
						...oldState,
						loader: false,
					}));

					return true;
				} else {
					showNotification("Server Error" + response.data.message, "error");
					setState((oldState) => ({
						...oldState,
						loader: false,
					}));
					return false;
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				setState((oldState) => ({
					...oldState,
					loader: false,
				}));
			});
	};

	const handleBlur = () => {
		if (state.artist_id) {
			setState((oldState) => ({
				...oldState,
				artistError: "",
			}));
		} else {
			setState((oldState) => ({
				...oldState,
				artistError: "Please Select Artist",
			}));
		}
	};

	const handleInput = (e, musi_idx, cat_idx, inst_idx) => {
		let temp = state.temp_musician;
		if (inst_idx || inst_idx === 0) {
			temp[musi_idx].instrument[cat_idx].data[inst_idx].musician_instrument_id =
				e.target.value;
			setState((oldState) => ({
				...oldState,
				temp_musician: temp,
			}));
		} else if (cat_idx != null && cat_idx !== undefined) {
			temp[musi_idx].instrument[cat_idx].instrument_category_id =
				e.target.value;
			temp[musi_idx].instrument[cat_idx].data = [
				{ musician_instrument_id: null },
			];
			setState((oldState) => ({
				...oldState,
				temp_musician: temp,
			}));
		} else {
			temp[musi_idx][e.target.name] = e.target.value;
			setState((oldState) => ({
				...oldState,
				temp_musician: temp,
			}));
		}

		setStateChanged(true);
	};

	const addInstrument = (musi_idx, cat_idx) => {
		let temp = state.temp_musician;
		let temp2 = temp[musi_idx].instrument;
		temp2[cat_idx].data.push({ musician_instrument_id: null });
		temp[musi_idx].instrument = temp2;
		setState((oldState) => ({
			...oldState,
			temp_musician: temp,
		}));
		setStateChanged(true);
	};

	const addCategory = (musi_idx) => {
		let temp = state.temp_musician;
		temp[musi_idx].instrument.push({
			instrument_category_id: null,
			data: [{ musician_instrument_id: null }],
		});
		setState((oldState) => ({
			...oldState,
			temp_musician: temp,
		}));
		setStateChanged(true);
	};

	const addMusician = () => {
		let temp = state.temp_musician;
		temp.push({ firstname: "", lastname: "", instrument: [] });
		setState((oldState) => ({
			...oldState,
			temp_musician: temp,
		}));
		setStateChanged(true);
	};

	const deleteMusician = (idx) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="confirm-ui">
						<div className="delete-prompt-box">
							<h2 className="text-uppercase mb10">
								Are You Sure You Would
								<br /> Like to Delete This?
							</h2>
							<div className="d-flex mr-3 justify-content-center">
								<button
									type="button"
									onClick={onClose}
									className="btn-save text-uppercase"
								>
									oops, No
								</button>
								<button
									type="button"
									onClick={() => {
										let temp = state.temp_musician;
										temp.splice(idx, 1);
										setState((oldState) => ({
											...oldState,
											temp_musician: temp,
											selectedDelete: undefined,
										}));
										setStateChanged(true);
										onClose();
									}}
									className="btn-cancel text-uppercase"
								>
									Delete
								</button>
							</div>
						</div>
					</div>
				);
			},
		});
	};

	const closeOops = () => {
		setState((oldState) => ({
			...oldState,
			selectedDelete: undefined,
		}));
	};

	const openOops = (idx) => {
		setState((oldState) => ({
			...oldState,
			selectedDelete: idx,
		}));
	};

	const sortIntoCategory = (temp_music = musician) => {
		if (!temp_music || temp_music.length === 0) {
			return;
		}
		let temp_musi = temp_music.map(function (item) {
			return Object.assign({}, item);
		});
		for (let [index, elem] of temp_musi.entries()) {
			var result = elem.instrument.reduce(function (r, a) {
				r[a.instrument_category_id] = r[a.instrument_category_id] || [];
				r[a.instrument_category_id].push(a);
				return r;
			}, Object.create(null));
			var arr = [];
			for (let key in result) {
				arr.push(
					Object.assign({ instrument_category_id: key, data: result[key] })
				);
			}
			temp_musi[index].instrument = arr;
		}
		setState((oldState) => ({
			...oldState,
			temp_musician: temp_musi,
		}));
	};

	return (
		<div>
			<button
				style={{ visibility: "hidden" }}
				id="forstep1"
				type="button"
				className="btn btn-primary"
				data-toggle="modal"
				data-target="#popup"
			></button>

			{
				<div
					className="modal fade"
					id="popup"
					data-backdrop="static"
					data-keyboard={true}
				>
					<div className="modal-dialog modal-dialog-scrollable modal-dialog-centered custome-model-full-width">
						<div className="modal-content custome-model text-center">
							{/* Lalo 01/29/2021 - commented out the button with the "x" */}
							<div className="modal-header">
								{/*
                                <button type="button" className="close" data-dismiss="modal">x</button>
*/}
							</div>

							<div className="modal-body din-font">
								<div className="top-head mt20">let's get it started</div>
								<div className="top-head3">7 steps to create your Dalbum</div>
								<div className="content2 mb10">
									The first 7 steps are all about your CORE DALBUM which
									consists of your audio tracks, artwork (your entire booklet or
									poster), embedded materials (yes, actual hidden tracks if you
									want), and credits! Prepare your Dalbum like you would a CD or
									Vinyl because once you publish it, you cannot make changes.
								</div>
								<div className="content2">
									PLEASE NOTE: If the unpublished Dalbum is inactive for more
									than 30 consecutive days, all content within that Dalbum will
									be deleted. You would have to start from scratch. This is to
									avoid an excess of data on our servers and keep costs low for
									everyone. Once your Dalbum is published, you’re good!
								</div>
								<div className="top-head3">
									This is going to be awesome. We hope you and your fans LOVE
									it!
								</div>
							</div>
							{/*
                            <p className="mb-0 show-box">
                                <input onClick={dontShowAgain} type="checkbox" id="checkbox3" className="red-checkbox" />
                                <label htmlFor="checkbox3" className="disable-checkbox enable-checkbox mb-0">
                                    DON'T SHOW AGAIN
                                </label>
                            </p>
*/}
							<div className="form">
								<div className="form-group">
									{/* Lalo: 03/01/2021    <button type="button" className="btn-save" data-dismiss="modal" onClick={() => { setUnderstandAgree()}}>I UNDERSTAND AND AGREE</button> */}
									<button
										type="button"
										className="btn-save"
										data-dismiss="modal"
									>
										I UNDERSTAND AND AGREE
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			}
			{/* {state.loader && <Loader />} */}
			<div className="row mb30">
				<div className="col-md-12">
					<div className="important-text">
						<h2>
							<span className="text-uppercase">important:</span> The information
							below should reflect the status of the artist{" "}
							<span className="text-uppercase">specifically</span> at the time
							this recording is/was originally released. It may or may not
							reflect the current status of the artist.
						</h2>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-md-8 offset-md-1 artist-info-form">
					<div className="form-group row">
						<label
							className="col-sm-4 col-form-label custome-align text-uppercase"
							htmlFor="name"
						>
							<span className="red">*</span>Artist Name:
						</label>
						<div className="custome-margin">
							<Autosuggestion
								onBlur={handleBlur}
								artist={state.artist}
								artistdetail={setArtist}
								artistname={state.artistname}
							></Autosuggestion>
							{state.artistError && (
								<span
									id="error"
									className="form-text error-text firstStepError"
								>
									{state.artistError}
								</span>
							)}
						</div>
					</div>
					
					{state.artistData.artist?.is_band === 1 &&
						<SortableContainer onSortEnd={onSortEnd} useDragHandle>
						{state.temp_musician?.map((musician, index) => (
							<SortableItem
								key={`item-${index}`}
								index={index}
								musician_no={state.temp_musician.length}
								musician_index={index}
								deleteMusician={deleteMusician}
								musician={musician}
								instruments={state.instruments}
								instrumentCategory={state.instrumentCategory}
								addInstrument={addInstrument}
								addCategory={addCategory}
								handleInput={handleInput}
							/>
						))}
					</SortableContainer>}
					{/* {state.selectedDelete != undefined && <OopsMenu hh={deleteMusician} close={closeOops} />} */}
					<div className="col-md-4 custom-lpadding customeorder1">
						<div className="add-role text-left text-uppercase">
							{state.artistData.artist &&
								state.artistData.artist.is_band === 1 && (
									<a
										className="colorCustom"
										onClick={() => {
											addMusician();
										}}
									>
										<i className="fa fa-plus"></i> Members
									</a>
								)}
						</div>
					</div>

					<div className="row form-group justify-content-end mobileCenter">
						<button
							type="button"
							className={"btn-cancel text-uppercase"}
							onClick={() => navigate("/artist/dashboard")}
						>
							cancel
						</button>
						<button
							type="button"
							className={
								(state.artist_id ? "btn-save" : "btn-cancel") +
								" text-uppercase"
							}
							disabled={state.artist_id ? false : true}
							onClick={() => {
								next();
							}}
						>
							Next
						</button>
					</div>
				</div>
			</div>
		</div>
	);
});

export default Step1AddAlbumComponent;
