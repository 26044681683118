import { useEffect, useState } from "react";
import axios from "axios";
import validator from "validator";
import { showNotification } from "../../shared/showNotification";

const ManageAccountComponent = () => {
	const [state, setState] = useState({
		view: "",
		account: {},
		language: [],
		newAccount: {},
		error: {},
	});

	const path = axios.defaults.baseURL;
	const baseURL = axios.defaults.baseURL;

	useEffect(() => {
		getAccount();
		getlanguage();
	}, []);

	const changeView = (view) => {
		setState((oldState) => ({
			...oldState,
			view: view,
		}));
		getAccount();
	};

	const getAccount = () => {
		var config = {
			headers: { Authorization: "Bearer " + localStorage.getItem("tokenFan") },
		};

		axios
			.get("/auth/fan/get_profile", config)
			.then(function (response) {
				//console.log(response.data.data)

				if (response.data.success) {
					showNotification("Added Successfully", "success");
					setState((oldState) => ({
						...oldState,
						account: response.data.data,
					}));
				} else {
					showNotification(response.data.message, "error");
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				//console.log(error);
			});
	};

	const getlanguage = () => {
		axios
			.get("/language")
			.then(function (response) {
				//console.log(response.data.data)

				if (response.data.success) {
					showNotification("Added Successfully", "success");
					setState((oldState) => ({
						...oldState,
						language: response.data.data.result,
					}));
				} else {
					showNotification(response.data.message, "error");
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				//console.log(error);
			});
	};

	const submit = (elem) => {
		if (state.error[elem]) {
			return false;
		}

		var config = {
			headers: { Authorization: "Bearer " + localStorage.getItem("tokenFan") },
		};

		let data = {};

		if (elem == "location") {
			data = {
				element: "location",
				value: [state.account.city, state.account.state, state.account.country],
			};
		} else {
			data = {
				element: elem,
				value: state.account[elem],
			};
		}

		axios
			.post("/auth/fan/update_profile", data, config)
			.then(function (response) {
				//console.log(response.data.data)

				if (response.data.success) {
					showNotification("Added Successfully", "success");
				} else {
					showNotification(response.data.message, "error");
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				//console.log(error);
			});
	};

	const updatepassword = (elem) => {
		if (state.error.password) {
			return false;
		}

		var config = {
			headers: { Authorization: "Bearer " + localStorage.getItem("tokenFan") },
		};

		let data = {
			password: state.account.password,
			newpassword: state.account.newPassword,
		};

		axios
			.post("/auth/fan/update_password", data, config)
			.then(function (response) {
				//console.log(response.data.data)

				if (response.data.success) {
					showNotification("Updated Successfully", "success");
				} else {
					// notify.show(response.data.message, 'error', 1200);
				}
			})
			.catch(function (error) {
				let err = state.error;
				err.password = error.response.data.error;
				setState((oldState) => ({
					...oldState,
					error: err,
				}));
				showNotification(error.name, "warning");
				//console.log(error.response.data.error);
			});
	};

	const validation = (elem) => {
		let err = state.error;

		if (elem === "email") {
			if (!validator.isEmail(state.account.email)) {
				err.email = "Invalid email";
			} else delete err.email;
		}
		if (elem === "confirmPassword") {
			if (state.account.confirmPassword != state.account.newPassword) {
				err.password = "Password Does not Match";
			} else delete err.password;
		}
		setState((oldState) => ({
			...oldState,
			error: err,
		}));
	};

	const handleInput = (e, elem) => {
		let temp = state.account;
		temp[elem] = e.target.value;
		setState((oldState) => ({
			...oldState,
			account: temp,
		}));
		validation(elem);
	};

	return (
		<section id="content" className="pt50">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<h3 className="page-heading text-uppercase">Account Details</h3>
					</div>
				</div>
			</div>
			<article className="content-heading">
				<div className="container">
					<div className="row">
						<div className="col-md-4">
							<h4 className="text-uppercase text-white">Basic Information</h4>
						</div>
						<div className="col-md-8">
							<h3 className="text-uppercase text-white">
								account created on: may 05, 2015
							</h3>
						</div>
					</div>
				</div>
			</article>
			<article className={state.view == "email" ? "article-bg" : ""}>
				<div className="container pt20">
					{!(state.view == "email") && (
						<div className="row border-bottom-gray content-detail mb20 m0">
							<div className="col-md-4 lpadding">
								<p className="text-uppercase text-bold">Email</p>
							</div>
							<div className="col-md-4">
								<p>{state.account.email}</p>
							</div>
							<div className="col-md-4 rpadding">
								<a
									onClick={() => {
										changeView("email");
									}}
									className="pull-right"
								>
									Edit
								</a>
							</div>
						</div>
					)}
					{state.view == "email" && (
						<div className="detail-edit-box border-bottom-gray ">
							<div className="row content-edit-view m0">
								<div className="col-md-4 lpadding">
									<p className="text-uppercase text-bold">Email</p>
								</div>
								<div className="col-md-8">
									{/* <div className="form-group row">
                                            <label className="col-md-4 col-form-label text-uppercase text-right" htmlFor="name">Current:</label>
                                            <input type="email" value={state.account.email} className="form-control col-md-8" id="" placeholder="" />
                                        </div> */}
									<div className="form-group row">
										<label
											className="col-md-4 col-form-label text-uppercase text-right"
											htmlFor="name"
										>
											New:
										</label>
										<input
											onChange={(e) => {
												handleInput(e, "email");
											}}
											value={state.account.email}
											type="email"
											className="form-control col-md-8"
											id=""
											placeholder=""
										/>
										<span className="error">{state.error.email}</span>
									</div>
									{/* <div className="form-group row">
                                            <label className="col-md-4 col-form-label text-uppercase text-right" htmlFor="name">Confirm:</label>
                                            <input type="email" className="form-control  col-md-8" id="" placeholder="" />
                                        </div> */}
								</div>
							</div>
							<div className="row justify-content-center mb20 mblBtn">
								<button
									onClick={() => {
										changeView("");
									}}
									type="button"
									className="btn-cancel text-uppercase"
								>
									Cancel
								</button>
								<button
									onClick={() => {
										submit("email");
									}}
									type="button"
									className="btn-gradient btn-save text-uppercase"
								>
									Confirm Changes
								</button>
							</div>
						</div>
					)}
				</div>
			</article>
			<article className={state.view == "password" ? "article-bg" : ""}>
				<div className="container">
					{!(state.view == "password") && (
						<div className="row border-bottom-gray content-detail mb20 m0">
							<div className="col-md-4 lpadding">
								<p className="text-uppercase text-bold">Password</p>
							</div>
							<div className="col-md-4">
								<p>*********</p>
							</div>
							<div className="col-md-4 rpadding">
								<a
									onClick={() => {
										changeView("password");
									}}
									className="pull-right"
								>
									Edit
								</a>
							</div>
						</div>
					)}
					{state.view == "password" && (
						<div className="detail-edit-box border-bottom-gray">
							<div className="row content-edit-view  m0">
								<div className="col-md-4 lpadding">
									<p className="text-uppercase text-bold">Password</p>
								</div>
								<div className="col-md-8">
									<div className="form-group row">
										<label
											className="col-sm-2 col-form-label text-uppercase text-right"
											htmlFor="name"
										>
											Current:
										</label>
										<input
											type="password"
											onChange={(e) => {
												handleInput(e, "password");
											}}
											className="form-control col-sm-4"
											id=""
											placeholder=""
										/>
									</div>
									<div className="form-group row">
										<label
											className="col-sm-2 col-form-label text-uppercase text-right"
											htmlFor="name"
										>
											New:
										</label>
										<input
											type="password"
											onChange={(e) => {
												handleInput(e, "newPassword");
											}}
											className="form-control col-sm-4"
											id=""
											placeholder=""
										/>
									</div>
									<div className="form-group row">
										<label
											className="col-sm-2 col-form-label text-uppercase text-right"
											htmlFor="name"
										>
											Confirm:
										</label>
										<input
											type="password"
											onChange={(e) => {
												handleInput(e, "confirmPassword");
											}}
											className="form-control col-sm-4"
											id=""
											placeholder=""
										/>
									</div>
									<span className="error">{state.error.password}</span>
								</div>
							</div>
							<div className="row justify-content-center mb20">
								<button
									onClick={() => {
										changeView("");
									}}
									type="button"
									className="btn-cancel text-uppercase"
								>
									Cancel
								</button>
								<button
									onClick={updatepassword}
									type="button"
									className="btn-gradient btn-save text-uppercase"
								>
									Confirm Changes
								</button>
							</div>
						</div>
					)}
				</div>
			</article>
			<article className={state.view == "name" ? "article-bg" : ""}>
				<div className="container">
					{!(state.view == "name") && (
						<div className="row border-bottom-gray content-detail mb20 m0">
							<div className="col-md-4 lpadding">
								<p className="text-uppercase text-bold">Name</p>
							</div>
							<div className="col-md-4">
								<p>{state.account.name}</p>
							</div>
							<div className="col-md-4 rpadding">
								<a
									onClick={() => {
										changeView("name");
									}}
									className="pull-right"
								>
									Edit
								</a>
							</div>
						</div>
					)}
					{state.view === "name" && (
						<div className="detail-edit-box border-bottom-gray">
							<div className="row content-edit-view m0 ">
								<div className="col-md-4 lpadding">
									<p className="text-uppercase text-bold">Name</p>
								</div>
								<div className="col-md-8">
									<div className="form-group row">
										<label
											className="col-sm-2 col-form-label text-uppercase text-right"
											htmlFor="name"
										>
											Name:
										</label>
										<input
											onChange={(e) => {
												handleInput(e, "name");
											}}
											type="email"
											value={state.account.name}
											className="form-control col-sm-4"
											id=""
											placeholder=""
										/>
									</div>
									{/* <div className="form-group row">
                                        <label className="col-sm-2 col-form-label text-uppercase text-right" htmlFor="name">Last:</label>
                                        <input type="email" value="Celi" className="form-control col-sm-4" id="" placeholder="" />
                                    </div> */}
								</div>
							</div>
							<div className="row justify-content-center mb20">
								<button
									onClick={() => {
										changeView("");
									}}
									type="button"
									className="btn-cancel text-uppercase"
								>
									Cancel
								</button>
								<button
									onClick={() => {
										submit("name");
									}}
									type="button"
									className="btn-gradient btn-save text-uppercase"
								>
									Confirm Changes
								</button>
							</div>
						</div>
					)}
				</div>
			</article>
			{/*<article className={state.view == "gender" ? "article-bg" : ""}>
				<div className="container">
					{!(state.view == "gender") && (
						<div className="row border-bottom-gray content-detail mb20 m0">
							<div className="col-md-4 lpadding">
								<p className="text-uppercase text-bold">Gender</p>
							</div>
							<div className="col-md-4">
								<p>{state.account.gender}</p>
							</div>
							<div className="col-md-4 rpadding">
								<a
									onClick={() => {
										changeView("gender");
									}}
									className="pull-right"
								>
									Edit
								</a>
							</div>
						</div>
					)}
					 {state.view == "gender" && ( 
						<div className="detail-edit-box border-bottom-gray">
							<div className="row content-edit-view  m0">
								<div className="col-md-4 lpadding">
									<p className="text-uppercase text-bold">Gender</p>
								</div>
								<div className="col-md-8">
									<div className="form-group row">
										{["male", "female"].map((elem, index) => (
											<div
												key={index}
												className="d-inline form-check form-group text-center"
											>
												<input
													type="radio"
													className="form-check-input"
													id={elem}
													name="gender"
													checked={state.account.gender == elem ? true : false}
													value={elem}
													onChange={(e) => handleInput(e, "gender")}
												/>

												<label className="form-check-label" htmlFor={elem}>
													{elem}
												</label>
											</div>
										))}
										//<div className="d-inline form-group text-center">
                                        //    <input type="checkbox" className="form-check-input" id="Male" />
                                        //    <label className="form-check-label" htmlFor="Male">Male</label>
                                        //</div>
                                        //<div className="d-inline form-group form-check text-center">
                                        //    <input type="checkbox" className="form-check-input" id="Female" />
                                        //    <label className="form-check-label" htmlFor="Female">Female</label>
                                        //</div>
									</div>
								</div>
							</div>
							<div className="row justify-content-center mb20">
								<button
									onClick={() => {
										changeView("");
									}}
									type="button"
									className="btn-cancel text-uppercase"
								>
									Cancel
								</button>
								<button
									onClick={() => {
										submit("gender");
									}}
									type="button"
									className="btn-gradient btn-save text-uppercase"
								>
									Confirm Changes
								</button>
							</div>
						</div>
					)}
				</div>
			</article> */}
			<article className={state.view == "language" ? "article-bg" : ""}>
				<div className="container">
					{!(state.view == "language") && (
						<div className="row border-bottom-gray content-detail mb20 m0">
							<div className="col-md-4 lpadding">
								<p className="text-uppercase text-bold">Language</p>
							</div>
							<div className="col-md-4">
								{state.language.length > 0 && state.account.language_id && (
									<p>
										{
											state.language.find(
												(elem) => elem.language_id == state.account.language_id
											).name
										}
									</p>
								)}
							</div>
							<div className="col-md-4 rpadding">
								<a
									onClick={() => {
										changeView("language");
									}}
									className="pull-right"
								>
									Edit
								</a>
							</div>
						</div>
					)}
					{state.view == "language" && (
						<div className="detail-edit-box border-bottom-gray">
							<div className="row content-edit-view m0 ">
								<div className="col-md-4 lpadding">
									<p className="text-uppercase text-bold">Language</p>
								</div>
								<div className="col-md-8">
									<div className="form-group row">
										<select
											value={state.account.language_id}
											onChange={(e) => {
												handleInput(e, "language_id");
											}}
											className="form-control col-sm-4"
										>
											{/* <option>Se</option> */}
											{state.language.map((elem, index) => (
												<option value={elem.language_id}>{elem.name}</option>
											))}
											{/* <option>French</option> */}
										</select>
									</div>
								</div>
							</div>
							<div className="row justify-content-center mb20">
								<button
									onClick={() => {
										changeView("");
									}}
									type="button"
									className="btn-cancel text-uppercase"
								>
									Cancel
								</button>
								<button
									onClick={() => {
										submit("language_id");
									}}
									type="button"
									className="btn-gradient btn-save text-uppercase"
								>
									Confirm Changes
								</button>
							</div>
						</div>
					)}
				</div>
			</article>
			<article className={state.view == "location" ? "article-bg" : ""}>
				<div className="container">
					{!(state.view == "location") && (
						<div className="row border-bottom-gray content-detail mb20 m0">
							<div className="col-md-4 lpadding">
								<p className="text-uppercase text-bold">Location</p>
							</div>
							<div className="col-md-4">
								<p>
									{state.account.city}, {state.account.state},{" "}
									{state.account.country}
								</p>
							</div>
							<div className="col-md-4 rpadding">
								<a
									onClick={() => {
										changeView("location");
									}}
									className="pull-right"
								>
									Edit
								</a>
							</div>
						</div>
					)}
					{state.view == "location" && (
						<div className="detail-edit-box border-bottom-gray">
							<div className="row content-edit-view  m0">
								<div className="col-md-4 lpadding">
									<p className="text-uppercase text-bold">Location</p>
								</div>
								<div className="col-md-8">
									<div className="form-group row">
										<label
											className="col-sm-2 col-form-label text-uppercase text-right"
											htmlFor="name"
										>
											City:
										</label>
										<input
											onChange={(e) => {
												handleInput(e, "city");
											}}
											type="email"
											value={state.account.city}
											className="form-control col-sm-4"
											id=""
											placeholder=""
										/>
									</div>
									<div className="form-group row">
										<label
											className="col-sm-2 col-form-label text-uppercase text-right"
											htmlFor="name"
										>
											State:
										</label>
										<input
											onChange={(e) => {
												handleInput(e, "state");
											}}
											type="email"
											value={state.account.state}
											className="form-control col-sm-4"
											id=""
											placeholder=""
										/>
									</div>
									<div className="form-group row">
										<label
											className="col-sm-2 col-form-label text-uppercase text-right"
											htmlFor="name"
										>
											Country:
										</label>
										<input
											onChange={(e) => {
												handleInput(e, "country");
											}}
											type="email"
											value={state.account.country}
											className="form-control col-sm-4"
											id=""
											placeholder=""
										/>
									</div>
								</div>
							</div>
							<div className="row justify-content-center mb20">
								<button
									onClick={() => {
										changeView("");
									}}
									type="button"
									className="btn-cancel text-uppercase"
								>
									Cancel
								</button>
								<button
									onClick={() => {
										submit("location");
									}}
									type="button"
									className="btn-gradient btn-save text-uppercase"
								>
									Confirm Changes
								</button>
							</div>
						</div>
					)}
				</div>
			</article>
			{/* <article className={state.view=='phone'?"article-bg":''}>
                    <div className="container">
                        {!(state.view == 'phone') && <div className="row content-detail mb20 m0">
                            <div className="col-md-4 lpadding">
                                <p className="text-uppercase text-bold">Phone</p>
                            </div>
                            <div className="col-md-4">
                                <p>555-555-0000</p>
                            </div>
                            <div className="col-md-4 rpadding">
                                <a onClick={() => { changeView('phone') }} className="pull-right">Edit</a>
                            </div>
                        </div>}
                        {state.view == 'phone' && <div className="detail-edit-box border-bottom-gray">
                            <div className="row content-edit-view  m0">
                                <div className="col-md-4 lpadding">
                                    <p className="text-uppercase text-bold">Phone</p>
                                </div>
                                <div className="col-md-8">
                                    <div className="form-group row">
                                        <input type="email" value="555-555-0000" className="form-control col-sm-4" id="" placeholder="" />
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center mb20">
                                <button type="button" className="btn-cancel text-uppercase">Cancel</button>
                                <button type="button" className="btn-gradient btn-save text-uppercase">Confirm Changes</button>
                            </div>
                        </div>}
                    </div>
                </article> */}
		</section>
	);
};

export default ManageAccountComponent;
