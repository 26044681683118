import axios from "axios";

const getFrontCoverThumbnailUrl = (artworks) => {
    return getFrontCoverThumbnail(artworks)?.urls;
 };

const getFrontCoverThumbnail = (artworks) => {
    const enrichedArtworks = enrichDataPoints(artworks);
    const isFrontCoverAnimated = enrichedArtworks[0].name.endsWith(".gif");

    return isFrontCoverAnimated ? enrichedArtworks.find(art => art.label === 'Front Cover Still') : enrichedArtworks[0];
};

const enrichDataPoints = (artworks) => {

    artworks.sort((a, b) => a.at_position - b.at_position);

    let isFirstArtwork = true;
    let foundGif = true;
    let pageCounter = 1;

    return artworks.map((artwork, index) => {
        const isGif = artwork.name.endsWith(".gif");

        if (isGif && foundGif) {
            foundGif = true;
        }

        if (isFirstArtwork) {
            isFirstArtwork = false;
            artwork.label = "Front Cover";

            if (!isGif) {
                artwork.square = true;
                artwork.editable = true;
            }
        } else if (artworks[0].name.endsWith(".gif") && foundGif && !isGif) {
            artwork.label = "Front Cover Still";
            foundGif = false;
            if (artworks[0].name.endsWith(".gif")) {
                artwork.square = true;
                artwork.editable = true;
            }
        } else if (index === artworks.length - 1) {
            artwork.label = "Back Cover";
            if (!isGif) {
                artwork.square = true;
                artwork.editable = true;
            } else {
                artwork.square = true;
                artwork.editable = false;
            }
        } else {
            artwork.label = `Booklet Page ${pageCounter}`;
            pageCounter++;
            artwork.square = false;
            artwork.editable = false;
        }

        artwork.urls = getArtworkUrls(artwork);

        return artwork;
    });
};

const getCroppedImageName = (imageName) => {
    const [name, extension] = imageName.split(".");
    return `${name}-cropped.${extension}`;
};

const getArtworkUrls = (artwork) => {
    const path = axios.defaults.baseURL + "uploads/";
    const images = [];

    if (artwork.square) {
        const croppedImageName = getCroppedImageName(artwork.name);
        images.push(`url(${path + croppedImageName})`);
    }

    images.push(`url(${path + artwork.name})`);

    return images.join(",");
};

export {
    getFrontCoverThumbnail,
    getFrontCoverThumbnailUrl,
    getArtworkUrls,
};