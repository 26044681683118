import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import validator from "validator";
import { withRouter } from "./../../shared/withRouter";
import { showNotification } from "../../shared/showNotification";

const LoginComponent = (props) => {
	const navigate = useNavigate();
	const { message } = useParams();
	const nameInputRef = useRef(null);
	const [state, setState] = useState({
		email: "",
		password: "",
		isChecked: false,
		validForm: true,
		rememberMe: false,
		serverError: "",
		formHasError: false,
		errors: {},
	});

	const validate = (fields) => {
		var errors = { ...state.errors };

		if (Array.isArray(fields)) {
			fields.map((val) => (errors = _validate(val, errors)));
		} else {
			errors = _validate(fields, errors);
		}

		setState((oldState) => ({
			...oldState,
			errors: errors,
		}));

		return Object.keys(errors).length;
	};

	const _validate = (field, errors) => {
		if (field === "email") {
			if (!validator.isEmail(state.email)) {
				errors.email = "Invalid email";
			} else {
				delete errors.email;
			}
		}

		if (field === "password") {
			if (validator.isEmpty(state.password)) {
				errors.password = "Please specify password";
			} else {
				delete errors.password;
			}
		}
		return errors;
	};

	const handleBlur = (event) => {
		validate(event.target.name);
	};

	const handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;

		setState((oldState) => ({
			...oldState,
			[name]: value,
			serverError: "",
		}));

		if (target.type === "checkbox") {
			localStorage.setItem("rememberMe", value);
		}
	};

	useEffect(() => {
		nameInputRef.current.focus();

		if (message) {
			if (message === "Please Login Again") {
				showNotification(message,"error",2000);
			} else {
				showNotification(message,"success",2000);
			}
		}

		if (!state.rememberMe) {
			localStorage.setItem("rememberMe", state.rememberMe);
		}
		if (localStorage.getItem("tokenArtist")) {
			navigate("/artist/dashboard");
		}
	}, []);

	const handleSubmit = (event) => {
		event.preventDefault();

		var errors = true;
		errors = validate(["email", "password"]);

		if (errors) return;

		setState((oldState) => ({
			...oldState,
			isChecked: true,
		}));

		axios
			.post("auth/admin/login", {
				email: state.email,
				password: state.password,
			})
			.then(function (response) {
				if (response.data.success) {
					showNotification("Login Successfull !","success",2000);
					localStorage.setItem("tokenArtist", response.data.data.token);
					localStorage.setItem("firstName", response.data.data.firstname);
					localStorage.setItem("email", response.data.data.email);
					localStorage.setItem("artistid", response.data.data.admin_id);
					localStorage.removeItem("tokenFan");
					navigate("/artist/dashboard", {
						state: { name: response.data.data.firstname },
					});
				} else {
					showNotification("Either your Email or Password is incorrect.","error",2000);
					setState((oldState) => ({
						...oldState,
						validForm: false,
						serverError: "Either your Email or Password is incorrect",
					}));
				}
			})
			.catch(function (error) {
				setState((oldState) => ({
					...oldState,
					validForm: false,
				}));
				showNotification(error.name,"warning",2000);
			});
	};

	return (
		<div>
			<section id="content" className="padding140 artist-side-bg flex-wrapper">
				{/* {!state.validForm &&
						<span id="error" className="form-text error-text"> {errorMessage} </span>} */}
				<div className="container">
					<div className="row">
						<div className="col-lg-4 col-md-6 mr-auto ml-auto col-sm-8">
							<div className="login-wrapper">
								<form onSubmit={handleSubmit}>
									<div className="form-group">
										<input
											type="text"
											className="form-control"
											id="exampleInputEmail1"
											aria-describedby="emailHelp"
											placeholder="Your email"
											name="email"
											ref={nameInputRef}
											onChange={(e) => handleInputChange(e)}
											onBlur={(e) => handleBlur(e)}
										/>
										{state.errors.email !== "" && (
											<span id="error" className="form-text error-text">
												{state.errors.email}
											</span>
										)}
										{state.serverError !== "" && (
											<span id="error" className="form-text error-text">
												{" "}
												{state.serverError}{" "}
											</span>
										)}
									</div>
									<div className="form-group">
										<input
											type="password"
											className="form-control"
											id="exampleInputPassword1"
											placeholder="Your Password"
											name="password"
											onChange={(e) => handleInputChange(e)}
											onBlur={(e) => handleBlur(e)}
										/>
										{state.errors.password && (
											<span id="error" className="form-text error-text">
												{state.errors.password}{" "}
											</span>
										)}
									</div>

									<div className="form-group">
										<button
											type="submit"
											className="btn btn-gradient btn-block"
										>
											Login
										</button>
									</div>
									<div className="form-group">
										<Link to={"/artist/forgetpassword"}>
											<span>
												<div>Forgot your Password?</div>
											</span>{" "}
										</Link>
									</div>
									<div className="form-group form-check text-center">
										<input
											type="checkbox"
											className="form-check-input"
											name="rememberMe"
											id="RememberMe"
											onClick={handleInputChange}
										/>
										<label className="form-check-label" htmlFor="RememberMe">
											Remember Me
										</label>
									</div>
									<h3 className="text-gray text-center mb10">
										Not Registered?
									</h3>
									<div className="form-group">
										<Link to={"/artist/register"}>
											<button
												type="submit"
												className="btn btn-gradient btn-block"
											>
												Register
											</button>
										</Link>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default withRouter(LoginComponent);
