import Header from "../../shared/FanHeader/header";
import Footer from "../../shared/footer/footer";
import FanSearchComponent from "../../components/fan/fanSearch";
import { useLocation } from "react-router";

const FanSearch = () => {
	const { state } = useLocation();
	return (
		<div>
			<Header />
			<FanSearchComponent type={state ? state : {}} />
			<Footer />
		</div>
	);
};

export default FanSearch;
