import Header from "../../shared/FanHeader/header";
import Footer from "../../shared/footer/footer";
import FeedbackComponent from "../../shared/feedback/feedbackComponent";

const FanFeedback = () => {
	return (
		<div>
			<Header />
			<FeedbackComponent />
			<Footer />
		</div>
	);
};

export default FanFeedback;
