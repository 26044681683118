import Header from "../../shared/ArtistHeader/header";
import Footer from "../../shared/footer/footer";

const Help = () => {
	return (
		<div>
			<Header />
			<section id="content" className="pt40">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="privacy-policy-content">
								<div className="privacy-policy-heading">
									<h1 className="text-green din-font text-uppercase text-center">
										NEED HELP?
									</h1>
								</div>

								<div className="privacy-content">
									<h2 className="mb30">
										<strong>
											Here are a few things we often get asked, maybe one of
											these is the reason why you are contacting us. Check them
											out, your question maybe already answered.
										</strong>
									</h2>
									<h3>What is DalbumX?</h3>
									<p>
										DalbumX is a platform designed to help artists build their
										audiences by distributing free music in exchange for fan
										data (email & postal code).
									</p>
									<div className="spacer100"></div>
									<h3>How did DalbumX start?</h3>
									<p>
										In 2006 one of our founders, singer/songwriter Derek Webb
										gave away one of his albums for free online, asking in
										return for a little information (name, email address, and
										postal code). In three month's time Webb gave away over
										80,000 full downloads of his album and collected valuable
										information for as many new fans. As a direct result, Derek
										saw many sold out shows, increased merchandise and album
										sales, including a curious spike in sales of the very album
										that was given for free. It was the massive success of this
										experiment that inspired Webb, with the help of a few
										trusted friends, to start DalbumX.
									</p>
									<div className="spacer100"></div>
									<h3>How does DalbumX work?</h3>
									<p>
										Artists sign up for free, then upload their music. They can
										then promote their giveaway on DalbumX or embed a custom
										widget on their own website or blog. Fans can download the
										music for free and leave a tip for any amount, as well as
										tell friends on Facebook, Twitter or email. Fans can also
										copy the widget’s code and add it to their own website or
										blog.
									</p>
									<div className="spacer100"></div>
									<h3>What does DalbumX do for artists?</h3>
									<p>
										DalbumX helps artists in a variety of ways. In the short
										term, you’re able to simply and freely distribute your music
										online, and enable your fans to virally promote it. You also
										capture fan information (email, country, postal code) and
										make money from fan’s tips. This leads to the long term
										benefit. By making meaningful connections with your fans,
										you’ll be able to promote additional digital music, physical
										albums and other products. For instance, record a live
										performance and give it away to gather information for your
										fans, or give away your last album as a lead generator to
										promote and sell a new release. Knowing your fans’ postal
										codes means knowing where they live, and having the ability
										to promote your shows and appearances. The more you know
										about your fans the easier it becomes to make your living.
									</p>
									<div className="spacer100"></div>
									<h3>What does DalbumX do for fans?</h3>
									<p>
										DalbumX provides an alternative for people who love free
										music to download great content and directly support the
										authors. As a music fan, you can choose to help in a few
										ways. You can tip the artist, share their music on Facebook
										or Twitter, and email your friends. And you can embed
										DalbumX widgets anywhere you are online.
									</p>
									<div className="spacer100"></div>
									<h3>What is a widget?</h3>
									<p>
										A widget is a simple, fully functional web application that
										is framed into a small box. DalbumX widgets are a great
										connection point for artists and fans. They are amazingly
										functional yet easily portable. You can embed a DalbumX
										widget anywhere online that accepts HTML, including
										websites, blogs, and some social networking sites.
									</p>
									<div className="spacer100"></div>
									<h3>Can anyone sign up at DalbumX?</h3>
									<p>
										If you are an artist and have any recorded music (that you
										have the rights to distribute), you can sign-up at DalbumX
										and create a free account. Simply click on 'Artist
										Sign-up/Login’ on the top right of the front page at
										DalbumX.com
									</p>
									<div className="spacer100"></div>
									<h3>Does DalbumX have a legal statement that I can read?</h3>
									<p>
										You can read DalbumX's legal statements here or by clicking
										'Legal' at the bottom of any page. If you have any
										additional questions you can always get in touch with our
										Support team.
									</p>
									<div className="spacer200"></div>
									<h2 className="text-uppercase mb10">Artist</h2>
									<h3>Why would I give music away for free?</h3>
									<p>
										In the ever-changing music industry, everyone is trying to
										figure out how to make meaningful connections with fans.
										Clearly there are more music fans now than any other time in
										history. But most artists can't find them because they're a
										new kind of music fans.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
};

export default Help;
