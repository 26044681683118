import { useEffect, useState } from "react";
import Header from "../../shared/ArtistHeader/header";
import Footer from "../../shared/footer/footer";
import axios from "axios";
import EditTrackDextra from "../../components/artist/trackDextra/trackDextra";
import TrackDextraList from "../../components/artist/trackDextra/trackList";
import ImageShared from "./../../shared/dextra/imageUpload";
import AudioUploadShared from "../../shared/dextra/audioUpload";
import TextUploadShared from "../../shared/dextra/textUpload";
import { useLocation } from "react-router";
import { getArtworkUrls } from "../../components/artworkHelper";

const TrackDextra = () => {
	const location = useLocation();

	const [state, setState] = useState({
		imagePath: "",
		size: 0,
		screen: 0,
		// type:null,
		elem: {},
		// index:null
	});

	useEffect(() => {
		if (!location.state) {
			const pathArray = window.location.href.split("/");
			const protocol = pathArray[0];
			const host = pathArray[2];
			const url = protocol + "//" + host;
			window.location = url + "/artist/dashboard";
		}

		let temp = location.state.data.artwork_images[0];
		setState((oldState) => ({
			...oldState,
			imagePath: axios.defaults.baseURL + temp.path + temp.name,
			size: location.state.data.total_size,
		}));
	}, []);

	const gotoEdit = (
		type = state.type,
		elem = state.elem,
		index = state.index
	) => {
		setState((oldState) => ({
			...oldState,
			type: type,
			elem: elem,
			index: index,
			screen: 6,
		}));
	};

	const backtoList = () => {
		setState((oldState) => ({
			...oldState,
			screen: 0,
		}));
	};

	const updateSize = () => {
		setState((oldState) => ({
			...oldState,
			loader: true,
		}));
		let body = { dalbum_id: sessionStorage.getItem("album_id") };
		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};
		axios
			.post("/app/dextra/getLabel", body, config)
			.then(function (response) {
				if (response.data.success) {
					setState((oldState) => ({
						...oldState,
						loader: false,
						size: response.data.data.album.total_size,
					}));
				} else {
					setState((oldState) => ({
						...oldState,
						loader: false,
					}));
					// notify.show(response.data.message, 'error', 1200);
				}
			})
			.catch(function (error) {
				// notify.show(error.name, 'warning', 1000);
				setState((oldState) => ({
					...oldState,
					loader: false,
				}));
			});
	};

	const uploadContent = (dextraLabelData, screen) => {
		setState((oldState) => ({
			...oldState,
			screen: dextraLabelData.type,
			artistDetails: dextraLabelData,
		}));
	};

	const goBack = () => {
		window.history.back();
	};

	return (
		<div>
			<Header />
			<section id="content" className="pt50">
				<div className="container">
					<div className="row mb30">
						<div className="col-md-12">
							<h1 className="text-center text-uppercase text-green">
								ADD TRACK DEXTRAS
							</h1>
						</div>
					</div>

					<div className="row mb30 justify-content-center">
						<div className="steps-progress-bar">
							<div className="progress" style={{ height: "40px" }}>
								<div
									className="progress-bar"
									role="progressbar"
									style={{
										width:
											(((state.size / (1024 * 1024)) * 100) / 750).toFixed(1) +
											"%",
									}}
									aria-valuenow="25"
									aria-valuemin="0"
									aria-valuemax="100"
								>
									{(((state.size / (1024 * 1024)) * 100) / 750).toFixed(1)}% of
									750 MB has been used
								</div>
								{/* <div className="progress-bar" role="progressbar" style={{ width: "25%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">20% of 750 MB has been used</div> */}
							</div>
						</div>
					</div>

					<div className="row mb30">
						<div className="col-md-12">
							<h2 className="text-justify mb20">
								Here's where you can upload and manage Dextras that pertain to
								the individual tracks! For example: demo versions, remixes,
								chords, photos, videos, the napkin where you wrote the
								lyrics-whatever you want (as long as it is legal and you own it,
								of course)! If a section is left empty, it will NOT appear in
								the Dalbum. Dextras can always be added or deleted. Fans can
								access these from the track listing on your Dalbum. See some
								suggested Dextras below
							</h2>
						</div>
					</div>

					<div className="row mb30">
						<div className="credit-step col-md-8 offset-md-4">
							<div className="media align-items-center">
								<div
									style={{
										width: "200px",
										height: "200px",
										backgroundImage: `${getArtworkUrls({
											name: location.state.data.artwork_images[0].name,
											square: true,
										})}`,
										backgroundPosition: "center",
										backgroundSize: "cover",
										backgroundRepeat: "no-repeat",
									}}
								></div>
								{/* <img className="mr-3" src="img/album-pic.png" alt="image" height="200" width="200" /> */}
								<div className="media-body ml-10">
									<h5 className="mt-0 text-uppercase">
										{location.state.data.name}
									</h5>
									<h5 className="mt-0 text-uppercase">
										{location.state.data.copyright_year}
									</h5>
									<h5 className="mt-0 text-uppercase">
										{location.state.data.dalbum_record_lbl}
									</h5>
								</div>
							</div>
						</div>
					</div>
					{state.screen === 0 && (
						<TrackDextraList
							uploadContent={uploadContent}
							gotoEdit={gotoEdit}
							albumData={location.state.data}
						/>
					)}
					{state.screen === 6 && (
						<EditTrackDextra
							uploadContent={uploadContent}
							type={state.type}
							index={state.index}
							elem={state.elem}
							backtoList={backtoList}
						/>
					)}
					{state.screen === 2 && (
						<ImageShared
							uploadContent={updateSize}
							artistDetails={state.artistDetails}
						/>
					)}

					{state.screen === 3 && (
						<ImageShared
							uploadContent={updateSize}
							artistDetails={state.artistDetails}
						/>
					)}
					{state.screen === 1 && (
						<AudioUploadShared
							uploadContent={updateSize}
							artistDetails={state.artistDetails}
						/>
					)}
					{state.screen === 4 && (
						<AudioUploadShared
							uploadContent={updateSize}
							artistDetails={state.artistDetails}
						/>
					)}
					{state.screen === 5 && (
						<TextUploadShared
							uploadContent={uploadContent}
							artistDetails={state.artistDetails}
							label={state.setLabel}
						/>
					)}
				</div>
				<div className="row no-margin justify-content-center">
					<button
						type="button"
						className="btn-cancel text-uppercase"
						onClick={() => {
							state.screen === 0
								? goBack()
								: state.screen === 6
								? backtoList()
								: gotoEdit();
						}}
					>
						{state.screen === 0 ? "Back to Preview" : "Back to Track List"}
					</button>
				</div>
			</section>
			<Footer />
		</div>
	);
};

export default TrackDextra;
