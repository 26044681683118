import Header from "../../shared/ArtistHeader/header";
import Footer from "../../shared/footer/footer";

const HowToSell = () => {
	return (
		<div>
			<Header />
			<section id="content">
				<div className="how-sell-bg">
					<div className="container gray-show">
						<div className="row">
							<div className="col-md-12">
								<div className="how-to-sell-content text-center mt-40">
									<h1 className="text-white text-uppercase mb0 din-font dinfont1 howFont">
										How to Sell Your
									</h1>
									<h1 className="text-white text-uppercase din-font mt0 mb0 dalbm">
										Dalbum
									</h1>
								</div>
							</div>
						</div>
						<div className="row mb40">
							<div className="col-md-3"></div>
							<div className="col-md-2"></div>
							<div className="col-md-12 col-lg-4 pr-0">
								<h4 className="text-white">
									The Reality is that most people don’t buy CD’s anymore. And,
									everyone loves Vinyl (we certainly do!), but it caters to a
									minority of the population. Now, you can give ALL your FANS a
									SUPERIOR Experience via your DALBUM!
								</h4>
							</div>
							<div className="col-md-3"></div>
						</div>
					</div>
				</div>

				<div className="sell-outer">
					<div className="row no-margin">
						<div className="col-md-3 no-padding">
							<img src={require("./../../assets/img/s1.png")} />
						</div>
						<div className="col-md-3 no-padding">
							<img src={require("./../../assets/img/s2.png")} />
						</div>
						<div className="col-md-3 no-padding">
							<img src={require("./../../assets/img/s3.png")} />
						</div>
						<div className="col-md-3 no-padding">
							<img src={require("./../../assets/img/s4.png")} />
						</div>
					</div>
				</div>
				<div className="sell-outer">
					<div className="row no-margin">
						<div className="col-md-3 no-padding">
							<img src={require("./../../assets/img/s5.png")} />
						</div>
						<div className="col-md-3 no-padding">
							<img src={require("./../../assets/img/s6.png")} />
						</div>
						<div className="col-md-3 no-padding">
							<img src={require("./../../assets/img/s7.png")} />
						</div>
						<div className="col-md-3 no-padding lastImg-sell">
							<img src={require("./../../assets/img/s8.png")} />
							<div className="img-contents">
								Sell your DALBUM with a link or with a QR Code at a show! Or
								post your URL on your social media sites or in an email. get
								your QR Code or URL on your <span>DALBUM</span> Management Page.
							</div>
						</div>
					</div>
				</div>
				<section className="bg-orange sell-section">
					<div className="container-fluid">
						<div className="row">
							<div className="offset-md-1 no-padding"></div>
							<div className="col-md-6 col-lg-8 sell-order1">
								<div className="charity-content-inner pd-20 m-p0">
									<h2 className="text-white mb20 justify tab-sell">
										While you can give a certain amount of Dalbums for free (to
										promoters, family, friends, club owners, to YOURSELF, etc),
										we STRONGLY encourage you to sell your Dalbums. You DESERVE
										to be paid for all of your immensely hard work! Pick a fair
										price and don’t be ashamed to ask for it! Make your Dextras
										“buzz worthy” to motivate fans to buy your Dalbum. Once
										purchased, they will be notified each time you add a new
										Dextra. Keep the conversation going!
									</h2>
								</div>
							</div>
							<div className="col-md-5 col-sm-12 col-lg-3 text-left sell-order2">
								<h1 className="din-font text-white text-uppercase mt20 dalbm text-center">
									SELL
								</h1>
								<p className="text-center">
									<img src={require("./../../assets/img/doller.png")} />
								</p>
							</div>
						</div>
					</div>
				</section>
			</section>
			<section className="sellbg-3">
				<div className="container">
					<div className="sell-last">
						<div className="row">
							<div className="col-md-8">
								<div className="record-scrore din-font">
									IT's like your very own <br />
									private record store
								</div>
							</div>
							<div className="col-md-4 sell-socil-space">
								<div className="row text-center">
									<div className="col-md-6 col-6">
										<a href="">
											<img
												className="img-responsive"
												src={require("./../../assets/img/app-store.png")}
											/>
										</a>
									</div>
									<div className="col-md-6 col-6">
										<a href="">
											<img
												className="img-responsive"
												src={require("./../../assets/img/google-play.png")}
											/>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Footer />
		</div>
	);
};

export default HowToSell;
