import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { showNotification } from "../../shared/showNotification";

const AlbumPreviewComponent = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const params = useParams();

	const [state, setState] = useState({
		artist: {},
		biz_contact: [],
		musician: [],
		social: [],
	});

	const path = axios.defaults.baseURL;
	useEffect(() => {
		getPrimaryRoles();
		if (params.id) {
			getArtist(params.id);
		} else {
			getArtist();
		}
	}, []);

	const getPrimaryRoles = () => {
		axios
			.get("/primary_role?page=1&limit=20")
			.then(function (response) {
				if (response.data.data.result) {
					setState((oldState) => ({
						...oldState,
						primaryRoles: response.data.data.result,
					}));
				}
			})
			.catch(function (error) {});
	};

	const getArtist = (elem) => {
		//console.log(elem)
		let artistId = elem;
		artistId = artistId
			? artistId
			: location.state
			? location.state.artist_id
			: null;
		var config = {
			headers: { Authorization: "Bearer " + localStorage.getItem("tokenFan") },
			params: { artist_id: artistId },
			// params: { 'artist_id': 1 }
		};
		if (artistId) {
			axios
				.get("/app/fan/getartist", config)
				.then(function (response) {
					//console.log(response.data.data)

					if (response.data.success) {
						// state.artists = response.data.data
						setState((oldState) => ({
							...oldState,
							// album: response.data.data.data.album,
							artist: response.data.data.artist,
							biz_contact: response.data.data.biz_contact,
							musician: response.data.data.musician,
							social: response.data.data.social,
						}));
					} else {
						showNotification(response.data.message, "error");
					}
				})
				.catch(function (error) {
					showNotification(error.name, "warning");
					//console.log(error);
				});
		}
	};

	const addToCart = () => {
		var config = {
			headers: { Authorization: "Bearer " + localStorage.getItem("tokenFan") },
		};

		let data = { album_id: state.album.dalbum_id };
		axios
			.post("/app/fan/addtocart", data, config)
			.then(function (response) {
				//console.log(response.data.data)

				if (response.data.success) {
					showNotification("Added Successfully", "success");
				} else {
					showNotification(response.data.message, "error");
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				//console.log(error);
			});
	};

	const addToWishlist = () => {
		var config = {
			headers: { Authorization: "Bearer " + localStorage.getItem("tokenFan") },
		};

		let data = { album_id: state.album.dalbum_id };
		axios
			.post("/app/fan/addtowishlist", data, config)
			.then(function (response) {
				//console.log(response.data.data)

				if (response.data.success) {
					showNotification("Added Successfully", "success");
				} else {
					showNotification(response.data.message, "error");
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				//console.log(error);
			});
	};

	const setAlbum = (album_id) => {
		// getAlbum(album_id);
	};

	return (
		<section id="content" className="pt50">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="artist-profile-heading">
							<h1 className="text-center text-uppercase text-bold text-green big-text mb20">
								Artist Page
							</h1>
						</div>
					</div>
				</div>
				<div className="row justify-content-center mb30 fullButton">
					{/* <button className="btn-gray text-uppercase" type="button" data-toggle="modal" data-target=".insider-invite">Insider Access</button> */}
					<button
						className="btn-gray text-uppercase"
						type="button"
						onClick={() => navigate(-1)}
					>
						Back
					</button>
					{/* <button
                            className="btn-orange text-uppercase"
                            type="button"

                        >Home</button> */}
				</div>

				<div className="row d-mb-10">
					<div className="col-md-6 mb20">
						<div
							className="artist-profile-pic relative"
							style={{ backgroundImage: "img/dummy-pic.png" }}
						>
							{/* {state.profilePicFile && <img style={{ height: 479 + 'px', width: 539 + 'px', objectFit: 'cover' }} src={URL.createObjectURL(state.profilePicFile)} />} */}

							{state?.artist && state?.artist?.profile_pic_path && (
								<img
									src={axios.defaults.baseURL + state?.artist?.profile_pic_path}
									style={{
										height: 539 + "px",
										width: 100 + "%",
										objectFit: "cover",
									}}
								/>
							)}
						</div>
					</div>
					<div className="col-md-6 mb20">
						<div className="bg-green pd20 height540 artist-bio profile-preview-green overflow-scroll">
							<h3 className="text-white">
								<br />
								<font size="3">{state.artist.bio && state.artist.bio !== 'null' ? state.artist.bio : ''}</font>
							</h3>
						</div>
					</div>
				</div>

				<div className="row mb30 mobile-artist-box1">
					<div className="col-md-6">
						<div className="bg-black content-scroll-780 height780 pd45 mCustomScrollbar">
							<div className="row mb20">
								<div className="col-md-12 text-right">
									<h3 className="text-white big-text mb20">
										{state.artist.name}
									</h3>
									{/* {state.artist.location && <h2 className="text-white mb20">{state.artist.location}</h2>} */}
									{/* {state.artist.record_label && <h2 className="text-white mb20">{state.artist.record_label}</h2>} */}
									{state.artist.location && state.artist.location !== 'null' &&
										!state?.artist?.artist_profile_permissions.some((x) => {
											return x.column_name === "location" && x.is_visible === 0;
										}) && (
											<h2 className="text-white mb40">
												{state.artist.location}
											</h2>
										)}
									{state.artist.record_label && state.artist.record_label !== 'null' &&
										!state?.artist?.artist_profile_permissions.some((x) => {
											return (
												x.column_name === "record_label" && x.is_visible === 0
											);
										}) && (
											<h2 className="text-white mb40">
												{state.artist.record_label}
											</h2>
										)}
									<h2 className="text-white mb40">
										{state.genre && (
											<span>
												{state?.genre?.some(
													(elem) => elem.genre_id === state.artist.genre
												)
													? state.artist.genres.name
													: ""}
											</span>
										)}
										{state.genre &&
											!state?.artist?.artist_profile_permissions.some((x) => {
												return (
													x.column_name === "sub_genre" && x.is_visible == 0
												);
											}) && (
												<span>
													{" "}
													/{" "}
													{state.sub_genre.some(
														(elem) =>
															elem.sub_genre_id === state?.artist.sub_genre
													)
														? state?.artist.subGenres.name
														: ""}
												</span>
											)}
									</h2>
									{/* {state.sub_genre && <h2 className="text-white mb40">{state.sub_genre.find(elem=>elem.sub_genre_id==state.artist.sub_genre).name}</h2>} */}

									{/* <h2 className="text-white mb20"> + {state.sub_genre}</h2> */}

									<h1 className="text-white mb5 text-uppercase">
										{state.artist.is_band ? "Band Members" : "ARTIST:"}
									</h1>
									{state.musician.map((musician) => (
										<div>
											<h2 className="text-white mb5">
												{musician?.firstname} {musician?.lastname}
											</h2>
											<div>
												{/* <h2 className="text-white mb20">{musician.instrument.map((inst, idx) => <>{idx != 0 && '+'} {state.idToName(inst.musician_instrument_id)} </>)}</h2> */}
												<h2 className="text-white mb20">
													{musician.instrument.map((inst, idx) => (
														<>
															{idx != 0 && "+"} {inst.musician_instrument_id}{" "}
														</>
													))}
												</h2>
												{/* <h2 className="text-white mb20">{instrument.name} +</h2> */}
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-6 mobile-artist-box">
						<div className="bg-orange height780 pd15">
							<h3 className="text-white text-uppercase mb10 big-text">
								Connect
							</h3>
							{/* <h2 className="text-white mb20">{state.dalbumUrl}</h2> */}
							<h2>
								{state.artist.website_url && state.artist.website_url !== 'null' && 
									<a
										href={"http://" + state.artist.website_url}
										className="text-white mb20"
									>
										http://{state.artist.website_url}
									</a>
								}
							</h2>
							{state.artist.email && state.artist.email !== 'null' &&
								<h2 className="text-white mb20">Email: {state.artist.email}</h2>
							}
							<h2 className="text-white mb20 text-uppercase">FOLLOW ON:</h2>
							<ul className="list-inline mb0">
								{state.social.map((elem) => (
									<>
										{elem.social_link_id == 1 && (
											<li className="list-inline-item">
												<a href={"http://" + elem.link} target="blank">
													{" "}
													<img
														className="media-icon"
														src={require("../../assets/img/fb-white-ico.png")}
														alt=""
														title=""
													/>
												</a>
											</li>
										)}

										{elem.social_link_id == 2 && (
											<li className="list-inline-item">
												<a href={"http://" + elem.link} target="blank">
													{" "}
													<img
														className="media-icon"
														src={require("../../assets/img/twitter-white-ico.png")}
														alt=""
														title=""
													/>
												</a>
											</li>
										)}

										{elem.social_link_id == 3 && (
											<li className="list-inline-item">
												<a href={"http://" + elem.link} target="blank">
													{" "}
													<img
														className="media-icon"
														src={require("../../assets/img/instagram-white-ico.png")}
														alt=""
														title=""
													/>
												</a>
											</li>
										)}

										{elem.social_link_id == 5 && (
											<li className="list-inline-item">
												<a href={"http://" + elem.link} target="blank">
													{" "}
													<img
														src={require("../../assets/img/snapchat-icon.png")}
														alt=""
														title=""
													/>
												</a>
											</li>
										)}

										{elem.social_link_id == 4 && (
											<li className="list-inline-item">
												<a href={"http://" + elem.link} target="blank">
													{" "}
													<img
														src={require("../../assets/img/tumbler-white-ico.png")}
														alt=""
														title=""
													/>
												</a>
											</li>
										)}
									</>
								))}
							</ul>
						</div>
					</div>
				</div>

				{state.biz_contact.length && (
					<div className="row mb30 mobile-artist-box1">
						<div className="col-md-12">
							<div className="pd20 bg-gray">
								<div className="row mb20">
									<div className="col">
										<h1 className="text-white text-uppercase mb0">
											Business <br /> Contacts
										</h1>
									</div>
								</div>
								{state.biz_contact.length == 0 && (
									<h1 className="text-white text-uppercase mb0">
										{" "}
										No Contacts Available !{" "}
									</h1>
								)}
								{state.biz_contact.map((biz) => (
									<div className="row mb20">
										<div className="col text-right">
											{!biz.artist_profile_permissions.some((x) => {
												return x.column_name == "lastname";
											}) && (
												<h2 className="text-white">
													{state.primaryRoles.find(
														(elem) =>
															elem.primary_role_id == biz.primary_role_id
													)
														? state.primaryRoles.find(
																(elem) =>
																	elem.primary_role_id == biz.primary_role_id
														  ).name
														: ""}{" "}
													{biz.firstname} {biz.lastname}
												</h2>
											)}
											{!biz.artist_profile_permissions.some((x) => {
												return x.column_name == "email";
											}) && <h2 className="text-white">{biz.email}</h2>}
											{!biz.artist_profile_permissions.some((x) => {
												return x.column_name == "website_url";
											}) && (
												<h2 className="text-white">
													<a href={"http://" + biz.website_url} target="_blank">
														{biz.website_url}
													</a>
												</h2>
											)}
											{!biz.artist_profile_permissions.some((x) => {
												return x.column_name == "company";
											}) && <h2 className="text-white"> {biz.company}</h2>}
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				)}
				<div className="row mb30">
					<div className="col-md-12 download-app">
						<ul className="list-inline">
							<li className="list-inline-item">
								<a href="#">
									<img src="img/app-store.png" alt="" title="" />
								</a>
							</li>
							<li className="list-inline-item">
								<a href="#">
									<img src="img/google-play.png" alt="" title="" />
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
	);
};

export default AlbumPreviewComponent;
