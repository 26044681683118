import Header from "../../shared/FanHeader/header";
import Footer from "../../shared/footer/footer";
import CheckoutComponent from "../../components/fan/checkout";
import { useLocation } from "react-router";

const FanCheckout = () => {
	const location = useLocation();

	return (
		<div>
			<Header />
			<CheckoutComponent data={location.state ? location.state.data : {}} />
			<Footer />
		</div>
	);
};

export default FanCheckout;
