import axios from "axios";
import Loader from "../../../shared/loader/loader";
import {
	sortableContainer,
	sortableElement,
	sortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable as arrayMove } from "array-move";
import Sound from "react-sound";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

const DragHandle = sortableHandle(() => (
	<i className="fa fa-bars" aria-hidden="true"></i>
));

const SortableItem = sortableElement(
	({ onchange, elem, idx, ondelete, play, togglePlay }) => (
		<div className="track-list-edit align-items-center mb20">
			<div className="track-item-count">
				<h2 className="din-font">{idx + 1}</h2>
			</div>
			<div className="track-edit-content pd15 light-bg">
				<div className="list-flex align-items-center text-white">
					<div>
						<DragHandle />
					</div>
					<div className="track-name-edit">
						<input
							type="text"
							onChange={(e) => {
								onchange(e, idx, elem);
							}}
							value={elem.name}
							className="form-control"
							placeholder="Lonely Beggar"
						/>
					</div>
					<div className="track-play">
						<i
							onClick={() => {
								togglePlay(idx);
							}}
							className={(play ? "fa-stop" : "fa-play") + " fa"}
						></i>
					</div>
				</div>
			</div>
			<a
				onClick={() => {
					ondelete(elem);
				}}
			>
				<span className="close">
					<i className="fa fa-times"></i>
				</span>
			</a>
		</div>
	)
);

const SortableContainer = sortableContainer(({ children }) => {
	return <div>{children}</div>;
});

const Step5AddAlbumComponent = forwardRef(
	({ updateSize, changestep, album_name }, ref) => {
		const [state, setState] = useState({
			files: [],
			items: [],
			loader: false,
			uploadPercentage: 0,
			playStatus: {},
			playIndex: null,
		});

		let playFlag = "";

		useEffect(() => {
			getstepdata();
		}, []);

		useImperativeHandle(ref, () => ({
			save: save,
		}));

		const onSortEnd = ({ oldIndex, newIndex }) => {
			setState((oldState) => ({
				...oldState,
				items: arrayMove(oldState.items, oldIndex, newIndex),
			}));
		};

		const uploadPreview = (e) => {
			var temp = state.files;
			for (var i = 0; i < e.target.files.length; i++) {
				temp.push(e.target.files[i]);
			}
			setState((oldState) => ({
				...oldState,
				files: temp,
			}));
		};
		const previewDelete = (index) => {
			var temp = state.files;
			temp.splice(index, 1);
			setState((oldState) => ({
				...oldState,
				files: temp,
			}));
		};

		const handleChange = (e, index, elem) => {
			var temp = state.items;
			temp[index].name = e.target.value;
			setState((oldState) => ({
				...oldState,
				items: temp,
			}));
		};

		const sortintoCatagory = (data) => {
			var sorted = data.sort(function (a, b) {
				return a.at_position - b.at_position;
			});
			setState((oldState) => ({
				...oldState,
				items: sorted,
			}));
		};

		const getstepdata = () => {
			setState((oldState) => ({
				...oldState,
				loader: true,
			}));
			var config = {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
				},
			};

			axios
				.get(
					"app/addalbum/step5?album_id=" + sessionStorage.getItem("album_id"),
					config
				)
				.then(function (response) {
					setState((oldState) => ({
						...oldState,
						loader: false,
					}));
					sortintoCatagory(response.data.data);
				})
				.catch(function (error) {
					setState((oldState) => ({
						...oldState,
						loader: false,
					}));
				});
		};

		const upload = () => {
			setState((oldState) => ({
				...oldState,
				loader: true,
			}));
			var config = {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
				},
				onUploadProgress: function (progressEvent) {
					setState((oldState) => ({
						...oldState,
						uploadPercentage: parseInt(
							Math.round((progressEvent.loaded * 100) / progressEvent.total)
						),
					}));
				},
			};

			var formData = new FormData();
			formData.append("album_id", sessionStorage.getItem("album_id"));

			var at_position = state.items.length;
			for (var i = 0; i < state.files.length; i++) {
				formData.append("uncompressed", state.files[i]);
			}

			formData.append("at_position", at_position);

			axios
				.post("app/addalbum/step5", formData, config)
				.then(function (response) {
					setState((oldState) => ({
						...oldState,
						files: [],
						loader: false,
						uploadPercentage: 0,
					}));
					getstepdata();
					updateSize();
					//console.log(response.data)
				})
				.catch(function (error) {
					//console.log(error);
					setState((oldState) => ({
						...oldState,
						loader: false,
					}));
				});
		};

		const save = () => {
			submit();
		};

		const validate = async () => {
			await submit();
			changestep(6);
		};

		const togglePlay = (idx) => {
			let elem = state.items[idx];
			if (state.playIndex == idx) {
				playFlag = playFlag == "PLAYING" ? "STOPPED" : "PLAYING";
				// alert(playFlag)
			} else {
				playFlag = "PLAYING";
			}
			setState((oldState) => ({
				...oldState,
				playStatus: elem,
				playIndex: state.playIndex === idx ? null : idx,
			}));
		};

		const submit = () => {
			setState((oldState) => ({
				...oldState,
				loader: true,
			}));
			var config = {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
				},
			};

			axios
				.post("app/addalbum/step5submit", state.items, config)
				.then(function (response) {
					setState((oldState) => ({
						...oldState,
						loader: false,
					}));
				})
				.catch(function (error) {
					setState((oldState) => ({
						...oldState,
						loader: false,
					}));
				});
		};

		const _delete = (id) => {
			setState((oldState) => ({
				...oldState,
				loader: true,
			}));

			var index = state.items.findIndex((item) => item.track_id == id.track_id);

			state.items.splice(index, 1);

			var config = {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
				},
			};

			axios
				.post(
					"app/addalbum/step5delete",
					{
						id: id,
						data: state.items,
						album_id: sessionStorage.getItem("album_id"),
					},
					config
				)
				.then(function (response) {
					getstepdata();
					updateSize();
					setState((oldState) => ({
						...oldState,
						loader: false,
					}));
				})
				.catch(function (error) {
					setState((oldState) => ({
						...oldState,
						loader: false,
					}));
				});
		};

		const back = () => {
			changestep(4);
		};

		const { items } = state;

		return (
			<div>
				{state.loader && <Loader />}
				<Sound
					url={
						axios.defaults.baseURL +
						state.playStatus.path +
						state.playStatus.filename
					}
					playStatus={Sound.status[playFlag]}
					playFromPosition={0 /* in milliseconds */}
					// onLoading={handleSongLoading}
					// onPlaying={handleSongPlaying}
					// onFinishedPlaying={handleSongFinishedPlaying}
				/>
				<div className="coming-soon-styles">
					<p className="title-coming-soon">COMING SOON</p>
					<p className="coming-soon-subtext">Once this feature is available, you will be able to add your uncompressed tracks, even after the Dalbum has been published. Stay tuned (pun intended).</p>
				</div>
				<div className="row form-group justify-content-center mblBtn button-styles-margin-top" >
					<button
						type="button"
						className="btn-cancel text-uppercase"
						onClick={() => {
							back();
						}}
					>
						Back
					</button>
					<button
						type="button"
						className="btn-cancel text-uppercase"
						onClick={() => {
							validate();
						}}
					>
						Skip this Step
					</button>
					<button
						type="button"
						className="btn-save text-uppercase"
						onClick={() => {
							validate();
						}}
					>
						Next
					</button>
				</div>
			</div>
		);
	}
);

export default Step5AddAlbumComponent;
