import axios from "axios";
import { useEffect, useState } from "react";

const useArtwork = (artworks, defaultHeight, artworksChanged = false, maxWidth = 600) => {
	const [artworksUpdated, setArtworksUpdated] = useState(0);
	const [isEnriched, setIsEnriched] = useState(false);

	const [hasAnimatedFrontCover, setHasAnimatedFrontCover] = useState(false);
	const [hasFrontCoverStill, setHasFrontCoverStill] = useState(false);

    const path = axios.defaults.baseURL + "uploads/";

	const getCroppedImageName = (imageName) => {
		const [name, extension] = imageName.split(".");
		return `${name}-cropped.${extension}`;
	};

	const getArtworkUrls = (artwork) => {
		const images = [];

		if (artwork.square) {
			const croppedImageName = getCroppedImageName(artwork.name);
			images.push(`url(${path + croppedImageName + '?' + Math.random().toString(36).substring(7)})`);
		}

		images.push(`url(${path + artwork.name})`);

		return images.join(",");
	};

	const getImageSize = async (url, fixedHeight) => {
		try {
			const img = new Image();
			img.src = url;
			await img.decode();
			const aspectRatio = img.width / img.height;
			const width = fixedHeight * aspectRatio;
			return { width, height: fixedHeight };
		} catch (error) {
			console.error("Error loading image:", error);
			throw new Error("Failed to load image");
		}
	};

	const getArtworkWidth = async (index) => {
		let width;
		if (artworks[index].square) {
			width = defaultHeight;
		} else {
			try {
				const { width: imageWidth } = await getImageSize(
					path + artworks[index].name,
					defaultHeight
				);
				width = Math.max(imageWidth, defaultHeight);
				width = Math.min(width, maxWidth);
			} catch (error) {
				console.error("Error getting artwork width:", error);
				width = defaultHeight; // fallback to default height
			}
		}

		artworks[index].width = width;
	};

	const enrichDataPoints = () => {
		let isFirstArtwork = true;
		let foundGif = true;
		let pageCounter = 1;

		artworks.map((artwork, index) => {
			const isGif = artwork.name.endsWith(".gif");

			if (isGif && foundGif) {
				foundGif = true;
			}

			if (isFirstArtwork) {
				isFirstArtwork = false;
				artwork.label = "Front Cover";

				if (!isGif) {
					artwork.square = true;
					artwork.editable = true;
                } else {
					setHasAnimatedFrontCover(true);
					artwork.square = true;
					artwork.editable = false;
                }
			} else if (artworks[0].name.endsWith(".gif") && foundGif && !isGif) {
				artwork.label = "Front Cover Still";
				setHasFrontCoverStill(true);
				foundGif = false;
				if (artworks[0].name.endsWith(".gif")) {
					artwork.square = true;
                    artwork.editable = true;
					setHasAnimatedFrontCover(true);
				}
			} else if (index === artworks.length - 1) {
				artwork.label = "Back Cover";
				if (!isGif) {
					artwork.square = true;
					artwork.editable = true;
				} else {
					artwork.square = true;
					artwork.editable = false;
				}
			}  else {
				artwork.label = `Booklet Page ${pageCounter}`;
				pageCounter++;
				artwork.square = false;
				artwork.editable = false;
			}

			artwork.urls = getArtworkUrls(artwork);
			artwork.height = defaultHeight;

			return artwork;
		});
    };

	useEffect(() => {
		
		setHasAnimatedFrontCover(false);
		setHasFrontCoverStill(false);

		let totalArtworks = artworks.length;

		if (totalArtworks > 0) {
			const calculateImageWidths = async () => {
				await Promise.all(
					artworks.map(async (_, index) => await getArtworkWidth(index))
				);
				setArtworksUpdated(prev => prev + 1);
				setIsEnriched(true);
			};
	
			enrichDataPoints();
			calculateImageWidths();
		}
        
    }, [artworks, defaultHeight, artworksChanged]);
    
	return { isEnriched, artworksUpdated, hasAnimatedFrontCover, hasFrontCoverStill };
};

export default useArtwork;
