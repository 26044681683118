import React from "react";
import * as serviceWorker from "./serviceWorker";
import { createRoot } from "react-dom/client";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import { createBrowserHistory } from "history";
import AppRoutes from "./routes";
// const history = createBrowserHistory();

axios.defaults.baseURL = process.env.REACT_APP_MIDDLEWARE_BASE_URL;

axios.interceptors.response.use(
	function (response) {
		if (
			response.data.message === "jwt expired" ||
			response.data.message === "jwt malformed"
		) {
			localStorage.clear();
			window.location = "/home/Please Login Again";
		} else {
			return response;
		}
		// Do something with response data
	},
	function (error) {
		// Do something with response error
		return Promise.reject(error);
	}
);

const rootNode = document.getElementById("root");
const root = createRoot(rootNode);
root.render(<AppRoutes />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
