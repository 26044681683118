import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import {
	EditorState,
	convertToRaw,
	ContentState,
	convertFromHTML,
} from "draft-js";
import axios from "axios";
import { useEffect, useState } from "react";
import { showNotification } from "../showNotification";

const TextUploadShared = ({ artistDetails, isImage }) => {
	const [state, setState] = useState({
		editorState: EditorState.createEmpty(),
		// editorState: EditorState.createWithContent(
		//     ContentState.createFromBlockArray(
		//         convertFromHTML('<p></p>')
		//     )),
		// sampleEditorContent : ContentState.createFromBlockArray(convertFromHTML("<p>dsfsdfsdfsd</p>\n" ) )
		// data: "<p>dsfsdfsdfsd</p>\n"
	});

	useEffect(() => {
		// setDataToState('albumData', artistDetails)
		getText();
	}, []);
	const onEditorStateChange = (editorState) => {
		//console.log(66666666666, editorState)
		setState((oldState) => ({
			...oldState,
			editorState,
			dataToSend: draftToHtml(convertToRaw(editorState.getCurrentContent())),
		}));
	};

	const getText = () => {
		setState((oldState) => ({
			...oldState,
			loader: true,
		}));
		let body = {
			dalbum_id: artistDetails.dalbum_id,
			type: artistDetails.type,
			label_id: artistDetails.dextra_label_id,
		};
		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};
		axios
			.post("/app/dextra/getDextra", body, config)
			.then(function (response) {
				if (response.data.success) {
					setState({
						loader: false,
					});
					if (response.data.data.data[0]) {
						setState((oldState) => ({
							...oldState,
							dataToSend: response.data.data.data[0].text_string,
							editorState: EditorState.createWithContent(
								ContentState.createFromBlockArray(
									convertFromHTML(response.data.data.data[0].text_string)
								)
							),
						}));
					}
				} else {
					showNotification(response.data.message, "error");
					setState((oldState) => ({
						...oldState,
						loader: false,
					}));
				}
			})
			.catch(function (error) {
				//console.log(error)
				// showNotification(error.name, 'warning', 1000);
				setState((oldState) => ({
					...oldState,
					loader: false,
				}));
			});
	};
	const upload = (event) => {
		setState((oldState) => ({
			...oldState,
			loader: true,
		}));
		// let body = { "dalbum_id": state.dalbumId }
		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};
		let body = {
			dalbum_id: artistDetails.dalbum_id,
			type: artistDetails.type,
			dextra_label_id: artistDetails.dextra_label_id,
			text: state.dataToSend,
			label_name: artistDetails.label,
		};
		axios
			.post("/app/dextra/saveDextra", body, config)
			.then(function (response) {
				if (response.data.success) {
					// setState({ items : state.files })
					// setState({ albumData: response.data.data.labels })
					showNotification("Uploaded", "success");
					setState((oldState) => ({
						...oldState,
						loader: false,
					}));
				} else {
					showNotification(response.data.message, "error");
					setState((oldState) => ({
						...oldState,
						loader: false,
					}));
				}
			})
			.catch(function (error) {
				setState((oldState) => ({
					...oldState,
					loader: false,
				}));
				// showNotification(error.name, 'warning', 1000);
			});
	};

	const { editorState } = state;
	return (
		<section>
			<div className="row mb30 upload-wrapper">
				<div className="col-lg-8 uploadtrackLeft">
					<div className="card">
						{/* <div className="card-header">
                                <div className="row align-items-center">

                                </div>
                            </div> */}
						<div className="card-body bg-white pd30 overflow-scroll h350">
							<div className="upload-wrapper-conent">
								<div className="row mb10">
									<div className="col-md-12">
										<Editor
											editorState={state.editorState}
											toolbar={{
												options: ["inline", "list", "emoji"],
											}}
											toolbarClassName="toolbarClassName"
											wrapperClassName="wrapperClassName"
											editorClassName="editorClassName"
											onEditorStateChange={onEditorStateChange}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-lg-4 tabStep4">
					<div className="upload-content-detail pd20 bg-white clearfix uploadTract">
						{isImage && (
							<h2 className="text-uppercase din-font mb10">
								Upload 1 Image To:
							</h2>
						)}

						{!isImage && (
							<h2 className="text-uppercase din-font mb10">
								Upload Images To:
							</h2>
						)}
						<div className="form-group">
							<input
								type="text"
								name=""
								disabled
								value={artistDetails.label}
								className="form-control"
								placeholder=""
							/>
						</div>
						<div className="album-specification">
							<h3 className="text-uppercase">Specifications</h3>
							<h4>
								Text will appear on your Dalbum as you see it displayed here
							</h4>
							{/* <div className="spacer20"></div>
                                <h4>METADATA can be included <br />with ISRC numbers.</h4>
                                <div className="spacer20"></div>
                                <h4>For more information on ISRC <br />numbers, visit:</h4>
                                <a className="text-orange brekText">http://www.nationwidebarcode.com/purchase-barcodes/barcodes-for-music/</a> */}
							<button
								type="button"
								className="btn-save text-uppercase pull-right mt30"
								onClick={upload}
							>
								Save
							</button>
							{/* <ToolTip
                                    message="skjdfsdfdddds" /> */}
						</div>
					</div>
				</div>
			</div>
			<div className="row form-group justify-content-center"></div>
		</section>
	);
};

export default TextUploadShared;
