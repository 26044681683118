import { useEffect, useState } from "react";
import axios from "axios";
import { showNotification } from "../../../shared/showNotification";

const BlogComponent = () => {
	const [state, setState] = useState({
		blogCategory: [],
		blog: [],
		view: false,
		selectedBlog: {},
		selectedCategory: {},
		selectedBlogs: [],
	});

	const d = new Date();

	useEffect(() => {
		getBlog();
		getBlogCategory();
	}, []);

	const getBlog = () => {
		axios
			.get("/app/blog")
			.then(function (response) {
				if (response.data.success) {
					// state.artists = response.data.data
					setState((oldState) => ({
						...oldState,
						blog: response.data.data.data,
					}));
				} else {
					showNotification(response.data.message, "error");
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				//console.log(error);
			});
	};

	const getBlogCategory = () => {
		axios
			.get("/app/blogcategory")
			.then(function (response) {
				if (response.data.success) {
					// state.artists = response.data.data
					setState((oldState) => ({
						...oldState,
						blogCategory: response.data.data.data,
					}));
					selectCategory(response.data.data.data[0]);
				} else {
					showNotification(response.data.message, "error");
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				//console.log(error);
			});
	};

	const viewBlog = (elem) => {
		setState((oldState) => ({
			...oldState,
			view: true,
			selectedBlog: elem,
		}));
	};

	const selectCategory = (elem) => {
		if (elem) {
			setState((oldState) => ({
				...oldState,
				selectedCategory: elem,
				view: false,
			}));

			decideCategory(elem);
		}
	};

	const decideCategory = (elem) => {
		setState((oldState) => ({
			...oldState,
			selectedBlogs: state.blog.filter(
				(blog) => blog.blog_category_id == elem.blog_category_id
			),
		}));
	};

	return (
		<section id="content" className="press-bg">
			<div className="container">
				<div className=" text-center">
					<h1 className=" text-white text-uppercase mt0 pt30 din-font mb30">
						I read the <span className="text-type2">news</span> today (oh boy)
					</h1>
				</div>
				<div className="row mb30">
					<div className="col-md-6">
						<div className="bg-white news-leftbar mb30 pd20">
							<ul>
								{state.blogCategory.map((elem, index) => (
									<li key={index}>
										<a
											onClick={() => {
												selectCategory(elem);
											}}
										>
											{elem.name}
										</a>
									</li>
								))}
								{/* <li><a href="">Music News</a></li>
                                    <li><a href="">Business News</a></li>
                                    <li><a href="" className="active">Tech News</a></li>
                                    <li><a href="">Social Media</a></li>
                                    <li><a href="">Media Contacts</a></li> */}
							</ul>
						</div>
						<div className="bg-white news-leftbar-sec pd20 mb30">
							<h2 className="mb10">DalbumX Tweets</h2>
							{/* <a href="https://twitter.com/intent/tweet?button_hashtag=LoveTwitter&ref_src=twsrc%5Etfw" className="twitter-hashtag-button">Tweet #LoveTwitter</a> */}
							<a
								className="twitter-timeline"
								href="https://twitter.com/TwitterDev"
							>
								Tweets by TwitterDev
							</a>
							{/* <a className="twitter-timeline" href="https://twitter.com/intent/tweet?button_hashtag=Nitin&ref_src=twsrc%5Etfw">Tweet #Nitin</a> */}
							{/* <ul>
                                    <li><span>@drdrebeats</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ultrices libero sit amet felis posuere.<p>1 hour ago</p></li>
                                    <li><span>@drdrebeats</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ultrices libero sit amet felis posuere.<p>1 hour ago</p></li>
                                    <li><span>@drdrebeats</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ultrices libero sit amet felis posuere.<p>1 hour ago</p></li>
                                    <li><span>@drdrebeats</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ultrices libero sit amet felis posuere.<p>1 hour ago</p></li>
                                </ul> */}
						</div>
						<div className="col-md-8 no-padding large-show">
							<div className="row text-center">
								<div className="col-md-6">
									<a href="">
										<img className="img-responsive" src="img/app-store.png" />
									</a>
								</div>
								<div className="col-md-6">
									<a href="">
										<img className="img-responsive" src="img/google-play.png" />
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="text-left latest-news bg-white pd20 mb20">
							<h1 className="text-type2 mt5 mb0">
								<b>{state.selectedCategory.name}</b>
							</h1>
							{state.view && (
								<a
									onClick={() =>
										setState((oldState) => ({
											...oldState,
											view: false,
										}))
									}
								>
									Back to Blog List
								</a>
							)}
							{!state.view && (
								<ul>
									{state.selectedBlogs.map((elem, index) => (
										<li key={index}>
											<a onClick={() => viewBlog(elem)}>
												<b>{elem.heading}</b>
												<p>{new Date(elem.createdAt).toDateString()}</p>
											</a>
										</li>
									))}
									{/* <li><b>DalbumX launches playlist to target brands</b><p>April 16, 2016</p></li>
                                    <li><b>Add a Soundtrack to your life with DalbumX on Facebook</b><p>April 16, 2016</p></li>
                                    <li><b>Introducing our latest update for DalbumX</b><p>April 16, 2016</p></li>
                                    <li><b>Introducing Touch Preview: a better way to preview any song, album, artist or playlist</b><p>April 16, 2016</p></li>
                                    <li><b>DalbumX paints it black with new look</b><p>April 16, 2016</p></li>
                                    <li><b>DalbumX and Beats partner to deliver ultimate listening experience</b><p>April 16, 2016</p></li>
                                    <li><b>$2 Billion and Counting </b><p>April 16, 2016</p></li>
                                    <li><b>Introducing the new DalbumX Player </b><p>April 16, 2016</p></li>
                                    <li><b>DalbumX launches playlist to target brands</b><p>April 16, 2016</p></li> */}
								</ul>
							)}
							{state.view && (
								<>
									<ul>
										<li>
											<b>{state.selectedBlog.heading}</b>
											<p>
												{new Date(state.selectedBlog.createdAt).toDateString()}
											</p>
										</li>
									</ul>
									<div className="press-content">
										{" "}
										<span
											dangerouslySetInnerHTML={{
												__html: state.selectedBlog.description,
											}}
										></span>
										{/* {state.selectedBlog.description} */}
									</div>
								</>
							)}
						</div>
					</div>
					<div className="col-sm-12 mbl-show">
						<div className="row text-center">
							<div className="col-6">
								<a href="">
									<img src="img/app-store.png" />
								</a>
							</div>
							<div className="col-6">
								<a href="">
									<img src="img/google-play.png" />
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default BlogComponent;
