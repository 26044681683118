import { Link } from "react-router-dom";

const Footer = () => {
	return (
		<div>
			<footer className="footer">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-8">
							<div className="quick-links">
								<ul className="list-inline">
									<li className="list-inline-item">
										<Link to="/charity">Charity</Link>
									</li>
									<li className="list-inline-item">
										<Link to="/terms-and-condition">Terms</Link>
									</li>
									<li className="list-inline-item">
										<Link to="/privacy-policy">Privacy</Link>
									</li>
									{/* <li className="list-inline-item"><Link to="/faq">FAQ</Link></li> */}
									<li className="list-inline-item">&copy; DalbumX 2016-2024.</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="social-links float-right">
								<ul className="list-inline">
									<li className="list-inline-item">Follow Us</li>
									<li className="list-inline-item">
										<a href="#" alt="facebook" title="facebook">
											<div className="social-icon">
												<i className="fa fa-facebook"></i>
											</div>
										</a>
									</li>
									<li className="list-inline-item">
										<a href="#" alt="Twitter" title="Twitter">
											<div className="social-icon">
												<i className="fa fa-twitter"></i>
											</div>
										</a>
									</li>
									<li className="list-inline-item">
										<a href="#" alt="Instagram" title="Instagram">
											<div className="social-icon">
												<i className="fa fa-instagram"></i>
											</div>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default Footer;
