import Header from "../../shared/FanHeader/header";
import Footer from "../../shared/footer/footer";
import GiftComponent from "../../components/fan/gift";
import { useLocation } from "react-router";

const Gift = () => {
	const { state } = useLocation();

	return (
		<div>
			<Header />
			<GiftComponent data={state ? state.data : []} />
			<Footer />
		</div>
	);
};

export default Gift;
