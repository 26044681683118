import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Tooltip from "../../shared/toolTip";
import { confirmAlert } from "react-confirm-alert";
import validator from "validator";
import { showNotification } from "../../shared/showNotification";
// -----------------------------------------------------------------
// Lalo: October 13, 2021 - Adding primaryRoles to eliminate
//                          hardcoded values starting on line 932
// Lalo: November 5, 2021 - Added 'primaryRoleName' to list to get
//                          the name of the primary_role the admin
//                          has. Coders hardcoded it. Grrr!!
// -----------------------------------------------------------------

const AccountInformationComponent = () => {
	const [state, setState] = useState({
		pubAlbums: [],
		UnPubAlbums: [],
		selectedArtist: {},
		insider: [],
		primaryRoles: [],
		primaryRoleName: "",
		primaryRoleID: "",
		InsiderData: [],
		artist: [],
		data: { email: "" },
		dataToSends: {},
		canceled : false,
		hiddenArticle: [
			false,
			false,
			false,
			false,
			false,
			false,
			false,
			false,
			false,
			false,
			false,
			false,
			false,
			false,
			false,
		],
		errors: [
			{ email: "" },
			{ newEmail: "" },
			{ confirm_email: "" },
			{ oldPassword: "" },
			{ newPassword: "" },
			{ ConfirmPassword: "" },
			{ firstname: "" },
			{ lastname: "" },
			{ gender: "" },
			{ selectedLanguage: "" },
			{ city: "" },
			{ state: "" },
			{ country: "" },
			{ phone: "" },
			{ selectedRole: "" },
		],
	});



	// -----------------------------------------------------------------
	// Lalo: October 13, 2021 - Copied function from "artistRegister.js"
	// -----------------------------------------------------------------
	const getPrimaryRole = () => {
		// console.log("Lalo: state.primaryRoleID = [" + state.primaryRoleID + "]");
		// Lalo: 09/18/2021 - Added filter of role_type from changes made on primary_role
		//                    table as it is used for both, registration and Bus COntacts
		axios
			.get("/primary_role?page=1&limit=2000", { params: { role_type: "P" } })
			.then(function (response) {
				if (response.data.success) {
					setState((oldState) => ({
						...oldState,
						primaryRoles: response.data.data.result,
					}));
				}
			})
			.catch(function (error) { });
	};



	const filteredRoles = state.primaryRoles.filter(role => role.primary_role_id === state.primaryRoleID);
	const roleName = filteredRoles.length > 0 ? filteredRoles[0].name : '';

	useEffect(() => {
		getMeData();
		getPrimaryRole();
	}, []);

	useEffect(() => { 
		if (state.canceled) {
			getMeData();
			setState((oldState) => ({
				...oldState,
				canceled: false,
			}));
		}
	}, [state.canceled]);

	const closeMouth = (articleNumber) => {
		var temp = state.hiddenArticle;
		temp[articleNumber] = temp[articleNumber] === true ? false : true;
		setState((oldState) => ({
			...oldState,
			hiddenArticle: temp,
			canceled: true
		}));
	};

	// ------------------------------------------------------------
	// Lalo: Added getPrimaryRole(); to get the primary role
	//       data from the table. It was hardcoded by the
	//       contractors, same as language
	// ------------------------------------------------------------

	const firstConfirmation = (index) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="confirm-ui">
						<div className="delete-prompt-box">
							<h2 className="text-uppercase mb10">
								Are you ABSOLUTELY sure you would like to delete THIS Dalbum and
								all of its content from DalbumX? It would be erased from the
								Xchange and from the Artist Side… Forever. Anyone who bought
								your Dalbum would still own it, however. If you wanted it back,
								you’d have to upload it from scratch. So, are you sure? Your
								fans and we would really miss you.
							</h2>
							<div className="d-flex mr-3 justify-content-center">
								<button
									type="button"
									onClick={onClose}
									className="btn-save text-uppercase"
								>
									No! Dont't Delete!!!
								</button>
								<button
									type="button"
									onClick={() => {
										onClose();
										secondConfirmation(index);
									}}
									className="btn-cancel text-uppercase"
								>
									yes, i am sure. delete
								</button>
							</div>
						</div>
					</div>
				);
			},
		});
	};
	const secondConfirmation = (index) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="confirm-ui">
						<div className="delete-prompt-box">
							<h2 className="text-uppercase mb10">
								We know you said you were sure, but are you 100% sure it isn’t a
								phase you may be going through? We don’t mean to presume, but
								maybe you want to sleep on it? If you really want to delete it,
								go ahead, but you will be missed. Remember, ALL of your content
								will be erased, FOREVER!
							</h2>
							<div className="d-flex mr-3 justify-content-center">
								<button
									type="button"
									onClick={onClose}
									className="btn-save text-uppercase"
								>
									No! Dont't Delete!!!
								</button>
								<button
									type="button"
									onClick={() => {
										deleteAlbum(index);
										onClose();
										doneConfirmation();
									}}
									className="btn-cancel text-uppercase"
								>
									yes, i am 100% sure. delete
								</button>
							</div>
						</div>
					</div>
				);
			},
		});
	};
	const doneConfirmation = () => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="confirm-ui">
						<div className="delete-prompt-box">
							<h2 className="text-uppercase mb10">
								Okay, fine. It will be taken off the Xchange and off your Admin
								Page, but just in case you made a rash decision, we will keep
								your Dalbum archived for 30 days. No one will have access to it,
								but you can recover it, if you so choose here in your settings.
								Once the 30 days have elapsed, it will be erased off the Server
								completely. We miss you already. (tear)!
							</h2>
							<div className="d-flex mr-3 justify-content-center">
								<button
									type="button"
									onClick={onClose}
									className="btn-save text-uppercase"
								>
									Okay
								</button>
							</div>
						</div>
					</div>
				);
			},
		});
	};

	const deleteUnPub = (index) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="confirm-ui">
						<div className="delete-prompt-box">
							<h2 className="text-uppercase mb10">
								Are You Sure You Would
								<br /> Like to Delete This?
							</h2>
							<div className="d-flex mr-3 justify-content-center">
								<button
									type="button"
									onClick={onClose}
									className="btn-save text-uppercase"
								>
									oops, No
								</button>
								<button
									type="button"
									onClick={() => {
										deleteAlbum(index);
										onClose();
									}}
									className="btn-cancel text-uppercase"
								>
									Delete
								</button>
							</div>
						</div>
					</div>
				);
			},
		});
	};
	const deleteAr = (artist_id) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="confirm-ui">
						<div className="delete-prompt-box">
							<h2 className="text-uppercase mb10">
								Are You Sure You Would
								<br /> Like to Delete This?
							</h2>
							<div className="d-flex mr-3 justify-content-center">
								<button
									type="button"
									onClick={onClose}
									className="btn-save text-uppercase"
								>
									oops, No
								</button>
								<button
									type="button"
									onClick={() => {
										deleteartist(artist_id);
										onClose();
									}}
									className="btn-cancel text-uppercase"
								>
									Delete
								</button>
							</div>
						</div>
					</div>
				);
			},
		});
	};

	const sortAlbums = (data) => {
		let unPub = [];
		let pub = [];
		for (let i of data) {
			if (i.is_published === 1) {
				pub.push(i);
			} else {
				unPub.push(i);
			}
		}
		setState((oldState) => ({
			...oldState,
			UnPubAlbums: unPub,
			pubAlbums: pub,
		}));
	};

	const artistSort = (data) => {
		var x = data.sort(function (a, b) {
			var nameA = a.name.toLowerCase(),
				nameB = b.name.toLowerCase();

			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		//console.log(data)
		return x;
	};

	const getMeData = () => {
		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};
		axios
			.get("auth/admin/get_profile", config)
			.then(function (response) {
				if (response.data.success) {
					sortAlbums(response.data.data.album);
					let sortedArtist = artistSort(response.data.data.artist);
					// ----------------------------------------------------
					// Lalo: response.data.data.data has the admin info
					//       Added primaryRoleID
					// ----------------------------------------------------
					setState((oldState) => ({
						...oldState,
						data: response.data.data.data,
						myEmail: response.data.data.data.email,
						insider: response.data.data.insider,
						artist: sortedArtist,
						primaryRoleID: response.data.data.data.primary_role_id,
					}));
				} else {
					showNotification(response.data.message, "error", 2000)
				}

				// console.log("====================================");
				// console.log("response.data.data.data = " + JSON.stringify(response.data.data.insider));
				// console.log("response.data.data.data.primary_role_id = " + JSON.stringify(response.data.data.data.primary_role_id));
				// console.log("primaryRoleID = " + state.primaryRoleID);
				// console.log("====================================");
			})
			.catch(function (error) {
				showNotification(error.name, "warning", 2000)
			});

		// console.log("state.data = [" + JSON.stringify(state.data) + "]");
	};

	const deleteAlbum = (album_id) => {
		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};
		axios
			.post("auth/admin/delete_album", album_id, config)
			.then(function (response) {
				if (response.data.success) {
					getMeData();
				} else {
					showNotification(response.data.message, "error", 2000)
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning", 2000)
			});
	};

	const handleHiddenArticle = (articleNumber) => {
		setState((oldState) => ({
			...oldState,
			canceled: true,
		}));
		var element = state.hiddenArticle;
		for (let index = 0; index < element.length; index++) {
			if (index === articleNumber) {
				element[index] = true;
			} else element[index] = false;
		}
		setState((oldState) => ({
			...oldState,
			hiddenArticle: element,
			primaryRoleName: roleName,
			dataToSends: {},
		}));
	};

	const handleInputChange = (event) => {
		const target = event.target;
		const value =
			target.type === "anchor" ? target.attributes.value.value : target.value;
		const name = target.name;

		// const name = target.name;
		var data = state.data;
		data[name] = value;
		setState((oldState) => ({
			...oldState,
			data: data,
		}));

		if (
			name === "email" ||
			name === "newPassword" ||
			name === "city" ||
			name === "state" ||
			name === "country" ||
			name === "firstname" ||
			name === "gender" ||
			name === "lastname" ||
			name === "phone"
		) {
			var datas = state.dataToSends;
			datas[name] = value;
			setState((oldState) => ({
				...oldState,
				dataToSends: datas,
			}));
		}

		if (name === "language") {
			//const names = name === 'language' ? "language_name" : "primaryRoleName";
			var datas = state.dataToSends;
			datas[name] = target.innerText;
			setState((oldState) => ({
				...oldState,
				dataToSends: datas,
			}));
		}

		if (name === "primaryRole") {
			const names = "primaryRoleName";
			var datas = state.dataToSends;
			setState((oldState) => ({
				...oldState,
				dataToSends: datas,
			}));
		}
	};

	const validate = (field) => {
		var error = state.errors;
		if (field === "email" && !validator.isEmail(state.data[field])) {
			// alert("not mail")
			error[field] = "Email not Valid";
			setState((oldState) => ({
				...oldState,
				errors: error,
			}));
		} else if (field === "confirm_email") {
			if (!validator.isEmail(state.data[field])) {
				error[field] = "Email not Valid";
			} else if (state.data.confirm_email !== state.data.email) {
				error[field] = "Email should be Same";
			}
		} else if (validator.isEmpty(state.data[field])) {
			error[field] = field + " is Empty";
		}

		setState((oldState) => ({
			...oldState,
			errors: error,
		}));
	};

	const handlePasswordChange = (event, field) => {
		if (state.data.new_password !== state.data.confirmPassword) {
			showNotification("New & Confirm password do not match", "error", 2000)
			setState(oldState => {
				// Find the index of the object with the key 'ConfirmPassword'
				const index = oldState.errors.findIndex(error => Object.keys(error)[0] === 'ConfirmPassword');

				// If the index is found, update the value
				if (index !== -1) {
					const updatedErrors = [...oldState.errors]; // Copy the errors array
					updatedErrors[index] = { ConfirmPassword: "Password does not match" }; // Update the value
					return { ...oldState, errors: updatedErrors }; // Update the state with the modified errors array
				}
			});

			return
		}
		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};
		axios
			.post(
				"/auth/admin/update_password",
				{
					password: state.data.password,
					new_password: state.data.new_password,
				},
				config
			)
			.then(function (response) {
				handleHiddenArticle(100);
				getMeData();
				if (response.data.success)
					showNotification("Password Changed !", "success", 2000)
				else showNotification(response.data.message, "error", 2000);
			})
			.catch(function (error) {
				// debugger
				showNotification(error.response.data.error, "warning", 2000);
			});
	};

	const getInsider = (elem) => {
		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};

		setState((oldState) => ({
			...oldState,
			selectedArtist: elem,
		}));
		axios
			.post("/app/admin/getInsider", elem, config)
			.then(function (response) {
				if (response.data.success) {
					setState((oldState) => ({
						...oldState,
						InsiderData: response.data.data,
					}));
				} else {
					showNotification(response.data.message, "error", 2000);
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning", 2000);
			});
	};

	const AddInsider = (e) => {
		e.preventDefault();

		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};
		state.insider.artist_id = state.selectedArtist.artist_id;
		axios
			.post("/app/admin/addInsider", state.insider, config)
			.then(function (response) {
				if (response.data.success) {
					setState((oldState) => ({
						...oldState,
						insider: {},
					}));
					// state.artists = response.data.data
					showNotification("Insider Added", "success", 2000);
				} else {
					showNotification(response.data.message, "error", 2000);
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning", 2000);
				//console.log(error);
			});
	};

	const editInsider = (e) => {
		let temp = state.insider;
		temp[e.target.name] = e.target.value;
		setState((oldState) => ({
			...oldState,
			insider: temp,
		}));
	};

	const handleSingleSubmit = () => {
		// console.log("At handleSingleSubmit");
		//  console.log("state.dataToSends = " + JSON.stringify(state.dataToSends));

		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};
		axios
			.post(
				"/auth/admin/update_details",
				{
					data: state.dataToSends,
				},
				config
			)
			.then(function (response) {
				getMeData();
				handleHiddenArticle(100);
				if (response.data.success) {
					//  console.log("At handleSingleSubmit Success");
					showNotification("Changed Successfully !", "success", 2000);
				} else {
					//  console.log("At handleSingleSubmit Error");
					showNotification(response.data.message, "error", 2000);
				}
			})
			.catch(function (error) {
				showNotification(error, "warning", 2000);
			});
	};

	const getRemovalDate = (value) => {
		console.log(value);
		var date = new Date(value);
		date.setDate(date.getDate() + 30);
		return date.toISOString().split("T")[0];
	};

	const deleteartist = (id) => {
		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};
		axios
			.post(
				"/app/admin/deleteArtist",
				{
					artist_id: id,
				},
				config
			)
			.then(function (response) {
				getMeData();
				handleHiddenArticle(100);
				if (response.data.success)
					showNotification("Artist Deleted !", "success", 2000);
				else showNotification(response.data.message, "error", 2000);
			})
			.catch(function (error) {
				showNotification(error, "warning", 2000);
			});
	};

	
	const handleDropdownChange = (event, selectedPrimaryRole) => {
		const target = event.target;
		const value = target.attributes.value.value;
		const name = target.name;
		// let primaryRoleName = state.primaryRoleName;
		let primaryRoleName = selectedPrimaryRole;
		if (name === "primaryRole") {
			const selectedRole = state.primaryRoles.filter(role => console.log(role.primary_role_id === value))
		//   const selectedRole = state.primaryRoles.find(role => role.primary_role_id === value);
		console.log(selectedRole)
		//   if (selectedRole) {
		// 	primaryRoleName = selectedRole.name;
		//   }
		}
	  
		setState((oldState) => ({
		  ...oldState,
		  primaryRoleName: primaryRoleName,
		  primaryRoleID: value,
		  data: {
			...oldState.data,
			[name]: value
		  },
		  dataToSends: {
			...oldState.dataToSends,
			primary_role_id: value,
			primaryRoleName: primaryRoleName
		  }
		}));
	  };
	  

	  

	return (
		<section id="content" className="pt50">
			<div className="modal fade" id="InsiderPopup">
				<div className="modal-dialog modal-dialog-scrollable modal-dialog-centered custome-model-full-width">
					<div className="modal-content custome-model text-center">
						<div className="modal-header">
							<button type="button" className="close" data-dismiss="modal">
								×
							</button>
						</div>

						<div className="modal-body din-font inside-invite">
							<div className="top-head">insider invite</div>
							<p>
								Enter emails to invite others to view all pages for this artist.
								They will not be able to make any changes. They will only be
								able to see what you have done. People you may want to share
								with include: band members, record label execs, your publicist,
								your mother. Whoever it is, make sure they're in your circle of
								trust. None of your personal information will be shared. And
								they will only be able to view this artist, no others.
							</p>

							<form className="form-horizontal">
								<div className="row align-items-center">
									<label className="control-label col-md-2" htmlFor="email">
										ARTIST:
									</label>
									<div className="col-md-10">
										<p>
											<strong>{state.selectedArtist.name}</strong>
										</p>
									</div>
								</div>
								<div className="form-group row align-items-center">
									<label className="control-label col-md-2" htmlFor="email">
										FROM:
									</label>
									<div className="col-md-10">
										<p>{state.data.email}</p>
									</div>
								</div>
								<div className="form-group row align-items-center">
									<label className="control-label col-md-2" htmlFor="email">
										TO:
									</label>
									<div className="col-md-10">
										<input
											name="email"
											className="form-control"
											onChange={editInsider}
											value={state.insider.email ? state.insider.email : ""}
										></input>
									</div>
								</div>
								<div className="form-group row align-items-center">
									<label className="control-label col-md-2" htmlFor="email">
										SUBJECT:
									</label>
									<div className="col-md-10">
										<input
											name="subject"
											className="form-control"
											onChange={editInsider}
											value={state.insider.subject ? state.insider.subject : ""}
										></input>
									</div>
								</div>
								<div className="form-group row">
									<label className="control-label col-md-2" htmlFor="email">
										MESSAGE:
									</label>
									<div className="col-md-10">
										<textarea
											name="description"
											className="form-control "
											rows="8"
											onChange={editInsider}
											value={
												state.insider.description
													? state.insider.description
													: ""
											}
										></textarea>
									</div>
								</div>
								<div className="row align-items-center">
									<div className="col-lg-12 text-center">
										<button
											onClick={AddInsider}
											className="btn-save text-uppercase"
										>
											Send Invitation
										</button>
									</div>
								</div>
							</form>
							<div className="row form-bottom">
								<div className="col-md-12">
									<div className="heading">current insiders</div>
								</div>
								<div className="col-md-12">
									{state.InsiderData.map((elem, index) => (
										<div className="row">
											<div className="col-md-2">
												<label></label>editInsider
											</div>
											<div className="col-md-5">
												<p>{elem.to_email}</p>
											</div>
											{/* <div className='col-md-5 text-right'><p className='text-uppercase'>deny access x</p></div> */}
											<div className="col-md-2">
												<label></label>
											</div>
										</div>
									))}
								</div>
							</div>
							{/* <div className="row form-bottom mb50">
                                        <div className='col-md-12'>
                                        <div className='heading'>current insiders</div>
                                        </div>
                                        <div className='col-md-12'>
                                        <div className='row'>
                                        <div className='col-md-2'>
                                            <label></label>
                                        </div>
                                        <div className='col-md-5'><p>kapilvarshneyweb@gmail.com</p></div>
                                        <div className='col-md-5 text-right'><p className='text-uppercase'>deny access x</p></div>
                                        </div>
                                        <div className='row'>
                                        <div className='col-md-2'>
                                            <label></label>
                                        </div>
                                        <div className='col-md-5'><p>kapilvarshneyweb@gmail.com</p></div>
                                        <div className='col-md-5 text-right'><p className='text-uppercase'>deny access x</p></div>
                                        </div>
                                        <div className='row'>
                                        <div className='col-md-2'>
                                            <label></label>
                                        </div>
                                        <div className='col-md-5'><p>kapilvarshneyweb@gmail.com</p></div>
                                        <div className='col-md-5 text-right'><p className='text-uppercase'>deny access x</p></div>
                                        </div>
                                        </div>
                                    </div> */}
						</div>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<h3 className="page-heading text-uppercase">Account Details</h3>
					</div>
				</div>
			</div>

			<article className="content-heading">
				<div className="container">
					<div className="row">
						<div className="col-sm-12">
							<h4 className="text-uppercase text-white">Basic Information</h4>
						</div>
					</div>
				</div>
			</article>
			{/* article-bg */}
			<article className="">
				<div className="container pt20">
					{!state.hiddenArticle[0] && (
						<div className="row border-bottom-gray content-detail mb20 m0">
							<div className="col-md-4 lpadding">
								<p className="text-uppercase text-bold">Email</p>
							</div>
							<div className="col-md-4">
								<p>{state.myEmail}</p>
							</div>
							<div className="col-md-4 rpadding">
								<a
									href="#"
									className="pull-right"
									onClick={() => handleHiddenArticle(0)}
								>
									Edit
								</a>
							</div>
						</div>
					)}
					{/* <!--row ends here-->

                         <!--Edit Field Content--> */}
					{state.hiddenArticle[0] && (
						<div className="detail-edit-box border-bottom-gray">
							<div className="row content-edit-view m0">
								<div className="col-md-4 lpadding">
									<p className="text-uppercase text-bold">Email</p>
								</div>
								<div className="col-md-8">
									<div className="form-group row">
										<div className="col-md-4">
											<label
												className="col-form-label text-uppercase text-right full-width"
												htmlFor="name"
											>
												Current:
											</label>
										</div>
										<div className="col-md-8 lpadding rpadding">
											<input
												type="email"
												value={state.myEmail}
												disabled
												className="form-control"
												name="email"
												id=""
												placeholder=""
												onChange={handleInputChange}
											/>
										</div>
									</div>

									<div className="form-group row">
										<div className="col-md-4">
											<label
												className="col-form-label text-uppercase text-right full-width"
												htmlFor="name"
											>
												New:
											</label>
										</div>
										<div className="col-md-8 lpadding rpadding">
											<input
												type="email"
												className="form-control"
												onBlur={() => validate("email")}
												name="email"
												id=""
												placeholder=""
												onChange={handleInputChange}
											/>
											<span id="error" className="form-text error-text">
												{state.errors.email}{" "}
											</span>
										</div>
									</div>

									<div className="form-group row">
										<label
											className="col-md-4 col-form-label text-uppercase text-right"
											htmlFor="name"
										>
											Confirm:
										</label>
										<input
											type="email"
											className="form-control col-md-8"
											id=""
											name="confirm_email"
											onBlur={() => validate("confirm_email")}
											placeholder=""
											onChange={handleInputChange}
										/>
										<span id="error" className="form-text error-text">
											{state.errors.confirm_email}{" "}
										</span>
									</div>
								</div>
							</div>
							<div className="row justify-content-center mb20 mblBtn">
								<button
									type="button"
									className="btn-cancel text-uppercase"
									onClick={() => {
										closeMouth(0);
									}}
								>
									Cancel
								</button>
								<button
									type="button"
									className="btn-gradient btn-save text-uppercase"
									onClick={handleSingleSubmit}
								>
									Confirm Changes
								</button>
							</div>
						</div>
					)}
				</div>
			</article>

			<article>
				<div className="container">
					{!state.hiddenArticle[1] && (
						<div className="row border-bottom-gray content-detail mb20 m0">
							<div className="col-md-4 lpadding">
								<p className="text-uppercase text-bold">Password</p>
							</div>
							<div className="col-md-4">
								<p>*********</p>
							</div>
							<div className="col-md-4 rpadding">
								<a
									href="#"
									className="pull-right"
									onClick={() => handleHiddenArticle(1)}
								>
									Edit
								</a>
							</div>
						</div>
					)}
					{/* <!--row ends here-->

                         <!--Edit Field Content--> */}
					{state.hiddenArticle[1] && (
						<div className="detail-edit-box border-bottom-gray">
							<div className="row content-edit-view m0">
								<div className="col-md-4  lpadding">
									<p className="text-uppercase text-bold">Password</p>
								</div>
								<div className="col-md-8">
									<div className="form-group row">
										<label
											className="col-md-4 col-form-label text-uppercase text-right"
											htmlFor="name"
										>
											Current:
										</label>
										<input
											type="password"
											name="password"
											className="form-control  col-md-8"
											onChange={handleInputChange}
											id=""
											placeholder=""
										/>
									</div>
									<div className="form-group row">
										<label
											className="col-md-4 col-form-label text-uppercase text-right"
											htmlFor="name"
										>
											New:
										</label>
										<input
											type="password"
											name="new_password"
											onBlur={() => validate("new_password")}
											className="form-control col-md-8"
											onChange={handleInputChange}
											id=""
											placeholder=""
										/>
									</div>
									<div className="form-group row">
										<label
											className="col-md-4 col-form-label text-uppercase text-right"
											htmlFor="name"
										>
											Confirm:
										</label>
										<input
											type="password"
											name="confirmPassword"
											className="form-control col-md-8"
											onBlur={() => validate("confirmPassword")}
											onChange={handleInputChange}
											id=""
											placeholder=""
										/>
										{state.errors[5].ConfirmPassword && (
											<span id="error" style={{ "fontSize": "12px", "color": "red", "marginLeft": "16rem" }}>
												{state.errors[5].ConfirmPassword}
											</span>
										)}
									</div>
								</div>
							</div>
							<div className="row justify-content-center mb20 mblBtn">
								<button
									type="button"
									className="btn-cancel text-uppercase"
									onClick={() => {
										closeMouth(1);
									}}
								>
									Cancel
								</button>
								<button
									type="button"
									className="btn-gradient btn-save text-uppercase"
									onClick={(e) => handlePasswordChange(e, "update_password")}
								>
									Confirm Changes
								</button>
							</div>
						</div>
					)}
				</div>
			</article>

			<article>
				<div className="container">
					{!state.hiddenArticle[2] && (
						<div className="row border-bottom-gray content-detail mb20 m0">
							<div className="col-md-4 lpadding">
								<p className="text-uppercase text-bold">Username</p>
							</div>
							<div className="col-md-4">
								<p>
									{state.data.firstname} {state.data.lastname}
								</p>
							</div>
							<div className="col-md-4 rpadding">
								<a
									href="#"
									className="pull-right"
									onClick={() => handleHiddenArticle(2)}
								>
									Edit
								</a>
							</div>
						</div>
					)}
					{/* <!--row ends here-->
        
                        <!--Edit Field Content--> */}
					{state.hiddenArticle[2] && (
						<div className="detail-edit-box border-bottom-gray">
							<div className="row content-edit-view m0">
								<div className="col-md-4 lpadding">
									<p className="text-uppercase text-bold">name</p>
								</div>
								<div className="col-md-8">
									<div className="form-group row">
										<label
											className="col-md-2 col-form-label text-uppercase text-right"
											htmlFor="name"
										>
											First:
										</label>
										<input
											type="email"
											value={state.data.firstname}
											name="firstname"
											className="form-control col-md-4"
											id=""
											placeholder=""
											onChange={handleInputChange}
											onBlur={() => validate("firstname")}
										/>
									</div>
									<div className="form-group row">
										<label
											className="col-md-2 col-form-label text-uppercase text-right"
											htmlFor="name"
										>
											Last:
										</label>
										<input
											type="email"
											name="lastname"
											value={state.data.lastname}
											className="form-control col-md-4"
											id=""
											placeholder=""
											onChange={handleInputChange}
											onBlur={() => validate("lastname")}
										/>
									</div>
								</div>
							</div>
							<div className="row justify-content-center mb20 mblBtn">
								<button
									type="button"
									className="btn-cancel text-uppercase"
									onClick={() => {
										closeMouth(2);
									}}
								>
									Cancel
								</button>
								<button
									type="button"
									className="btn-gradient btn-save text-uppercase"
									onClick={handleSingleSubmit}
								>
									Confirm Changes
								</button>
							</div>
						</div>
					)}
				</div>
			</article>

			<article>
				<div className="container">
					{/* {!state.hiddenArticle[3] &&
                            <div className="row border-bottom-gray content-detail mb20 m0">
                                <div className="col-md-4 lpadding">
                                    <p className="text-uppercase text-bold">Gender</p>
                                </div>
                                <div className="col-md-4">
                                    <p> {state.data.gender === "male" ? "Male" : "Female"}</p>
                                </div>
                                <div className="col-md-4 rpadding">
                                    <a href="#" className="pull-right" onClick={() => handleHiddenArticle(3)} >Edit</a>
                                </div>
                            </div>} */}
					{/* <!--row ends here-->

        <!--Edit Field Content--> */}
					{state.hiddenArticle[3] && (
						<div className="detail-edit-box border-bottom-gray">
							<div className="row content-edit-view m0">
								<div className="col-md-4 lpadding">
									<p className="text-uppercase text-bold">Gender</p>
								</div>
								<div className="col-md-8">
									<div className="form-group row">
										<div className="d-inline form-group text-center">
											{state.data.gender === "male" && (
												<input
													type="radio"
													name="gender"
													className="form-check-input"
													value="male"
													checked
													id="Male"
													onChange={handleInputChange}
												/>
											)}
											{state.data.gender !== "male" && (
												<input
													type="radio"
													name="gender"
													className="form-check-input"
													value="male"
													id="Male"
													onChange={handleInputChange}
												/>
											)}
											<label className="form-check-label" htmlFor="Male">
												Male
											</label>
										</div>
										<div className="d-inline form-group form-check text-center">
											{state.data.gender === "female" && (
												<input
													type="radio"
													checked
													className="form-check-input"
													name="gender"
													value="female"
													id="Female"
													onChange={handleInputChange}
												/>
											)}
											{state.data.gender !== "female" && (
												<input
													type="radio"
													className="form-check-input"
													name="gender"
													value="female"
													id="Female"
													onChange={handleInputChange}
												/>
											)}
											<label className="form-check-label" htmlFor="Female">
												Female
											</label>
										</div>
									</div>
								</div>
							</div>
							<div className="row justify-content-center mb20 mblBtn">
								<button
									type="button"
									className="btn-cancel text-uppercase"
									onClick={() => {
										closeMouth(3);
									}}
								>
									Cancel
								</button>
								<button
									type="button"
									className="btn-gradient btn-save text-uppercase"
									onClick={handleSingleSubmit}
								>
									Confirm Changes
								</button>
							</div>
						</div>
					)}
				</div>
			</article>

			<article>
				<div className="container">
					{!state.hiddenArticle[4] && (
						<div className="row border-bottom-gray content-detail mb20 m0">
							<div className="col-md-4 lpadding">
								<p className="text-uppercase text-bold">Language</p>
							</div>
							<div className="col-md-4">
								<p>English</p>
							</div>
							<div className="col-md-4 rpadding">
								<a
									href="#"
									className="pull-right"
									onClick={() => handleHiddenArticle(4)}
								>
									Edit
								</a>
							</div>
						</div>
					)}
					{/* <!--row ends here-->
        
                        <!--Edit Field Content--> */}
					{state.hiddenArticle[4] && (
						<div className="detail-edit-box border-bottom-gray">
							<div className="row content-edit-view m0">
								<div className="col-md-4 lpadding">
									<p className="text-uppercase text-bold">Language</p>
								</div>
								<div className="col-md-8">
									<div className="form-group row">
										<select className="form-control col-sm-4">
											<option>English</option>
											{/*
                                                <option>Spanish</option>
                                                <option>French</option>
*/}
										</select>
									</div>
								</div>
							</div>
							<div className="row justify-content-center mb20 mblBtn">
								<button
									type="button"
									className="btn-cancel text-uppercase"
									onClick={() => {
										closeMouth(4);
									}}
								>
									Cancel
								</button>
								<button
									type="button"
									className="btn-gradient btn-save text-uppercase"
									onClick={handleSingleSubmit}
								>
									Confirm Changes
								</button>
							</div>
						</div>
					)}
				</div>
			</article>

			<article>
				<div className="container">
					{!state.hiddenArticle[5] && (
						<div className="row border-bottom-gray content-detail mb20 m0">
							<div className="col-md-4 lpadding">
								<p className="text-uppercase text-bold">Location</p>
							</div>
							<div className="col-md-4">
								<p>
									{state.data.city}, {state.data.state}, {state.data.country}{" "}
								</p>
							</div>
							<div className="col-md-4 rpadding">
								<a
									href="#"
									className="pull-right"
									onClick={() => handleHiddenArticle(5)}
								>
									Edit
								</a>
							</div>
						</div>
					)}
					{/* <!--row ends here-->

                         <!--Edit Field Content--> */}
					{state.hiddenArticle[5] && (
						<div className="detail-edit-box border-bottom-gray">
							<div className="row content-edit-view m0">
								<div className="col-md-4 lpadding">
									<p className="text-uppercase text-bold">Location</p>
								</div>
								{/* Lalo: Nov 7, 2021 - Changed className="col-md-2 to className="col-md-4 to make sure they aligned correctly*/}
								<div className="col-md-8">
									<div className="form-group row">
										<label
											className="col-md-4 col-form-label text-uppercase text-left"
											htmlFor="name"
										>
											City:
										</label>
										<input
											type="text"
											name="city"
											className="form-control col-md-4"
											value={state.data.city}
											id=""
											placeholder=""
											onChange={handleInputChange}
											onBlur={() => validate("city")}
										/>
									</div>
									<div className="form-group row">
										<label
											className="col-md-4 col-form-label text-uppercase text-left"
											htmlFor="name"
										>
											State:
										</label>
										<input
											type="text"
											name="state"
											className="form-control col-md-4"
											value={state.data.state}
											id=""
											placeholder=""
											onChange={handleInputChange}
											onBlur={() => validate("state")}
										/>
									</div>
									<div className="form-group row">
										<label
											className="col-md-4 col-form-label text-uppercase text-left"
											htmlFor="name"
										>
											Country:
										</label>
										<input
											type="email"
											name="country"
											className="form-control col-md-4"
											value={state.data.country}
											id=""
											placeholder=""
											onChange={handleInputChange}
											onBlur={() => validate("country")}
										/>
									</div>
								</div>
							</div>
							<div className="row justify-content-center mb20 mblBtn">
								<button
									type="button"
									className="btn-cancel text-uppercase"
									onClick={() => {
										closeMouth(5);
									}}
								>
									Cancel
								</button>
								<button
									type="button"
									className="btn-gradient btn-save text-uppercase"
									onClick={handleSingleSubmit}
								>
									Confirm Changes
								</button>
							</div>
						</div>
					)}
				</div>
			</article>

			<article>
				<div className="container">
					{!state.hiddenArticle[6] && (
						<div className="row border-bottom-gray content-detail mb20 m0">
							<div className="col-md-4 lpadding">
								<p className="text-uppercase text-bold">Phone</p>
							</div>
							<div className="col-md-4">
								<p>{state.data.phone}</p>
							</div>
							<div className="col-md-4 rpadding">
								<a
									href="#"
									className="pull-right"
									onClick={() => handleHiddenArticle(6)}
								>
									Edit
								</a>
							</div>
						</div>
					)}
					{/* <!--row ends here--> */}

					{/* <!--Edit Field Content--> */}
					{state.hiddenArticle[6] && (
						<div className="detail-edit-box border-bottom-gray">
							<div className="row content-edit-view m0">
								<div className="col-md-4 lpadding">
									<p className="text-uppercase text-bold">Phone</p>
								</div>
								<div className="col-md-8">
									<div className="form-group row">
										{/* Lalo - 11/19/2020 - Changing type from "number" to "text" 
                                                <input type="number" name="phone" value={state.data.phone} onChange={handleInputChange} className="form-control col-sm-4" id="" placeholder="" />  */}
										<input
											type="text"
											name="phone"
											value={state.data.phone}
											onChange={handleInputChange}
											className="form-control col-sm-4"
											id=""
											placeholder=""
										/>
									</div>
								</div>
							</div>
							<div className="row justify-content-center mb20 mblBtn">
								<button
									type="button"
									className="btn-cancel text-uppercase"
									onClick={() => {
										closeMouth(6);
									}}
								>
									Cancel
								</button>
								<button
									type="button"
									className="btn-gradient btn-save text-uppercase"
									onClick={handleSingleSubmit}
								>
									Confirm Changes
								</button>
							</div>
						</div>
					)}
				</div>
			</article>

			<article>
				<div className="container">
					{!state.hiddenArticle[7] && (
						<div className="row border-bottom-gray content-detail mb20 m0">
							<div className="col-md-4 lpadding">
								<p className="text-uppercase text-bold">Primary Role</p>
							</div>
							<div className="col-md-4">
								<p>{roleName}</p>
							</div>
							<div className="col-md-4 rpadding">
								<a
									href="#"
									className="pull-right"
									onClick={() => handleHiddenArticle(7)}
								>
									Edit
								</a>
							</div>
						</div>
					)}
					{state.hiddenArticle[7] && (
						<div className="detail-edit-box border-bottom-gray">
							<div className="row content-edit-view m0">
								<div className="col-md-4 lpadding">
									<p className="text-uppercase text-bold">
										<span className="red">*</span>Primary Role
									</p>
								</div>
								<div className="input-group select-wrapper col-lg-4 lpadding rpadding">
									<input
										type="text"
										className="form-control"
										aria-label="Text input with dropdown button"
										name="primaryRole"
										value={state.primaryRoleName} // Use value instead of disabled
										onChange={() => { }} // Add empty onChange handler to suppress console warnings
									/>
									<div className="input-group-append">
										<button
											className="btn btn-default btn-md m-0 px-3 py-2 z-depth-0 dropdown-toggle btn-gradient"
											type="button"
											data-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false"			
										></button>
										<div className="dropdown-menu dropdown-menu-right">

											{state.primaryRoles.map((primaryRole) => (
												<a
													className="dropdown-item"
													type="anchor"
													onClick={(event) => handleDropdownChange(event,primaryRole.name)}
													key={primaryRole.primary_role_id}
													name="primaryRole"
													value={primaryRole.primary_role_id}

												>
													{primaryRole.name}
												</a>
											))}
										</div>
									</div>
								</div>
								{/* Lalo: --------------------------------------------------------------- */}

								{state.errors.primaryRole_name && (
									<span id="error" className="error-text">
										{state.errors.primaryRole_name}{" "}
									</span>
								)}
							</div>
							<div className="row justify-content-center mb20 mblBtn">
								<button
									type="button"
									className="btn-cancel text-uppercase"
									onClick={() => {
										closeMouth(7);
									}}
								>
									Cancel
								</button>
								<button
									type="button"
									className="btn-gradient btn-save text-uppercase"
									onClick={handleSingleSubmit}
								>
									Confirm Changes
								</button>
							</div>
						</div>
					)}
				</div>
			</article>

			<article className="content-heading">
				<div className="container">
					<div className="row m0">
						<h4 className="text-uppercase text-white">History</h4>
					</div>
				</div>
			</article>

			<article className="">
				<div className="container pt20">
					{!state.hiddenArticle[8] && (
						<div className="row border-bottom-gray content-detail mb20 m0">
							<div className="col-md-4 lpadding">
								<p className="text-uppercase text-bold">Published Dalbums</p>
							</div>
							<div className="col-md-4">
								<p>
									Total: <strong>{state.pubAlbums.length}</strong>
								</p>
							</div>
							<div className="col-md-4 rpadding">
								<a
									href="#"
									className="pull-right"
									onClick={() => handleHiddenArticle(8)}
								>
									View
								</a>
							</div>
						</div>
					)}
					{/* <!--row ends here-->

                         <!--Edit Field Content--> */}
					{state.hiddenArticle[8] && (
						<div className="detail-edit-box border-bottom-gray">
							<div className="row content-edit-view m0">
								<div className="col-md-4 lpadding">
									<p className="text-uppercase text-bold">Published Dalbums</p>
								</div>
								<div className="col-md-8">
									<p className="">
										Total: <strong>{state.pubAlbums.length}</strong>
									</p>
								</div>
							</div>
							<div className="row article-bg">
								<div className="col-md-10 offset-md-1">
									<div className="table-responsive">
										<table className="table">
											<thead>
												<tr>
													<th className="text-uppercase">Dalbum</th>
													<th className="text-uppercase">Artist</th>
													<th className="text-uppercase">Published</th>
													<th className="text-uppercase">Delete</th>
												</tr>
											</thead>
											<tbody>
												
												{state.pubAlbums.map((elem, index) => (
													<tr>
														<td className="text-bold">
															<Link
																to={{
																	pathname: "/artist/manage-album",
																	state: { album: elem },
																}}
															>
																{elem.name}
															</Link>
														</td>
														<td className="text-bold">
															<Link
																to={{
																	pathname: "/artist/editArtist",
																	state: { id: elem.artist?.artist_id },
																}}
															>
																{elem.artist?.name}
															</Link>
														</td>
														<td>{getRemovalDate(elem.created_at)}</td>
														<td className="text-bold">
															<a
																onClick={() => firstConfirmation(elem)}
																href="#"
															>
																X
															</a>
															<div className="delete-popup">
																<div className="row">
																	<div className="col-md-12">
																		<p className="text-justify">
																			Are you ABSOLUTELY sure you would like to
																			delete THIS Dalbum and all of its content
																			from DalbumX? It would be erased from the
																			Xchange and from the Artist Side… Forever.
																			Anyone who bought your Dalbum would still
																			own it, however. If you wanted it back,
																			you’d have to upload it from scratch. So,
																			are you sure? Your fans and we would
																			really miss you.
																		</p>
																		<p className="text-justify">
																			We know you said you were sure, but are
																			you 100% sure it isn’t a phase you may be
																			going through? We don’t mean to presume,
																			but maybe you want to sleep on it? If you
																			really want to delete it, go ahead, but
																			you will be missed. Remember, ALL of your
																			content will be erased, FOREVER!
																		</p>
																		<p className="text-justify">
																			Okay, fine. It will be taken off the
																			Xchange and off your Admin Page, but just
																			in case you made a rash decision, we will
																			keep your Dalbum archived for 30 days. No
																			one will have access to it, but you can
																			recover it, if you so choose here in your
																			settings. Once the 30 days have elapsed,
																			it will be erased off the Server
																			completely. We miss you already. (tear)!
																		</p>
																	</div>
																</div>
																<div className="row justify-content-center mt10 mb10">
																	<button
																		type="button"
																		className="btn-save text-uppercase"
																	>
																		No! Don't Delete
																	</button>
																	<button
																		type="button"
																		className="btn-cancel text-uppercase"
																		onClick={() => {
																			closeMouth(8);
																		}}
																	>
																		Yes, I'm Sure. Delete
																	</button>
																	<button
																		type="button"
																		className="btn-save text-uppercase"
																	>
																		No! Don't Delete
																	</button>
																	<button
																		type="button"
																		className="btn-cancel text-uppercase"
																		onClick={() => {
																			closeMouth();
																		}}
																	>
																		Yes, I'm 100% Sure. Delete
																	</button>
																	<button
																		type="button"
																		className="btn-cancel text-uppercase"
																		onClick={() => {
																			closeMouth();
																		}}
																	>
																		Okay
																	</button>
																</div>
															</div>
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<div className="row justify-content-center mb20">
								<button
									type="button"
									className="btn-cancel text-uppercase"
									onClick={() => {
										closeMouth(8);
									}}
								>
									Close
								</button>
							</div>
						</div>
					)}
				</div>
			</article>

			<article>
				<div className="container">
					{!state.hiddenArticle[9] && (
						<div className="row border-bottom-gray content-detail mb20 m0">
							<div className="col-md-4 lpadding">
								<p className="text-uppercase text-bold">unpublished Dalbums</p>
							</div>
							<div className="col-md-4">
								<p>
									Total: <strong>{state.UnPubAlbums.length}</strong>
								</p>
							</div>
							<div className="col-md-4 rpadding">
								<a
									href="#"
									className="pull-right"
									onClick={() => handleHiddenArticle(9)}
								>
									View
								</a>
							</div>
						</div>
					)}
					{/* <!--row ends here--> */}

					{/* <!--Edit Field Content--> */}
					{state.hiddenArticle[9] && (
						<div className="detail-edit-box border-bottom-gray">
							<div className="row content-edit-view m0">
								<div className="col-md-4 lpadding">
									<p className="text-uppercase text-bold">
										Unpublished Dalbums
									</p>
								</div>
								<div className="col-md-8">
									<p className="">
										Total: <strong>{state.UnPubAlbums.length}</strong>
									</p>
								</div>
							</div>
							<div className="row article-bg">
								<div className="col-md-10 offset-md-1">
									<div className="table-responsive">
										<table className="table">
											<thead>
												<tr>
													<th className="text-uppercase text-bold">Dalbum</th>
													<th className="text-uppercase text-bold">Artist</th>
													{/* <th className="text-uppercase text-bold">Status</th> */}
													<th className="text-uppercase text-bold table-toltip">
														Removal Date{" "}
														<a href="#" className="info-trigger">
															<Tooltip
																message={
																	"Dalbums must be published within 30 days of start date. If the unpublished Album is left inactive for more than 7 consecutive days or left unpublished for 30 days (even if active), will be deleted and all information uploaded will be lost."
																}
																color="#a9adac"
															/>
														</a>
														<div className="info-box">
															<p className="mb0">
																Dalbums must be published within 30 days of
																start date. If the unpublished Dalbum is left
																inactive for more than 7 consecutive days or
																left unpublished for 30 days (even if active),
																it will be deleted and all information uploaded
																will be lost. <a href="#">Why?</a>{" "}
															</p>
														</div>
													</th>
													<th className="text-uppercase text-bold">Delete</th>
												</tr>
											</thead>
											<tbody>
												{/* <tr>
                                                        <td className="text-bold">El Pintor</td>
                                                        <td className="text-bold">Interpol</td>
                                                        <td className="text-bold">50%</td>
                                                        <td className="text-bold">04/02/2015</td>
                                                        <td className="text-bold"><a href="#">X</a></td>
                                                    </tr> */}
												{state.UnPubAlbums.map((elem, index) => (
													
													<tr>
														<td className="text-bold">
															<Link
																to={{
																	pathname: "/artist/addalbum",
																	state: { album: elem },
																}}
																onClick={sessionStorage.setItem(
																	"album_id",
																	elem.dalbum_id
																)}
															>
																{elem.name}
																
															</Link>
														</td>
														<td className="text-bold">
															<Link
																to={{
																	pathname: "/artist/editArtist",
																	state: { id: elem.artist?.artist_id },
																}}
															>
																{elem.artist?.name}
															</Link>
														</td>
														{/* <td>{new Date(elem.created_at).toDateString()}</td> */}
														<td>{getRemovalDate(elem.created_at)}</td>
														<td className="text-bold">
															<a onClick={() => deleteUnPub(elem)} href="#">
																X
															</a>
															<div className="delete-popup">
																<div className="row">
																	<div className="col-md-12">
																		<p className="text-justify">
																			Are you ABSOLUTELY sure you would like to
																			delete THIS Dalbum and all of its content
																			from DalbumX? It would be erased from the
																			Xchange and from the Artist Side… Forever.
																			Anyone who bought your Dalbum would still
																			own it, however. If you wanted it back,
																			you’d have to upload it from scratch. So,
																			are you sure? Your fans and we would
																			really miss you.
																		</p>
																		<p className="text-justify">
																			We know you said you were sure, but are
																			you 100% sure it isn’t a phase you may be
																			going through? We don’t mean to presume,
																			but maybe you want to sleep on it? If you
																			really want to delete it, go ahead, but
																			you will be missed. Remember, ALL of your
																			content will be erased, FOREVER!
																		</p>
																		<p className="text-justify">
																			Okay, fine. It will be taken off the
																			Xchange and off your Admin Page, but just
																			in case you made a rash decision, we will
																			keep your Dalbum archived for 30 days. No
																			one will have access to it, but you can
																			recover it, if you so choose here in your
																			settings. Once the 30 days have elapsed,
																			it will be erased off the Server
																			completely. We miss you already. (tear)!
																		</p>
																	</div>
																</div>
																<div className="row justify-content-center mt10 mb10">
																	<button
																		type="button"
																		className="btn-save text-uppercase"
																	>
																		No! Don't Delete
																	</button>
																	<button
																		type="button"
																		className="btn-cancel text-uppercase"
																		onClick={() => {
																			closeMouth(8);
																		}}
																	>
																		Yes, I'm Sure. Delete
																	</button>
																	<button
																		type="button"
																		className="btn-save text-uppercase"
																	>
																		No! Don't Delete
																	</button>
																	<button
																		type="button"
																		className="btn-cancel text-uppercase"
																		onClick={() => {
																			closeMouth();
																		}}
																	>
																		Yes, I'm 100% Sure. Delete
																	</button>
																	<button
																		type="button"
																		className="btn-cancel text-uppercase"
																		onClick={() => {
																			closeMouth();
																		}}
																	>
																		Okay
																	</button>
																</div>
															</div>
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<div className="row justify-content-center mb20">
								<button
									type="button"
									className="btn-cancel text-uppercase"
									onClick={() => {
										closeMouth(9);
									}}
								>
									Close
								</button>
							</div>
						</div>
					)}
				</div>
			</article>

			<article className="container">
				<div>
					{!state.hiddenArticle[10] && (
						<div className="row border-bottom-gray content-detail mb20 m0">
							<div className="col-md-4 lpadding">
								<p className="text-uppercase text-bold">Artist(Arranged A-Z)</p>
							</div>
							<div className="col-md-4">
								<p>
									Total: <strong>{state.artist.length}</strong>
								</p>
							</div>
							<div className="col-md-4 rpadding">
								<a
									href="#"
									className="pull-right"
									onClick={() => handleHiddenArticle(10)}
								>
									View
								</a>
							</div>
						</div>
					)}
					{/* <!--row ends here-->

                          <!--Edit Field Content--> */}
					{state.hiddenArticle[10] && (
						<div className="detail-edit-box border-bottom-gray">
							<div className="row content-edit-view m0">
								<div className="col-md-4 lpadding">
									<p className="text-uppercase text-bold">
										Artist(Arranged A-Z)
									</p>
								</div>
								<div className="col-md-8">
									<p className="">
										Total: <strong>{state.artist.length}</strong>
									</p>
								</div>
							</div>
							<div className="row article-bg mb-10">
								<div className="col-md-10 offset-md-1">
									{/* <div className="table-responsive">
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <td className="text-bold">X Charlie</td>
                                                        <td className="text-bold">X Charlie</td>
                                                        <td className="text-bold">X Sam J. Houston</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-bold">X Charlie</td>
                                                        <td className="text-bold">X Interpol</td>
                                                        <td className="text-bold">X Sam J. Houston</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-bold">X Charlie</td>
                                                        <td className="text-bold">X Interpol</td>
                                                        <td className="text-bold">X Sam J. Houston</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-bold">X Charlie</td>
                                                        <td className="text-bold">X Interpol</td>
                                                        <td className="text-bold">X Sam J. Houston</td>
                                     elem               </tr>
                                                    <tr>
                                                        <td className="text-bold">X Charlie</td>
                                                        <td className="text-bold">X Interpol</td>
                                                        <td className="text-bold">X Sam J. Houston</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div> */}
									<ul className="list-unstyled custom-table-list">
										{state.artist.map((elem, idx) => (
											<li>
												<a
													to={{
														pathname: "/artist/editArtist",
														state: { id: elem.artist_id },
													}}
													style={{}}
												>
													{elem.name}{" "}
													<span className="pull-right">
														<i
															className="fa fa-times"
															onClick={() => deleteAr(elem.artist_id)}
														></i>
													</span>
												</a>
											</li>
										))}
									</ul>
								</div>
							</div>
							<div className="row justify-content-center mb20">
								<button
									type="button"
									className="btn-cancel text-uppercase"
									onClick={() => {
										closeMouth(10);
									}}
								>
									Close
								</button>
							</div>
						</div>
					)}
				</div>
			</article>

			<article className="">
				<div className="container">
					{!state.hiddenArticle[11] && (
						<div className="row content-detail mb10 m0">
							<div className="col-md-4 lpadding">
								<p className="text-uppercase text-bold">Insider Access</p>
							</div>
							<div className="col-md-4">
								<p>
									Total: <strong>{state.insider.length}</strong>
								</p>
							</div>
							<div className="col-md-4 rpadding">
								<a
									href="#"
									className="pull-right"
									onClick={() => handleHiddenArticle(11)}
								>
									View
								</a>
							</div>
						</div>
					)}
					{/* <!--row ends here-->
        <!--Edit Field Content--> */}
					{state.hiddenArticle[11] && (
						<div className="detail-edit-box border-bottom-gray">
							<div className="row content-edit-view m0">
								<div className="col-md-4 lpadding">
									<p className="text-uppercase text-bold">Insider Access</p>
								</div>
								<div className="col-md-8">
									<p className="">
										Total: <strong>{state.insider.length}</strong>
									</p>
								</div>
							</div>

							<div className="row article-bg mb-10">
								<div className="col-md-10 offset-md-1">
									{/* <div className="table-responsive">
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <td className="text-bold">Charlie</td>
                                                        <td className="text-bold">Interpol</td>
                                                        <td className="text-bold">Sam J. Houston</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-bold">Charlie</td>
                                                        <td className="text-bold">Interpol</td>
                                                        <td className="text-bold">Sam J. Houston</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-bold">Charlie</td>
                                                        <td className="text-bold">Interpol</td>
                                                        <td className="text-bold">Sam J. Houston</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-bold">Charlie</td>
                                                        <td className="text-bold">Interpol</td>
                                                        <td className="text-bold"></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-bold">Charlie</td>
                                                        <td className="text-bold">Interpol</td>
                                                        <td className="text-bold"></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div> */}
									<ul className="list-unstyled custom-table-list">
										{state.insider.map((elem, idx) => (
											<li>
												<a
													data-toggle="modal"
													onClick={() => getInsider(elem.artist)}
													data-target="#InsiderPopup"
													href="#"
												>
													{" "}
													{elem.artist.name}
												</a>
											</li>
										))}
										{/* <li><a href="#"> X Charlie</a></li>
                                          <li><a href="#"> X Sam J. Houston</a></li>
                                          <li><a href="#"> X Charlie</a></li> */}
									</ul>
								</div>
							</div>
							<div className="row justify-content-center mb20">
								<button
									type="button"
									className="btn-cancel text-uppercase"
									onClick={() => {
										closeMouth(11);
									}}
								>
									Close
								</button>
							</div>
						</div>
					)}
				</div>
			</article>

			<article className="content-heading">
				<div className="container">
					<div className="row m0">
						<h4 className="text-uppercase text-white">Community</h4>
					</div>
				</div>
			</article>

			<article className="article-bg">
				<div className="container pt20">
					{!state.hiddenArticle[12] && (
						<div className="row content-detail mb10 m0">
							<div className="col-md-4 lpadding">
								<p className="text-uppercase text-bold">Social</p>
							</div>
							<div className="col-md-4">
								<p>Link to your Facebook, Twitter, etc.</p>
							</div>
							<div className="col-md-4 rpadding">
								<a
									href="#"
									className="pull-right"
									onClick={() => handleHiddenArticle(12)}
								>
									Edit
								</a>
							</div>
						</div>
					)}
					{/* <!--row ends here--> */}

					{/* <!--Edit Field Content--> */}
					{state.hiddenArticle[12] && (
						<div
							className="detail-edit-box border-bottom-gray social-fan-acc"
							style={{ display: "block" }}
						>
							<div className="row content-edit-view m0">
								<div className="col-md-4 lpadding">
									<p className="text-uppercase text-bold">Social</p>
								</div>
								<div className="col-md-5">
									<form>
										<div className="form-group row">
											<label className="col-2">
												<img
													src={require("../../assets/img/fb-white-ico.png")}
													alt="facebook"
													title="facebook"
												/>
											</label>
											<input
												type="text"
												className="col-10 form-control"
												name=""
												placeholder="URL"
											/>
										</div>
										<div className="form-group row">
											<label className="col-2">
												<img
													src={require("../../assets/img/twitter-white-ico.png")}
													alt="Twitter"
													title="Twitter"
												/>
											</label>
											<input
												type="text"
												className="col-10 form-control"
												name=""
												placeholder="URL"
											/>
										</div>
										<div className="form-group row">
											<label className="col-2">
												<img
													src={require("../../assets/img/gplus-white-ico.png")}
													alt="Google+"
													title="Google+"
												/>
											</label>
											<input
												type="text"
												className="col-10 form-control"
												name=""
												placeholder="URL"
											/>
										</div>
										<div className="form-group row">
											<label className="col-2">
												<img
													src={require("../../assets/img/instagram-white-ico.png")}
													alt="Instagram"
													title="Instagram"
												/>
											</label>
											<input
												type="text"
												className="col-10 form-control"
												name=""
												placeholder="URL"
											/>
										</div>
										<div className="form-group row">
											<label className="col-2">
												<img
													src={require("../../assets/img/viemo-white-ico.png")}
													alt="Viemo"
													title="Viemo"
												/>
											</label>
											<input
												type="text"
												className="col-10 form-control"
												name=""
												placeholder="URL"
											/>
										</div>
										<div className="form-group row">
											<label className="col-2">
												<img
													src={require("../../assets/img/tumbler-white-ico.png")}
													alt="Tumbler"
													title="Tumbler"
												/>
											</label>
											<input
												type="text"
												className="col-10 form-control"
												name=""
												placeholder="URL"
											/>
										</div>
									</form>
								</div>
							</div>
							<div className="row justify-content-center mb20">
								<button
									type="button"
									className="btn-cancel text-uppercase"
									onClick={() => {
										closeMouth(12);
									}}
								>
									Cancel
								</button>
								<button
									type="button"
									className="btn-save text-uppercase"
									onClick={handleSingleSubmit}
								>
									Confirm Changes
								</button>
							</div>
						</div>
					)}
				</div>
			</article>

			<article className="content-heading white-heading inactive">
				<div className="container">
					<div className="row m0">
						<div className="col-md-4 lpadding">
							<h4 className="text-uppercase gray-text">Rights</h4>
						</div>
						<div className="col-md-4">
							<h4 className="text-uppercase gray-text">(Coming Soon)</h4>
						</div>
					</div>
				</div>
			</article>

			<article className="inactive">
				<div className="container pt20">
					<div className="row border-bottom-white content-detail mb10 m0">
						<div className="col-md-4 lpadding">
							<p className="text-uppercase text-bold">Transfer Admin</p>
						</div>
						<div className="col-md-4">
							<p>Transfer ALL artist to another user</p>
						</div>
						<div className="col-md-4 rpadding">
							<a
								href="#"
								className="pull-right"
								onClick={() => handleHiddenArticle(13)}
							>
								Edit
							</a>
						</div>
					</div>
					{/* <!--row ends here--> */}
				</div>
			</article>

			<article className="inactive">
				<div className="container">
					<div className="row border-bottom-white content-detail mb10 m0">
						<div className="col-md-4 lpadding">
							<p className="text-uppercase text-bold">Transfer Artist</p>
						</div>
						<div className="col-md-4">
							<p>Transfer ALL artist to another user</p>
						</div>
						<div className="col-md-4 rpadding">
							<a
								href="#"
								className="pull-right"
								onClick={() => handleHiddenArticle(14)}
							>
								Edit
							</a>
						</div>
					</div>
					{/* <!--row ends here--> */}
				</div>
			</article>
		</section>
	);
};

export default AccountInformationComponent;
