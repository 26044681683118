import ArtistHeader from "../../shared/ArtistHeader/header";
import FanHeader from "../../shared/FanHeader/header";
import Header from "../../shared/header/header";
import Footer from "../../shared/footer/footer";


// ================================================================
// Lalo: December 23, 2020 - Change code to make it smaller
//                           and easier to add new FAQs.
// At this time each entry in the JSON file is limited to six
// entries "content_00" through _005. Later I will change the code
// to make it more intelligent, if needed.
// ================================================================

let iCount = 0;
const styles = {
	marginBottom: "5px",
	fontFamily: "Arial",
};

const FAQreadJson = () => {
	return (
		<div>
			{localStorage.getItem("tokenFan") ? (
				<FanHeader />
			) : localStorage.getItem("tokenArtist") ? (
				<ArtistHeader />
			) : (
				<Header />
			)}
			<section id="content" className="pt40">
				<h1>Coming Soon.</h1>
			</section>

			<Footer />
		</div>
	);
};

export default FAQreadJson;
