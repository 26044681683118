import Header from "../../shared/header/header";
import Footer from "../../shared/footer/footer";
import ArtistNewPassword from "../../components/artist/artistResetPassword";

const ArtistNewPasswordConfirm = () => {
	return (
		<div>
			<Header />
			<ArtistNewPassword />
			<Footer />
		</div>
	);
};

export default ArtistNewPasswordConfirm;
