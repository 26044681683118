import validator from "validator";
import axios from "axios";
import { useState } from "react";
import { showNotification } from "../showNotification";

const ContactUsComponent = () => {
	const [state, setState] = useState({
		maxChar: 250,
		error: {},
		email: "",
		subject: "",
		description: "",
	});

	const handleInput = (e) => {
		let name = e.target.name;
		setState((oldState) => ({
			...oldState,
			[e.target.name]: e.target.value,
		}));
		errorValidation(name);
	};

	const errorValidation = (name, flag) => {
		{
			/* Lalo: October 12, 2021 - Took out "Ohone is required" per request in spreadsheet */
		}
		{
			/* let required = { email: 'Email is Invalid', phone: 'Phone Number is required and Should be valid', description: 'Description in required' } */
		}
		let required = {
			email: "Email is Invalid",
			subject: "Subject is required",
			description: "Description is required",
		};
		var err = state.error;
		if (flag) {
			for (var elem in required) {
				errorValidation(elem);
			}
			return;
		}

		if (required[name]) {
			if (name === "email") {
				// alert(validator.isEmail(state.email)||validator.isEmpty(state.email))
				if (!validator.isEmail(state.email) || validator.isEmpty(state.email)) {
					err.email = required.email;
				} else {
					delete err[name];
				}
			} else if (name === "subject") {
				if (validator.isEmpty(state.subject)) {
					err.subject = required.subject;
				} else {
					delete err[name];
				}
			} else if (name === "description") {
				if (validator.isEmpty(state.description)) {
					err.description = required.description;
				} else {
					delete err[name];
				}
			}
		}

		setState((oldState) => ({
			...oldState,
			error: err,
		}));
	};

	const validate = async () => {
		errorValidation("temp", true);
		if (Object.keys(state.error).length > 0) {
			return false;
		}
		await sendRequest();
		return true;
	};

	const sendRequest = () => {
		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};

		axios
			.post("app/contactus", state, config)
			.then(function (response) {
				if (response.data.success) {
					setState((oldState) => ({
						...oldState,
						email: "",
						subject: "",
						description: "",
					}));
					showNotification("Request Sent", "success");
				} else {
					showNotification("Server Error" + response.data.message, "error");
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				//console.log(error);
			});
	};

	return (
		<section id="content" className="pt40">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="privacy-policy-content">
							<div className="privacy-policy-heading">
								<h1 className="text-green din-font text-uppercase text-center">
									Submit a Request
								</h1>
							</div>

							<div className="col-md-8 offset-md-2">
								<h2 className="mb20">
									We want to make the DalbumXperience the absolute, most
									satisfying and fulfilling experience possible for Artists and
									Fans alike! So, if you like something, we want to hear about
									it! And, if you don’t like something or have suggestions on
									how to improve the DalbumXperience, we would love to hear your
									thoughts! Thank you!
								</h2>
								<h2 className="pull-right">
									<span className="red">*</span>Indicates required field
								</h2>
								<div className="form-content mt50">
									<form id="contactUsform">
										<div className="form-group">
											<label className="text-uppercase">
												Your Email<span className="red">*</span>
											</label>
											<input
												value={state.email ?? ""}
												onChange={handleInput}
												name="email"
												type="text"
												className="form-control"
												placeholder=""
											/>
											{state.error.email && (
												<span id="error" className="error-text1">
													{state.error.email}{" "}
												</span>
											)}
										</div>
										<div className="form-group">
											{/* Lalo: October 12, 2021 - removed red asterisk per request - <label className="text-uppercase">Phone<span className="red">*</span></label>   */}
											<label className="text-uppercase">
												Phone<span className="red"></span>
											</label>
											<input
												value={state.phone ?? ""}
												onChange={handleInput}
												name="phone"
												type="text"
												className="form-control"
												placeholder=""
											/>
											{state.error.phone && (
												<span id="error" className="error-text1">
													{state.error.phone}{" "}
												</span>
											)}
										</div>
										<div className="form-group">
											<label className="text-uppercase">
												Subject<span className="red">*</span>
											</label>
											<input
												value={state.subject ?? ""}
												onChange={handleInput}
												name="subject"
												type="text"
												className="form-control"
												placeholder=""
											/>
											{state.error.subject && (
												<span id="error" className="error-text1">
													{state.error.subject}{" "}
												</span>
											)}
										</div>
										<div className="form-group">
											<label className="text-uppercase">
												Description<span className="red">*</span>
											</label>
											<textarea
												value={state.description ?? ""}
												onChange={handleInput}
												name="description"
												className="form-control"
												rows="8"
											></textarea>
											{/* <p>Characters Left: {state.maxChar - (state.chars ? state.chars : 0)}/{state.maxChar}</p> */}
											{state.error.description && (
												<span id="error" className="error-text1">
													{state.error.description}{" "}
												</span>
											)}
										</div>
										<div className="form-group mb20">
											<h3 className="mb10">
												If you’re an Admin and are having issues with your
												account, <br /> please copy/paste your DalbumX URL in
												the field below.
											</h3>
											<input
												value={state.dalbumUrl ?? ""}
												onChange={handleInput}
												name="dalbumUrl"
												type="text"
												className="form-control"
												placeholder=""
											/>
										</div>
										<div className="form-group text-center">
											<button
												type="button"
												className="btn-cancel text-uppercase"
											>
												Cancel
											</button>
											<button
												type="button"
												className="btn-save text-uppercase"
												onClick={validate}
											>
												Send
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ContactUsComponent;
