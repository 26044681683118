import React, { Component, useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import ToolTip from '../toolTip'
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
	EditorState,
	convertToRaw,
	ContentState,
	convertFromHTML,
} from "draft-js";

const TextUploadShared = ({ items, dimension_id, type, onupload }) => {
	const [state, setState] = useState({
		editorState: EditorState.createWithContent(
			ContentState.createFromText("Click here to start.")
		),
		// sampleEditorContent : ContentState.createFromBlockArray(convertFromHTML("<p>dsfsdfsdfsd</p>\n" ) )
		data: htmlToDraft("<p>dsfsdfsdfsd</p>\n"),
	});

	useEffect(() => {
		//console.log()
		if (items.items[0]) {
			setState((oldState) => ({
				...oldState,
				editorState: EditorState.createWithContent(
					ContentState.createFromBlockArray(
						convertFromHTML(items.items[0].text_string)
					)
				),
			}));
		}
	}, []);

	const onEditorStateChange = (editorState) => {
		setState((oldState) => ({
			...oldState,
			editorState,
			dataToSend: draftToHtml(
				convertToRaw(state.editorState.getCurrentContent())
			),
		}));
	};

	const upload = () => {
		if (state.dataToSend.length === 0) {
			return false;
		}
		var returndata = {
			hidden_dimension_id: dimension_id,
			type: type,
			text: state.dataToSend,
		};
		onupload(returndata);
	};

	const { editorState } = state;
	return (
		<section id="content" className="pt50">
			<div className="row mb30 upload-wrapper">
				<div className="col-md-8 rpadding">
					<div className="card">
						{/* <div className="card-header">
                                <div className="row align-items-center">

                                </div>
                            </div> */}
						<div className="card-body bg-white pd30 overflow-scroll h600">
							<div className="upload-wrapper-conent">
								<div className="row mb10">
									<div className="col-md-12">
										<Editor
											editorState={state.editorState}
											toolbar={{
												options: ["inline", "list", "emoji"],
											}}
											toolbarClassName="toolbarClassName"
											wrapperClassName="wrapperClassName"
											editorClassName="editorClassName"
											onEditorStateChange={onEditorStateChange}
											defaultEditorState={state.data}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-4 lpadding">
					<div className="upload-content-detail pd20 bg-white clearfix">
						{/* <h2 className="text-uppercase din-font mb10">Upload Text To:</h2> */}

						{/* <div className="form-group">
                                <input value={album_name} readOnly type="text" name="" className="form-control" placeholder="Album Title" />
                            </div> */}
						<div className="album-specification">
							{/* <h3 className="text-uppercase">Specifications</h3> */}
							<h4>NOTE:</h4>
							<div className="spacer20"></div>
							{/* <h4>METADATA can be included <br />with ISRC numbers.</h4> */}
							<div className="spacer20"></div>
							<h4>
								This will appear on your Artwork <br />
								as user click on it
							</h4>
							<button
								type="button"
								onClick={() => {
									upload();
								}}
								className="btn-save text-uppercase pull-right mt30"
							>
								Save
							</button>
							{/* <ToolTip
                                    message="skjdfsdfdddds" /> */}
						</div>
					</div>
				</div>
			</div>
			<div className="row form-group justify-content-center"></div>
		</section>
	);
};

export default TextUploadShared;
