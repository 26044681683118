import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import url from "../../defaults";
import { confirmAlert } from "react-confirm-alert";
import { showNotification } from "../../shared/showNotification";
import QrCode from "./manageAlbum/qrCode";

const DashBoardComponentArtist = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [state, setState] = useState({
        artists: [],
        albums: [],
        name: "",
        sort: "recent",
        sorted: false,
        firstName: "",
        email: "",
        insider: { subject: "You are invited to be an INSIDER" },
        InsiderData: [],
        selectedArtist: {},
    });

    // ---------------------------------------------------------------------
    // Lalo: March 19, 2022 - To eliminate the "SHARE DALBUM" if it has not
    //                        been pubclished.
    // ---------------------------------------------------------------------
    const shareOrNot = (isPublishedOrNot) => {
        if (isPublishedOrNot) return "Share Dalbum";
        else return "";
    };

    // ---------------------------------------------------------------------
    // Lalo: October 12, 2021 - needed this function for the function below
    //                          The function below is used to show the name
    //                          of the band on top of the Dalbum picture.
    // ---------------------------------------------------------------------
    const replaceAll = (string, search, replace) => {
        return string.split(search).join(replace);
    };

    const getDalbumArtistName = (albumName, artist_id) => {
        let dNameAndArtist = "";
        let bFound = false;
        let i = 0;

        for (i = 0; i < state.artists.length; i++) {
            if (state.artists[i].artist_id === artist_id) {
                dNameAndArtist = JSON.stringify(state.artists[i].name);
                dNameAndArtist = replaceAll(dNameAndArtist, '"', "");
                bFound = true;
                break;
            }
        }
        if (bFound) {
            if (dNameAndArtist.length > 27) return dNameAndArtist.substr(0, 27);
            else return dNameAndArtist;
        }
    };
    // ---------------------------------------------------------------------

    // --- Lalo --- 02/11/2021 Using JC's Alert Function
    // function comingSoon()
    const comingSoon = (index) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="confirm-ui">
                        <div className="delete-prompt-box">
                            <h1
                                className="text-uppercase mb10"
                                style={{ fontFamily: "AvenirNext-UltraLight" }}
                            >
                                COMING SOON
                            </h1>
                            <div
                                style={{
                                    borderTop: "1px solid #278C8e",
                                    marginLeft: 20,
                                    marginRight: 20,
                                    marginBottom: 20,
                                }}
                            ></div>
                            <h2 className="justify-content-center mb40">
                                <strong>Thank you for your patience.</strong>
                            </h2>
                            <div className="d-flex mr-3 justify-content-center">
                                <button
                                    type="button"
                                    onClick={() => {
                                        onClose();
                                        // removing this function call, as navigation intercepted already
                                        // cancelAndClose();
                                    }}
                                    className="btn-save"
                                >
                                    Okay
                                </button>
                            </div>
                        </div>
                    </div>
                );
            },
        });
    };

    const setOnStorage = (album) => {
        sessionStorage.setItem("album_id", album.dalbum_id);
        if (!album.is_published) {
            // sessionStorage.setItem("album_id", album.dalbum_id)
        }
    };

    const editInsider = (e) => {
        let temp = state.insider;
        temp[e.target.name] = e.target.value;
        setState((oldState) => ({
            ...oldState,
            insider: temp,
        }));
    };

    const showArtistDalbumsOnly = (elem) => {
        console.log("At showArtistDalbumsOnly");
    };

    const getInsider = (elem) => {
        var config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
            },
        };

		setState((oldState) => ({
			...oldState,
			selectedArtist: elem,
		}));
		axios
			.post("/app/admin/getInsider", elem, config)
			.then(function (response) {
				if (response.data.success) {
					setState((oldState) => ({
						...oldState,
						InsiderData: response.data.data,
					}));
				} else {
					showNotification(response.data.message, "error");
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
			});
	};

    const AddInsider = (e) => {
        e.preventDefault();

        var config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
            },
        };
        state.insider.artist_id = state.selectedArtist.artist_id;
        axios
            .post("/app/admin/addInsider", state.insider, config)
            .then(function (response) {
                if (response.data.success) {
                    setState((oldState) => ({
                        ...oldState,
                        insider: {},
                    }));
                    // state.artists = response.data.data
                    showNotification("Insider Added", "success");
                } else {
                    showNotification(response.data.message, "error");
                }
            })
            .catch(function (error) {
                showNotification(error.name, "warning");
            });
    };
    const getArtist = () => {
        if (state.sort === "AZ") {
            state.artists.sort(function (a, b) {
                var nameA = a.name.toLowerCase(),
                    nameB = b.name.toLowerCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
            });
            setState((oldState) => ({ ...oldState, sorted: true }));
            return 1;
        }

        var config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
            },
        };
        axios
            .get("/app/admin/dashboard" + "?sort=" + state.sort, config)
            .then(function (response) {
                if (response.data.success) {
                    setState((oldState) => ({
                        ...oldState,
                        name: response.data.data.name,
                        artists: response.data.data.artist,
                        albums: response.data.data.album,
                    }));
                } else {
                    showNotification(response.data.message, "error");
                }
            })
            .catch(function (error) {
                showNotification(error.name, "warning");
            });
    };

    const setAlbum = (album_id) => {
        navigate("/artist/dashboard", {
            state: { album_id: album_id },
        });
    };

    const keepMeinformed = (KeepInform) => {
        console.log(
            "I will keep you informed [" +
            document.getElementById("KeepInform").checked +
            "]"
        );
    };

    const getAlbumCoverCroppedImageName = (cover) => {
        const imageName = cover.name;

        let parts = imageName.split('.');
		let name = parts[0];
		let extension = parts[1];
		return name + '-cropped.' + extension;
    }
    
    const getAlbumConverImage = (album) => {
        const baseUrl = axios.defaults.baseURL;
        const cover = album.artwork_images.find((elem) => {
            return elem.at_position === -1 || elem.at_position === 0;
        });

        const urls = [];

        const croppedImageName = getAlbumCoverCroppedImageName(cover);
        urls.push(`url(${baseUrl}${cover.path}${croppedImageName})`);
        urls.push(`url(${baseUrl}${cover.path}${cover.name})`);
        
        return urls.join(',');
    }
    
    const shareAlbum = (album) => {
        navigate("/artist/share", {
            state: {
                albumData: {
                    album: album
                }
            },
        });
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

        var name = localStorage.getItem("firstName");
        sessionStorage.removeItem("album_id");
        sessionStorage.removeItem("currentStep");
        setState((oldState) => ({
            ...oldState,
            firstName: name,
            email: localStorage.getItem("email"),
        }));
        getArtist();
    }, []);

    let totalArtists = state.artists.length;

    return (
        <>
            <section id="content" className="pt50">
                <div className="container">
                    <div className="row mb80">
                        {/* <div className="col-lg-6"> */}
                        <div className="welcome-box" style={{ paddingLeft: "15px", paddingRight: "15px"}}>
                            <h2 className="mb10 text-bold">Hello, {state.firstName}! </h2>
                            <h3>Welcome to your DASHBOARD, where you can access your artist and Dalbum information.</h3>
                            {/* <h3 className="mb10">
                                        {" "}
                                        
                                    </h3> */}
                            <h3 className="mt20">
                                It’s so wonderful to have you here, and we can’t wait for
                                your fans to Xperience your Dalbums!
                            </h3>
                            <h3 className="mt20">
                                Total Dalbums Published:{" "}
                                {state.albums.filter((elem) => elem.is_published === 1).length}
                            </h3>
                        </div>
                        {/* </div> */}
                    </div>
                    <div className="modal fade" id="InsiderPopup">
                        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered custome-model-full-width">
                            <div className="modal-content custome-model text-center">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal">
                                        ×
                                    </button>
                                </div>
        
                                <div className="modal-body din-font inside-invite">
                                    <div className="top-head">insider invite</div>
                                    <p>
                                        Enter emails to invite others to view all pages for this
                                        artist. They will not be able to make any changes. They will
                                        only be able to see what you have done. People you may want to
                                        share with include: band members, record label execs, your
                                        publicist, your mother. Whoever it is, make sure they're in
                                        your circle of trust. None of your personal information will
                                        be shared. And they will only be able to view this artist, no
                                        others.
                                    </p>
        
                                    <form className="form-horizontal">
                                        <div className="row align-items-center">
                                            <label className="control-label col-md-2" htmlFor="email">
                                                ARTIST:
                                            </label>
                                            <div className="col-md-10">
                                                <p>
                                                    <strong>{state.selectedArtist.name}</strong>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="form-group row align-items-center">
                                            <label className="control-label col-md-2" htmlFor="email">
                                                FROM:
                                            </label>
                                            <div className="col-md-10">
                                                <p>{state.email}</p>
                                            </div>
                                        </div>
                                        <div className="form-group row align-items-center">
                                            <label className="control-label col-md-2" htmlFor="email">
                                                TO:
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    name="email"
                                                    className="form-control"
                                                    onChange={editInsider}
                                                    value={state.insider.email ? state.insider.email : ""}
                                                ></input>
                                            </div>
                                        </div>
                                        <div className="form-group row align-items-center">
                                            <label className="control-label col-md-2" htmlFor="email">
                                                SUBJECT:
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    name="subject"
                                                    className="form-control"
                                                    onChange={editInsider}
                                                    value={
                                                        state.insider.subject ? state.insider.subject : ""
                                                    }
                                                ></input>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="control-label col-md-2" htmlFor="email">
                                                MESSAGE:
                                            </label>
                                            <div className="col-md-10">
                                                <textarea
                                                    name="description"
                                                    className="form-control "
                                                    rows="8"
                                                    onChange={editInsider}
                                                    value={
                                                        state.insider.description
                                                            ? state.insider.description
                                                            : ""
                                                    }
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className="col-lg-12 text-center">
                                                <button
                                                    onClick={AddInsider}
                                                    className="btn-save text-uppercase"
                                                >
                                                    Send Invitation
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="row form-bottom">
                                        <div className="col-md-12">
                                            <div className="heading">current insiders</div>
                                        </div>
                                        <div className="col-md-12">
                                            {state.InsiderData.map((elem, index) => (
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label></label>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <p>{elem.to_email}</p>
                                                    </div>
                                                    {/* <div className='col-md-5 text-right'><p className='text-uppercase'>deny access x</p></div> */}
                                                    <div className="col-md-2">
                                                        <label></label>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    {/* <div className="row form-bottom mb50">
                                                <div className='col-md-12'>
                                                <div className='heading'>current insiders</div>
                                                </div>
                                                <div className='col-md-12'>
                                                <div className='row'>
                                                <div className='col-md-2'>
                                                    <label></label>
                                                </div>
                                                <div className='col-md-5'><p>kapilvarshneyweb@gmail.com</p></div>
                                                <div className='col-md-5 text-right'><p className='text-uppercase'>deny access x</p></div>
                                                </div>
                                                <div className='row'>
                                                <div className='col-md-2'>
                                                    <label></label>
                                                </div>
                                                <div className='col-md-5'><p>kapilvarshneyweb@gmail.com</p></div>
                                                <div className='col-md-5 text-right'><p className='text-uppercase'>deny access x</p></div>
                                                </div>
                                                <div className='row'>
                                                <div className='col-md-2'>
                                                    <label></label>
                                                </div>
                                                <div className='col-md-5'><p>kapilvarshneyweb@gmail.com</p></div>
                                                <div className='col-md-5 text-right'><p className='text-uppercase'>deny access x</p></div>
                                                </div>
                                                </div>
                                            </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
        
                    <div className="row">
                        <div className="col-md-12">
                            <div className="artist-tab-heading light-bg">
                                <a
                                    data-toggle="collapse"
                                    data-target="#collapseArtist"
                                    className="text-uppercase ml-10 text-white"
                                >
                                    Artists ({totalArtists}){" "}
                                </a>
                                <a
                                    href="#"
                                    className="pull-right"
                                    id="dropdownMenuLink"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <i className="fa fa-angle-right text-white"></i>
                                </a>
                                <div
                                    className="custom-toggle dropdown-menu"
                                    aria-labelledby="dropdownMenuLink"
                                >
                                    <a
                                        className="dropdown-item"
                                        onClick={() => {
                                            setState((oldState) => ({ ...oldState, sort: "recent" }));
                                            getArtist();
                                        }}
                                    >
                                        Recent
                                    </a>
                                    <a
                                        className="dropdown-item"
                                        onClick={() => {
                                            setState((oldState) => ({ ...oldState, sort: "AZ" }));
                                            getArtist();
                                        }}
                                    >
                                        A-Z
                                    </a>
                                </div>
                            </div>
                            <div
                                id="collapseArtist"
                                className="collapse show artist-tab-content pd15 light-bg"
                            >
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="artist-list-flex custom-flex">
                                            <div className="artist-pic relative border-white pd10 br10 mb10">
                                                <Link to="/artist/addArtist">
                                                    <img
                                                        className="fullWidthimg"
                                                        src={require("./../../assets/img/add-new-artist.png")}
                                                    />
                                                </Link>
                                            </div>
                                            {state.artists.map((artist) => (
                                                <div
                                                    key={artist.artist_id}
                                                    className="artist-pic relative mb10"
                                                    style={{
                                                        backgroundImage:
                                                            `url(` + url + artist.profile_pic_path + `)`,
                                                    }}
                                                >
                                                    <div className="artist-name absolute">
                                                        <p className="text-white mb0 pd5">{artist.name}</p>
                                                    </div>
                                                    <div className="manage-profile">
                                                        <ul className="list-unstyled">
                                                            <li>
                                                                <Link
                                                                    to="/artist/editArtist"
                                                                    state={{
                                                                        isBand: artist.is_band,
                                                                        id: artist.artist_id,
                                                                        name: artist.name,
                                                                    }}
                                                                    className="text-uppercase text-white"
                                                                >
                                                                    Manage Profile
                                                                </Link>
                                                            </li>
                                                            {/* Lalo: December 16, 2020 - Comment out "Insider Access"
                                                                    <li>
                                                                        <a data-toggle="modal" onClick={() => getInsider(artist)} data-target="#InsiderPopup" className="text-uppercase text-white">Insider Access</a>
                                                                    </li>
                                                                    */}
                                                            <li>
                                                                <a
                                                                    onClick={() => showArtistDalbumsOnly(artist)}
                                                                    className="text-uppercase text-white"
                                                                >
                                                                    Artist Dalbums
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <Link
                                                                    to="/artist/addAlbum"
                                                                    state={{
                                                                        id: artist.artist_id,
                                                                    }}
                                                                    className="text-uppercase text-white"
                                                                >
                                                                    {" "}
                                                                    Add New DAlbum
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        
                    <div className="row mb30">
                        <div className="col-md-12">
                            <div className="artist-tab-heading bg-white">
                                <a
                                    href="#"
                                    data-toggle="collapse"
                                    data-target="#collapseAlbum"
                                    className="text-uppercase ml-10 text-orange"
                                >
                                    DAlbums ({state.albums.length})
                                </a>
                                <a href="#" className="pull-right">
                                    <i className="fa fa-angle-right text-orange"></i>
                                </a>
                            </div>
                            <div
                                id="collapseAlbum"
                                className="collapse show artist-tab-content pd15 bg-white"
                            >
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="">
                                            <div className="album-list-flex custom-flex">
                                                <div className="album-img relative border-green pd10 br10" style={{
                                                    height: "206px",
                                                    width: "206px",
                                                }}>
                                                    <Link
                                                        to="/artist/addAlbum"
                                                        onClick={() => {
                                                            sessionStorage.removeItem("album_id");
                                                        }}
                                                    >
                                                        <img
                                                            src={require("./../../assets/img/Original2.png")}
                                                        />
                                                    </Link>
                                                </div>
        
                                                {state.albums.map((album, index) => (
                                                    <div
                                                        key={index}
                                                        className="album-img relative"
                                                        style={{
                                                            backgroundImage: album.artwork_images.some((elem) => {
                                                                return elem.at_position === -1 || elem.at_position === 0;
                                                            })
                                                                ? getAlbumConverImage(album)
                                                                : "",
                                                            backgroundSize: "cover",
                                                            backgroundRepeat: "no-repeat",
                                                            border: album.is_published === 1 ? "5px solid #d95335" : "none", // Conditionally apply border style
                                                            height: "206px",
                                                            width: "206px",
                                                        }}
                                                    >
                                                        <div className="artist-name absolute">
                                                            <p className="text-white mb0 pd5 hover-style" >
                                                                {album.name === null
                                                                    ? "Name Missing"
                                                                    : album.name.length > 26
                                                                        ? album.name.substr(0, 26) + "..."
                                                                        : album.name}
                                                            </p>
                                                            <p className="text-white mb0 pd5">
                                                                {getDalbumArtistName(
                                                                    album.name,
                                                                    album.artist_id,
                                                                    album.is_published
                                                                )}
                                                            </p>
                                                        </div>
                                                        {/* ============================================================ */}
                                                        <div className="album-manage-profile">
                                                            <ul className="list-unstyled">
                                                                <li>
                                                                    <Link
                                                                        to={
                                                                            album.is_published
                                                                                ? "/artist/manage-album"
                                                                                : "/artist/addalbum"
                                                                        }
                                                                        state={{ album: album }}
                                                                        onClick={() => {
                                                                            setOnStorage(album);
                                                                        }}
                                                                        className="text-uppercase text-white"
                                                                    >
                                                                        Manage Dalbum
                                                                    </Link>
                                                                </li>
                                                                {album.is_published === null ? <li style={{ display: "none" }}>
                                                                    <a
                                                                        onClick={() => shareAlbum(album)}
                                                                        className="text-uppercase text-white"
                                                                    >
                                                                        {shareOrNot(album.is_published)}
                                                                    </a>
                                                                </li> : <li>
                                                                    <a
                                                                        onClick={() => shareAlbum(album)}
                                                                        className="text-uppercase text-white"
                                                                    >
                                                                        {shareOrNot(album.is_published)}
                                                                    </a>
                                                                </li>}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                ))}
        
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        
                    <div className="row mb30">
                        <div className="col-md-6"></div>
                        <div className="col-md-6">
                            <div className="dark-bg pl15 pr15 pb0   text-center text-white">
                                <h2 className="text-white text-uppercase din-font pt10">
                                    Money that's what i want (and deserve)
                                </h2>
        
                                <Link
                                    to="/artist/how-to-sell"
                                    className="text-white text-bold"
                                    style={{ textDecoration: "underline" }}
                                >
                                    Learn More
                                </Link>
        
                                <p className="text-center mb0">
                                    <img src={require("./../../assets/img/logo-bottom.png")} />
                                </p>
                            </div>
                        </div>
                    </div>
        
                    <div className="row mb30">
                        <div className="col-md-12 download-app">
                            <ul className="list-inline">
                                <li className="list-inline-item">
                                    <a href="#">
                                        <img src="img/app-store.png" alt="" title="" />
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="#">
                                        <img src="img/google-play.png" alt="" title="" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default DashBoardComponentArtist;