import React, { Component, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { withRouter } from "./../../shared/withRouter";
import validator from "validator";
import { showNotification } from "../../shared/showNotification";

const LoginComponent = () => {
	const navigate = useNavigate();
	const params = useParams();
	const nameInput = useRef();

	const [state, setState] = useState({
		email: "",
		password: "",
		rememberPassword: "",
		errors: {},
	});

	useEffect(() => {
		nameInput.current.focus();

		if (params.message) {
			showNotification(params.message, "success");
		}
	}, []);

	const validate = (fields) => {
		var errors = { ...state.errors };

		if (Array.isArray(fields))
			fields.map(
				function (val) {
					errors = _validate(val, errors);
				}.bind(this)
			);
		else errors = _validate(fields, errors);

		setState((oldState) => ({
			...oldState,
			errors: errors,
		}));

		return Object.keys(errors).length;
	};

	const _validate = (field, errors) => {
		if (field === "email") {
			if (!validator.isEmail(state.email)) errors.email = "Invalid email";
			else delete errors.email;
		}

		if (field === "password") {
			if (validator.isEmpty(state.password))
				errors.password = "Please specify password";
			else delete errors.password;
		}
		//console.log(field, errors)
		return errors;
	};

	const handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;
		setState((oldState) => ({
			...oldState,
			[name]: value,
			serverError: "",
		}));
	};

	const handleBlur = (event) => {
		validate(event.target.name);
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		var errors = true;
		errors = validate(["email", "password"]);

		if (errors) return;

		axios
			.post("/auth/fan/login", {
				email: state.email,
				password: state.password,
			})
			.then(function (response) {
				localStorage.setItem("tokenFan", response.data.data.token);
				if (response.data.success) {
					showNotification("Login Successfull !", "success");
					navigate("/fan/dashboard");
				} else {
					showNotification(
						"Invalid Email or Password !" + response.data.message,
						"error",
					);
					setState((oldState) => ({
						...oldState,
						serverError: "Either your Email or Password is incorrect",
					}));
				}
			})
			.catch(function (error) {
				showNotification(
					"Either your Email or Password is incorrect",
					"error"
				);
				setState((oldState) => ({
					...oldState,
					serverError: "Either your Email or Password is incorrect",
				}));
			});
	};

	return (
		<section id="content" className="fan-side-bg flex-wrapper">
			<div className="container">
				<div className="row">
					<div className="col-lg-4 col-md-6 mr-auto ml-auto col-sm-8">
						<div className="login-wrapper">
							<form onSubmit={handleSubmit}>
								<div className="form-group">
									<input
										type="text"
										className="form-control"
										id="exampleInputEmail1"
										aria-describedby="emailHelp"
										placeholder="Email"
										name="email"
										ref={(input) => {
											nameInput.current = input;
										}}
										onChange={(e) => handleInputChange(e)}
										onBlur={(e) => handleBlur(e)}
									/>
									{state.errors.email != "" && (
										<span id="error" className="form-text error-text">
											{state.errors.email}
										</span>
									)}
									{state.serverError != "" && (
										<span id="error" className="form-text error-text">
											{" "}
											{state.serverError}{" "}
										</span>
									)}
								</div>
								<div className="form-group">
									<input
										type="password"
										className="form-control"
										id="exampleInputPassword1"
										placeholder="Your Password"
										name="password"
										autoComplete="on"
										onChange={(e) => handleInputChange(e)}
										onBlur={(e) => handleBlur(e)}
									/>
									{state.errors.password && (
										<span id="error" className="form-text error-text">
											{state.errors.password}{" "}
										</span>
									)}
								</div>
								<div className="form-group">
									<button
										id="btnlogin"
										type="submit"
										className="btn btn-gradient btn-block"
									>
										Login
									</button>
								</div>
								<div className="form-group">
									<Link to={"/fan/forgetpassword"}>
										{" "}
										<span>Forgot your Password?</span>
									</Link>
								</div>
								<div className="form-group form-check text-center">
									<input
										type="checkbox"
										className="form-check-input"
										id="RememberMe"
										name="rememberPassword"
										onClick={handleInputChange}
									/>
									<label className="form-check-label" htmlFor="RememberMe">
										Remember Me
									</label>
								</div>
								<div className="mb20 text-center round-list">
									<ul className="list-inline mb0">
										<li className="list-inline-item">
											<div className="circle"></div>
										</li>
										<li className="list-inline-item">
											<div className="circle"></div>
										</li>
										<li className="list-inline-item">
											<div className="circle"></div>
										</li>
										<li className="list-inline-item">
											<div className="circle"></div>
										</li>
										<li className="list-inline-item">
											<div className="circle"></div>
										</li>
										<li className="list-inline-item">
											<div className="circle"></div>
										</li>
									</ul>
								</div>
								<h3 className="text-gray text-center mb10">Not Registered?</h3>
								<div className="form-group">
									<Link to={"/fan/register"}>
										<button
											type="submit"
											className="btn btn-gradient btn-block"
										>
											Register
										</button>
									</Link>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default withRouter(LoginComponent);
