import { Link } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { showNotification } from "../../shared/showNotification";

const RedeemCouponComponent = () => {
	const [state, setState] = useState({
		coupon: "",
		error: null,
		album: {},
	});

	const handleInputChange = (event) => {
		setState((oldState) => ({
			...oldState,
			coupon: event.target.value,
		}));
	};

	const validate = (event) => {
		event.preventDefault();

		if (!state.coupon || state.coupon === "") {
			setState((oldState) => ({
				...oldState,
				error: "Please Enter Coupon",
			}));
		} else {
			handleSubmit(event);
			setState((oldState) => ({
				...oldState,
				error: null,
			}));
		}
	};

	const handleSubmit = (event) => {
		var config = {
			headers: { Authorization: "Bearer " + localStorage.getItem("tokenFan") },
		};
		axios
			.post("/app/fan/redeem-coupon", { coupon: state.coupon }, config)
			.then(function (response) {
				if (response.data.success) {
					setState((oldState) => ({
						...oldState,
						success: true,
						album: response.data.data.data,
					}));
				} else {
					// notify.show('Email does not exist', 'error', 1200);
					setState((oldState) => ({
						...oldState,
						error: response.data.message,
					}));
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning");
				//console.log(error);
			});
	};

	return (
		<div>
			{!state.success && (
				<section id="content" className="fan-side-bg flex-wrapper">
					<div className="container">
						<div className="row">
							<div className="col-lg-6 col-md-6 mr-auto ml-auto col-sm-8">
								<h2 className="font67 text-uppercase text-gray text-center mb30 din-font">
									Have A Coupon?
								</h2>
								<h5 className="text-center">Just Redeem It</h5>
								<div className="spacer40"></div>
								<div className="login-inner-wrapper text-center">
									<form>
										<div className="form-group">
											<h4 className="bold-text text-gray mb20">Enter Coupon</h4>
											<input
												type="text"
												className="form-control"
												id=""
												aria-describedby="emailHelp"
												placeholder="Enter Coupon"
												name="coupon"
												onChange={handleInputChange}
											/>
											{state.error && (
												<span id="error" className="form-text error-text">
													{state.error}
												</span>
											)}
										</div>
										<div className="form-group">
											<button
												className="btn btn-gradient btn-block"
												onClick={validate}
											>
												Continue <i className="fa fa-angle-right"></i>
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</section>
			)}
			{state.success && (
				<section id="content" className="fan-side-bg flex-wrapper">
					<div className="container">
						<div className="row">
							<div className="col-lg-6 col-md-6 mr-auto ml-auto col-sm-8">
								<h2 className="font67 text-uppercase text-gray text-center mb30 din-font">
									Coongratulation!
									<br /> Be Happy!
								</h2>
								<h5 className="text-justify">
									Album {state.album.name} has been Added to Your Library
									<b></b>
									<Link to="/fan/library" className="text-underline">
										Go to Library
									</Link>
								</h5>
							</div>
						</div>
					</div>
				</section>
			)}
		</div>
	);
};

export default RedeemCouponComponent;
