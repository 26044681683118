import ArtistHeader from "../../shared/ArtistHeader/header";
import FanHeader from "../../shared/FanHeader/header";
import Header from "../../shared/header/header";
import Footer from "../../shared/footer/footer";
import BlogComponent from "../../components/artist/blogs/blogComponent";

const Blog = () => {
	return (
		<div>
			{localStorage.getItem("tokenFan") ? (
				<FanHeader />
			) : localStorage.getItem("tokenArtist") ? (
				<ArtistHeader />
			) : (
				<Header />
			)}
			{/* <Header /> */}
			<BlogComponent />
			<Footer />
		</div>
	);
};

export default Blog;
