import axios from "axios";

const Preview = (props) => {
	let arr = [
		{ column_name: "lastname", is_visible: 0 },
		{ column_name: "company", is_visible: 0 },
		{ column_name: "email", is_visible: 0 },
		{ column_name: "website_url", is_visible: 0 },
	];
	let a = props.biz_contact.filter(function (x) {
		return x.artist_profile_permissions.length == arr.length;
	}).length;

	return (
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					<div className="artist-profile-heading">
						{/* Lalo - November 3, 2020 - Change the page title to include the artist name*/}
						<h1 className="text-center text-uppercase text-bold text-green big-text mb20">
							{props.artist.name} (Preview)
						</h1>
					</div>
				</div>
			</div>
			<div className="row justify-content-center mb30 fullButton">
				{/* Lalo - 11/06/2020 - Commented out the button as there is no functionality available at this time for this feature.
                <button className="btn-gray text-uppercase" type="button" data-toggle="modal" data-target=".insider-invite">Insider Access</button>
*/}
				<button
					className="btn-gray text-uppercase"
					type="button"
					onClick={props.togglePreview}
				>
					Back
				</button>

				{/* Lalo - 11/03/2020 - Change the onClick to go to the Dashboard and not to the Edit */
				/* Old Code: onClick={props.togglePreview}                                           */}
				<button
					className="btn-orange text-uppercase"
					type="button"
					onClick={(event) => (window.location.href = "/artist/dashboard")}
				>
					Dashboard
				</button>
			</div>

			<div className="row d-mb-10">
				<div className="col-md-6 mb10">
					<div
						className="artist-profile-pic relative"
						style={{ backgroundImage: "img/dummy-pic.png" }}
					>
						{props.profilePicFile && (
							<img
								style={{
									height: 539 + "px",
									width: 539 + "px",
									objectFit: "cover",
								}}
								src={URL.createObjectURL(props.profilePicFile)}
							/>
						)}

						{!props.profilePicFile && props.artist.profile_pic_path && (
							<img
								src={axios.defaults.baseURL + props.artist.profile_pic_path}
								style={{
									height: 539 + "px",
									width: 100 + "%",
									objectFit: "cover",
								}}
							/>
						)}
					</div>
				</div>
				<div className="col-md-6 mb10">
					<div className="bg-green pd20 height540 artist-bio profile-preview-green overflow-scroll">
						{/* Lalo - November 3, 2020 - Changed <h2> to <h3> to get a smaller font.
                            plus included the <font> tag to play around with the size to fill the box - text-justify*/}
						<h3 className="text-white">
							<br />
							<font size="3">{props.artist.bio && props.artist.bio !== 'null' ? props.artist.bio : ''}</font>
						</h3>
					</div>
				</div>
			</div>

			<div className="row mb30 mobile-artist-box1">
				<div style={{paddingRight:"0px"}} className="col-md-6">
					<div className="bg-black content-scroll-780 height780 pd45 mCustomScrollbar">
						<div className="row mb20">
							<div className="col-md-12 text-right">
								<h4 className="text-white big-text mb5">{props.artist.name}</h4>
								{/* {props.artist.location && <h2 className="text-white mb20">{props.artist.location}</h2>} */}
								{/* {props.artist.record_label && <h2 className="text-white mb20">{props.artist.record_label}</h2>} */}
								{props.artist.location && props.artist.location !== 'null' &&
									!props.artist.artist_profile_permissions.some((x) => {
										return x.column_name == "location" && x.is_visible == 0;
									}) && (
										<h2 className="text-white mb5">
											Location: {props.artist.location}
										</h2>
									)}
								{props.artist.record_label && props.artist.record_label !== 'null' &&
									!props.artist.artist_profile_permissions.some((x) => {
										return x.column_name == "record_label" && x.is_visible == 0;
									}) && (
										<h2 className="text-white mb5">
											Record Label: {props.artist.record_label}
										</h2>
									)}
								<h2 className="text-white mb40">
									Genre:
									{props.genre && (
										<span>
											{" "}
											{props.genre.some(
												(elem) => elem.genre_id == props.artist.genre
											)
												? props.genre.find(
														(elem) => elem.genre_id == props.artist.genre
												  ).name
												: ""}
										</span>
									)}
									{props.genre &&
										!props.artist.artist_profile_permissions.some((x) => {
											return x.column_name == "sub_genre" && x.is_visible == 0;
										}) && (
											<span>
												{" "}
												/{" "}
												{props.sub_genre.some(
													(elem) => elem.sub_genre_id == props.artist.sub_genre
												)
													? props.sub_genre.find(
															(elem) =>
																elem.sub_genre_id == props.artist.sub_genre
													  ).name
													: ""}
											</span>
										)}
								</h2>
								{/* {props.sub_genre && <h2 className="text-white mb40">{props.sub_genre.find(elem=>elem.sub_genre_id==props.artist.sub_genre).name}</h2>} */}

								{/* <h2 className="text-white mb20"> + {props.sub_genre}</h2> */}

								{/* Lalo - November 3, 2020 - Changed <h1> to <h2>zx */}
								<h2 className="text-white mb5 text-uppercase">
									{props.artist.is_band ? "Band Members" : "ARTIST:"}
								</h2>
								{props.musician.map((musician) => (
									<div>
										<h4 className="text-white mb5">
											{musician.firstname} {musician.lastname}
										</h4>
										<div>
											{/* <h2 className="text-white mb20">{musician.instrument.map((inst, idx) => <>{idx != 0 && '+'} {props.idToName(inst.musician_instrument_id)} </>)}</h2> */}
											<h4 className="text-white mb20">
												{musician.instrument.map((inst, idx) => (
													<>
														{idx != 0 && "+"} {inst.musician_instrument_id}{" "}
													</>
												))}
											</h4>
											{/* <h2 className="text-white mb20">{instrument.name} +</h2> */}
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-6 mobile-artist-box">
					<div className="bg-orange height780 pd15">
						<h3 className="text-white text-uppercase mb10 big-text">Connect</h3>
						{/* <h2 className="text-white mb20">{this.state.dalbumUrl}</h2>
                            Lalo - 11/03/2020 - Every <h> tag was moved down by 1
                            Took the hardcoded "http://" out because it appeared twice on the page.
                            <h4><a href={"http://"+props.artist.website_url} className="text-white mb20"><strong>Website:</strong> http://{props.artist.website_url}</a></h4>
                         */}
						<h4>
							{props.artist.website_url && props.artist.website_url !== 'null' &&
								<a href={props.artist.website_url} className="text-white mb20">
									<strong>Website:</strong> {props.artist.website_url}
								</a>
							}
						</h4>
						{props.artist.email && props.artist.email !== 'null' && 
							<h4 className="text-white mb20 mt-2">
								<strong>Email:</strong> {props.artist.email}
							</h4>
						}
						<h3 className="text-white mb20 text-uppercase">FOLLOW ON:</h3>
						<ul className="list-inline mb0">
							{props.social.map((elem) => (
								<>
									{elem.social_link_id == 1 && (
										<li className="list-inline-item">
											<a href={"http://" + elem.link} target="blank">
												{" "}
												<img
													src={require("../../../assets/img/fb-white-ico.png")}
													className="media-icon"
													alt=""
													title=""
												/>
											</a>
										</li>
									)}

									{elem.social_link_id == 2 && (
										<li className="list-inline-item">
											<a href={"http://" + elem.link} target="blank">
												{" "}
												<img
													src={require("../../../assets/img/twitter-white-ico.png")}
													className="media-icon"
													alt=""
													title=""
												/>
											</a>
										</li>
									)}

									{elem.social_link_id == 3 && (
										<li className="list-inline-item">
											<a href={"http://" + elem.link} target="blank">
												{" "}
												<img
													src={require("../../../assets/img/instagram-white-ico.png")}
													className="media-icon"
													alt=""
													title=""
												/>
											</a>
										</li>
									)}
								</>
							))}
						</ul>
					</div>
				</div>
			</div>

			{a != props.biz_contact.length && (
				<div className="row mb30 mobile-artist-box1">
					<div className="col-md-12">
						<div className="pd20 bg-gray">
							<div className="row mb20">
								<div className="col">
									{/* Lalo - November 3, 2020 - Changed <h1> to <h2> */}
									<h2 className="text-white text-uppercase mb0">
										Business <br /> Contacts
									</h2>
								</div>
							</div>
							{props.biz_contact.length == 0 && (
								<h1 className="text-white text-uppercase mb0">
									{" "}
									No Contacts Available !{" "}
								</h1>
							)}
							{props.biz_contact.map((biz) => (
								<div className="row mb20">
									<div className="col text-right">
										{!biz.artist_profile_permissions.some((x) => {
											return x.column_name == "lastname";
										}) && (
											<h3 className="text-white">
												{props.primaryRoles.find(
													(elem) => elem.primary_role_id == biz.primary_role_id
												)
													? props.primaryRoles.find(
															(elem) =>
																elem.primary_role_id == biz.primary_role_id
													  ).name
													: ""}{" "}
												: {biz.firstname} {biz.lastname}
											</h3>
										)}
										{!biz.artist_profile_permissions.some((x) => {
											return x.column_name == "email";
										}) && <h3 className="text-white">{biz.email}</h3>}
										{!biz.artist_profile_permissions.some((x) => {
											return x.column_name == "website_url";
										}) && (
											<h3 className="text-white">
												<a href={"http://" + biz.website_url} target="_blank">
													{biz.website_url}
												</a>
											</h3>
										)}
										{!biz.artist_profile_permissions.some((x) => {
											return x.column_name == "company";
										}) && <h3 className="text-white"> {biz.company}</h3>}
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			)}
			<div className="row mb30">
				<div className="col-md-12 download-app">
					<ul className="list-inline">
						<li className="list-inline-item">
							<a href="#">
								<img src="img/app-store.png" alt="" title="" />
							</a>
						</li>
						<li className="list-inline-item">
							<a href="#">
								<img src="img/google-play.png" alt="" title="" />
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};
export default Preview;
