import React, { useEffect, useState } from "react";
import axios from "axios";
import validator from "validator";
import { withRouter } from "./../../shared/withRouter";
import { useNavigate } from "react-router";
import { showNotification } from "../../shared/showNotification";

const AddArtistComponent = ({ artistName }) => {
	const navigate = useNavigate();
	const [state, setState] = useState({
		isChecked: false,
		name: "",
		soloOrBand: "",
		validName: true,
		redirect: false,
	});

	useEffect(() => {
		if (artistName) {
			setState((oldState) => ({
				...oldState,
				name: artistName,
				redirect: true,
			}));
		}
	}, []);

	const handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;
		setState((oldState) => ({
			...oldState,
			[name]: value,
			error: ""
		}));
	};

	const validate = () => {
		if (validator.isEmpty(state.name)) {
			setState((oldState) => ({
				...oldState,
				validName: false,
				error: "Please specify Artist Name",
			}));
			return true;
		}
		if (validator.isEmpty(state.soloOrBand)) {
			setState((oldState) => ({
				...oldState,
				validName: false,
				error: "Please specify whether the Artist is Solo or Band.",
			}));
			return true;
		}

		/**
		 * (JCR) really hackish way to check  Validator library doesn't have defined pattern for alpha-humeric-AND spaces, so
		 * ned to create one with REGEX and not used npm validator package for this check. TODO- future state to have large mapping
		 * of acceptable characters including other special character sets
		 *
		 * ex: https://stackoverflow.com/questions/13283470/regex-for-allowing-alphanumeric-and-space
		 *
		 */
		const regexPattern = new RegExp(/[\w]+([-_\s]{1}[a-zA-Z0-9]+)*$/i);
		if (!regexPattern.test(state.name)) {
			setState((oldState) => ({
				...oldState,
				validName: false,
				error: "Only alpha-numeric, space, hyphen, or underscores",
			}));
			return true;
		} else {
			setState((oldState) => ({
				...oldState,
				validName: true,
				error: null,
			}));
			return false;
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		if (validate()) return;

		setState((oldState) => ({ ...oldState, isChecked: true }));

		var config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tokenArtist"),
			},
		};
		axios
			.post(
				"app/addartist",
				{
					name: state.name,
					is_band: state.soloOrBand === "solo" ? 0 : 1,
				},
				config
			)
			.then(function (response) {
				if (response.data.success) {
					showNotification("Artist Added !", "success")
					navigate("/artist/editArtist", {
						state: {
							id: response.data.data.artist_id,
							name: state.name,
							isBand: state.soloOrBand === "band" ? true : false,
							redirect: state.redirect,
						},
					});
				} else {
					setState((oldState) => ({
						...oldState,
						error: response.data.error,
					}));
					showNotification(response.data.error, "error");
				}
			})
			.catch(function (error) {
				showNotification(error.name, "warning")
			});
	};

	return (
		<section id="content" className="artist-side-bg flex-wrapper">
			<div className="container">
				<div className="row">
					<div className="col-lg-10 col-md-12 mr-auto ml-auto col-sm-8 custom-font">
						<h2 className="font60 text-uppercase text-gray text-center mb20 din-font ">
							Register a new Artist
						</h2>
						{/* Lalo: 02/12/2021 Change text as provided by Manolo */}
						<p>
							<strong>IMPORTANT INFORMATION:</strong>
						</p>
						<ol>
							<li>
								The Artist Name will officially be stored after your first
								Dalbum has been published.
							</li>
							<li>
								You have 30 days to publish your Dalbum once you begin the
								process.
							</li>
							<li>
								It must be your actual Artist Name, but please be aware that
								DalbumX does NOT allow duplicate Artist Names. No two artists
								can register the same name; (We apologize, John Smith!)
							</li>
							<li>
								For “non-established” names (not trademarked), our policy is
								first come, first served. In case of a duplicate, you can get
								creative (EG: John Smith from Norwalk)
							</li>
							<li>
								For a suspicious or reported ‘false name’, DalbumX will suspend
								and investigate. If you registered another artist's established
								name, your Dalbum(s) and Artist info will be permanently
								deleted. There are no refunds to published Dalbums.
							</li>
							<li>
								Triple check that your Artist Name is correct. You will NOT be
								able to change the name once you click ’Continue’.
							</li>
							<li>
								If you made a mistake with your Artist Name, delete the Artist
								completely (through Manage Profile) and start over. All content
								corresponding to that Artist Name will also be deleted.
							</li>
						</ol>
						<span>
							{" "}
							Please remember that there are no refunds to published Dalbums. In
							others words, just keep it real, friends.
						</span>
						<br></br>
						<p></p>
						<span>
							Make sure you correctly select whether you are a solo artist or a
							band. This will affect the type of information you can include
							later.
						</span>
						<br></br>
						<span>
							For a solo artist, if you want to include your Backup Band
							credits, you will be able to do so later.
						</span>
						<div className="spacer20"></div>

						<div className="login-inner-wrapper text-center">
							<form>
								<div className="form-group">
									<input
										type="text"
										className="form-control"
										id=""
										aria-describedby="emailHelp"
										placeholder="Add Artist"
										name="name"
										value={state.name}
										onChange={handleInputChange}
										onBlur={validate}
									/>
									{state.error && (
										<span id="error" className="error-text">
											{state.error}
										</span>
									)}
								</div>
								<div className="form-group row align-items-center">
									<label className="col-sm-4 mb0" htmlFor="name">
										Solo or Band
									</label>
									<div className="col-md-8 lpadding">
										<div className="d-inline form-group text-center">
											<input
												className="form-check-input"
												type="radio"
												id="Solo"
												name="soloOrBand"
												value="solo"
												onChange={handleInputChange}
											/>

											<label className="form-check-label" htmlFor="Solo">
												Solo
											</label>
										</div>
										<div className="d-inline form-group form-check text-center">
											<input
												className="form-check-input"
												type="radio"
												id="Band"
												name="soloOrBand"
												value="band"
												onChange={handleInputChange}
											/>
											<label className="form-check-label" htmlFor="Band">
												Band
											</label>
										</div>
									</div>
								</div>
								<div className="form-group">
									<button
										type="submit"
										className="btn btn-gradient btn-block"
										onClick={handleSubmit}
									>
										Continue <i className="fa fa-angle-right"></i>
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default withRouter(AddArtistComponent);
