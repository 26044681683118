import { useEffect, useRef } from "react";
import Header from "../../shared/ArtistHeader/header";
import Footer from "../../shared/footer/footer";
import { useLocation, useNavigate } from "react-router";
import { getArtworkUrls } from "../../components/artworkHelper";

const FinalCheck = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const contentRef = useRef(null);

	useEffect(() => {
		contentRef.current.scrollIntoView({ behavior: "smooth" });
		if (!location.state || !sessionStorage.getItem("album_id")) {
			const pathArray = window.location.href.split("/");
			const protocol = pathArray[0];
			const host = pathArray[2];
			const url = protocol + "//" + host;
			window.location = url + "/artist/dashboard";
		}
	}, []);

	const proceed = () => {
		navigate("/artist/pre-publish-preview", {
			state: { album_id: sessionStorage.getItem("album_id") },
		});
	};

	const back = () => {
		navigate("/artist/addalbum");
	};

	return (
		<div ref={contentRef}>
			<Header />
			<div>
			<div className="row no-gutters" >
				<div className="col-sm-12 col-md-3 mobile-hide">
					<div
						className="core-upload-left"
						style={{
							backgroundImage: `${getArtworkUrls({
								name: location.state.artwork_name,
								square: true,
							})}`,
						}}
					></div>
				</div>
				<div className="col-sm-12 col-md-6">
					<div className="core-upload-middle pd30 bg-white height600 mobilAutoHeight">
						<div className="core-upload-content text-center">
							<h2 className="text-uppercase text-gray mb0">
								you have Just Uploaded Your
							</h2>
							<h1 className="text-uppercase big-text text-gray mt-0 din-font">
								Core Dalbum
							</h1>
							<p className="text-justify">
								You can continue to make changes until you PUBLISH. Once
								officially published, however, this is the part of your Dalbum
								that CANNOT be changed. You will be able to edit all of your
								Dalbum Dextras all you want, but NOT your CORE DALBUM. Why?
								Because your fans deserve consistency when it comes to your
								official presentation. Please double, triple, quadruple check
								everything in your CORE DALBUM. Imagine you were sending this to
								be pressed as a CD or Vinyl – once it is pressed, there is no
								turning back! (Yes, you can add Credits after publishing, but
								they cannot be removed afterwards.)
							</p>
						</div>
					</div>
				</div>
				<div className="col-sm-12 col-md-3 mobile-hide">
					<div
						className="core-upload-right relative"
						style={{
							backgroundImage:
								`url(` +
								require("./../../assets/img/core-upload-right.png") +
								`)`,
						}}
					></div>
				</div>
			</div>
			</div>
			<div className="row no-gutters mb30">
				<div className="col-sm-12 col-md-3 mobile-hide">
					<div
						className="core-upload-double"
						style={{
							backgroundImage:
								`url(` +
								require("./../../assets/img/core-upload-right.png") +
								`)`,
						}}
					></div>
				</div>

				<div className="col-sm-12 col-md-6">
					<div className="core-upload-middle pd30 bg-gray height600 mobilAutoHeight">
						<div className="core-upload-content">
							<h1 className="text-uppercase big-text text-gray mt-0 din-font text-white">
								Double, Triple, Quadruple check:
							</h1>
							<ul className="list-unstyled">
								<li>
									<p href="#" className="text-white">
										No typos in the text
									</p>
								</li>
								<li>
									<p href="#" className="text-white">
										All the tracks uploaded correctly
									</p>
								</li>
								<li>
									<p href="#" className="text-white">
										Did you listen to all tracks front to back?
									</p>
								</li>
								<li>
									<p href="#" className="text-white">
										Make sure your tracks are in the correct order
									</p>
								</li>
								<li>
									<p href="#" className="text-white">
										You included your hidden tracks, if you have any
									</p>
								</li>
								<li>
									<p href="#" className="text-white">
										Your embedded materials are in the right place
									</p>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div className="col-sm-12 col-md-3 mobile-hide">
					<div
						className="core-upload-left relative"
						style={{
							backgroundImage: `${getArtworkUrls({
								name: location.state.artwork_name,
								square: true,
							})}`,
						}}
					></div>
				</div>
			</div>
			<div className="row form-group justify-content-center no-margin">
				<button
					type="button"
					className="btn-cancel text-uppercase"
					onClick={back}
				>
					Back
				</button>
				<button
					type="button"
					className="btn-save text-uppercase"
					onClick={proceed}
				>
					Preview
				</button>
			</div>

			<Footer />
		</div>
	);
};

export default FinalCheck;
