import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import {
	sortableContainer,
	sortableElement,
	sortableHandle,
} from "react-sortable-hoc";
import { showNotification } from "../../../shared/showNotification";
import { errorModal } from "../../../shared/errorModal";

const DragHandle = sortableHandle(({ id }) => (
	<i className="fa fa-bars handle-bar" aria-hidden="true">
		&nbsp;&nbsp; {id}
	</i>
));

const SortableItem = sortableElement(({ onchange, elem, idx, ondelete }) => (
	<li className="list-inline-item mb20 ">
		<div
			className="hidden-embed-image"
			style={{
				"background-image":
					"url(" + axios.defaults.baseURL + elem.path + elem.filename + ")",
			}}
		>
			<span
				className="close"
				onClick={() => {
					ondelete(idx, 2);
				}}
			>
				<i className="fa fa-times"></i>
			</span>
			<DragHandle id={idx + 1} />
		</div>
		{/* <div className="" style="background-image:url()"></div> */}
	</li>
));

const SortableContainer = sortableContainer(({ children }) => {
	return <ul className="list-inline">{children}</ul>;
});
const UploadImage = ({
	artistDetails,
	AudioDelete,
	album_name,
	dimension_id,
	items,
	isImage,
	type,
	onupload,
	save,
	onSortEnd,
	AudioNameChange,
}) => {
	const [state, setState] = useState({
		file: [],
	});

	const handleInputChange = (e) => {
		var temp = [];
		for (var i = 0; i < e.target.files.length; i++) {
			// Check if the file type is allowed before adding it to the temp array
			if (isFileTypeAllowed(e.target.files[i])) {
				temp.push(e.target.files[i]);
			} else {
				// Optionally, you can provide feedback to the user about invalid file types
				errorModal("Invalid file type")
				return;
			}
		}
		var returndata = {
			hidden_dimension_id: dimension_id,
			type: type,
			files: temp,
		};
		onupload(returndata);
	};

	const isFileTypeAllowed = (file) => {
		// Define allowed file types
		const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
	
		// Check if the file type is included in the allowed types
		return allowedTypes.includes(file.type);
	};

	const upload = () => {
		return;
		if (state.file.length == 0) {
			return false;
		}
		var returndata = {
			hidden_dimension_id: dimension_id,
			type: type,
			files: state.file,
		};
		onupload(returndata);
	};

	useEffect(() => {
		setDataToState("albumData", artistDetails);
	}, []);

	const setDataToState = (ToState, fromVariable) => {
		setState((oldState) => ({
			...oldState,
			[ToState]: fromVariable,
		}));
	};
	const deleteMember = (idx) => {
		var imageFiles = state.file;
		imageFiles.splice(idx, 1);
		setState((oldState) => ({
			...oldState,
			file: imageFiles,
		}));
	};

	return (
		<div>
			<div className="row mb30 upload-wrapper">
				<div className="col-md-8 rpadding border-right">
					<div className="card">
						<div className="card-header">
							<div className="row align-items-center">
								<div className="col-md-6">
									{isImage && (
										<input
											className="form-control uploadFile"
											type="file"
											onChange={items.items && items.items.length > 0 ? "" : handleInputChange}
											accept=".jpg, .jpeg, .png, .gif"
										/>
									)}
									{!isImage && (
										<input
											className="form-control uploadFile"
											type="file"
											onChange={handleInputChange}
											multiple
											accept=".jpg, .jpeg, .png, .gif"
										/>
									)}
								</div>
								<div className="col-md-6">
									<div className="option-list pull-right">
										<ul className="list-inline mb0">
											{type == 3 && (
												<li className="list-inline-item">
													<a
														onClick={() => {
															save(3);
														}}
														className="btn-agree text-white text-uppercase"
													>
														Save
													</a>
												</li>
											)}
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="card-body bg-white pd15">
							<div className="upload-wrapper-conent">
								<div className="row mb10">
									<div className="col-md-12">
										{state.file && (
											<div className="">
												<a>
													<SortableContainer
														axis="xy"
														onSortEnd={onSortEnd}
														useDragHandle
													>
														{items && items.items &&
															items.items.map((elem, index) => (
																<SortableItem
																	key={`item-${index}`}
																	index={index}
																	idx={index}
																	onchange={AudioNameChange}
																	elem={elem}
																	ondelete={AudioDelete}
																/>
															))}
													</SortableContainer>
												</a>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-4 lpadding">
					<div className="upload-content-detail pd20 bg-white clearfix">
						{isImage && (
							<h2 className="text-uppercase din-font mb10">
								Upload 1 Image To:
							</h2>
						)}

						{!isImage && (
							<h2 className="text-uppercase din-font mb10">
								Upload Images To:
							</h2>
						)}
						<div className="form-group">
							<input
								value={album_name}
								readOnly
								type="text"
								name=""
								className="form-control"
								placeholder="Dalbum Title"
							/>
						</div>
						<div className="album-specification">
							<h3 className="text-uppercase">SPECS: </h3>
							<h4>Formats accepted: JPG, PNG or GIF</h4>
							<div className="spacer20"></div>
							<h4>Minimum Resolution: TBD</h4>
							<h4>Maximum File Size: TBD</h4>
							<div className="spacer20"></div>
							{/* <h4>For more information on ISRC <br />numbers, visit:</h4>
                                <a className="text-orange brekText">http://www.nationwidebarcode.com/purchase-barcodes/barcodes-for-music/</a> */}
							{/* <button type="button" onClick={() => { upload() }} className="btn-save text-uppercase pull-right mt30">Upload and Continue</button> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UploadImage;
